import { cmsBaseUrl, TeamData } from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsTeamsUrl: string = cmsApiUrl + "/team";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let teamsList: TeamData[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetTeamsFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsTeamsUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      teamsList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSTeams: (token: string) => Promise<boolean> =
  GetTeamsFromCMS;

async function RemoveTeamsFromCMS(id: number, token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsTeamsUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSTeams: (id: number, token: string) => Promise<boolean> =
  RemoveTeamsFromCMS;

async function InsertTeamsFromCMS(
  team: TeamData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsTeamsUrl, {
    method: "POST",
    body: JSON.stringify({ teamData: team }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSTeams: (team: TeamData, token: string) => Promise<number> =
  InsertTeamsFromCMS;

async function ModifyTeamsFromCMS(
  team: TeamData,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsTeamsUrl, {
    method: "PUT",
    body: JSON.stringify({ teamData: team, id: team.id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const UpdateCMSTeams: (
  team: TeamData,
  token: string
) => Promise<boolean> = ModifyTeamsFromCMS;

function getTeams(): TeamData[] | undefined {
  if (teamsList === undefined) return undefined;

  return teamsList;
}
export const GetTeams: () => TeamData[] | undefined = getTeams;

function getTeamsByClubID(id: number): TeamData[] | undefined {
  if (teamsList === undefined) return undefined;
  
  return teamsList.filter((t) => t.club_id.toString() === id.toString());
}
export const GetTeamsByClubID: (id: number) => TeamData[] | undefined =
  getTeamsByClubID;
