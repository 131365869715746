import { cmsBaseUrl } from "./Icms";
//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsFileUrl: string = cmsApiUrl + "/file";

export const cmsStorageURL: string = cmsBaseUrl + "/storage/";

async function RemoveFileFromCMS(id: number, token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsFileUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSFile: (id: number, token: string) => Promise<boolean> =
  RemoveFileFromCMS;

async function InsertFileFromCMS(
  image: File,
  name: string,
  token: string
): Promise<number> {
  var formData = new FormData();
  formData.append("file", image);
  formData.append("name", name);
  //Lanzamos la petición
  let response = await fetch(cmsFileUrl, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSFile: (
  image: File,
  name: string,
  token: string
) => Promise<number> = InsertFileFromCMS;
