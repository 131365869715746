import React, { useEffect, useState } from "react";
import logo from "../images/Logo-SF-Blanc.png";
import club_icon from "../images/icons/clubs-icon.svg";
import logout_icon from "../images/icons/logout-icon.svg";
import hamburguer_icon from "../images/icons/hamburger-icon.svg";

import c_tecnico_icon from "../images/icons/c_tecnico.svg";
import calendar_icon from "../images/icons/calendar.svg";
import camiseta_icon from "../images/icons/camiseta.svg";
import captacion_icon from "../images/icons/captacion.svg";
import metodologia_icon from "../images/icons/metodologia.svg";
import strategy_icon from "../images/icons/strategy.svg";
import team_icon from "../images/icons/team.svg";
import tutorial_icon from "../images/icons/tutorial.svg";

import { NavLink } from "react-router-dom";
import useStore from "../store";
import "../styles/style.css";
import { useLocation } from "react-router-dom";
import { routes } from "../routes";
import { useTranslation } from "react-i18next";
import DataField from "./DataField";
import { CMSChangePass } from "../CMSUtils/LoginCalls";

const Navbar = (props: any) => {
  const { t, i18n } = useTranslation();
  const showMenu = useStore((state) => state.showMenu);
  const toggleShowMenu = useStore((state) => state.toggleShowMenu);
  const setModalContent = useStore((state) => state.setModalContent);
  const cambiosSinGuardar = useStore((state) => state.cambiosSinGuardar);
  const loggedUser = useStore((state) => state.loggedUser);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const setToken = useStore((state) => state.setToken);
  const token = useStore((state) => state.token);
  const seasons = useStore((state) => state.seasons);
  const selectedSeason = useStore((state) => state.selectedSeason);
  const setSelectedSeason = useStore((state) => state.setSelectedSeason);
  const [locationChange, setLocationChange] = useState(false);
  let location = useLocation().pathname.substring(1);
  const filteredClub = useStore((state) => state.filteredClub);
  let oldPassword:string, newPassword:string, newPassword2 : string;

  useEffect(() => {
    setLocationChange(!locationChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLocation().pathname]);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  function setCookie(name: string, val: string) {
    const value = val;

    // Set it
    document.cookie = name + "=" + value + "; path=/";
  }

  function modifiedPass(){
    return(
      <React.Fragment>
        <div className="py-4">
          <div className="pb-5 px-5 text-center">{ t("01122021.modified_password")}</div>
        </div>
      </React.Fragment>)
  }

  function getCookie(name: string): string | null {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(";")
        .map((c) => c.trim())
        .filter((cookie) => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map((cookie) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }
  async function changePassApi() {
    const response = await CMSChangePass(token, "", oldPassword, newPassword).catch(
      (r) => undefined
    );

    if (
      response &&
      response.message === "Successfully updated password!"
    ) {
      setModalContent(modifiedPass(), t("01122021.changed_password"));
    }
  }

  function changePass(showError:boolean){
    return(
      <React.Fragment>
        <div className="py-4">
          <div className="pb-5 px-5 text-center">{ t("01122021.password_change_text")}</div>
            <DataField
              label={t("01122021.old_pass")}
              data={oldPassword}
              type="password"
              onChange={(e) => {
                oldPassword = e;
              }}
            />
            <DataField
              label={t("01122021.new_pass")}
              data={newPassword}
              type="password"
              onChange={(e) => {
                newPassword = e;
                if(showError){
                  setModalContent(changePass(false),t("01122021.change_password"));
                }
              }}
            />
            <DataField
              label={t("01122021.new_pass_repeat")}
              data={newPassword2}
              type="password"
              onChange={(e) => {
                newPassword2 = e;
                if(showError){
                  setModalContent(changePass(false),t("01122021.change_password"));
                }
              }}
            />
          </div>
          {showError && <p className="text-center text-xs text-red-500">{t("01122021.password_repeat_error")}</p>}
          <div className="py-4 mb-auto">
          <button
            className="flex bg-sf-mediumblue hover:bg-sf-lightblue  m-auto py-2 px-10 rounded-full text-white"
            onClick={() => {if(newPassword === newPassword2){changePassApi()}else{
              setModalContent(changePass(true),t("01122021.change_password"));
            }}}
          >
            {t("01122021.password_change_button")}
          </button>
        </div>
      </React.Fragment>
    );
  }

  function goTo(direccion: string) {
    if (
      (location.includes("/create") || location.includes("/detail")) &&
      cambiosSinGuardar
    ) {
      setModalContent(exitModal(direccion));
    } else {
      props.history.push({
        pathname: direccion,
      });
    }
  }

  function exitModal(direccion: string) {
    return (
      <React.Fragment>
        <h2 className="text-center font-bold text-sf-darkblue text-xl pb-4">
        {t("01122021.topbar.salir_title")}
        </h2>
        <p className="text-center">{t("01122021.topbar.perder_modificacion")}</p>
        <p className="py-2 text-center">{t("01122021.topbar.confirmar_salir")}</p>
        <div className="flex pt-4 justify-center">
          <button
            className={
              "flex text-sf-mediumblue  bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full  mr-2"
            }
            onClick={() => {
              setCambiosSinGuardar(false);
              setModalContent(undefined);
              props.history.push({
                pathname: direccion,
              });
            }}
          >
            <p className="pl-2 self-center">{t("01122021.topbar.salir_no_guardar")}</p>
          </button>
          <button
            className={
              "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
            }
            onClick={() => {
              setModalContent(undefined);
            }}
          >
            <p className="pl-2 self-center">{t("01122021.topbar.salir_cancelar")}</p>
          </button>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="flex flex-col w-full h-full roboto">
        <div className="w-full h-20 bg-sf-backgroundimg flex absolute justify-between z-20">
          <img
            src={hamburguer_icon}
            alt="Hambuerguer"
            className="h-full p-5 self-center cursor-pointer"
            onClick={() => toggleShowMenu()}
          />
          <h1 className="self-center text-white text-2xl uppercase">
            {t(
              `ui.titles.${
                routes[
                  location.split("/")[0] +
                    (location.split("/")[1] &&
                    location.split("/")[1] !== "detail" &&
                    location.split("/")[1] !== "create"
                      ? "_" + location.split("/")[1]
                      : "")
                ]?.title
              }`
            )}
          </h1>
          <img
            src={logo}
            alt="SmartFootball Logo"
            className="h-full p-5 mr-10 self-center object-contain"
          />
        </div>
        <nav
          className={
            "absolute h-full pt-40 transition-all duration-300 ease-in-out z-10 " +
            (showMenu ? "w-menu" : "w-menu-collapsed")
          }
        >
          <div className="h-full bg-white border-r-4 border-gray-400 overflow-y-auto overflow-x-hidden">
            <div
              className={
                "w-full border border-l-0 border-r-0 border-t-0 border-b-1 border-sf-mediumblue px-2 py-4 flex border-opacity-25" +
                (!showMenu
                  ? " hover:bg-sf-extralightblue transition-colors duration-300 ease-in-out cursor-pointer"
                  : "")
              }
            >
              <div
                className={
                  "relative flex self-center w-full " +
                  (!showMenu ? "pl-3" : "pl-6")
                }
              >
                {loggedUser && (
                  <div
                    className={
                      "w-full pr-12 " +
                      (!showMenu ? "hidden" : "flex flex-col from-hidden")
                    }
                  >
                    <p className="text-xl truncate">{loggedUser.name}</p>
                    <p className="text-sm text-gray-700 truncate">
                      {loggedUser.email}
                    </p>
                    <p className="text-xs w-full pt-2 text-sf-mediumblue underline cursor-pointer overflow-ellipsis" onClick={()=>setModalContent(changePass(false), t("01122021.change_password"))}>
                      {t("01122021.change_password")}
                    </p>
                    <div className="w-2/3 pt-3">
                      <DataField
                        data={
                          getCookie("lang")
                            ? getCookie("lang")
                            : navigator.language === "es" ||
                              navigator.language === "en" ||
                              navigator.language === "ru"
                            ? navigator.language
                            : "en"
                        }
                        options={[
                          { text: "Español", value: "es" },
                          { text: "English", value: "en" },
                          { text: "русский", value: "ru" },
                        ]}
                        onChange={(v) => {
                          changeLanguage(v);
                          setCookie("lang", v);
                        }}
                        type="select"
                      />
                    </div>
                    {(filteredClub || loggedUser.role.name === "Club") &&
                      seasons.length > 0 && (
                        <div className="pt-3">
                          <DataField
                            data={selectedSeason}
                            flexible={true}
                            placeholder="Temporada actual"
                            options={(seasons as any[]).map((s) => {
                              return { value: s.id, text: s.name };
                            })}
                            onChange={(v) => setSelectedSeason(v)}
                            type="select"
                          />
                        </div>
                      )}
                  </div>
                )}
                <img
                  src={logout_icon}
                  alt="logout"
                  className={
                    "my-auto h-8 absolute right-0 top-0 bottom-0 mr-2 transition-transform  transform rotate-180 self-center duration-100 ease-in-out " +
                    (!showMenu
                      ? "left-0 mx-auto scale-100"
                      : "cursor-pointer hover:scale-110")
                  }
                  onClick={() => {
                    const date = new Date();
                    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
                    document.cookie =
                      "tk=; expires=" + date.toUTCString() + "; path=/";
                    setToken(null);
                  }}
                />
              </div>
            </div>
            <ul>
              {loggedUser?.role.name === "Admin" && (
                <NavLink
                  to="/clubs"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/clubs");
                    e.preventDefault();
                  }}
                >
                  <li className="pl-6 flex py-2 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                    <img src={club_icon} className="h-8 pt-1" alt="Club" />
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.club")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer") && (
                <NavLink
                  to="/equipos"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/equipos");
                    e.preventDefault();
                  }}
                >
                  <li
                    className={
                      "flex py-2 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out pl-6"
                    }
                  >
                    <img src={team_icon} className="h-8 pt-1" alt="Club" />
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.team")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer") && (
                <NavLink
                  to="/jugadores"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/jugadores");
                    e.preventDefault();
                  }}
                >
                  <li className="pl-6 flex py-2 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                    <img src={camiseta_icon} className="h-8 pt-1" alt="Club" />
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.player")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator") && (
                <NavLink
                  to="/entrenadores"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/entrenadores");
                    e.preventDefault();
                  }}
                >
                  <li className="pl-6 flex py-2 pb-4 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                    <img src={c_tecnico_icon} className="h-8 pt-1" alt="Club" />
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.staff")}
                    </p>
                  </li>
                </NavLink>
              )}
              {showMenu &&
                (loggedUser?.role.name === "Admin" ||
                  loggedUser?.role.name === "Club" ||
                  loggedUser?.role.name === "Coordinator" ||
                  loggedUser?.role.name === "Trainer" ||
                  loggedUser?.role.name === "Goal_Bio_Trainer") && (
                  <li className="pl-6 flex py-2 pb-1 border-t-2 border-gray-200 transition-bg duration-300 ease-in-out">
                    <img src={strategy_icon} className="h-8 pt-1" alt="Club" />
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.experience")}
                    </p>
                  </li>
                )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer") && (
                <NavLink
                  to="/experiencias/globales"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/experiencias/globales");
                    e.preventDefault();
                  }}
                >
                  <li
                    className={
                      "text-sm flex py-1 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out" +
                      (showMenu ? " pl-16" : " pl-6 border-t-2 border-gray-200")
                    }
                  >
                    {!showMenu && (
                      <img
                        src={strategy_icon}
                        className="h-8 pt-1"
                        alt="Club"
                      />
                    )}
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.experience_global")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer") && (
                <NavLink
                  to="/experiencias/porteros"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/experiencias/porteros");
                    e.preventDefault();
                  }}
                >
                  <li
                    className={
                      "text-sm flex py-1 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out" +
                      (showMenu ? " pl-16" : " pl-6")
                    }
                  >
                    {!showMenu && (
                      <img
                        src={strategy_icon}
                        className="h-8 pt-1"
                        alt="Club"
                      />
                    )}
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.experience_goalkeeper")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer") && (
                <NavLink
                  to="/experiencias/biologicas"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/experiencias/biologicas");
                    e.preventDefault();
                  }}
                >
                  <li
                    className={
                      "text-sm flex py-1 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out" +
                      (showMenu ? " pl-16" : " pl-6")
                    }
                  >
                    {!showMenu && (
                      <img
                        src={strategy_icon}
                        className="h-8 pt-1"
                        alt="Club"
                      />
                    )}
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.experience_bio")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer") && (
                <NavLink
                  to="/experiencias/audiovisuales"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/experiencias/audiovisuales");
                    e.preventDefault();
                  }}
                >
                  <li
                    className={
                      "text-sm flex pb-4 py-1 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out" +
                      (showMenu ? " pl-16" : " pl-6")
                    }
                  >
                    {!showMenu && (
                      <img
                        src={strategy_icon}
                        className="h-8 pt-1"
                        alt="Club"
                      />
                    )}
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.experience_audiovisual")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer" ||
                loggedUser?.role.name === "Player") && (
                <NavLink
                  to="/calendario"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/calendario");
                    e.preventDefault();
                  }}
                >
                  <li className="pl-6 flex py-2 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                    <img src={calendar_icon} className="h-8 pt-1" alt="Club" />
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.calendar")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer" ||
                loggedUser?.role.name === "Player") && showMenu &&(
                <NavLink
                  to="/eventos"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/eventos");
                    e.preventDefault();
                  }}
                >
                  <li className="text-sm pl-16 flex py-1 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.event")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer" ||
                loggedUser?.role.name === "Player") && showMenu && (
                <NavLink
                  to="/objetivos"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/objetivos");
                    e.preventDefault();
                  }}
                >
                  <li className="text-sm pl-16 flex py-1 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.weekly_obj")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer" ||
                loggedUser?.role.name === "Player") && showMenu && (
                <NavLink
                  to="/entrenamiento"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/entrenamiento");
                    e.preventDefault();
                  }}
                >
                  <li className="text-sm pl-16 flex py-1 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.training")}
                    </p>
                  </li>
                </NavLink>
              )}
              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Coordinator" ||
                loggedUser?.role.name === "Trainer" ||
                loggedUser?.role.name === "Goal_Bio_Trainer" ||
                loggedUser?.role.name === "Player") && showMenu &&(
                <NavLink
                  to="/partido"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/partido");
                    e.preventDefault();
                  }}
                >
                  <li className="text-sm pl-16 flex pb-4 py-1 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.match")}
                    </p>
                  </li>
                </NavLink>
              )}

              {(loggedUser?.role.name === "Admin" ||
                loggedUser?.role.name === "Club" ||
                loggedUser?.role.name === "Scouter") && (
                <NavLink
                  to="/captacion"
                  activeClassName="active"
                  onClick={(e) => {
                    goTo("/captacion");
                    e.preventDefault();
                  }}
                >
                  <li className="pl-6 flex py-2 border-t-2 border-gray-200 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                    <img src={captacion_icon} className="h-8 pt-1" alt="Club" />
                    <p
                      className={
                        "self-center pl-6" +
                        (!showMenu ? " hidden" : " from-hidden")
                      }
                    >
                      {t("menu.watched")}
                    </p>
                  </li>
                </NavLink>
              )}
              <NavLink
                to="/metodologia"
                activeClassName="active"
                onClick={(e) => {
                  goTo("/metodologia");
                  e.preventDefault();
                }}
              >
                <li className="pl-6 flex py-2 border-t-2 border-gray-200 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                  <img src={metodologia_icon} className="h-8 pt-1" alt="Club" />
                  <p
                    className={
                      "self-center pl-6" +
                      (!showMenu ? " hidden" : " from-hidden")
                    }
                  >
                    {t("menu.methodology")}
                  </p>
                </li>
              </NavLink>
              <NavLink
                to="/tutorial"
                activeClassName="active"
                onClick={(e) => {
                  goTo("/tutorial");
                  e.preventDefault();
                }}
              >
                <li className="pl-6 flex py-2 pb-4 hover:bg-sf-extralightblue cursor-pointer transition-bg duration-300 ease-in-out">
                  <img src={tutorial_icon} className="h-8 pt-1" alt="Club" />
                  <p
                    className={
                      "self-center pl-6" +
                      (!showMenu ? " hidden" : " from-hidden")
                    }
                  >
                    {t("menu.tutorial")}
                  </p>
                </li>
              </NavLink>
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
