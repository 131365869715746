import React, { useState } from "react";
import add_icon from "../../images/icons/add-icon.svg";
import minus_icon from "../../images/icons/papelera-icon.svg";
import { PersonData } from "../../CMSUtils/Icms";
import DataField from "../DataField";
import { useTranslation } from "react-i18next";
export const PersonDetail: React.FC<{
  person: any;
  required?: boolean;
  tipo?: string;
  hasTipo?: boolean;
  hasLang?: boolean;
  editable?: boolean;
  onUpdate: (person: PersonData | undefined, tipo: string | undefined) => void;
}> = ({
  person,
  required = false,
  onUpdate,
  tipo,
  hasTipo = false,
  hasLang = false,
  editable = true,
}) => {
  const { t } = useTranslation();
  //let initialData = Object.assign({}, person);

  if (required && !person) {
    person = {} as PersonData;
  }
  const [persona, setPersona] = useState(person);

  function crearPersona() {
    if(hasTipo)tipo = "";
    setPersona({} as PersonData);
    onUpdate(persona,tipo);
  }

  function borrarPersona() {
    if(hasTipo)tipo = "";
    setPersona(undefined);
    onUpdate(undefined, undefined);
  }

  return (
    <div className={"flex flex-col p-6"}>
      <div className={"p-2 px-4 h-full"}>
        {persona && (
          <React.Fragment>
            <div className="flex py-3 flex-col">
              <DataField
                disabled={!editable}
                data={persona.name}
                label={t("person.name")}
                onChange={(e) => {
                  persona.name = e;
                  onUpdate(persona, tipo);
                }}
                required
              />
            </div>
            {hasTipo && (
              <div className="flex py-3 flex-col">
                <DataField
                  disabled={!editable}
                  data={tipo}
                  label={t("01122021.parentesco.title")}
                  placeholder={t("01122021.parentesco.desc")}
                  onChange={(e) => {
                    tipo = e;
                    onUpdate(persona, e);
                  }}
                  required
                />
              </div>
            )}
            <div className="flex py-3 flex-col">
              <DataField
                disabled={!editable}
                data={persona.email}
                label={t("person.email")}
                onChange={(e) => {
                  persona.email = e;
                  onUpdate(persona, tipo);
                }}
                required
              />
            </div>
            <div className="flex py-3 flex-col">
              <DataField
                disabled={!editable}
                data={persona.phone_number}
                label={t("person.phone")}
                maxLength={20}
                onChange={(e) => {
                  persona.phone_number = e;
                  onUpdate(persona, tipo);
                }}
                required
              />
            </div>
            {hasLang && (
              <div className="flex py-3 flex-col">
                <DataField
                  disabled={!editable}
                  data={persona.languages}
                  label={t("person.lang")}
                  type="country"
                  placeholder={t("person.lang_placeholder")}
                  onChange={(e) => {
                    persona.languages = e;
                    onUpdate(persona, tipo);
                  }}
                  required
                />
              </div>
            )}

            {/* <div className="flex py-3 flex-col">
            <DataField
                    data={person.name}
                    label="Comentarios"
                    onChange={(e) => (person.name = e)}
                    required={required}
                  />
        </div> */}
          </React.Fragment>
        )}
        {editable && (
          <div className={"h-10 flex" + (persona ? "" : " justify-center")}>
            {persona && !required && (
              <React.Fragment>
                <button
                  className={
                    "flex bg-white hover:bg-gray-200 py-1 pr-4 pl-2 rounded-full text-black border border-1 border-gray-400 mr-2" +
                    (required ? " hidden" : "")
                  }
                  onClick={() => borrarPersona()}
                >
                  <img
                    src={minus_icon}
                    alt="Añadir"
                    className="h-6 transform scale-50 self-center"
                  />
                  <p className="self-center pl-1">{t("person.delete")}</p>
                </button>
              </React.Fragment>
            )}

            {!persona && (
              <button
                className={
                  "flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
                }
                onClick={() => crearPersona()}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50 self-center"
                  style={{ filter: "invert()" }}
                />
                <p className="self-center pl-1">{t("person.add")}</p>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
