import React from "react";
import { useTranslation } from "react-i18next";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { ClubData } from "../../CMSUtils/Icms";
import ver_icon from "../../images/icons/ver-icon.svg";
import club_icon from "../../images/icons/clubs-icon.svg";

const ClubItem: React.FC<{
  onCheck: (e: any) => void;
  onClick: () => void;
  onFilter: () => void;
  datos: ClubData;
  filtro: number | undefined;
  index: number;
}> = ({ onCheck, onClick, onFilter, datos, filtro, index }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      key={index}
      className={
        "w-full cursor-pointer font-bold " +
        ((index + 1) % 2 === 0
          ? " bg-f2f2f2 hover:bg-gray-500"
          : "bg-white text-gray-800 hover:bg-gray-200")
      }
    >
      <div className="w-full xl:w-3/4 flex flex-row mx-auto py-2">
        <div className="w-1/12 flex flex-row px-1 self-center">
          <input
            type="checkbox"
            name="select"
            value="select"
            className="cursor-pointer my-auto transform mx-2"
            onChange={(e) => onCheck(e)}
            onClick={(e) => e.stopPropagation()}
            //checked={clubsMarcados.includes(club.id) || false}
          />
          {datos.image && (
            <img
              src={cmsStorageURL + datos.image?.file}
              alt="placeholder"
              style={{ maxWidth: "35px", maxHeight: "35px", margin: "auto" }}
            />
          )}
          {!datos.image && (
            <img
              src={club_icon}
              className="p-1 bg-gray-500 opacity-25"
              alt="placeholder"
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                margin: "auto",
              }}
            />
          )}
        </div>
        <div className="w-3/12 px-1 self-center">
          <p className="truncate">{datos.entity_name}</p>
          <p className="text-xs text-gray-600">{datos.entity_abr}</p>
        </div>
        <div className="w-1/12 px-1 self-center">
          {datos.entity_country_code}
        </div>
        <div
          className={
            "w-3/12 truncate px-1 self-center" +
            (datos.entity_contact1 &&
            datos.entity_contact1.name &&
            datos.entity_contact1.email
              ? " flex flex-col"
              : "")
          }
        >
          <p className="truncate">
            {datos.entity_contact1 ? datos.entity_contact1.name : ""}
          </p>
          <p className="truncate text-xs text-gray-600">
            {datos.entity_contact1 ? datos.entity_contact1.email : ""}
          </p>
        </div>
        <div
          className={
            "w-2/12 self-center px-1" +
            (new Date(datos.entity_contract_end) < new Date(Date.now())
              ? " text-red-700 font-bold"
              : "")
          }
        >
          {" "}
          {datos.entity_contract_start
            ? new Date(datos.entity_contract_start)
                .toLocaleString()
                .split(" ")[0]
            : "/"}{" "}
          -{" "}
          {datos.entity_contract_end
            ? new Date(datos.entity_contract_end).toLocaleString().split(" ")[0]
            : "/"}
        </div>
        <div
          className={
            "w-1/12 px-1 self-center " +
            (datos.active ? "text-green-600" : "text-red-600")
          }
        >
          {datos.active
            ? t("14052021.club.activo")
            : t("14052021.club.inactivo")}
        </div>
        <div className="w-1/12 px-1 self-center">
          <button
            className={
              (filtro === datos.id
                ? "bg-sf-mediumblue hover:bg-sf-lightblue text-white"
                : "bg-white hover:bg-sf-lightblue text-black border border-1 border-gray-500") +
              " flex py-2 pr-4 pl-2 rounded-full mr-2 self-center"
            }
            onClick={(e) => {
              e.stopPropagation();
              onFilter();
            }}
          >
            <img
              src={ver_icon}
              alt="Filtrar"
              className="h-2 transform self-center"
              style={{ filter: "invert()" }}
            />
            <p className="pl-2 self-center pr-2 text-xs">
              {t("14052021.club.ver")}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClubItem;
