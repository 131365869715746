import React, { useState } from "react";
import logo from "../images/Logo-SF-Gris.png";
import useStore from "../store";
import DataField from "../components/DataField";
import { GetCMSUsers, LoginCMS, ResetPassCMS } from "../CMSUtils/LoginCalls";
import { useTranslation } from "react-i18next";

interface User {
  name: string;
  password: string;
  token: string | null;
}
function Login() {
  const [userData, setUserData] = useState<User>({ name: "", token: "", password: "" });
  const token = useStore((state) => state.setToken);
  const setLoggedUser = useStore((state) => state.setLoggedUser);
  const [loginResult, setLoginResult] = useState(undefined);
  const setModalContent = useStore((state) => state.setModalContent);
let recoveryMail : string = "";
  const { t } = useTranslation();

  function setCookie(name: string, val: string) {
    const value = val;
    document.cookie = name + "=" + value + "; path=/";
  }

  
  async function resetPass() {
    const response = await ResetPassCMS(recoveryMail).catch(
      (r) => undefined
    );

    if (
      response &&
      response.hasOwnProperty("status_code") &&
      response.status_code === 200
    ) {
      setModalContent(confirmMail(), t("01122021.reseted_title"));
    }
  }

  async function login() {
    const response = await LoginCMS(userData.name, userData.password).catch(
      (r) => setLoginResult(r.toString())
    );

    if (
      response &&
      response.hasOwnProperty("status_code") &&
      response.status_code === 200
    ) {
      token(response.token_type + " " + response.access_token);
      setCookie("tk", response.token_type + " " + response.access_token);
      const userData = await GetCMSUsers(
        response.token_type + " " + response.access_token
      );
      setLoggedUser(userData);
    }else{
      setLoginResult(t("01122021.wrong_password"));
      userData.password = "";
      setUserData(Object.assign({}, userData));
    }
  }

  function confirmMail(){
    return(
      <React.Fragment>
        <div className="py-4">
          <div className="pb-5 px-5 text-center">{ t("01122021.reseted_text")}</div>
        </div>
      </React.Fragment>)
  }

  function showRecovery(){
    return(
      <React.Fragment>
        <div className="py-4">
          <div className="pb-5 px-5 text-center">{ t("01122021.password_recovery_text")}</div>
            <DataField
              label={t("14052021.login.correo")}
              data={recoveryMail}
              onChange={(e) => {
                recoveryMail = e;
              }}
            />
          </div>
          <div className="py-4 mb-auto">
          <button
            className="flex bg-sf-mediumblue hover:bg-sf-lightblue  m-auto py-2 px-10 rounded-full text-white"
            onClick={() => resetPass()}
          >
            {t("01122021.password_recovery_button")}
          </button>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div
      className="bg-white w-1/2 absolute left-0 right-0 m-auto py-16 px-32 top-0 bottom-0 flex flex-col"
      style={{ height: "75%" }}
    >
      <img src={logo} className="w-5/6 mx-auto" alt="logo" />
      <div className="self-center w-full flex flex-col h-full">
        <div className="w-full my-auto">
          <div className="py-4">
            <DataField
              label={t("14052021.login.correo")}
              data={userData.name}
              onChange={(e) => {
                userData.name = e;
                setLoginResult(undefined);
              }}
            />
          </div>
          <div className="py-4">
            <DataField
              label={t("14052021.login.pass")}
              type="password"
              data={userData.password}
              onChange={(e) => {
                userData.password = e;
                setLoginResult(undefined);
              }}
            />
            <div className="text-xs w-full text-right pt-2 text-sf-mediumblue underline cursor-pointer" onClick={()=> setModalContent(showRecovery(), t("01122021.password_recovery_title"))}>{t("14052021.login.forgot")}</div>
          </div>
          {loginResult && (
            <p className="text-center text-xs text-red-500">{loginResult}</p>
          )}
        </div>
        {/*
      <label>Username:</label>
      <input type="text" onChange={(e) => (userData.name = e.target.value)} />
      <label>Password:</label>
      <input
        type="password"
        onChange={(e) => (userData.password = e.target.value)}
      />
      <button onClick={() => login()}>Log in</button>*/}
        <div className="py-4 mb-auto">
          <button
            className="flex bg-sf-mediumblue hover:bg-sf-lightblue  m-auto py-2 px-10 rounded-full text-white"
            onClick={() => login()}
          >
            {t("14052021.login.entrar")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
