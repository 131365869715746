import React, { useState } from "react";
import arrow_icon from "../images/icons/next.svg";

const CollapsablePanel: React.FC<{
  titulo: string;
  colapsado?: boolean;
  multiidioma?: boolean;
  idiomaChange?: (idioma: string) => void;
}> = ({
  children,
  titulo,
  colapsado = true,
  multiidioma = false,
  idiomaChange,
}) => {
  const [collapsed, setCollapsed] = useState(colapsado);
  const [currentLang, setCurrentLang] = useState("ES");
  return (
    <div
      className={
        "mx-auto flex flex-col w-full shadow-md border-gray-500 border-b-2 " +
        (collapsed ? "bg-f2f2f2" : "bg-white")
      }
    >
      <div
        className="h-16 px-6 flex mx-auto border-b-2 border-gray-200 cursor-pointer w-full"
        style={{ maxWidth: "1280px" }}
      >
        <div
          className="h-full flex flex-1"
          onClick={() => setCollapsed(!collapsed)}
        >
          <h1
            className={
              "self-center text-xl font-bold " +
              (collapsed ? "text-gray-800" : "text-sf-mediumblue")
            }
          >
            {titulo}
          </h1>
        </div>
        {multiidioma && idiomaChange && (
          <div className="flex">
            <div
              className={
                "h-full w-24 text-center flex p-2 border-r" +
                (currentLang === "ES" ? " font-bold text-sf-mediumblue" : "")
              }
              onClick={() => {
                idiomaChange("ES");
                setCurrentLang("ES");
              }}
            >
              <p className="self-center w-full text-center">ES</p>
            </div>
            <div
              className={
                "h-full w-24 text-center p-2 flex border-l border-r" +
                (currentLang === "EN" ? " font-bold text-sf-mediumblue" : "")
              }
              onClick={() => {
                idiomaChange("EN");
                setCurrentLang("EN");
              }}
            >
              <p className="self-center w-full text-center">EN</p>
            </div>
            <div
              className={
                "h-full w-24 text-center flex p-2 border-l" +
                (currentLang === "RU" ? " font-bold text-sf-mediumblue" : "")
              }
              onClick={() => {
                idiomaChange("RU");
                setCurrentLang("RU");
              }}
            >
              <p className="self-center w-full text-center">RU</p>
            </div>
          </div>
        )}
        <div
          className="ml-auto my-auto h-10 flex px-4"
          onClick={() => setCollapsed(!collapsed)}
        >
          <img
            src={arrow_icon}
            className={
              "m-auto self-center transform " +
              (collapsed ? "rotate-90" : "-rotate-90")
            }
            alt="minimizar"
            width="20px"
          />
        </div>
      </div>
      <div className={collapsed ? " hidden" : ""}>
        <div className="pt-2 pb-3 mx-auto max-w-generic">{children}</div>
      </div>
    </div>
  );
};

export default CollapsablePanel;
