import React, { useEffect, useState } from "react";
import add_icon from "../../images/icons/add-icon.svg";
import loadingGif from "../../images/loading.gif";

import "../../styles/fa.css";
import "../../styles/style.css";
import exp_icon from "../../images/icons/soccer-field.svg";

import CollapsablePanel from "../CollapsablePanel";
import DataField from "../DataField";
import {
  ExperienceData,
  PlayerEvaluation,
  TrainingData,
  TrainingGuest,
} from "../../CMSUtils/Icms";
import { ExperienceCard } from "../Experience/ExperienceCard";
import useStore from "../../store";
import { GetClubs, LoadCMSClubs } from "../../CMSUtils/ClubCalls";
import { GetTeams, LoadCMSTeams} from "../../CMSUtils/TeamCalls";
import {
  GetAudiovisualExperiences,
  GetBioExperiences,
  GetGlobalExperiences,
  GetGoalkeeperExperiences,
  LoadCMSExperiences,
} from "../../CMSUtils/ExperienceCalls";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import GlobalExperienceDetail from "../Experience/GlobalExperienceDetail";
import { disponibilidad, positions, riesgo } from "../../data/arrays";
import GoalkeeperExperienceDetail from "../Experience/GoalkeeperExperienceDetail";
import AudiovisualExperienceDetail from "../Experience/AudiovisualExperienceDetail";
import { useTranslation } from "react-i18next";

export const TrainingDetail: React.FC<{
  selected: TrainingData;
  editable: boolean;
  validTraining: (value: boolean) => void;
}> = ({ selected, validTraining, editable }) => {
  const { t, i18n } = useTranslation();
  const loggedUser = useStore((state) => state.loggedUser);
  const esPortero = loggedUser?.role.name === "Goal_Bio_Trainer";
  const token = useStore((state) => state.token);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const [listaEquipos, setListaEquipos] = useState<any[]>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [listaJugadores, setListaJugadores] = useState<PlayerEvaluation[]>();
  const [listaJugadoresPRE, setListaJugadoresPRE] = useState<any[]>();
  const [listaJugadoresPOST, setListaJugadoresPOST] = useState<any[]>();
  const [experienciasGlobales, setExperienciasGlobales] = useState<any[]>([]);
  const [experienciasAudiovisuales, setExperienciasAudiovisuales] = useState<
    any[]
  >([]);
  const [experienciasBiologicasPRE, setExperienciasBiologicasPRE] = useState<
    any[]
  >([]);
  const [experienciasBiologicasPOST, setExperienciasBiologicasPOST] = useState<
    any[]
  >([]);
  const [experienciasPorteros, setExperienciasPorteros] = useState<any[]>([]);
  const setModalContent = useStore((state) => state.setModalContent);
  const [listaInvitados, setListaInvitados] = useState<TrainingGuest[]>(
    selected.guests ?? []
  );

  useEffect(() => {
    
    
    

    let isMounted = true;
    fetchData().then(()=>{if(isMounted){
      if(selected.club_id !== 0 && selected.club_id !== undefined && selected.club_id !== null){
        seleccionarClub(selected.club_id, true);
      }else if(loggedUser?.role.name !== "Admin" && (loggedUser as any)?.staff?.club_id){
        selected.club_id = (loggedUser as any)?.staff?.club_id;
        seleccionarClub(selected.club_id, true);
      }
      teamSelected();
      if (!selected.experiences) return;
      setExperienciasGlobales(
        selected.experiences
          .filter((e) => e.type === "Global")
          .map((e) => {
            let exp: any = {};
            exp.experience = GetGlobalExperiences()?.find(
              (exp) => exp.id === e.experience_id
            );
            exp.experience_id = e.experience_id;
            exp.id = e.id;
            exp.type = e.type;
            exp.inv_time = e.inv_time;
            exp.long = e.long;
            exp.obs = e.obs;
            exp.order = e.order;
            exp.player_id = e.player_id;
            exp.ref_time = e.ref_time;
            exp.reps = e.reps;
            exp.series = e.series;
            exp.width = e.width;
            return exp;
          })
      );
      setExperienciasPorteros(
        selected.experiences
          .filter((e) => e.type === "Porteros")
          .map((e) => {
            let exp: any = {};
  
            exp.experience = GetGoalkeeperExperiences()?.find(
              (exp) => exp.id === e.experience_id
            );
            exp.experience_id = e.experience_id;
            exp.id = e.id;
            exp.type = e.type;
            exp.inv_time = e.inv_time;
            exp.long = e.long;
            exp.obs = e.obs;
            exp.order = e.order;
            exp.player_id = e.player_id;
            exp.ref_time = e.ref_time;
            exp.reps = e.reps;
            exp.series = e.series;
            exp.width = e.width;
            return exp;
          })
      );
      setExperienciasAudiovisuales(
        selected.experiences
          .filter((e) => e.type === "Audiovisual")
          .map((e) => {
            let exp: any = {};
  
            exp.experience = GetAudiovisualExperiences()?.find(
              (exp) => exp.id === e.experience_id
            );
            exp.experience_id = e.experience_id;
            exp.id = e.id;
            exp.type = e.type;
            exp.inv_time = e.inv_time;
            exp.long = e.long;
            exp.obs = e.obs;
            exp.order = e.order;
            exp.player_id = e.player_id;
            exp.ref_time = e.ref_time;
            exp.reps = e.reps;
            exp.series = e.series;
            exp.width = e.width;
            return exp;
          })
      );
      setExperienciasBiologicasPRE(
        selected.experiences
          .filter((e) => e.type === "BioPre")
          .map((e) => {
            let exp: any = {};
  
            exp.experience = GetBioExperiences()?.find(
              (exp) => exp.id === e.experience_id
            );
            exp.experience_id = e.experience_id;
            exp.id = e.id;
            exp.type = e.type;
            exp.inv_time = e.inv_time;
            exp.long = e.long;
            exp.obs = e.obs;
            exp.order = e.order;
            exp.player_id = e.player_id;
            exp.ref_time = e.ref_time;
            exp.reps = e.reps;
            exp.series = e.series;
            exp.width = e.width;
            return exp;
          })
      );
      setExperienciasBiologicasPOST(
        selected.experiences
          .filter((e) => e.type === "BioPost")
          .map((e) => {
            let exp: any = {};
  
            exp.experience = GetBioExperiences()?.find(
              (exp) => exp.id === e.experience_id
            );
            exp.experience_id = e.experience_id;
            exp.id = e.id;
            exp.type = e.type;
            exp.inv_time = e.inv_time;
            exp.long = e.long;
            exp.obs = e.obs;
            exp.order = e.order;
            exp.player_id = e.player_id;
            exp.ref_time = e.ref_time;
            exp.reps = e.reps;
            exp.series = e.series;
            exp.width = e.width;
            return exp;
          })
      );
      setExperienciasBiologicasPRE(
        selected.experiences
          .filter((e) => e.type === "BioPre")
          .map((e) => {
            let exp: any = {};
  
            exp.experience = GetBioExperiences()?.find(
              (exp) => exp.id === e.experience_id
            );
            exp.experience_id = e.experience_id;
            exp.id = e.id;
            exp.type = e.type;
            exp.inv_time = e.inv_time;
            exp.long = e.long;
            exp.obs = e.obs;
            exp.order = e.order;
            exp.player_id = e.player_id;
            exp.ref_time = e.ref_time;
            exp.reps = e.reps;
            exp.series = e.series;
            exp.width = e.width;
            return exp;
          })
      );
      setIsLoaded(true);
    }});

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSClubs(token);
    await LoadCMSTeams(token);
    await LoadCMSExperiences(token);
    // setListaExperienciasGlobales(GetGlobalExperiences() as ExperienceData[]);
    // setListaExperienciasAudiovisuales(
    //   GetAudiovisualExperiences() as ExperienceData[]
    // );
    // setListaExperienciasPorteros(
    //   GetGoalkeeperExperiences() as ExperienceData[]
    // );
    // setListaExperienciasBiologicas(GetBioExperiences() as ExperienceData[]);
    return null;
  }

  function getClubNames() {
    let clubs: any[] = [];
    if(GetClubs() && GetClubs()!.length > 0){
      GetClubs()?.map((c) => clubs.push({ value: c.id, text: c.entity_name }));
    }else if(loggedUser?.role?.name !== "Admin" && GetTeams()){
      let teams = GetTeams();
      
      if(teams){
        clubs.push({ value: teams[0].club_id, text: teams[0].club?.entity_name });
      }
    }
    return clubs;
  }

  function saveInvitados() {
    selected.guests = listaInvitados;
    setListaInvitados(listaInvitados.map((i) => i));
  }

  function getGoalkeeperExperiencesWindow(experience: ExperienceData[]) {
    return (
      <React.Fragment>
        <DataField
          data={""}
          placeholder={t("14052021.training.filter")}
          onChange={(e) => {
            let xpList =
              e !== ""
                ? GetGoalkeeperExperiences()?.filter((exp) =>
                    exp.name_es.includes(e)
                  )
                : GetGoalkeeperExperiences();
            if (!xpList) xpList = [];
            setModalContent(getGoalkeeperExperiencesWindow(xpList));
          }}
        />
        <div className="grid gap-3 grid-cols-4 pt-4">
          {experience?.map((e, index) => {
            return (
              <ExperienceCard
                key={index}
                data={e}
                seleccionada={
                  experienciasPorteros.find((ex) =>
                    ex.experience_id ? ex.experience_id === e.id : ex.id === e.id
                  )
                    ? true
                    : false
                }
                onClick={(v) => {
                  if (v) {
                    if (
                      !experienciasPorteros ||
                      experienciasPorteros.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length === 0
                    ) {
                      experienciasPorteros?.push(e);
                    }
                  } else {
                    if (
                      experienciasPorteros &&
                      experienciasPorteros.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length > 0
                    ) {
                      experienciasPorteros?.splice(
                        experienciasPorteros?.findIndex((ex) =>
                          ex.experience_id
                            ? ex.experience_id === e.id
                            : ex.id === e.id
                        ),
                        1
                      );
                    }
                  }

                  setExperienciasPorteros(
                    experienciasPorteros?.map((exp) => exp)
                  );
                  saveExperiences();
                  setModalContent(getGoalkeeperExperiencesWindow(experience));
                }}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  function getAudiovisualExperiencesWindow(experience: ExperienceData[]) {
    return (
      <React.Fragment>
        <DataField
          data={""}
          placeholder={t("14052021.training.filter")}
          onChange={(e) => {
            let xpList =
              e !== ""
                ? GetAudiovisualExperiences()?.filter((exp) =>
                    exp.name_es.includes(e)
                  )
                : GetAudiovisualExperiences();
            if (!xpList) xpList = [];
            setModalContent(getAudiovisualExperiencesWindow(xpList));
          }}
        />
        <div className="grid gap-3 grid-cols-4 pt-4">
          {experience?.map((e, index) => {
            return (
              <ExperienceCard
                key={index}
                data={e}
                seleccionada={
                  experienciasAudiovisuales.find((ex) =>
                    ex.experience_id ? ex.experience_id === e.id : ex.id === e.id
                  )
                    ? true
                    : false
                }
                onClick={(v) => {
                  if (v) {
                    if (
                      !experienciasAudiovisuales ||
                      experienciasAudiovisuales.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length === 0
                    ) {
                      experienciasAudiovisuales?.push(e);
                    }
                  } else {
                    if (
                      experienciasAudiovisuales &&
                      experienciasAudiovisuales.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length > 0
                    ) {
                      experienciasAudiovisuales?.splice(
                        experienciasAudiovisuales?.findIndex((ex) =>
                          ex.experience_id
                            ? ex.experience_id === e.id
                            : ex.id === e.id
                        ),
                        1
                      );
                    }
                  }

                  setExperienciasAudiovisuales(
                    experienciasAudiovisuales?.map((exp) => exp)
                  );
                  saveExperiences();
                  setModalContent(getAudiovisualExperiencesWindow(experience));
                }}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }
  function guidelineCheckedUpdate(experience_id: number, guideline: any) {
    let guidelineDefault = selected.guidelines.find(
      (g) => g.guideline_id === guideline.id && g.type === guideline.type
    );
    if (guidelineDefault) {
      (guidelineDefault as any).checked = guideline.checked;
    } else {
      selected.guidelines.push({
        type: guideline.type,
        guideline: guideline.guideline,
        guideline_id: guideline.id,
        experience_id: experience_id,
        should_add: true,
        checked: guideline.checked,
      } as any);
    }
  }

  function guidelineDestinyUpdate(experience_id: number, guideline: any) {
    let guidelineDefault = selected.guidelines.find(
      (g) => g.guideline_id === guideline.id && g.type === guideline.type
    );

    if (guidelineDefault) {
      (guidelineDefault as any).destiny = guideline.destiny;
    } else {
      selected.guidelines.push({
        type: guideline.type,
        guideline: guideline.guideline,
        guideline_id: guideline.id,
        experience_id: experience_id,
        should_add: true,
        checked: guideline.checked,
        destiny: guideline.destiny
      } as any);
    }
  }

  function guidelineAdd(experience_id: number, guideline: any) {
    let guidelineDefault = selected.guidelines
      .filter((g) => g.should_add === false)
      .find((g) => g.guideline_id?.toString() === guideline.id?.toString());
    if (guidelineDefault) {
      selected.guidelines.splice(
        selected.guidelines.indexOf(guidelineDefault),
        1
      );
    } else {
      selected.guidelines.push({
        type: guideline.type,
        guideline: guideline.guideline,
        guideline_id: guideline.id,
        experience_id: experience_id,
        should_add: true,
        checked: false,
      } as any);
    }
  }

  function guidelineRemove(experience_id: number, guideline: any) {
    let guidelineDefault = selected.guidelines
      .filter((g) => g.should_add === true)
      .find((g) => g.guideline_id === guideline.id);
    if (guidelineDefault) {
      selected.guidelines.splice(
        selected.guidelines.indexOf(guidelineDefault),
        1
      );
    } else {
      selected.guidelines.push({
        type: guideline.type,
        guideline_id: guideline.id,
        experience_id: experience_id,
        should_add: false,
        checked: false,
      } as any);
    }
  }

  function getPREBioExperiencesWindowPerPlayer(
    experience: ExperienceData[],
    player_id: number
  ) {
    let jugador = listaJugadoresPRE?.find((p) => p.id === player_id);

    if (!jugador.experiences) jugador.experiences = [];
    return (
      <React.Fragment>
        <DataField
          data={""}
          placeholder={t("14052021.training.filter")}
          onChange={(e) => {
            let xpList =
              e !== ""
                ? GetBioExperiences()?.filter((exp) => exp.name_es.includes(e))
                : GetBioExperiences();
            if (!xpList) xpList = [];
            setModalContent(
              getPREBioExperiencesWindowPerPlayer(xpList, player_id)
            );
          }}
        />
        <div className="grid gap-3 grid-cols-4 pt-4">
          {experience?.map((e, index) => {
            const exp = jugador.experiences.find((ex: any) =>
              ex.experience_id ? ex.experience_id?.toString() === e.id?.toString() : ex.id?.toString() === e.id?.toString()
            );
            return (
              <ExperienceCard
                key={index}
                data={e}
                personalizable
                extraData={{
                  series: exp?.series ? exp.series : "",
                  reps: exp?.reps ? exp.reps : "",
                  inv_time: exp?.inv_time ? exp.inv_time : "",
                  ref_time: exp?.ref_time ? exp.ref_time : "",
                  width: exp?.width ? exp.width : "",
                  obs: exp?.obs ? exp.obs : "",
                }}
                seleccionada={exp ? true : false}
                onClick={(v) => {
                  if (v) {
                    if (
                      jugador.experiences &&
                      jugador.experiences.filter((ex: any) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length === 0
                    ) {
                      jugador.experiences?.push(e);
                    }
                  } else {
                    if (
                      jugador.experiences &&
                      jugador.experiences.filter((ex: any) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length > 0
                    ) {
                      jugador.experiences?.splice(
                        jugador.experiences?.findIndex((ex: any) =>
                          ex.experience_id
                            ? ex.experience_id === e.id
                            : ex.id === e.id
                        ),
                        1
                      );
                    }
                  }

                  setListaJugadoresPRE(listaJugadoresPRE?.map((exp) => exp));
                  saveExperiences();
                  setModalContent(
                    getPREBioExperiencesWindowPerPlayer(experience, player_id)
                  );
                }}
                onDataChange={(v) => {
                  if (jugador.experiences && exp) {
                    exp.series = v.series;
                    exp.reps = v.reps;
                    exp.inv_time = v.inv_time;
                    exp.ref_time = v.ref_time;
                    exp.width = v.width;
                    exp.obs = v.obs;
                    setListaJugadoresPRE(listaJugadoresPRE?.map((exp) => exp));
                    saveExperiences();
                    setModalContent(
                      getPREBioExperiencesWindowPerPlayer(experience, player_id)
                    );
                  }
                }}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  function getPOSTBioExperiencesWindowPerPlayer(
    experience: ExperienceData[],
    player_id: number
  ) {
    let jugador = listaJugadoresPOST?.find((p) => p.id === player_id);
    if (!jugador.experiences) jugador.experiences = [];
    return (
      <React.Fragment>
        <DataField
          data={""}
          placeholder={t("14052021.training.filter")}
          onChange={(e) => {
            let xpList =
              e !== ""
                ? GetBioExperiences()?.filter((exp) => exp.name_es.includes(e))
                : GetBioExperiences();
            if (!xpList) xpList = [];
            setModalContent(
              getPOSTBioExperiencesWindowPerPlayer(xpList, player_id)
            );
          }}
        />
        <div className="grid gap-3 grid-cols-4 pt-4">
          {experience?.map((e, index) => {
            const exp = jugador.experiences.find((ex: any) =>
            ex.experience_id ? ex.experience_id?.toString() === e.id?.toString() : ex.id?.toString() === e.id?.toString()
          );
            return (
              <ExperienceCard
                key={index}
                data={e}
                personalizable
                extraData={{
                  series: exp?.series ? exp.series : "",
                  reps: exp?.reps ? exp.reps : "",
                  inv_time: exp?.inv_time ? exp.inv_time : "",
                  ref_time: exp?.ref_time ? exp.ref_time : "",
                  width: exp?.width ? exp.width : "",
                  obs: exp?.obs ? exp.obs : "",
                }}
                seleccionada={
                  exp
                    ? true
                    : false
                }
                onClick={(v) => {
                  if (v) {
                    if (
                      jugador.experiences &&
                      jugador.experiences.filter((ex: any) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length === 0
                    ) {
                      jugador.experiences?.push(e);
                    }
                  } else {
                    if (
                      jugador.experiences &&
                      jugador.experiences.filter((ex: any) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length > 0
                    ) {
                      jugador.experiences?.splice(
                        jugador.experiences?.findIndex((ex: any) =>
                          ex.experience_id
                            ? ex.experience_id === e.id
                            : ex.id === e.id
                        ),
                        1
                      );
                    }
                  }
                  
                  setListaJugadoresPOST(listaJugadoresPOST?.map((exp) => exp));
                  saveExperiences();
                  setModalContent(
                    getPOSTBioExperiencesWindowPerPlayer(experience, player_id)
                  );
                }}
                onDataChange={(v) => {
                  if (jugador.experiences && exp) {
                    exp.series = v.series;
                    exp.reps = v.reps;
                    exp.inv_time = v.inv_time;
                    exp.ref_time = v.ref_time;
                    exp.width = v.width;
                    exp.obs = v.obs;
                    setListaJugadoresPOST(listaJugadoresPOST?.map((exp) => exp));
                    saveExperiences();
                    setModalContent(
                      getPOSTBioExperiencesWindowPerPlayer(experience, player_id)
                    );
                  }
                }}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  function getBioExperiencesWindowPRE(experience: ExperienceData[]) {
    return (
      <React.Fragment>
        <DataField
          data={""}
          placeholder={t("14052021.training.filter")}
          onChange={(e) => {
            let xpList =
              e !== ""
                ? GetBioExperiences()?.filter((exp) => exp.name_es.includes(e))
                : GetBioExperiences();
            if (!xpList) xpList = [];
            setModalContent(getBioExperiencesWindowPRE(xpList));
          }}
        />
        <div className="grid gap-3 grid-cols-4 pt-4">
          {experience?.map((e, index) => {
            return (
              <ExperienceCard
                key={index}
                data={e}
                seleccionada={
                  experienciasBiologicasPRE.find((ex) =>
                    ex.experience_id ? ex.experience_id === e.id : ex.id === e.id
                  )
                    ? true
                    : false
                }
                onClick={(v) => {
                  if (v) {
                    if (
                      !experienciasBiologicasPRE ||
                      experienciasBiologicasPRE.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length === 0
                    ) {
                      experienciasBiologicasPRE?.push(e);
                    }
                  } else {
                    if (
                      experienciasBiologicasPRE &&
                      experienciasBiologicasPRE.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length > 0
                    ) {
                      experienciasBiologicasPRE?.splice(
                        experienciasBiologicasPRE?.findIndex((ex) =>
                          ex.experience_id
                            ? ex.experience_id === e.id
                            : ex.id === e.id
                        ),
                        1
                      );
                    }
                  }

                  setExperienciasBiologicasPRE(
                    experienciasBiologicasPRE?.map((exp) => exp)
                  );
                  saveExperiences();
                  setModalContent(getBioExperiencesWindowPRE(experience));
                }}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  function getCustomExperience(experience: ExperienceData) {
    let expAux = Object.assign({}, experience);
    
    selected.guidelines
      .filter((g) => g.should_add === false)
      .forEach((g) => {
        if (g.experience_id === experience.id) {
        let guidelineBorrar = expAux.guidelines.find(
          (guideline) =>
            guideline.guideline_id === g.guideline_id &&
            guideline.type === g.type
        );
        if (guidelineBorrar)
          expAux.guidelines.splice(
            expAux.guidelines.indexOf(guidelineBorrar),
            1
          );
        }
      });
      
    selected.guidelines
      .filter((g) => g.should_add === true)
      .forEach((g) => {
        if (g.experience_id === experience.id) {
          let guidelineNueva = expAux.guidelines.find(
            (guideline) =>
              guideline.guideline_id?.toString() === g.guideline_id?.toString() &&
              guideline.type === g.type
          );
          
          let guidelineName = guidelineNueva?.guideline?.name ?? "";
          if (guidelineNueva) {
            if(!(g as any).guideline)(g as any).guideline = guidelineNueva.guideline;
            expAux.guidelines.splice(
              expAux.guidelines.indexOf(guidelineNueva),
              1
            );
          }
          expAux.guidelines.push({
            experience_id: g.experience_id,
            guideline_id: g.guideline_id,
            type: g.type,
            name: guidelineName,
            checked: g.checked,
            destiny: (g as any).destiny,
            guideline: (g as any).guideline,
          });
        }
      });
    return expAux;
  }

  function getBioExperiencesWindowPOST(experience: ExperienceData[]) {
    return (
      <React.Fragment>
        <DataField
          data={""}
          placeholder={t("14052021.training.filter")}
          onChange={(e) => {
            let xpList =
              e !== ""
                ? GetBioExperiences()?.filter((exp) => exp.name_es.includes(e))
                : GetBioExperiences();
            if (!xpList) xpList = [];
            setModalContent(getBioExperiencesWindowPOST(xpList));
          }}
        />
        <div className="grid gap-3 grid-cols-4 pt-4">
          {experience?.map((e, index) => {
            return (
              <ExperienceCard
                key={index}
                data={e}
                seleccionada={
                  experienciasBiologicasPOST.find((ex) =>
                    ex.experience_id ? ex.experience_id === e.id : ex.id === e.id
                  )
                    ? true
                    : false
                }
                onClick={(v) => {
                  if (v) {
                    if (
                      !experienciasBiologicasPOST ||
                      experienciasBiologicasPOST.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length === 0
                    ) {
                      experienciasBiologicasPOST?.push(e);
                    }
                  } else {
                    if (
                      experienciasBiologicasPOST &&
                      experienciasBiologicasPOST.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length > 0
                    ) {
                      experienciasBiologicasPOST?.splice(
                        experienciasBiologicasPOST?.findIndex((ex) =>
                          ex.experience_id
                            ? ex.experience_id === e.id
                            : ex.id === e.id
                        ),
                        1
                      );
                    }
                  }

                  setExperienciasBiologicasPOST(
                    experienciasBiologicasPOST?.map((exp) => exp)
                  );
                  saveExperiences();
                  setModalContent(getBioExperiencesWindowPOST(experience));
                }}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  function saveExperiences() {
    selected.experiences = [];

    experienciasGlobales.forEach((e) => {
      let expAux = Object.assign({}, e);
      expAux.type = "Global";
      if (!expAux.experience_id) {
        expAux.experience_id = e.id;
      }
      selected.experiences.push(expAux);
    });
    experienciasPorteros.forEach((e) => {
      let expAux = Object.assign({}, e);
      if (!expAux.experience_id) {
        expAux.experience_id = e.id;
      }
      expAux.type = "Porteros";
      selected.experiences.push(expAux);
    });
    experienciasAudiovisuales.forEach((e) => {
      let expAux = Object.assign({}, e);
      if (!expAux.experience_id) {
        expAux.experience_id = e.id;
      }
      expAux.type = "Audiovisual";
      selected.experiences.push(expAux);
    });
    experienciasBiologicasPRE.forEach((e) => {
      let expAux = Object.assign({}, e);
      if (!expAux.experience_id) {
        expAux.experience_id = e.id;
      }
      expAux.type = "BioPre";
      selected.experiences.push(expAux);
    });
    experienciasBiologicasPOST.forEach((e) => {
      let expAux = Object.assign({}, e);
      if (!expAux.experience_id) {
        expAux.experience_id = e.id;
      }
      expAux.type = "BioPost";
      selected.experiences.push(expAux);
    });
    listaJugadoresPRE?.forEach((j) =>
      j.experiences.forEach((e: any) => {
        let expAux = Object.assign({}, e);
        if (!expAux.experience_id) {
          expAux.experience_id = e.id;
        }
        expAux.player_id = j.id;
        expAux.type = "PlayerPRE";
        selected.experiences.push(expAux);
      })
    );
    listaJugadoresPOST?.forEach((j) =>
      j.experiences.forEach((e: any) => {
        let expAux = Object.assign({}, e);
        if (!expAux.experience_id) {
          expAux.experience_id = e.id;
        }
        expAux.player_id = j.id;
        expAux.type = "PlayerPOST";
        selected.experiences.push(expAux);
      })
    );
  }

  function getGlobalExperiencesWindow(experience: ExperienceData[]) {
    return (
      <React.Fragment>
        <DataField
          data={""}
          placeholder="Filtrar"
          onChange={(e) => {
            let xpList =
              e !== ""
                ? GetGlobalExperiences()?.filter((exp) =>
                    exp.name_es.includes(e)
                  )
                : GetGlobalExperiences();
            if (!xpList) xpList = [];
            setModalContent(getGlobalExperiencesWindow(xpList));
          }}
        />
        <div className="grid gap-4 grid-cols-4 pt-4">
          {experience?.map((e, index) => {
            return (
              <ExperienceCard
                key={index}
                data={e}
                seleccionada={
                  experienciasGlobales?.find((ex) =>
                    ex.experience_id ? ex.experience_id === e.id : ex.id === e.id
                  )
                    ? true
                    : false
                }
                onClick={(v) => {
                  if (v) {
                    if (
                      !experienciasGlobales ||
                      experienciasGlobales.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length === 0
                    ) {
                      experienciasGlobales?.push(e);
                    }
                  } else {
                    if (
                      experienciasGlobales &&
                      experienciasGlobales.filter((ex) =>
                        ex.experience_id
                          ? ex.experience_id === e.id
                          : ex.id === e.id
                      ).length > 0
                    ) {
                      experienciasGlobales?.splice(
                        experienciasGlobales?.findIndex((ex) =>
                          ex.experience_id
                            ? ex.experience_id === e.id
                            : ex.id === e.id
                        ),
                        1
                      );
                    }
                  }

                  setExperienciasGlobales(
                    experienciasGlobales?.map((exp) => exp)
                  );
                  saveExperiences();
                  setModalContent(getGlobalExperiencesWindow(experience));
                }}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  function savePlayersData() {
    setListaJugadores(listaJugadores?.map((j) => j));
    selected.players = listaJugadores ?? [];
  }

  function ventanaConfigPlayer(jugador: PlayerEvaluation) {
    // if(jugador.before === undefined || jugador.before === "" || jugador.before === null){
    //   jugador.before = JSON.stringify({obs:"", score:1});
    // }
    // if(jugador.after === undefined || jugador.after === "" || jugador.after === null){
    //   jugador.after = JSON.stringify({obs:"", score:1});
    // }
    return (
      <div className="p-6">
        <table className="table-fixed w-full">
          <tbody style={{ verticalAlign: "top" }}>
            <tr>
              {/*<td className="w-1/2 py-4 px-6">
                <DataField
                  disabled={!editable}
                  data={jugador.player_id}
                  label={t("training.playerconfig.player")}
                  type="select"
                  options={listaJugadores?.map((j) => {
                    return { value: j.player_id, text: j.name };
                  })}
                  onChange={(e) => {
                    let player = listaJugadores?.find((j) => j.player_id.toString() === e.toString());
                    console.log(player);
                    if (!player) return;
                    setModalContent(ventanaConfigPlayer(player));
                  }}
                />
                </td>*/}
              <td className="w-1/2 py-4 px-6">
                {jugador.ipis && jugador.ipis.length > 0 && (
                  <div>
                    IPIS
                    <div className="border border-gray-300 w-full">
                      {jugador.ipis?.map((ipi) => (
                        <div className="flex">
                          <input
                            type="checkbox"
                            disabled={!editable}
                            defaultChecked={ipi.checked}
                            onChange={(e) => {
                              ipi.checked = e.target.checked;
                              savePlayersData();
                            }}
                          />
                          {ipi.name}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-1/2 pt-4 px-6 pb-6">
                <div className="flex">
                  <div className="w-1/2 pr-2">
                    <DataField
                      disabled={!editable}
                      data={jugador.disp}
                      label={t("training.playerconfig.disp")}
                      type="select"
                      flexible
                      options={disponibilidad}
                      onChange={(e) => {
                        jugador.disp = e;
                        savePlayersData();
                      }}
                    />
                  </div>
                  <div className="w-1/2 pl-2">
                    <DataField
                      disabled={!editable}
                      data={jugador.risk}
                      label={t("training.playerconfig.risk")}
                      type="select"
                      flexible
                      options={riesgo}
                      onChange={(e) => {
                        jugador.risk = e;
                        savePlayersData();
                      }}
                    />
                  </div>
                </div>
              </td>
              <td>
              <div className="grid grid-cols-2 gap-1">
              <div className="bg-green-200 rounded-md w-full text-center">
                {t("29082021.muy_bien")}
              </div>
              <div className="bg-yellow-200 rounded-md w-full text-center">
                {t("29082021.mal")}
              </div>
              <div
                className="rounded-md w-full text-center"
                style={{ backgroundColor: "#88DD0033" }}
              >
                {t("29082021.bien")}
              </div>
              <div className="bg-red-200 rounded-md w-full text-center">
                {t("29082021.muy_mal")}
              </div>
            </div>
              </td>
            </tr>
            <tr className="border-t border-gray-300 w-full">
              <th className="text-left text-xl font-bold w-full pb-4 pt-6 px-6">
              {t("09022022.valoracion")}
              </th>
            </tr>
            <tr>
              <h1 className="px-6">{t("09022022.pretraining")}</h1>
              <p className="px-6"> {t("training.playerconfig.before")}</p>
            </tr>
            <tr>
              <td className="w-1/2 py-4 px-6 ">
                <div className="flex w-full">
                  <div className="w-1/2 text-sm text-gray-600 self-center">
                    <p>{t("09022022.verygood")}</p>
                    <p>{t("09022022.verybad")}</p>
                  </div>
                  <div className="w-1/2 flex">
                    <div className="pr-1 self-center w-full">
                      <DataField
                        disabled={true}
                        data={
                          jugador.before? JSON.parse(jugador.before).score : "1"
                        }
                        type="select"
                        flexible
                        options={[
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "10",
                        ]}
                        onChange={(e) => {
                          let scoreAux = JSON.parse(jugador.before);
                          scoreAux.score = e;
                          jugador.before = JSON.stringify(scoreAux);
                          savePlayersData();}}
                          
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td className="w-1/2 py-4 px-6 self-center">
                <DataField
                disabled={true}
                  data={jugador.before?JSON.parse(jugador.before).obs:""}
                  type="textarea"
                  label={t("09022022.obs")}
                  rows={2}
                  onChange={(e) => {
                    let obsAux = JSON.parse(jugador.before);
                    obsAux.obs = e;
                    jugador.before = JSON.stringify(obsAux);
                    savePlayersData();}}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <h1 className="px-6">{t("09022022.posttraining")}</h1>
                <p className="px-6">{t("training.playerconfig.after")}</p>
              </td>
            </tr>
            <tr>
              <td className="w-1/2 py-4 px-6">
                <div className="flex">
                  <div className="w-1/2 text-sm text-gray-600 self-center">
                    <p>{t("09022022.verygood")}</p>
                    <p>{t("09022022.verybad")}</p>
                  </div>
                  <div className="w-1/2 pl-3 self-center">
                    <DataField
                    disabled={true}
                      data={jugador.after?JSON.parse(jugador.after).score:"1"}
                      type="select"
                      flexible
                      options={[
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                      ]}
                      onChange={(e) => {let scoreAux = JSON.parse(jugador.after);
                        scoreAux.score = e;
                        jugador.after = JSON.stringify(scoreAux);
                        savePlayersData();}}
                    />
                  </div>
                </div>
              </td>
              <td className="w-1/2 py-4 px-6 self-center">
                <DataField
                disabled={true}
                  data={jugador.after?JSON.parse(jugador.after).obs:""}
                  type="textarea"
                  rows={2}
                  label={t("09022022.obs")}
                  onChange={(e) => {let obsAux = JSON.parse(jugador.after);
                    obsAux.obs = e;
                    jugador.after = JSON.stringify(obsAux);
                    savePlayersData();}}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button
          className={
            "flex bg-sf-mediumblue hover:bg-sf-lightblue ml-auto py-2 pr-4 px-2 mt-4 rounded-full text-white mx-auto text-lg"
          }
          onClick={() => setModalContent(null)}
        >
          <p>{t("09022022.save")}</p>
        </button>
      </div>
    );
  }

  function seleccionarClub(id: number, keepTeam:boolean = false) {
    let club;
    if(GetClubs()){
      club = GetClubs()?.find((club) => club.id.toString() === id.toString());
    }
    
    //if (!club) return;
    if(!keepTeam)selected.team_id = 0;
    if(club && club?.club_teams?.length > 0){
      setListaEquipos(club.club_teams.map((t) => {return{value:t.id, text:t.team_name}}));
    }else if(loggedUser?.role?.name !== "Admin" && GetTeams()) {
      setListaEquipos(GetTeams()?.map((t) => {return{value:t.id, text:t.team_name}}));
    }else{
      setListaEquipos([]);
    }
    
    
  }

  function teamSelected(id?: number) {
    if (id) {
      selected.team_id = id;
    }
    let equipo = GetTeams()?.find((t) => t.id?.toString() === selected.team_id?.toString());
    if (!equipo) return;
    selected.players = equipo.players.map((j) => {
      let jugadorSeleccionado = selected.players.find(
        (jug) => jug.player_id?.toString() === j.id?.toString()
      );
      if (jugadorSeleccionado) {
        jugadorSeleccionado.name = j.name;
        return jugadorSeleccionado;
      } else {
        return {
          player_id: j.id,
          name: j.name,
          disp: "disp",
          position: j.position,
          ipis:
            j.evaluations[0]?.ipis &&
            j.evaluations[0]?.ipis
              .filter((ipi) => ipi.checked === false)
              .map((ipi) => {
                return { name: ipi.name, checked: false };
              }),
        } as PlayerEvaluation;
      }
    });
    setListaJugadores(selected.players);

    setListaJugadoresPRE(
      equipo.players.map((j: any) => {
        let playerExperiences = selected.experiences.filter(
          (e) => e.player_id?.toString() === j.id?.toString() && e.type === "PlayerPRE"
        );
        let jAux = Object.assign({}, j);
        jAux.experiences = playerExperiences;
        return jAux;
      })
    );
    setListaJugadoresPOST(
      equipo.players.map((j: any) => {
        let playerExperiences = selected.experiences.filter(
          (e) => e.player_id?.toString() === j.id?.toString() && e.type === "PlayerPOST"
        );
        let jAux = Object.assign({}, j);
        jAux.experiences = playerExperiences;
        return jAux;
      })
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <form
        onSubmit={(e) => e.preventDefault()}
        onChange={(e) => {
          setCambiosSinGuardar(true);
          validTraining(e.currentTarget.checkValidity() && selected.date_and_time !== undefined && selected.date_and_time !== null);
        }}
        onLoad={(e) => validTraining(e.currentTarget.checkValidity() && selected.date_and_time !== undefined && selected.date_and_time !== null)}
      >
        <CollapsablePanel titulo={t("training.data.title")} colapsado={false}>
          <table className="table-fixed w-full">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                {/*<td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!seasons || seasons.length === 0}
                    data={selected.temp}
                    label={t("training.data.season")}
                    type="select"
                    options={(seasons as any[]).map((s) => {
                      return { value: s.id, text: s.name };
                    })}
                    placeholder={!seasons || seasons.length === 0 ? t("01122021.season.no_season") : t("training.data.season_placeholder")}
                    onChange={(e) => (selected.temp = e)}
                  />
                  </td>*/}
                <td className="w-2/3 py-4 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-2">
                      <DataField
                        disabled={!editable}
                        data={selected.date_and_time}
                        label={t("training.data.date")}
                        type="date"
                        required
                        onChange={(e) => (selected.date_and_time = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <DataField
                        disabled={!editable}
                        data={selected.week}
                        label={t("training.data.week")}
                        onChange={(e) => (selected.week = e)}
                      />
                    </div>
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-2">
                      <DataField
                        disabled={!editable}
                        data={selected.only_time}
                        label={t("training.data.hour")}
                        type="time"
                        onChange={(e) => (selected.only_time = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <DataField
                        disabled={!editable}
                        data={selected.num_session}
                        label={t("training.data.session")}
                        type="number"
                        onChange={(e) => (selected.num_session = e)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6" colSpan={3}>
                  <div className="w-full flex">
                    <div className="w-1/3 pr-3">
                      <DataField
                        disabled={loggedUser?.role.name !== "Admin"}
                        data={selected.club_id}
                        label={t("training.data.club")}
                        type="select"
                        
                        options={getClubNames()}
                        placeholder={t("training.data.club_placeholder")}
                        onChange={(e) => seleccionarClub(e)}
                      />
                    </div>
                    <div className="w-1/3 px-3">
                      <DataField
                        disabled={!editable || listaEquipos?.length === 0 || listaEquipos === undefined || listaEquipos === null}
                        data={selected.team_id}
                        label={t("training.data.team")}
                        type="select"
                        flexible
                        options={listaEquipos}
                        placeholder={t("training.data.team_placeholder")}
                        onChange={(e) => teamSelected(e)}
                      />
                    </div>
                    <div className="w-1/3 pl-3">
                      <DataField
                        disabled={!editable}
                        data={selected.camp}
                        label={t("training.data.field")}
                        onChange={(e) => (selected.camp = e)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="w-full py-4 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-2">
                      <DataField
                        disabled={!editable}
                        data={selected.session}
                        label={t("training.data.session_obj")}
                        type="textarea"
                        onChange={(e) => (selected.session = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <DataField
                        disabled={!editable}
                        data={selected.obs}
                        label={t("training.data.obs")}
                        type="textarea"
                        onChange={(e) => (selected.obs = e)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>

        <CollapsablePanel titulo={t("training.players.title")}>
          <div className="h-20 w-3/4 m-auto bg-gray-700 text-white rounded-lg flex items-center text-center">
            <div className="h-full w-3/12 border-r-2 border-white items-center flex">
              <div className="w-full">
                <p>{t("training.players.total")}</p>{" "}
                <p>
                  {(listaJugadores?.length ?? 0) +
                    (listaInvitados?.length ?? 0)}
                </p>
              </div>
            </div>
            <div className="w-2/12">
              <p>{t("training.players.goalkeeper")}</p>{" "}
              <p>
                {(listaJugadores?.filter((j) => j.position === "P").length ??
                  0) +
                  (listaInvitados?.filter((j) => j.position === "P").length ??
                    0)}
              </p>
            </div>
            <div className="w-2/12">
              <p>{t("training.players.def")}</p>{" "}
              <p>
                {(listaJugadores?.filter(
                  (j) =>
                    j.position === "LD" ||
                    j.position === "LI" ||
                    j.position === "DFC" ||
                    j.position === "DFCD" ||
                    j.position === "DFCI" ||
                    j.position === "CAD" ||
                    j.position === "CAI" ||
                    (j as any).player?.position === "LD" ||
                    (j as any).player?.position === "LI" ||
                    (j as any).player?.position === "DFC" ||
                    (j as any).player?.position === "DFCD" ||
                    (j as any).player?.position === "DFCI" ||
                    (j as any).player?.position === "CAD" ||
                    (j as any).player?.position === "CAI"
                ).length ?? 0) +
                  (listaInvitados?.filter(
                    (j) =>
                      j.position === "LD" ||
                      j.position === "LI" ||
                      j.position === "DFC" ||
                      j.position === "DFCD" ||
                      j.position === "DFCI" ||
                      j.position === "CAD" ||
                      j.position === "CAI"||
                      (j as any).player?.position === "LD" ||
                      (j as any).player?.position === "LI" ||
                      (j as any).player?.position === "DFC" ||
                      (j as any).player?.position === "DFCD" ||
                      (j as any).player?.position === "DFCI" ||
                      (j as any).player?.position === "CAD" ||
                      (j as any).player?.position === "CAI"
                  ).length ?? 0)}
              </p>
            </div>
            <div className="w-3/12">
              <p>{t("training.players.cent")}</p>{" "}
              <p>
                {(listaJugadores?.filter(
                  (j) =>{
                    return j.position === "MCD" ||
                    j.position === "MC" ||
                    j.position === "MCO" ||
                    j.position === "MD" ||
                    j.position === "MI" || 
                    (j as any).player?.position === "MCD" ||
                    (j as any).player?.position === "MC" ||
                    (j as any).player?.position === "MCO" ||
                    (j as any).player?.position === "MD" ||
                    (j as any).player?.position === "MI"}
                ).length ?? 0) +
                  (listaInvitados?.filter(
                    (j) =>
                      j.position === "MCD" ||
                      j.position === "MC" ||
                      j.position === "MCO" ||
                      j.position === "MD" ||
                      j.position === "MI"|| 
                      (j as any).player?.position === "MCD" ||
                      (j as any).player?.position === "MC" ||
                      (j as any).player?.position === "MCO" ||
                      (j as any).player?.position === "MD" ||
                      (j as any).player?.position === "MI"
                  ).length ?? 0)}
              </p>
            </div>
            <div className="w-2/12">
              <p>{t("training.players.del")}</p>{" "}
              <p>
                {(listaJugadores?.filter(
                  (j) =>
                    j.position === "ED" ||
                    j.position === "EI" ||
                    j.position === "DC" ||
                    (j as any).player?.position === "ED" ||
                    (j as any).player?.position === "EI" ||
                    (j as any).player?.position === "DC"
                ).length ?? 0) +
                  (listaInvitados?.filter(
                    (j) =>
                      j.position === "ED" ||
                      j.position === "EI" ||
                      j.position === "DC" ||
                      (j as any).player?.position === "ED" ||
                      (j as any).player?.position === "EI" ||
                      (j as any).player?.position === "DC"
                  ).length ?? 0)}
              </p>
            </div>
          </div>
          <div className="pb-2 px-6 py-4 bg-sf-darkblue text-white mt-4">{t("training.players.players")}</div>
          {
            <div className="w-full border-t-2 border-gray-200 grid grid-cols-2 gap-x-6 px-6">
              {listaJugadores?.map((j, index) => {
                return (
                  <div
                    className={"w-full flex py-3 border-b border-gray-400"}
                    key={index}
                  >
                    <div className="flex-1 self-center">{j.name}</div>
                    <div className="mr-2 self-center px-4">
                      {t(
                        disponibilidad.find((d) => d.value === j.disp)
                          ?.text as string
                      )}
                    </div>
                    <div>
                      <button
                        className={
                          "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full text-sm m-auto"
                        }
                        onClick={() =>
                          setModalContent(
                            ventanaConfigPlayer(j),
                            t("09022022.configplayer") + " - "+ j.name
                          )
                        }
                      >
                        <p>{t("training.players.config")}</p>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          }
          <table className="table-fixed w-full border-t-2 border-gray-200">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  {t("training.players.guest")}
                </td>

                <td className="w-1/3 py-4 px-6"></td>
                <td className="w-1/3 py-4 px-6"></td>
              </tr>
              {listaInvitados.map((invitado, index) => (
                <tr key={index} className="border-b border-gray-400">
                  <td className="w-1/3 py-4 px-6">
                    <DataField
                      disabled={!editable}
                      data={invitado.name}
                      label={t("14052021.training.name")}
                      onChange={(e) => {
                        invitado.name = e;
                        saveInvitados();
                      }}
                    />
                  </td>

                  <td className="w-1/3 py-4 px-6">
                    <DataField
                      disabled={!editable}
                      data={invitado.position}
                      label={t("14052021.training.pos")}
                      placeholder={t("14052021.training.pos_place")}
                      type="select"
                      flexible
                      options={positions}
                      onChange={(e) => {
                        invitado.position = e;
                        saveInvitados();
                      }}
                    />
                  </td>
                  <td className="w-1/3 py-4 px-6">
                    <DataField
                      disabled={!editable}
                      data={invitado.obs}
                      label={t("14052021.training.obs")}
                      onChange={(e) => {
                        invitado.obs = e;
                        saveInvitados();
                      }}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td className="w-1/3 py-4 px-6">
                  {editable && (
                    <button
                      className={
                        "flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
                      }
                      onClick={() => {
                        listaInvitados.push({} as TrainingGuest);
                        setListaInvitados(listaInvitados.map((i) => i));
                      }}
                    >
                      <img
                        src={add_icon}
                        alt="Añadir"
                        className="h-6 transform scale-50"
                        style={{ filter: "invert()" }}
                      />
                      <p className="pl-2">{t("training.players.add")}</p>
                    </button>
                  )}
                </td>

                <td className="w-1/3 py-4 px-6"></td>
                <td className="w-1/3 py-4 px-6"></td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>

        <CollapsablePanel titulo={t("training.global.title")}>
          <div className="flex px-6">
            <div className="w-1/2 pr-1">
              <DataField
                disabled={!editable}
                data={selected.global_obj}
                label={t("training.global.obj")}
                type="textarea"
                onChange={(e) => (selected.global_obj = e)}
              />
            </div>
            <div className="w-1/2 pl-1">
              <DataField
                disabled={!editable}
                data={selected.global_mat}
                label={t("training.global.material")}
                type="textarea"
                onChange={(e) => (selected.global_mat = e)}
              />
            </div>
          </div>
          <div className="px-6 botder-t-2 border-gray-200 py-2">
            <p>{t("training.global.exp")}</p>
            {editable && (
              <button
                className={
                  "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2 mt-2"
                }
                onClick={() => {
                  setModalContent(
                    getGlobalExperiencesWindow(GetGlobalExperiences() ?? [])
                  );
                }}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("training.global.add_exp")}</p>
              </button>
            )}
            <div className="grid grid-cols-2 gap-2 my-2">
              {experienciasGlobales?.map((e, index) => (
                <div className="w-full pr-1" key={index}>
                  <div className="flex">
                    <div className="w-1/2 p-2 flex flex-col">
                      <div className="flex justify-between">
                        <p>{i18n.language === "es" ? e.name_es ?? e.experience?.name_es : i18n.language === "ru" ? e.name_ru ?? e.experience?.name_ru : e.name_en ?? e.experience?.name_en}</p>
                        {editable && (
                          <button
                            className="text-right cursor-pointer"
                            onClick={() => {
                              experienciasGlobales.splice(
                                experienciasGlobales.indexOf(e),
                                1
                              );

                              setExperienciasGlobales(
                                experienciasGlobales.map((exp) => exp)
                              );
                              saveExperiences();
                            }}
                          >
                            x
                          </button>
                        )}
                      </div>
                      <div className="flex-1">
                        <div className="w-full h-full relative flipcard">
                          {e.file1?.file ? (
                            <img
                              src={cmsStorageURL + e.file1?.file}
                              alt="placeholder"
                              className="max-w-full max-h-full"
                            />
                          ) : e.experience?.file1?.file ? (
                            <img
                              src={cmsStorageURL + e.experience?.file1?.file}
                              alt="placeholder"
                              className="max-w-full max-h-full"
                            />
                          ) : (
                            <img
                              src={exp_icon}
                              alt="icon"
                              className="max-w-full max-h-full h-40"
                            />
                          )}
                          <div className="text-sm font-normal w-full  text-gray-600 absolute top-0 right-0 bottom-0 left-0 px-4 overflow-auto">
                            {i18n.language === "es" ? e.description_es : i18n.language === "ru" ? e.description_ru : e.description_en}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2">
                      <div className="grid grid-cols-2 gap-1">
                        <DataField
                          disabled={!editable}
                          data={e.series}
                          label={t("training.global.exp_series")}
                          onChange={(value) => {
                            e.series = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable}
                          data={e.reps}
                          label={t("training.global.exp_rep")}
                          onChange={(value) => {
                            e.reps = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable}
                          data={e.inv_time}
                          label={t("training.global.exp_inv")}
                          onChange={(value) => {
                            e.inv_time = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable}
                          data={e.ref_time}
                          label={t("training.global.exp_ref")}
                          onChange={(value) => {
                            e.ref_time = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable}
                          data={e.width}
                          label={t("training.global.exp_width")}
                          onChange={(value) => {
                            e.width = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable}
                          data={e.long}
                          label={t("training.global.exp_long")}
                          onChange={(value) => {
                            e.long = value;
                            saveExperiences();
                          }}
                        />
                      </div>
                      <button
                        className={
                          "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-4"
                        }
                        onClick={() => {
                          setModalContent(
                            <GlobalExperienceDetail
                              selected={getCustomExperience(e.experience ?? e)}
                              image={undefined}
                              uploadImage={() => undefined}
                              editable={false}
                              onGuidelineAdd={(guideline: any) => {
                                guidelineAdd(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                );
                              }}
                              onCheckedUpdate={(guideline: any) =>
                                {guidelineCheckedUpdate(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                )}
                              }
                              onDestinyUpdate={(guideline: any) =>
                                {
                                guidelineDestinyUpdate(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                )}
                              }
                              onGuidelineRemove={(guideline: any) => {
                                guidelineRemove(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                );
                              }}
                            />
                          );
                        }}
                      >
                        <img
                          src={add_icon}
                          className="h-6 p-1 text-center m-auto"
                          alt="add"
                        />
                        <p>{t("training.global.custom")}</p>
                      </button>
                    </div>
                  </div>
                  <DataField
                    disabled={!editable}
                    data={e.obs}
                    label={t("training.global.dist")}
                    type="textarea"
                    onChange={(value) => {e.obs = value;saveExperiences();}}
                  />
                </div>
              ))}
            </div>
            <div className="w-full pt-3 border-t-2 border-gray-200 mt-2">
              <div className="w-full">
                <DataField
                  disabled={!editable}
                  data={selected.global_anot}
                  label={t("training.global.post")}
                  type="textarea"
                  onChange={(e) => (selected.global_anot = e)}
                />
              </div>
            </div>
          </div>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("training.goalkeeper.title")}>
          <div className="flex px-6">
            <div className="w-1/2 pr-1">
              <DataField
                disabled={!editable}
                data={selected.goal_obj}
                label={t("training.goalkeeper.obj")}
                type="textarea"
                onChange={(e) => (selected.goal_obj = e)}
              />
            </div>
            <div className="w-1/2 pl-1">
              <DataField
                disabled={!editable}
                data={selected.goal_mat}
                label={t("training.goalkeeper.material")}
                type="textarea"
                onChange={(e) => (selected.goal_mat = e)}
              />
            </div>
          </div>
          <div className="px-6 botder-t-2 border-gray-200 py-2">
            <p>{t("training.goalkeeper.exp")}</p>
            {(editable || esPortero) && (
              <button
                className={
                  "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2 mt-2"
                }
                onClick={() => {
                  setModalContent(
                    getGoalkeeperExperiencesWindow(
                      GetGoalkeeperExperiences() ?? []
                    )
                  );
                }}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("training.goalkeeper.add_exp")}</p>
              </button>
            )}
            <div className="grid grid-cols-2 gap-2 mt-2">
              {experienciasPorteros?.map((e, index) => (
                <div className="w-full pr-1" key={index}>
                  <div className="flex">
                    <div className="w-1/2 p-2 flex flex-col">
                      <div className="flex justify-between">
                        <p>{i18n.language === "es" ? e.name_es : i18n.language === "ru" ? e.name_ru : e.name_en}</p>
                        {(editable || esPortero) && (
                          <div
                            className="text-right cursor-pointer"
                            onClick={() => {
                              experienciasPorteros.splice(
                                experienciasPorteros.indexOf(e),
                                1
                              );
                              setExperienciasGlobales(
                                experienciasPorteros.map((exp) => exp)
                              );
                              saveExperiences();
                            }}
                          >
                            x
                          </div>
                        )}
                      </div>
                      <div className="flex-1">
                        <div className="w-full h-full relative flipcard">
                          {e.file1?.file ? (
                            <img
                              src={cmsStorageURL + e.file1?.file}
                              alt="placeholder"
                              className="max-w-full max-h-full"
                            />
                          ) : e.experience?.file1?.file ? (
                            <img
                              src={cmsStorageURL + e.experience?.file1?.file}
                              alt="placeholder"
                              className="max-w-full max-h-full"
                            />
                          ) : (
                            <img
                              src={exp_icon}
                              alt="icon"
                              className="max-w-full max-h-full h-40"
                            />
                          )}
                          <div className="text-sm font-normal w-full  text-gray-600 absolute top-0 right-0 bottom-0 left-0 px-4 overflow-auto">
                          {i18n.language === "es" ? e.description_es : i18n.language === "ru" ? e.description_ru : e.description_en}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 p-1">
                      <div className="grid grid-cols-2 gap-1">
                        <DataField
                          disabled={!editable && !esPortero}
                          data={e.series}
                          label={t("training.goalkeeper.exp_series")}
                          onChange={(value) => {
                            e.series = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable && !esPortero}
                          data={e.reps}
                          label={t("training.goalkeeper.exp_rep")}
                          onChange={(value) => {
                            e.reps = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable && !esPortero}
                          data={e.inv_time}
                          label={t("training.goalkeeper.exp_inv")}
                          onChange={(value) => {
                            e.inv_time = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable && !esPortero}
                          data={e.ref_time}
                          label={t("training.goalkeeper.exp_ref")}
                          onChange={(value) => {
                            e.ref_time = value;
                            saveExperiences();
                          }}
                        />
                      </div>
                      <DataField
                        disabled={!editable && !esPortero}
                        data={e.obs}
                        label={t("training.goalkeeper.obs")}
                        type="textarea"
                        rows={3}
                        onChange={(value) => {
                          e.obs = value;
                          saveExperiences();
                        }}
                      />
                      <button
                        className={
                          "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-4"
                        }
                        onClick={() => {
                          setModalContent(
                            <GoalkeeperExperienceDetail
                              selected={getCustomExperience(e.experience ?? e)}
                              image={undefined}
                              uploadImage={() => undefined}
                              editable={false}
                              onGuidelineAdd={(guideline: any) => {
                                guidelineAdd(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                );
                              }}
                              onCheckedUpdate={(guideline: any) =>
                                {guidelineCheckedUpdate(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                )}
                              }
                              onDestinyUpdate={(guideline: any) =>
                                {
                                guidelineDestinyUpdate(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                )}
                              }
                              onGuidelineRemove={(guideline: any) => {
                                guidelineRemove(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                );
                              }}
                            />
                            
                          );
                        }}
                      >
                        <img
                          src={add_icon}
                          className="h-6 p-1 text-center m-auto"
                          alt="add"
                        />
                        <p>{t("training.goalkeeper.custom")}</p>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full pt-3 border-t-2 border-gray-200 mt-2">
              <div className="w-1/2">
                <DataField
                  disabled={!editable}
                  data={selected.goal_anot}
                  label={t("training.goalkeeper.post")}
                  type="textarea"
                  onChange={(e) => (selected.goal_anot = e)}
                />
              </div>
            </div>
          </div>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("training.bio.title")}>
          <p className="px-6">Sesión PRE</p>
          <div className="flex px-6">
            <div className="w-1/2 pr-1">
              <DataField
                disabled={!editable}
                data={selected.bio_pre_obj}
                label={t("training.bio.obj")}
                type="textarea"
                onChange={(e) => (selected.bio_pre_obj = e)}
              />
            </div>
            <div className="w-1/2 pl-1">
              <DataField
                disabled={!editable}
                data={selected.bio_pre_mat}
                label={t("training.bio.material")}
                type="textarea"
                onChange={(e) => (selected.bio_pre_mat = e)}
              />
            </div>
          </div>
          <div className="px-6 botder-t-2 border-gray-200 py-2">
            <p>{t("training.bio.exp_pre")}</p>
            {editable && (
              <button
                className={
                  "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2 my-2"
                }
                onClick={() => {
                  setModalContent(
                    getBioExperiencesWindowPRE(GetBioExperiences() ?? [])
                  );
                }}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("training.bio.add_exp_pre")}</p>
              </button>
            )}
            <div className="grid grid-cols-2 gap-2">
              {experienciasBiologicasPRE?.map((e,ind) => (
                <div className="w-full pr-1" key={ind}>
                  <div className="flex">
                    <div className="w-1/2 p-2 flex flex-col">
                      <div className="flex justify-between">
                        <p>{i18n.language === "es" ? e.name_es : i18n.language === "ru" ? e.name_ru : e.name_en}</p>
                        {editable && (
                          <div
                            className="text-right cursor-pointer"
                            onClick={() => {
                              experienciasBiologicasPRE.splice(
                                experienciasBiologicasPRE.indexOf(e),
                                1
                              );
                              setExperienciasBiologicasPRE(
                                experienciasBiologicasPRE.map((exp) => exp)
                              );
                              saveExperiences();
                            }}
                          >
                            x
                          </div>
                        )}
                      </div>
                      <div className="flex-1">
                        <div className="w-full h-full relative flipcard">
                          {e.file1?.file ? (
                            <img
                              src={cmsStorageURL + e.file1?.file}
                              alt="placeholder"
                              className="max-w-full max-h-full"
                            />
                          ) : e.experience?.file1?.file ? (
                            <img
                              src={cmsStorageURL + e.experience?.file1?.file}
                              alt="placeholder"
                              className="max-w-full max-h-full"
                            />
                          ) : (
                            <img
                              src={exp_icon}
                              alt="icon"
                              className="max-w-full max-h-full h-40"
                            />
                          )}
                          <div className="text-sm font-normal w-full  text-gray-600 absolute top-0 right-0 bottom-0 left-0 px-4 overflow-auto">
                            {i18n.language === "es" ? e.description_es : i18n.language === "ru" ? e.description_ru : e.description_en}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 p-1">
                      <div className="grid grid-cols-2 gap-1">
                        <DataField
                          disabled={!editable}
                          data={e.series}
                          label={t("training.bio.exp_series")}
                          onChange={(value) => {
                            e.series = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable}
                          data={e.reps}
                          label={t("training.bio.exp_rep")}
                          onChange={(value) => {
                            e.reps = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable}
                          data={e.inv_time}
                          label={t("training.bio.exp_rep")}
                          onChange={(value) => {
                            e.inv_time = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable}
                          data={e.ref_time}
                          label={t("training.bio.exp_descanso")}
                          onChange={(value) => {
                            e.ref_time = value;
                            saveExperiences();
                          }}
                        />
                        <DataField
                          disabled={!editable}
                          data={e.width}
                          label={t("training.bio.exp_load")}
                          onChange={(value) => {
                            e.width = value;
                            saveExperiences();
                          }}
                        />
                      </div>
                      <DataField
                        disabled={!editable}
                        data={e.obs}
                        label={t("training.bio.obs")}
                        type="textarea"
                        rows={3}
                        onChange={(value) => {
                          e.obs = value;
                          saveExperiences();
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {t("training.bio.exp_player_pre")}
            <div className="grid grid-cols-2 gap-x-3 mt-2">
              {listaJugadoresPRE?.map((j, index) => (
                <div className="flex py-3 border-b border-gray-400" key={index}>
                  <p className="self-center">{j.name}</p>
                  <p className="self-center text-right flex-1 pr-2">
                    {j.experiences?.length > 0 ? j.experiences?.length : ""}
                  </p>
                  <button
                    className={
                      "flex text-sm bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto"
                    }
                    onClick={() =>
                      setModalContent(
                        getPREBioExperiencesWindowPerPlayer(
                          GetBioExperiences()?.map(e => Object.assign({},e)) ?? [],
                          j.id
                        )
                      )
                    }
                  >
                    <p>{t("training.bio.config_pre")}</p>
                  </button>
                </div>
              ))}
            </div>
            <div className="w-full pt-3 border-t-2 border-gray-200 mt-2">
              <div className="w-1/2">
                <DataField
                  disabled={!editable}
                  data={selected.bio_pre_anot}
                  label={t("training.bio.evaluation_post")}
                  type="textarea"
                  onChange={(e) => (selected.bio_pre_anot = e)}
                />
              </div>
            </div>
          </div>

          <p className="px-6 mt-4">{t("training.bio.session_post")}</p>
          <div className="flex px-6">
            <div className="w-1/2 pr-1">
              <DataField
                disabled={!editable}
                data={selected.bio_post_obj}
                label={t("training.bio.obj_session_post")}
                type="textarea"
                onChange={(e) => (selected.bio_post_obj = e)}
              />
            </div>
            <div className="w-1/2 pl-1">
              <DataField
                disabled={!editable}
                data={selected.bio_post_mat}
                label={t("training.bio.obj_material_post")}
                type="textarea"
                onChange={(e) => (selected.bio_post_mat = e)}
              />
            </div>
          </div>
          <div className="px-6 botder-t-2 border-gray-200 py-2 mt-2">
            <p>{t("training.bio.exp_post")}</p>
            {editable && (
              <button
                className={
                  "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2 my-2"
                }
                onClick={() => {
                  setModalContent(
                    getBioExperiencesWindowPOST(GetBioExperiences() ?? [])
                  );
                }}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("training.bio.add_exp_post")}</p>
              </button>
            )}
            <div className="grid grid-cols-2 gap-2">
              {experienciasBiologicasPOST?.map((e,ind) => (
                <div className="w-full pr-1" key={ind}>
                  <div className="flex">
                    <div className="w-1/2 p-2 flex flex-col">
                      <div className="flex justify-between">
                        <p>{i18n.language === "es" ? e.name_es : i18n.language === "ru" ? e.name_ru : e.name_en}</p>
                        {editable && (
                          <div
                            className="text-right cursor-pointer"
                            onClick={() => {
                              experienciasBiologicasPOST.splice(
                                experienciasBiologicasPOST.indexOf(e),
                                1
                              );
                              setExperienciasBiologicasPOST(
                                experienciasBiologicasPOST.map((exp) => exp)
                              );
                              saveExperiences();
                            }}
                          >
                            x
                          </div>
                        )}
                      </div>
                      <div className="flex-1">
                        {e.file1?.file ? (
                          <img
                            src={cmsStorageURL + e.file1?.file}
                            alt="placeholder"
                            className="max-w-full max-h-full"
                          />
                        ) : e.experience?.file1?.file ? (
                          <img
                            src={cmsStorageURL + e.experience?.file1?.file}
                            alt="placeholder"
                            className="max-w-full max-h-full"
                          />
                        ) : (
                          <img
                            src={exp_icon}
                            alt="icon"
                            className="max-w-full max-h-full h-40"
                          />
                        )}
                      </div>
                    </div>
                    <div className="w-1/2 p-1">
                      <DataField
                        disabled={!editable}
                        data={e.obs}
                        label={t("training.bio.obs_post")}
                        type="textarea"
                        rows={7}
                        onChange={(value) => {
                          e.obs = value;
                          saveExperiences();
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {t("training.bio.exp_player_post")}
            <div className="grid grid-cols-2 gap-x-3 mt-2">
              {listaJugadoresPOST?.map((j, index) => (
                <div className="flex border-b border-gray-400 py-3" key={index}>
                  <p className="self-center">{j.name}</p>
                  <p className="self-center text-right flex-1 pr-2">
                    {j.experiences?.length > 0 ? j.experiences?.length : ""}
                  </p>
                  <button
                    className={
                      "flex text-sm bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto"
                    }
                    onClick={() =>
                      setModalContent(
                        getPOSTBioExperiencesWindowPerPlayer(
                          GetBioExperiences()?.map(e => Object.assign({},e)) ?? [],
                          j.id
                        )
                      )
                    }
                  >
                    <p>{t("training.bio.config_post")}</p>
                  </button>
                </div>
              ))}
            </div>
            <div className="w-full pt-3 border-t-2 border-gray-200 mt-2">
              <div className="w-1/2">
                <DataField
                  disabled={!editable}
                  data={selected.bio_post_anot}
                  label={t("training.bio.evaluation_post_post")}
                  type="textarea"
                  onChange={(e) => (selected.bio_post_anot = e)}
                />
              </div>
            </div>
          </div>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("training.audiovisual.title")}>
          <div className="flex px-6">
            <div className="w-1/2 pr-1">
              <DataField
                disabled={!editable}
                data={selected.audio_obj}
                label={t("training.audiovisual.obj")}
                type="textarea"
                onChange={(e) => (selected.audio_obj = e)}
              />
            </div>
            <div className="w-1/2 pl-1">
              <DataField
                disabled={!editable}
                data={selected.audio_mat}
                label={t("training.audiovisual.material")}
                type="textarea"
                onChange={(e) => (selected.audio_mat = e)}
              />
            </div>
          </div>
          <div className="px-6 botder-t-2 border-gray-200 py-2 mt-2">
            <p>{t("training.bio.exp_post")}</p>
            {editable && (
              <button
                className={
                  "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2 my-2"
                }
                onClick={() => {
                  setModalContent(
                    getAudiovisualExperiencesWindow(
                      GetAudiovisualExperiences() ?? []
                    )
                  );
                }}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("training.audiovisual.add_exp")}</p>
              </button>
            )}
            <div className="grid grid-cols-2 gap-2">
              {experienciasAudiovisuales?.map((e,ind) => (
                <div className="w-full pr-1" key={ind}>
                  <div className="flex">
                    <div className="w-1/2 p-2 flex flex-col">
                      <div className="flex justify-between">
                        <p>{i18n.language === "es" ? e.name_es : i18n.language === "ru" ? e.name_ru : e.name_en}</p>
                        {editable && (
                          <div
                            className="text-right cursor-pointer"
                            onClick={() => {
                              experienciasAudiovisuales.splice(
                                experienciasAudiovisuales.indexOf(e),
                                1
                              );
                              setExperienciasAudiovisuales(
                                experienciasAudiovisuales.map((exp) => exp)
                              );
                              saveExperiences();
                            }}
                          >
                            x
                          </div>
                        )}
                      </div>
                      <div className="flex-1">
                        <div className="w-full h-full relative flipcard">
                          {e.file1?.file ? (
                            <img
                              src={cmsStorageURL + e.file1?.file}
                              alt="placeholder"
                              className="max-w-full max-h-full"
                            />
                          ) : e.experience?.file1?.file ? (
                            <img
                              src={cmsStorageURL + e.experience?.file1?.file}
                              alt="placeholder"
                              className="max-w-full max-h-full"
                            />
                          ) : (
                            <img
                              src={exp_icon}
                              alt="icon"
                              className="max-w-full max-h-full h-40"
                            />
                          )}
                          <div className="text-sm font-normal w-full  text-gray-600 absolute top-0 right-0 bottom-0 left-0 px-4 overflow-auto">
                            {i18n.language === "es" ? e.description_es : i18n.language === "ru" ? e.description_ru : e.description_en}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 p-1">
                      <DataField
                        disabled={!editable}
                        data={e.obs}
                        label={t("training.audiovisual.exp_obs")}
                        type="textarea"
                        rows={7}
                        onChange={(value) => {
                          e.obs = value;
                          saveExperiences();
                        }}
                      />
                      <button
                        className={
                          "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-4"
                        }
                        onClick={() => {
                          setModalContent(
                            <AudiovisualExperienceDetail
                              selected={getCustomExperience(e.experience ?? e)}
                              image={undefined}
                              image2={undefined}
                              uploadImage2={() => undefined}
                              uploadImage={() => undefined}
                              editable={false}
                              onGuidelineAdd={(guideline) => {
                                guidelineAdd(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                );
                              }}
                              onCheckedUpdate={(guideline: any) =>
                                {guidelineCheckedUpdate(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                )}
                              }
                              onDestinyUpdate={(guideline: any) =>
                                {
                                guidelineDestinyUpdate(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                )}
                              }
                              onGuidelineRemove={(guideline) => {
                                guidelineRemove(
                                  e.experience ? e.experience.id : e.id,
                                  guideline
                                );
                              }}

                            />
                          );
                        }}
                      >
                        <img
                          src={add_icon}
                          className="h-6 p-1 text-center m-auto"
                          alt="add"
                        />
                        <p>{t("training.audiovisual.custom")}</p>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full pt-3 border-t-2 border-gray-200 mt-2">
              <div className="w-1/2">
                <DataField
                  disabled={!editable}
                  data={selected.audio_anot_post}
                  label={t("training.audiovisual.evaluation_post")}
                  type="textarea"
                  onChange={(e) => (selected.audio_anot_post = e)}
                />
              </div>
            </div>
          </div>
        </CollapsablePanel>
      </form>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="m-auto flex flex-col">
          <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
          <p>{t("29082021.loading.generic")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrainingDetail;
