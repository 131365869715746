import { cmsBaseUrl, Report } from "./Icms";

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsReportsUrl: string = cmsApiUrl + "/report";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let reportsList: Report[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetReportsFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsReportsUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      reportsList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSReports: (token: string) => Promise<boolean> =
  GetReportsFromCMS;

async function RemoveReportsFromCMS(
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsReportsUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSReports: (id: number, token: string) => Promise<boolean> =
  RemoveReportsFromCMS;

async function InsertReportsFromCMS(
  report: Report,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsReportsUrl, {
    method: "POST",
    body: JSON.stringify({ reportData: report }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSReports: (report: Report, token: string) => Promise<number> =
  InsertReportsFromCMS;

async function ModifyReportsFromCMS(
  report: Report,
  token: string
): Promise<number> {
  //Tenemos que hacer una petición por cada 30 datos dentro del array
  let reportData = report.data;
  report.deletePrevious = true;
  let outputID = -1;

  for (let i = 0; i < reportData.length; i += 30) {
    //Generamos el array actual
    report.data = reportData.slice(i, i + 30);

    //Generamos el JSON
    //let jsonData = JSON.stringify(report);

    //Enviamos la petición
    let response = await fetch(cmsReportsUrl, {
      method: "PUT",
      body: JSON.stringify({ reportData: report, id: report.id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.ok === false) return -1;

    //Obtenemos los datos para colocar el id de salida
    try {
      let result = await response.json();
      outputID = result.id;
    } catch (error) {
      return -1;
    }

    //E indicamos que ya no elimine más los datos
    report.deletePrevious = false;
  }

  return outputID;
}

export const UpdateCMSReports: (
  report: Report,
  token: string
) => Promise<number> = ModifyReportsFromCMS;

function getReports(): Report[] | undefined {
  if (reportsList === undefined) return undefined;

  return reportsList;
}
export const GetReports: () => Report[] | undefined = getReports;
