import { cmsBaseUrl, StaffData } from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsTeamsUrl: string = cmsApiUrl + "/staff";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let staffList: StaffData[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetStaffFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsTeamsUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      staffList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSStaff: (token: string) => Promise<boolean> =
  GetStaffFromCMS;

async function RemoveStaffFromCMS(id: number, token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsTeamsUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSStaff: (id: number, token: string) => Promise<boolean> =
  RemoveStaffFromCMS;

async function InsertStaffFromCMS(
  staff: StaffData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsTeamsUrl, {
    method: "POST",
    body: JSON.stringify({ staffData: staff }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSStaff: (staff: StaffData, token: string) => Promise<number> =
  InsertStaffFromCMS;

async function ModifyStaffFromCMS(
  staff: StaffData,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsTeamsUrl, {
    method: "PUT",
    body: JSON.stringify({ staffData: staff, id: staff.id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const UpdateCMSStaff: (
  staff: StaffData,
  token: string
) => Promise<boolean> = ModifyStaffFromCMS;

function getStaff(): StaffData[] | undefined {
  if (staffList === undefined) return undefined;

  return staffList;
}
export const GetStaff: () => StaffData[] | undefined = getStaff;

function getStaffByClubID(id: number): StaffData[] | undefined {
  if (staffList === undefined) return undefined;

  return staffList.filter((p) => p.club_id === id);
}

export const GetStaffByClubID: (id: number) => StaffData[] | undefined =
  getStaffByClubID;

function getStaffByID(id: number): StaffData | undefined {
  if (staffList === undefined) return undefined;

  return staffList.find((p) => p.id === id);
}

export const GetStaffByID: (id: number) => StaffData | undefined = getStaffByID;

async function CreateUserForStaff(
  staffData: StaffData,
  token: string
): Promise<boolean> {
  //Si no hay datos de usuario o ya hay uno creado, avisamos de error y devolvemos
  if (
    (staffData.user !== undefined && staffData.user !== null) ||
    staffData.userEmail === "" ||
    staffData.userName === "" ||
    staffData.userPass === ""
  ) {
    console.log("Error creating user for staff. One variable is wrong.");
    return false;
  }

  let url = cmsApiUrl + "/auth/createAccount";
  let type = "";

  switch (staffData.sport_rol) {
    case "Scouter":
      type = "scouter";
      break;
    case "GoalkeeperTrainer":
    case "BiologicTrainer":
      type = "Goal_Bio_Trainer";
      break;
    case "Trainer":
    case "SecondTrainer":
      type = "trainer";
      break;
    case "Coordinator":
      type = "coordinator";
      break;
    default:
      return false;
  }

  //Lanzamos la petición
  let response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      userName: staffData.userName,
      userEmail: staffData.userEmail,
      userPassword: staffData.userPass,
      staff_id: staffData.id,
      type: type,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  return response.ok !== false;
}
export const CreateUserStaff: (
  staffData: StaffData,
  token: string
) => Promise<boolean> = CreateUserForStaff;
