import {
  cmsBaseUrl,
  PlayerEvaluation,
  TrainingData,
} from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsTrainingUrl: string = cmsApiUrl + "/training";
const cmsAssesmentUrl: string = cmsApiUrl + "/playerTraining";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let experiencesList: TrainingData[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetTrainingsFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsTrainingUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      experiencesList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSTrainings: (token: string) => Promise<boolean> =
  GetTrainingsFromCMS;

async function RemoveTrainingsFromCMS(
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsTrainingUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSTrainings: (
  id: number,
  token: string
) => Promise<boolean> = RemoveTrainingsFromCMS;

async function InsertTrainingsFromCMS(
  training: TrainingData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let cleanTraining = Object.assign({}, training);
  cleanTraining.players = [];
  let response = await fetch(cmsTrainingUrl, {
    method: "POST",
    body: JSON.stringify({ trainingData: cleanTraining }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSTrainings: (
  training: TrainingData,
  token: string
) => Promise<number> = InsertTrainingsFromCMS;

async function ModifyTrainingsFromCMS(
  training: TrainingData,
  token: string,
  lang: string,
  send_email: number
): Promise<any> {
  //Lanzamos la petición

  let response = await fetch(cmsTrainingUrl, {
    method: "PUT",
    body: JSON.stringify({
      trainingData: training,
      id: training.id,
      email: send_email,
      lang: lang,
    }),
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.updatedData;
  } catch (error) {
    return -1;
  }
}

export const UpdateCMSTrainings: (
  training: TrainingData,
  token: string,
  lang: string,
  send_email: number
) => Promise<any> = ModifyTrainingsFromCMS;

async function ModifyPlayerTrainingsFromCMS(
  playerEvaluation: PlayerEvaluation,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsAssesmentUrl, {
    method: "PUT",
    body: JSON.stringify({
      id: playerEvaluation.id,
      playerTrainingData: playerEvaluation,
    }),
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const UpdatePlayerCMSTrainings: (
  playerEvaluation: PlayerEvaluation,
  token: string
) => Promise<number> = ModifyPlayerTrainingsFromCMS;

function getTrainings(): TrainingData[] | undefined {
  if (experiencesList === undefined) return undefined;

  return experiencesList;
}
export const GetTrainings: () => TrainingData[] | undefined = getTrainings;
