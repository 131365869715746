import React from "react";
import backarrow_icon from "../images/icons/arrow-back-icon.svg";
import add_icon from "../images/icons/add-icon.svg";
import minus_icon from "../images/icons/papelera-icon.svg";
import useStore from "../store";
import { useTranslation } from "react-i18next";


const Topbar: React.FC<{
  marked?: number;
  selected?: any;
  createText?: string;
  onUpdate?: () => void;
  onDelete?: () => void;
  onCreate: (value?: any) => void;
  onBack?: () => void;
  forceBack?: boolean;
  onFilter?: (value: string) => void;
  onDownload?: any;
  createOptions?: string[];
  disabled?: boolean;
  visibleButtons?: boolean;
}> = ({
  marked,
  selected,
  createText,
  onBack,
  forceBack = false,
  onFilter,
  onCreate,
  onUpdate,
  onDelete,
  onDownload,
  createOptions,
  disabled,
  visibleButtons = true,
}) => {
  const { t } = useTranslation();
  const setModalContent = useStore((state) => state.setModalContent);
  const cambiosSinGuardar = useStore((state) => state.cambiosSinGuardar);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  function backModal() {
    return (
      <React.Fragment>
        <h2 className="text-center font-bold text-sf-darkblue text-xl pb-4">
          {t("01122021.topbar.salir_title")}
        </h2>
        <p>{t("01122021.topbar.perder_modificacion")}</p>
        <p className="py-2">{t("01122021.topbar.confirmar_salir")}</p>

        <div className="flex pt-4 justify-center">
          <button
            className={
              "flex text-sf-mediumblue  bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-ful mr-2"
            }
            onClick={() => {
              setCambiosSinGuardar(false);
              setModalContent(undefined);
              if (onBack) onBack();
            }}
          >
            <p className="pl-2 self-center">{t("01122021.topbar.salir_no_guardar")}</p>
          </button>
          <button
            className={
              "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
            }
            onClick={() => {
              setModalContent(undefined);
            }}
          >
            <p className="pl-2 self-center">{t("01122021.topbar.salir_cancelar")}</p>
          </button>
        </div>
      </React.Fragment>
    );
  }

  function removeModal() {
    return (
      <React.Fragment>
        <h2 className="text-center font-bold text-sf-darkblue text-xl pb-4">
          {t("01122021.topbar.borrar_archivo")}
        </h2>
        <p>{t("01122021.topbar.confirmar_borrado")}</p>
        <p className="py-2">{t("01122021.topbar.borrado_seguro")}</p>
        <div className="flex pt-4 justify-center">
          <button
            className={
              "flex text-sf-mediumblue  bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full mr-2"
            }
            onClick={() => {
              setModalContent(undefined);
              if (onDelete) onDelete();
            }}
          >
            <p className="pl-2 self-center">{t("01122021.topbar.borrar_si")}</p>
          </button>
          <button
            className={
              "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
            }
            onClick={() => {
              setModalContent(undefined);
            }}
          >
            <p className="pl-2 self-center">{t("01122021.topbar.borrar_no")}</p>
          </button>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div
      className="h-12 w-full relative border border-solid border-b-0 border-l-0 border-t-0 border-r-0 pb-2"
      style={{ borderColor: "#476d82" }}
    >
      <div className="w-full xl:w-3/4 mx-auto flex justify-between px-2 py-1 h-full">
        <img
          src={backarrow_icon}
          alt="Atrás"
          className={
            "self-center cursor-pointer transition-transform transform scale-100 hover:scale-110 duration-100 ease-in-out" +
            (selected || forceBack ? "" : " hidden")
          }
          style={{ filter: "invert()" }}
          onClick={() => {
            if (cambiosSinGuardar) {
              setModalContent(backModal());
            } else {
              if (onBack) {
                onBack();
              }
            }
          }}
        />
        {onFilter && (
          <div className={"w-1/3 flex relative " + (selected ? "hidden" : "")}>
            <input
              type="text"
              onChange={(e) => onFilter(e.target.value)}
              className="w-full px-6 text-white bg-sf-darkblue bg-opacity-75 border-solid border border-gray-600 border-1 rounded-full"
              placeholder={t("ui.search")}
            />
            <svg
              className="text-gray-600 h-4 w-4 fill-current absolute right-0 self-center mr-4"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              width="512px"
              height="512px"
            >
              <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
            </svg>
          </div>
        )}

        {selected ? (
          selected?.id && (
            <p className="self-center text-sm text-white">
              {t("14052021.topbar.updated") + " "}{" "}
              {new Date(selected?.updated_at).toLocaleString()}
            </p>
          )
        ) : (
          <p
            className={
              "self-center text-xl text-white " +
              (marked && marked > 0 ? "opacity-1" : "opacity-0")
            }
          >
            {marked +
              (marked && marked > 1
                ? " " + t("01122021.topbar.elementos_seleccionados")
                : " "+t("01122021.topbar.elemento_seleccionado"))}
          </p>
        )}
        {visibleButtons && (
          <div className={"self-center flex"}>
            {!createOptions ? (
              <div className="flex">
                {onDownload && (
                  <button
                  disabled={disabled && selected}
                  onClick={()=> onDownload()}
                    className={
                      "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-4"
                    }
                  >
                    {t("14052021.downloadPDF")}
                    
                  </button>
                )}
                <button
                  className={
                    "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
                  }
                  onClick={() => {
                    if (selected) {
                      if (onUpdate) onUpdate();
                      
                    } else {
                      onCreate();
                    }
                    setCambiosSinGuardar(false);
                  }}
                  disabled={disabled && selected}
                >
                  <img
                    src={add_icon}
                    alt="Añadir"
                    className="h-6 transform scale-50"
                    style={{ filter: "invert()" }}
                  />
                  <p className="pl-2 truncate">
                    {selected ? t("14052021.topbar.guardar") : createText}
                  </p>
                </button>
              </div>
            ) : (
              <select
                className=" bg-sf-mediumblue py-1 pr-4 pl-2 rounded-full text-white"
                onChange={(e) => onCreate(e.target.value)}
              >
                <option disabled selected hidden>
                  {t("14052021.topbar.nuevo")}
                </option>
                {createOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            )}

            <button
              className={
                "hover:bg-red-500 py-1 px-4 rounded-full " +
                ((selected && selected?.id) || (marked && marked > 0)
                  ? ""
                  : "invisible")
              }
              onClick={() => setModalContent(removeModal())}
            >
              <img
                src={minus_icon}
                alt="Borrar"
                className="h-6"
                style={{ filter: "invert()" }}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Topbar;
