import React, { useEffect, useState } from "react";
import loadingGif from "../images/loading.gif";
import useStore from "../store";
import Topbar from "../components/Topbar";
import {
  ExperienceData,
} from "../CMSUtils/Icms";
import { AddCMSFile, DeleteCMSFile } from "../CMSUtils/FileCalls";
import {
  AddCMSExperiences,
  DeleteCMSExperiences,
  GetGoalkeeperExperiences,
  LoadCMSExperiences,
  UpdateCMSExperiences,
} from "../CMSUtils/ExperienceCalls";
import ExperienceItem from "../components/Experience/ExperienceItem";
import GoalkeeperExperienceDetail from "../components/Experience/GoalkeeperExperienceDetail";
import { useTranslation } from "react-i18next";
import { goalkeeperCategories } from "../data/arrays";

const GoalkeeperExperiences = (props: any) => {
  const { t } = useTranslation();
  const token = useStore((state) => state.token);
  const [cargandoDatos, setCargandoDatos] = useState(false);
  const loggedUser = useStore((state) => state.loggedUser);
  const [categoria, setCategoria] = useState("");
  const [valid, setValid] = useState<boolean>(false);
  const [listaExperiencias, setListaExperiencias] =
    useState<ExperienceData[]>();
  const [experienciaSeleccionada, setExperienciaSeleccionada] =
    useState<ExperienceData>();

  useEffect(() => {
    let isMounted = true;
    setExperienciaSeleccionada(
      props.location.state ? props.location?.state.data : undefined
    );
    if (!cargandoDatos) fetchData().then(e => {
      if(isMounted){
        setListaExperiencias(e);
      }
    });

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state?.data]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSExperiences(token);

    let experiences = GetGoalkeeperExperiences();
    if (!experiences) experiences = [];
    return experiences;
    //setListaExperiencias(experiences);
  }

  async function borrarExperiencia() {
    if (!token) return;
    setCargandoDatos(true);

    if (experienciaSeleccionada) {
      await DeleteCMSExperiences(experienciaSeleccionada.id, token);
      setListaExperiencias(await fetchData());

      setExperienciaSeleccionada(undefined);
      props.history.push("/experiencias/porteros");
    }
    setCargandoDatos(false);
  }

  async function actualizarExperiencia() {
    if (!experienciaSeleccionada || !token) return;

    setCargandoDatos(true);

    //Si no está creada en base de datos, la creamos y guardamos el ID
    if (!experienciaSeleccionada.id) {
      const experienceID = await crearExperiencia(experienciaSeleccionada);
      if (experienceID === -1) return;
      experienciaSeleccionada.id = experienceID;
    }

    if (imagen) {
      const imageID = await AddCMSFile(imagen, generateUniqueString(), token);
      await DeleteCMSFile(experienciaSeleccionada.file_1_id, token);

      experienciaSeleccionada.file_1_id = imageID;
    }

    await UpdateCMSExperiences(experienciaSeleccionada, token);
    setListaExperiencias(await fetchData());

    setImagen(undefined);
    setCargandoDatos(false);
    setExperienciaSeleccionada(undefined);
  }

  async function crearExperiencia(experiencia: ExperienceData) {
    if (!token) return -1;

    let exp = Object.assign({}, experiencia);
    exp.guidelines = [];

    return await AddCMSExperiences(exp, token);
  }

  function updateFiltro(busqueda: string) {
    let experiences = GetGoalkeeperExperiences();

    if (!experiences) return;

    let filteredExperiences = experiences.filter((e) =>
      e.name_es.toLowerCase().includes(busqueda.toLowerCase())
    );
    setListaExperiencias(filteredExperiences);
  }

  /*     OLD DATA     */
  const [imagen, setImagen] = useState<File>();

  function generateUniqueString() {
    var ts = String(new Date().getTime()),
      i = 0,
      out = "";

    for (i = 0; i < ts.length; i += 2) {
      out += Number(ts.substr(i, 2)).toString(36);
    }

    return ts + "-" + out;
  }

  const uploadImage = (image: File | undefined) => {
    setImagen(image);
  };

  return (
    <React.Fragment>
      <div className="flex flex-col h-full z-0">
        <Topbar
          visibleButtons={loggedUser?.role.name === "Admin" && categoria !== ""}
          selected={experienciaSeleccionada}
          createText={t("experience_goalkeeper.add")}
          onBack={() => {
            props.history.goBack();
            if (!experienciaSeleccionada) {
              setCategoria("");
            }
          }}
          disabled={!valid}
          forceBack={categoria !== ""}
          onFilter={(text: string) => updateFiltro(text)}
          onCreate={() =>
            props.history.push({
              pathname: props.location.pathname + "/create",
              state: {
                data: Object.assign({
                  type: "goalkeeper",
                  generalType: "shared",
                  category: categoria,
                  guidelines: [] as any[],
                } as ExperienceData),
              },
            })
          }
          onDelete={() => borrarExperiencia()}
          onUpdate={() => {
            actualizarExperiencia();
            props.history.goBack();
          }}
        />
        <div
          className={
            "mt-8 w-full flex-1 overflow-auto" +
            (experienciaSeleccionada ? " bg-f2f2f2" : "")
          }
        >
          {categoria ? (
            experienciaSeleccionada ? (
              <GoalkeeperExperienceDetail
                selected={experienciaSeleccionada}
                uploadImage={uploadImage}
                image={imagen}
                valid={(value) => setValid(value)}
              />
            ) : (
              <div className="mx-auto flex flex-col w-full h-full">
                <div className="bg-f2f2f2 flex-1">
                  <div className="grid grid-cols-4 w-full py-12 px-16 gap-8">
                    {listaExperiencias && !cargandoDatos ? (
                      listaExperiencias.filter(
                        (xp) => xp.category === categoria
                      ) ? (
                        listaExperiencias
                          .filter((xp) => xp.category === categoria)
                          .map((experiencia: ExperienceData, i: number) => {
                            //checkboxSelectedClubs[club.id] = false;
                            return (
                              <ExperienceItem
                                key={i}
                                onClick={() => {
                                  props.history.push({
                                    pathname:
                                      props.location.pathname + "/detail",
                                    state: {
                                      data: Object.assign({}, experiencia),
                                    },
                                  });
                                }}
                                datos={experiencia}
                                index={i}
                              />
                            );
                          })
                      ) : (
                        <div className="flex h-full w-full">
                          <div className="m-auto">
                            {t("29082021.no_content")}
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="flex h-full w-full">
                        <div className="m-auto flex flex-col">
                          <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
                          <p>{t("29082021.loading.generic")}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="mx-auto flex flex-col w-full h-full">
              <div className="bg-f2f2f2 flex-1 p-6">
                {goalkeeperCategories.map((c, ind) => (
                  <div key={ind} className="flex flex-col max-w-generic mx-auto my-8">
                    <h1 className="text-2xl px-4">{t(c.category)}</h1>
                    <div className="grid grid-cols-4 w-full py-8 gap-8">
                      {c.subcategories.map((s, index) => (
                        <div
                        key={index}
                          className={
                            "p-4 shadow-md cursor-pointer flex h-40 justify-center flex-col " +
                            (c.category !== ""
                              ? "bg-white"
                              : "bg-gray-700 text-white")
                          }
                          onClick={() => {
                            setCategoria(s.value);
                            props.history.push({
                              state: {
                                category: s.value,
                              },
                            });
                          }}
                        >
                          <div className="h-16 text-2xl">{s.code}</div>
                          <div
                            className={
                              "w-1/4 border-b-2 " +
                              (c.category !== ""
                                ? "border-sf-darkblue"
                                : "border-white")
                            }
                          ></div>
                          <div className="h-24 py-4 overflow-hidden">
                            {t(s.text)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default GoalkeeperExperiences;
