import React from "react";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { TeamData } from "../../CMSUtils/Icms";
import team_icon from "../../images/icons/team.svg";

const ClubItem: React.FC<{
  onCheck: (e: any) => void;
  onClick: () => void;
  datos: TeamData;
  index: number;
}> = ({ onCheck, onClick, datos, index }) => {
  console.log(datos);
  return (
    <div
      onClick={onClick}
      key={index}
      className={
        "w-full cursor-pointer font-bold " +
        ((index + 1) % 2 === 0
          ? " bg-f2f2f2 hover:bg-gray-500"
          : "bg-white text-gray-800 hover:bg-gray-200")
      }
    >
      <div className="w-full xl:w-3/4 flex flex-row mx-auto py-2">
        <div className="w-1/12 flex flex-row px-1 self-center">
          <input
            type="checkbox"
            name="select"
            value="select"
            className="cursor-pointer my-auto transform mx-2"
            onChange={(e) => onCheck(e)}
            onClick={(e) => e.stopPropagation()}
            //checked={clubsMarcados.includes(club.id) || false}
          />
          {datos.image && (
            <img
              src={cmsStorageURL + datos.image?.file}
              alt="placeholder"
              style={{ maxWidth: "35px", maxHeight: "35px", margin: "auto" }}
            />
          )}
          {!datos.image && (
            <img
              src={team_icon}
              alt="placeholder"
              className="p-1 bg-gray-500 opacity-25"
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                margin: "auto",
              }}
            />
          )}
        </div>
        <div className="w-3/12 px-1 self-center truncate">
          {datos.team_name}
        </div>
        <div className="w-1/12 px-1 self-center truncate">{datos.category}</div>
        <div className={"w-2/12 px-1 self-center truncate"}>
          {datos.club?.entity_name}
        </div>
        <div className={"w-3/12 px-1 self-center"}>
          <p>{datos.staffs?.find(s => s.role === "Entrenador")?.staff?.name}</p>
        </div>
        <div
          className={
            "w-2/12 self-center px-1" +
            (new Date(datos.temp_end) < new Date(Date.now())
              ? " text-red-700 font-bold"
              : "")
          }
        >
          {" "}
          {datos.temp_start
            ? new Date(datos.temp_start).toLocaleString().split(" ")[0]
            : "/"}{" "}
          -{" "}
          {datos.temp_end
            ? new Date(datos.temp_end).toLocaleString().split(" ")[0]
            : "/"}
        </div>
      </div>
    </div>
  );
};

export default ClubItem;
