import React, { useEffect, useState } from "react";
import { PersonDetail } from "../Person/PersonDetail";
import club_icon from "../../images/icons/clubs-icon.svg";
import add_icon from "../../images/icons/add-icon.svg";
import close_icon from "../../images/icons/close.svg";

import "../../styles/fa.css";
import "../../styles/style.css";

import CollapsablePanel from "../CollapsablePanel";
import ImageField from "../ImageField";
import loadingGif from "../../images/loading.gif";
import DataField from "../DataField";
import {
  Hito,
  Idioma,
  PersonData,
  Report,
  ReportData,
  StaffData,
} from "../../CMSUtils/Icms";
import useStore from "../../store";
import {
  GetStaffByID,
} from "../../CMSUtils/StaffCalls";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { GetClubs, LoadCMSClubs } from "../../CMSUtils/ClubCalls";
import { staff_titles } from "../../data/arrays";
import { getReportData } from "../../data/StaffReportHelper";
import { Radar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { CMSChangePass } from "../../CMSUtils/LoginCalls";

export const StaffDetail: React.FC<{
  selected: StaffData;

  uploadImage: (image: File | undefined) => void;
  image: File | undefined;
  validForm: (state: boolean) => void;
}> = ({ uploadImage, selected, image, validForm }) => {
  const { t } = useTranslation();
  const token = useStore((state) => state.token);
  const loggedUser = useStore((state) => state.loggedUser);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const [isLoaded, setIsLoaded] = useState(false);
  const [nombreInforme, setNombreInforme] = useState("");
  const [informes, setInformes] = useState<Report[]>(
    selected.evaluations[0]?.reports ?? []
  );
  const [listaIdiomas, setListaIdiomas] = useState<Idioma[]>(
    selected.job_languages ? JSON.parse(selected.job_languages) : []
  );
  const setModalContent = useStore((state) => state.setModalContent);
  const [listaHitos, setListaHitos] = useState<Hito[]>(
    selected.sport_history ? JSON.parse(selected.sport_history) : []
  );
  const [sportRol, setSportRol] = useState(selected.sport_rol);

  useEffect(() => {
    let isMounted = true;
    if (loggedUser?.role.name === "Club") {
      selected.club_id = loggedUser?.club_id;
    }
    fetchData().then(()=>{if(isMounted){
      setIsLoaded(true);
    }});
    if (!selected.agent) {
      selected.agent = {} as PersonData;
    }

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSClubs(token);
    if (loggedUser && loggedUser.role.name === "Coordinator") {
      let coord = GetStaffByID(loggedUser.staff_id)?.club_id;
      if (coord) {
        selected.club_id = coord;
      }
    }
    return null;
  }

  function getClubNames() {
    if (!GetClubs()) return [];
    let clubs: any[] = [];
    GetClubs()?.map((c) => clubs.push({ value: c.id, text: c.entity_name }));
    return clubs;
  }
  function getAverage(report: Report, type: string) {
    let avgScore: number = 0;
    let count = 0;
    report.data.forEach((r) => {
      if ((r.type.startsWith(`${type}|`) || r.type === type) && r.score > 0) {
        count++;
        avgScore += Number(r.score);
      }
    });

    let avg = Math.round((avgScore / count) * 100) / 100;

    let avgData = report.data.find((r) => r.type === `${type}Average`);
    if (avgData) {
      avgData.score = avg;
    } else {
      report.data.push({
        type: `${type}Average`,
        score: avg,
      } as ReportData);
    }
  }

  function reportDetail(
    report: Report,
    title: string,
    section: string = "experiences"
  ) {
    return (
      <div>
        <div className="w-full flex">
          <div className="w-full grid grid-cols-10 mx-auto bg-sf-darkblue text-gray-400 border-r border-gray-200 text-center">
            <div
              className={
                "cursor-pointer py-3 px-2" +
                (section === "experiences"
                  ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, "experiences"),
                  title
                )
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_interaction")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "experiencesAverage")
                  ?.score ?? "") >= 0
                  ? report.data.find((r) => r.type === "experiencesAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3 px-2" +
                (section === "group" ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white" : "")
              }
              onClick={() =>
                setModalContent(reportDetail(report, title, "group"), title)
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_manag")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "groupAverage")?.score ??
                  "") >= 0
                  ? report.data.find((r) => r.type === "groupAverage")?.score ??
                    ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3 px-2" +
                (section === "game" ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white" : "")
              }
              onClick={() =>
                setModalContent(reportDetail(report, title, "game"), title)
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_comp")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "gameAverage")?.score ??
                  "") >= 0
                  ? report.data.find((r) => r.type === "gameAverage")?.score ??
                    ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3 px-2" +
                (section === "training"
                  ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(reportDetail(report, title, "training"), title)
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_design")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "trainingAverage")
                  ?.score ?? "") >= 0
                  ? report.data.find((r) => r.type === "trainingAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer px-2 py-3" +
                (section === "values" ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white" : "")
              }
              onClick={() =>
                setModalContent(reportDetail(report, title, "values"), title)
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_values")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "valuesAverage")?.score ??
                  "") >= 0
                  ? report.data.find((r) => r.type === "valuesAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3 px-2" +
                (section === "feelings"
                  ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(reportDetail(report, title, "feelings"), title)
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_emotions")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "feelingsAverage")
                  ?.score ?? "") >= 0
                  ? report.data.find((r) => r.type === "feelingsAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3 px-2" +
                (section === "verbal" ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white" : "")
              }
              onClick={() =>
                setModalContent(reportDetail(report, title, "verbal"), title)
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_verbal")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "verbalAverage")?.score ??
                  "") >= 0
                  ? report.data.find((r) => r.type === "verbalAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3 px-2" +
                (section === "nonverbal"
                  ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(reportDetail(report, title, "nonverbal"), title)
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_non_verbal")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "nonverbalAverage")
                  ?.score ?? "") >= 0
                  ? report.data.find((r) => r.type === "nonverbalAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3 px-2" +
                (section === "competition"
                  ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, "competition"),
                  title
                )
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_competition")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "competitionAverage")
                  ?.score ?? "") >= 0
                  ? report.data.find((r) => r.type === "competitionAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3 px-2" +
                (section === "selection"
                  ? " border-b-2 border-white bg-sf-mediumblue bg-opacity-50 flex flex-col text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(reportDetail(report, title, "selection"), title)
              }
            >
              <p className="overflow-hidden">{t("14052021.staff.report_experience")}</p>
              <p className="pt-1 mt-auto">
                {(report.data.find((r) => r.type === "selectionAverage")
                  ?.score ?? "") >= 0
                  ? report.data.find((r) => r.type === "selectionAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full">
          {report.data.map((r,index) => {
            if (r.type.startsWith(section) && !r.type.includes("Average")) {
              return (
                <div key={index} className={"w-1/2 flex border-b border-gray-400 py-2" + (index%2 === 0 ? " bg-gray-400": "")}>
                  <div className="w-10/12 pr-1">{t(r.type.split("|")[1])}</div>
                  <div className="w-2/12 px-1">
                    <DataField
                      data={r.score >= 0 ? r.score : ""}
                      placeholder="1-10"
                      max={10}
                      min={0}
                      type="number"
                      onChange={(e) => {
                        r.score = e;
                        getAverage(report, section);
                        setModalContent(
                          reportDetail(report, title, section),
                          title
                        );
                      }}
                    />
                  </div>
                </div>
              );
            }
            return undefined;
          })}
        </div>
      </div>
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <form
        onSubmit={(e) => e.preventDefault()}
        onChange={(e) => {
          setCambiosSinGuardar(true);
          validForm(
            e.currentTarget.checkValidity() &&
              selected.club_id !== null &&
              selected.sport_rol !== null &&
              selected.sport_rol !== undefined
          );
        }}
        onLoad={(e) =>
          validForm(
            e.currentTarget.checkValidity() &&
              selected.club_id !== null &&
              selected.sport_rol !== null &&
              selected.sport_rol !== undefined
          )
        }
      >
        {(sportRol === "Trainer" ||
          sportRol === "SecondTrainer" ||
          sportRol === "Scout" ||
          sportRol === "Coordinator" ||
          sportRol === "BiologicTrainer" ||
          sportRol === "GoalkeeperTrainer") && (
          <CollapsablePanel titulo={t("staff.credentials.title")}>
            <table className="table-fixed w-full">
              <tbody style={{ verticalAlign: "top" }}>
                <tr>
                  <td className="w-4/12 py-4 px-6">
                    <DataField
                      data={selected.user?.name ?? selected.userName}
                      label={t("staff.credentials.username")}
                      onChange={(e) => (selected.userName = e)}
                      disabled={selected.user ? true : false}
                    />
                  </td>
                  <td className="w-4/12 py-4 px-6">
                    <DataField
                      data={selected.user?.email ?? selected.userEmail}
                      label={t("staff.credentials.email")}
                      onChange={(e) => (selected.userEmail = e)}
                      disabled={selected.user ? true : false}
                    />
                  </td>
                  
                    <td className="w-4/12 py-4 px-6">
                      <DataField
                        data={selected.userPass}
                        label={t("staff.credentials.password")}
                        type="password"
                        onChange={(e) => (selected.userPass = e)}
                      />
                    </td>
                    <td className="w-2/12 align-middle">{selected.user && <button
                      className="self-center bg-sf-mediumblue rounded-full text-white px-4 mt-4"
                      onClick={() =>CMSChangePass(token, selected.user!.email, "", selected.userPass)
                      }
                    >
                      {t("club.credentials.change_pass")}
                    </button>}
                    </td>
                </tr>
              </tbody>
            </table>
          </CollapsablePanel>
        )}
        <CollapsablePanel titulo={t("staff.data.title")} colapsado={false}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td rowSpan={3} className="w-1/3">
                  <div
                    className="flex flex-col m-auto"
                    style={{ width: "14rem" }}
                  >
                    <ImageField
                      emptyImage={club_icon}
                      uploadedImage={image}
                      uploadImage={uploadImage}
                      defaultImage={
                        selected.image
                          ? cmsStorageURL + selected.image.file
                          : undefined
                      }
                    />
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.name}
                    label={t("staff.data.name")}
                    onChange={(e) => (selected.name = e)}
                    required
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.surname}
                    label={t("staff.data.surname")}
                    onChange={(e) => (selected.surname = e)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.birthday}
                    label={t("staff.data.birthdate")}
                    type="date"
                    onChange={(e) => (selected.birthday = e)}
                    required
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.birth_country}
                    type="country"
                    label={t("staff.data.country")}
                    onChange={(e) => (selected.birth_country = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.dni}
                    label={t("staff.data.dni")}
                    placeholder={t("staff.data.dni_placeholder")}
                    onChange={(e) => (selected.dni = e)}
                  />
                </td>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.dni_expiration_date}
                    label={t("staff.data.dni_expiration")}
                    type="date"
                    onChange={(e) => (selected.dni_expiration_date = e)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="py-4 px-6">
                  <DataField
                    data={selected.email}
                    label={t("staff.data.email")}
                    onChange={(e) => (selected.email = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.phone_number}
                    label={t("staff.data.phone")}
                    onChange={(e) => (selected.phone_number = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  {loggedUser?.role.name === "Admin" && (
                    <DataField
                      data={selected.club_id}
                      type="select"
                      label={t("staff.data.club")}
                      options={getClubNames()}
                      placeholder={t("staff.data.club_placeholder")}
                      onChange={(e) => {
                        selected.club_id = e;
                        fetchData();
                        //getJugadoresSinEquipo();
                      }}
                      required
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="pt-4 pb-8 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-1">
                      <DataField
                        data={selected.addres}
                        label={t("staff.data.address")}
                        type="textarea"
                        onChange={(e) => (selected.addres = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        data={selected.actual_addres}
                        label={t("staff.data.actual_address")}
                        type="textarea"
                        onChange={(e) => (selected.actual_addres = e)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <div className="flex">
                    <div className="w-1/2 flex">
                      <div className="1/3 px-6">
                        {t("staff.data.driving_license")}
                        <div
                          className="flex justify-center"
                          onChange={(e: any) =>
                            (selected.driving_license =
                              e.target.value === "si" ? true : false)
                          }
                        >
                          <input
                            className="self-center"
                            type="radio"
                            id="cy"
                            name="carnet"
                            value="si"
                            defaultChecked={selected.driving_license === true}
                          />
                          <label className="self-center pl-1 pr-5" htmlFor="cy">
                            {t("staff.data.driving_yes")}
                          </label>
                          <input
                            className="self-center pl-5"
                            type="radio"
                            id="cn"
                            name="carnet"
                            value="no"
                            defaultChecked={selected.driving_license === false}
                          />
                          <label className="self-center pl-1" htmlFor="cn">
                            {t("staff.data.driving_no")}
                          </label>
                        </div>
                      </div>
                      <div className="1/3 ml-auto">
                        {t("staff.data.own_car")}
                        <div
                          className="flex justify-center"
                          onChange={(e: any) =>
                            (selected.own_car =
                              e.target.value === "si" ? true : false)
                          }
                        >
                          <input
                            className="self-center"
                            type="radio"
                            id="cpy"
                            name="coche"
                            value="si"
                            defaultChecked={selected.own_car === true}
                          />
                          <label
                            className="self-center pl-1 pr-5"
                            htmlFor="cpy"
                          >
                            {t("staff.data.own_yes")}
                          </label>
                          <input
                            className="self-center pl-5"
                            type="radio"
                            id="cpn"
                            name="coche"
                            value="no"
                            defaultChecked={selected.own_car === false}
                          />
                          <label className="self-center pl-1" htmlFor="cpn">
                            {t("staff.data.own_no")}
                          </label>
                        </div>
                      </div>
                      <div className="1/3 px-6">
                        <DataField
                          data={selected.license_points}
                          label={t("staff.data.license_points")}
                          placeholder="XX"
                          type="number"
                          onChange={(e) => (selected.license_points = e)}
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="py-4 px-6">
                  <DataField
                    data={selected.instagram}
                    label={t("staff.data.instagram")}
                    placeholder={t("staff.data.instagram_placeholder")}
                    onChange={(e) => (selected.instagram = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.facebook}
                    label={t("staff.data.facebook")}
                    placeholder={t("staff.data.facebook_placeholder")}
                    onChange={(e) => (selected.facebook = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.twitter}
                    label={t("staff.data.twitter")}
                    placeholder={t("staff.data.twitter_placeholder")}
                    onChange={(e) => (selected.twitter = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.web}
                    label={t("staff.data.web")}
                    placeholder={t("staff.data.web_placeholder")}
                    onChange={(e) => (selected.web = e)}
                  />
                </td>
                <td colSpan={2} className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.other_socials}
                    label={t("staff.data.other")}
                    placeholder={t("staff.data.other_placeholder")}
                    onChange={(e) => (selected.other_socials = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>

        <CollapsablePanel titulo={t("staff.family.title")}>
          <table className="w-full table-fixed my-2">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td rowSpan={2} className="py-4 pl-6 pr-1">
                  <DataField
                    data={selected.core_composition}
                    label={t("staff.family.core_composition")}
                    type="textarea"
                    onChange={(e) => (selected.core_composition = e)}
                  />
                </td>
                <td rowSpan={2} className="py-4 pr-6 pl-1">
                  <DataField
                    data={selected.comentary}
                    label={t("staff.family.comentary")}
                    type="textarea"
                    onChange={(e) => (selected.comentary = e)}
                  />
                </td>
                <td className="py-4 pr-6 pl-1">
                  <DataField
                    data={selected.civil_state}
                    label={t("staff.family.civil_state")}
                    type="select"
                    flexible
                    options={[
                      {
                        value: "",
                        text: t("01122021.select_placeholders.level"),
                      },
                      {
                        value: "civil_single",
                        text: t("staff.family.civil_single"),
                      },
                      {
                        value: "civil_married",
                        text: t("staff.family.civil_married"),
                      },
                    ]}
                    onChange={(e) => (selected.civil_state = e)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <DataField
                    data={selected.partner_name}
                    label={t("staff.family.partner_name")}
                    onChange={(e) => (selected.partner_name = e)}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div className="flex">
            <div className="w-1/3">
              <PersonDetail
                tipo={selected.contact_1_type}
                person={selected.contact_1}
                onUpdate={(e, t) => {
                  selected.contact_1 = e as any;
                  selected.contact_1_type = t as any;
                }}
                hasTipo
                hasLang
              />
            </div>
            <div className="w-1/3">
              <PersonDetail
                tipo={selected.contact_2_type}
                person={selected.contact_2}
                onUpdate={(e, t) => {
                  selected.contact_2 = e as any;
                  selected.contact_2_type = t as any;
                }}
                hasTipo
                hasLang
              />
            </div>
            <div className="w-1/3">
              <DataField
                data={selected.comentary}
                label={t("staff.family.partner_comments")}
                type="textarea"
                onChange={(e) => (selected.comentary = e)}
              />
            </div>
          </div>
        </CollapsablePanel>

        <CollapsablePanel titulo={t("staff.job.title")} colapsado={false}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <div className="pr-1 text-center">
                    {t("staff.job.excl")}
                    <div
                      className="flex justify-center"
                      onChange={(e: any) =>
                        (selected.club_excl =
                          e.target.value === "si" ? true : false)
                      }
                    >
                      <input
                        className="self-center"
                        type="radio"
                        id="cy"
                        name="exclusividad"
                        value="si"
                        defaultChecked={selected.club_excl === true}
                      />
                      <label className="self-center pl-1 pr-5" htmlFor="cy">
                        {t("staff.job.excl_yes")}
                      </label>
                      <input
                        className="self-center pl-5"
                        type="radio"
                        id="cn"
                        name="exclusividad"
                        value="no"
                        defaultChecked={selected.club_excl === false}
                      />
                      <label className="self-center pl-1" htmlFor="cn">
                        {t("staff.job.excl_no")}
                      </label>
                    </div>
                  </div>
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.main_job}
                    label={t("staff.job.main_job")}
                    onChange={(e) => (selected.main_job = e)}
                  />
                </td>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.job_addres}
                    type="textarea"
                    label={t("staff.job.address")}
                    onChange={(e) => (selected.job_addres = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.job_site}
                    label={t("staff.job.position")}
                    onChange={(e) => (selected.job_site = e)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.main_job}
                    label={t("staff.job.trainer_title")}
                    type="select"
                    placeholder={t("01122021.select_placeholders.level")}
                    flexible
                    options={[
                      { value: "uefa_d", text: "UEFA D" },
                      { value: "uefa_c", text: "UEFA C" },
                      { value: "uefa_b", text: "UEFA B" },
                      { value: "uefa_a", text: "UEFA A" },
                      { value: "uefa_pro", text: "UEFA Pro" },
                    ]}
                    onChange={(e) => (selected.main_job = e)}
                  />
                </td>
                <td rowSpan={3} className="w-1/3 px-6 py-4">
                  {listaIdiomas.map((i, index) => (
                    <div className="flex">
                      <div className="w-3/6 pr-1">
                        <DataField
                          data={i.value}
                          label={t("staff.job.lang")}
                          onChange={(e) => {
                            i.value = e;
                            selected.job_languages =
                              JSON.stringify(listaIdiomas);
                          }}
                          required
                        />
                      </div>
                      <div className="w-2/6 pl-1">
                        <DataField
                          data={i.level}
                          label={t("staff.job.level")}
                          type="select"
                          flexible
                          placeholder={t("01122021.select_placeholders.level")}
                          options={[
                            {
                              value: "job_low",
                              text: t("staff.job.level_low"),
                            },
                            {
                              value: "job_medium",
                              text: t("staff.job.level_medium"),
                            },
                            {
                              value: "job_high",
                              text: t("staff.job.level_high"),
                            },
                            {
                              value: "job_max",
                              text: t("staff.job.level_max"),
                            },
                          ]}
                          onChange={(e) => {
                            i.level = e;
                            selected.job_languages =
                              JSON.stringify(listaIdiomas);
                          }}
                          required
                        />
                      </div>
                      <div
                        className="w-1/6 pl-1 flex-1 flex cursor-pointer pt-4"
                        onClick={() => {
                          listaIdiomas.splice(index, 1);
                          setListaIdiomas(listaIdiomas.map((idioma) => idioma));
                          selected.job_languages = JSON.stringify(listaIdiomas);
                        }}
                      >
                        <p className="self-center m-auto">
                          <img src={close_icon} className="h-3 w-3" alt="close" />
                        </p>
                      </div>
                    </div>
                  ))}
                  <button
                    className={
                      "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full mt-4"
                    }
                    onClick={() => {
                      listaIdiomas.push({} as Idioma);
                      setListaIdiomas(listaIdiomas.map((i) => i));
                    }}
                  >
                    <img
                      src={add_icon}
                      alt="add"
                      className="h-6 p-1 text-center m-auto"
                    />
                    <p>{t("staff.job.add_lang")}</p>
                  </button>
                </td>
                <td rowSpan={3} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.job_comentary}
                    label={t("staff.job.trainer_title_text")}
                    type="textarea"
                    onChange={(e) => (selected.job_comentary = e)}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.academic_titles}
                    label={t("staff.job.academic")}
                    type="textarea"
                    onChange={(e) => (selected.academic_titles = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("staff.sport.title")} colapsado={false}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.sport_name}
                    label={t("staff.sport.name")}
                    onChange={(e) => (selected.sport_name = e)}
                  />
                </td>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.sport_aspiring}
                    label={t("staff.sport.asp")}
                    type="textarea"
                    onChange={(e) => (selected.sport_aspiring = e)}
                  />
                </td>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.projection}
                    type="select"
                    flexible
                    placeholder={t("01122021.select_placeholders.level")}
                    label={t("staff.sport.projection")}
                    options={[
                      {
                        value: "project_max",
                        text: t("staff.sport.projection_max"),
                      },
                      {
                        value: "project_high",
                        text: t("staff.sport.projection_high"),
                      },
                      {
                        value: "project_medium",
                        text: t("staff.sport.projection_medium"),
                      },
                      {
                        value: "project_low",
                        text: t("staff.sport.projection_low"),
                      },
                    ]}
                    onChange={(e) => (selected.projection = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.sport_rol}
                    label={t("staff.sport.role")}
                    type="select"
                    options={staff_titles}
                    onChange={(e) => {
                      selected.sport_rol = e;
                      setSportRol(e);
                    }}
                    placeholder={t("staff.sport.role_placeholder")}
                    disabled={selected.user ? true : false}
                    required
                  />
                </td>
              </tr>
              <tr className="w-full border-t-2 border-gray-200">
                <td colSpan={3} className="px-6 py-4">
                  {t("staff.sport.sport_history")}
                </td>
              </tr>
              {listaHitos.map((i, index) => (
                <tr>
                  <td className="px-6 py-4 flex" style={{ margin: "0px" }}>
                    <div className="w-1/2 pr-1">
                      <DataField
                        data={i.season}
                        flexible
                        placeholder={t("staff.sport.history_season")}
                        onChange={(e) => {
                          i.season = e;
                          selected.sport_history = JSON.stringify(listaHitos);
                        }}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        data={i.rol}
                        type="select"
                        flexible
                        placeholder={t("staff.sport.history_role")}
                        options={staff_titles}
                        onChange={(e) => {
                          i.rol = e;
                          selected.sport_history = JSON.stringify(listaHitos);
                        }}
                      />
                    </div>
                  </td>

                  <td className="w-1/3 px-6 py-4">
                    <DataField
                      data={i.club}
                      placeholder={t("staff.sport.history_club")}
                      onChange={(e) => {
                        i.club = e;
                        selected.sport_history = JSON.stringify(listaHitos);
                      }}
                    />
                  </td>
                  <td>
                    <div
                      className="w-1/6 pl-1 flex-1 flex cursor-pointer pt-4"
                      onClick={() => {
                        listaHitos.splice(index, 1);
                        setListaHitos(listaHitos.map((hito) => hito));
                        selected.sport_history = JSON.stringify(listaHitos);
                      }}
                    >
                      <p className="self-center">
                        <img src={close_icon} className="h-5 w-5 py-1" alt="close" />
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td className="px-6 py-4">
                  <button
                    className={
                      "flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
                    }
                    onClick={() => {
                      listaHitos.push({} as Hito);
                      setListaHitos(listaHitos.map((h) => h));
                    }}
                  >
                    <img
                      src={add_icon}
                      alt="Añadir"
                      className="h-6 transform scale-50"
                      style={{ filter: "invert()" }}
                    />
                    <p className="pl-2">{t("staff.sport.add_history")}</p>
                  </button>
                </td>
              </tr>
              <tr className="w-full border-t-2 border-gray-200">
                <td className="w-1/3 px-6 py-4" style={{ margin: "0px" }}>
                  <DataField
                    data={selected.inc_date}
                    type="date"
                    label={t("staff.sport.inc_date")}
                    onChange={(e) => (selected.inc_date = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.procedence_club}
                    label={t("staff.sport.procedence_club")}
                    onChange={(e) => (selected.procedence_club = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.club_temps}
                    label={t("staff.sport.club_temps")}
                    onChange={(e) => (selected.club_temps = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.end_contract}
                    label={t("staff.sport.end_contract")}
                    type="date"
                    onChange={(e) => (selected.end_contract = e)}
                  />
                </td>
                <td colSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.previous_coordinators}
                    label={t("staff.sport.previous_coord")}
                    onChange={(e) => (selected.previous_coordinators = e)}
                  />
                </td>
              </tr>
              <tr className="w-full border-t-2 border-gray-200">
                <td className="px-6 py-4" style={{ margin: "0px" }}>
                  <DataField
                    data={selected.agent?.name}
                    label={t("staff.sport.agent_name")}
                    onChange={(e) => (selected.agent.name = e)}
                  />
                </td>
                <td rowSpan={3} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.internal_relations}
                    label={t("staff.sport.internal_relations")}
                    type="textarea"
                    onChange={(e) => (selected.internal_relations = e)}
                  />
                </td>
                <td rowSpan={3} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.sport_comentary}
                    label={t("staff.sport.commentary")}
                    type="textarea"
                    onChange={(e) => (selected.sport_comentary = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.agent?.phone_number}
                    label={t("staff.sport.phone")}
                    onChange={(e) => (selected.agent.phone_number = e)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.agent?.email}
                    label={t("staff.sport.email")}
                    onChange={(e) => (selected.agent.email = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("staff.report.title")}>
            <div className="flex">
              <DataField
                data={undefined}
                onChange={(v) => setNombreInforme(v)}
                placeholder={t("14052021.player.report_name")}
              />
              <button
                className={
                  "flex ml-6 py-1 pr-4 pl-2 rounded-full text-white mr-2 " + ((nombreInforme === null || nombreInforme === undefined || nombreInforme === "") ? "bg-gray-500 hover:bg-gray-400": "bg-sf-mediumblue hover:bg-sf-lightblue")
                }
                onClick={() => {
                  if(nombreInforme === null || nombreInforme === undefined || nombreInforme === "")return;
                  informes.push({ data: getReportData(), name: nombreInforme } as Report);
    
                  setInformes(informes.map((i) => i));
                  selected.evaluations[0].reports = informes;
                }}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("player.reports.new_report")}</p>
              </button>
            </div>
          <div className="grid grid-cols-2">
            {informes.map((informe, index) => (
              <div key={index} className="px-12">
                <div className="py-2">
                  <Radar
                    data={{
                      labels: [
                        t("staff.report.report_interacion"),
                        t("staff.report.report_group"),
                        t("staff.report.report_game"),
                        t("staff.report.report_design"),
                        t("staff.report.report_values"),
                        t("staff.report.report_feelings"),
                        t("staff.report.report_verbal"),
                        t("staff.report.report_non_verbal"),
                        t("staff.report.report_competition"),
                        t("staff.report.report_experiences"),
                      ],
                      datasets: [
                        {
                          data: [
                            informe.data?.find(
                              (i) => i.type === "experiencesAverage"
                            )?.score,
                            informe.data?.find((i) => i.type === "groupAverage")
                              ?.score,
                            informe.data?.find((i) => i.type === "gameAverage")
                              ?.score,
                            informe.data?.find(
                              (i) => i.type === "trainingAverage"
                            )?.score,
                            informe.data?.find(
                              (i) => i.type === "valuesAverage"
                            )?.score,
                            informe.data?.find(
                              (i) => i.type === "feelingsAverage"
                            )?.score,
                            informe.data?.find(
                              (i) => i.type === "verbalAverage"
                            )?.score,
                            informe.data?.find(
                              (i) => i.type === "nonverbalAverage"
                            )?.score,
                            informe.data?.find(
                              (i) => i.type === "competitionAverage"
                            )?.score,
                            informe.data?.find(
                              (i) => i.type === "selectionAverage"
                            )?.score,
                          ],
                          backgroundColor: "rgba(0, 150, 255, 0.2)",
                          borderColor: "rgba(0, 100, 255, 0.4)",
                        },
                      ],
                    }}
                    legend={null}
                    width={100}
                    height={100}
                    options={{
                      scale: {
                        ticks: {
                          suggestedMin: 0,
                          suggestedMax: 10,
                        },
                      },
                    }}
                  />
                </div>
                {informe.name} -{" "}
                {informe.created_at
                  ? new Date(informe.created_at).toLocaleString()
                  : new Date().toLocaleString()}
                <button
                  className={
                    "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-4"
                  }
                  onClick={() =>
                    setModalContent(
                      reportDetail(
                        informe,
                        informe.created_at
                          ? new Date(informe.created_at).toLocaleString()
                          : new Date().toLocaleString()
                      ),
                      informe.created_at
                        ? new Date(informe.created_at).toLocaleString()
                        : new Date().toLocaleString()
                    )
                  }
                  type="button"
                >
                  <img src={add_icon} className="h-6 p-1 text-center m-auto" alt="add"/>
                  <p>{t("staff.report.edit")}</p>
                </button>
              </div>
            ))}
          </div>
        </CollapsablePanel>
      </form>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="m-auto flex flex-col">
          <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
          <p>{t("29082021.loading.generic")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StaffDetail;
