import React, { useEffect, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import loadingGif from "../images/loading.gif";
import useStore from "../store";
import Topbar from "../components/Topbar";
import {
  AssesmentData,
  MatchData,
} from "../CMSUtils/Icms";
import {
  AddAssesment,
  AddCMSMatches,
  DeleteCMSMatches,
  GetMatches,
  LoadCMSMatches,
  updateAssesment,
  UpdateCMSMatches,
} from "../CMSUtils/MatchCalls";
import MatchDetail from "../components/Match/MatchDetail";
import FullCalendar from "@fullcalendar/react";
import { useTranslation } from "react-i18next";
import DataField from "../components/DataField";

const Match = (props: any) => {
  const { t, i18n } = useTranslation();
  const loggedUser = useStore((state) => state.loggedUser);
  const editable =
    loggedUser?.role.name === "Admin" || loggedUser?.role.name === "Trainer";
  const token = useStore((state) => state.token);
  const [cargandoDatos, setCargandoDatos] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [listaMatches, setListaMatches] = useState<MatchData[]>();
  const [validMatch, setValidMatch] = useState<boolean>(false);
  const [matchSeleccionado, setMatchSeleccionado] = useState<MatchData>();
  const setModalContent = useStore((state) => state.setModalContent);

  useEffect(() => {
    let isMounted = true;
    setMatchSeleccionado(
      props.location.state ? props.location?.state.data : undefined
    );
    
    if (!cargandoDatos) fetchData().then(matches => {
      if(isMounted){
        setListaMatches(matches);
        console.log(matches);
        setIsLoaded(true);
        if (loggedUser?.role.name === "Player") {
          const nextMatch = matches!
            .filter(
              (match) => {
                let matchAux : Date = new Date(match.date);
                let matchHourAux = match.hour? match.hour.split(":") : undefined;
                if(matchHourAux){
                  matchAux.setHours(Number.parseInt(matchHourAux[0]) );
                  matchAux.setMinutes(Number.parseInt(matchHourAux[1]));
                }
                return matchAux.getTime() > new Date().getTime()}
            )
            .sort((m1, m2) =>
              new Date(m1.date).getTime() > new Date(m2.date).getTime() ? 1 : -1
            )[0];
            const nextMatchPlayerData = nextMatch?.assessments?.find(
              (a) => {
                if(a.player_id === loggedUser.player_id && a.obs !== null && a.obs !== "" && a.obs !== undefined){
                  const parsedData = JSON.parse(a.obs);
                  if(parsedData.category === "autoevaluation"){
                    return a;
                  }
                }
                return undefined;}
            );

          if (
            nextMatch &&
            !nextMatchPlayerData
          ) {
            setModalContent(
              matchModal(nextMatch),
              t("29082021.convocatoria_title")
            );
          } else {
            const prevMatch = matches!
            .filter(
              (match) => {
                let matchAux : Date = new Date(match.date);
                let matchHourAux = match.hour? match.hour.split(":") : undefined;
                if(matchHourAux){
                  matchAux.setHours(Number.parseInt(matchHourAux[0]) );
                  matchAux.setMinutes(Number.parseInt(matchHourAux[1]));
                }
                return matchAux.getTime() < new Date().getTime() && !match.assessments?.find(
                  (a) => {
                    if(a.player_id === loggedUser.player_id && a.obs !== null && a.obs !== "" && a.obs !== undefined){
                      const parsedData = JSON.parse(a.obs);
                      if(parsedData.category === "autoevaluation" && parsedData.answered === true){
                        return a;
                      }
                    }
                    return undefined;}
                )}
            )
              .sort((m1, m2) =>
                new Date(m1.date).getTime() < new Date(m2.date).getTime() ? 1 : -1
              );
            if(prevMatch && prevMatch.length > 0){
              let prevAssesment = prevMatch[0]?.assessments?.find(
                (a) => {
                  if(a.player_id === loggedUser.player_id && a.obs !== null && a.obs !== "" && a.obs !== undefined){
                    const parsedData = JSON.parse(a.obs);
                    if(parsedData.category === "autoevaluation" && parsedData.answered === true){
                      return a;
                    }
                  }
                  return undefined;}
              );
              if (!prevAssesment) {
                let newAssesment: AssesmentData;
                AddAssesment(
                  loggedUser.player_id,
                  prevMatch[0]?.id,
                  JSON.stringify({
                    category: "autoevaluation",
                    assist: true,
                    answered: false,
                  }),
                  token!
                ).then((e) => {
                  newAssesment = Object.assign({}, e);
                  if (prevMatch && newAssesment) {
                    setModalContent(
                      prevMatchModal(prevMatch[0], newAssesment),
                      t("29082021.valoracion_title")
                    );
                  }
                });
              } else {
                if (
                  prevMatch &&
                  prevAssesment &&
                  JSON.parse(prevAssesment.obs).assist === true &&
                  JSON.parse(prevAssesment.obs).answered === false
                ) {
                  setModalContent(
                    prevMatchModal(prevMatch[0], prevAssesment),
                    t("29082021.valoracion_title")
                  );
                }
              }
            }

            /*if (!prevAssesment && prevMatch) {
              let newAssesment: AssesmentData;
              AddAssesment(
                loggedUser.player_id,
                prevMatch[0]?.id,
                JSON.stringify({
                  category: "autoevaluation",
                  assist: true,
                  answered: false,
                }),
                token!
              ).then((e) => {
                newAssesment = Object.assign({}, e);
                if (prevMatch && newAssesment) {
                  setModalContent(
                    prevMatchModal(prevMatch[0], newAssesment),
                    t("29082021.valoracion_title")
                  );
                }
              });
            } else {
              if (
                prevMatch &&
                prevAssesment &&
                JSON.parse(prevAssesment.obs).assist === true &&
                JSON.parse(prevAssesment.obs).answered === false
              ) {
                setModalContent(
                  prevMatchModal(prevMatch[0], prevAssesment),
                  t("29082021.valoracion_title")
                );
              }
            }*/
          }
        }
      }
    });

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state?.data]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSMatches(token);

    let matches = GetMatches();
    if (!matches) matches = [];
    return matches;
    //setListaMatches(matches);
    //setIsLoaded(true);
  }

  async function borrarPartido() {
    if (!token) return;
    setCargandoDatos(true);

    if (matchSeleccionado) {
      await DeleteCMSMatches(matchSeleccionado.id, token);
      setListaMatches(await fetchData());

      setMatchSeleccionado(undefined);
      props.history.push("/partido");
    } 

    setCargandoDatos(false);
  }

  async function actualizarPartido() {
    if (!matchSeleccionado || !token) return;
    let send_email = 0;
    setCargandoDatos(true);

    //Si no está creado en base de datos, lo creamos y guardamos el ID
    if (!matchSeleccionado.id) {
      let matchBasic = Object.assign({}, matchSeleccionado);
      matchBasic.player_data = [];
      matchBasic.assessments = [];
      const matchID = await crearPartido(matchBasic);
      if (matchID === -1) return;
      matchSeleccionado.id = matchID;
      send_email = 1;
    }

    await UpdateCMSMatches(matchSeleccionado, token, i18n.language, send_email);
    setListaMatches(await fetchData());

    setCargandoDatos(false);
    setMatchSeleccionado(undefined);
  }

  // async function actualizarMatch(match: MatchData) {
  //   if (!token) return;
  //   setCargandoDatos(true);
  //   await UpdateCMSMatches(match, token);
  //   await fetchData();
  //   setCargandoDatos(false);
  // }

  async function crearPartido(match: MatchData) {
    if (!token) return -1;

    return await AddCMSMatches(match, token);
  }

  function getPartidos() {
    if (listaMatches && listaMatches.length > 0) {
      let events = listaMatches?.map((e, index) => {
        return {
          title:( e.hour? e.hour +" - " : "") + (e.team?.team_name ?? (e as any).team_name ?? "Sin título"),
          date: e.date,
          id: index,
          color: "#0e7bc0",
          textColor: "white",
          display: "block",
        };
      });
      return events as unknown as [{ title: string; date: string }];
    } else {
      return undefined;
    }
  }

  function updateFiltro(busqueda: string) {
    let matchs = GetMatches();
    if (!matchs) return;

    let filteredMatchs = matchs.filter((p) =>
      p.referee.toLowerCase().includes(busqueda.toLowerCase())
    );
    setListaMatches(filteredMatchs);
  }

  function matchModal(matchData: MatchData) {
    /*let assesment: AssesmentData = {
      match_id: matchData.id,
      player_id: loggedUser?.player_id,
      obs: "assist",
      score: 1,
    } as AssesmentData;*/
    return (
      <div style={{minWidth:"500px"}}>
        <div className="bg-f2f2f2 rounded-md w-11/12 mx-auto p-4">
          <div className="py-1 flex">
          <p className="font-bold mr-2">{t("match.data.date")+":"}</p>
            {new Date(matchData.date).toLocaleDateString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            {matchData.hour ? ", " + matchData.hour : ""}
          </div>
          {matchData.enemy && <div className="flex py-1"><p className="font-bold mr-2">{t("match.data.rival")+":"}</p> {matchData.enemy}</div>}
          {matchData.field && <div className="flex py-1"><p className="font-bold mr-2">{t("match.data.field")+":"}</p>{matchData.field}</div>}
        </div>
        {/*<div className="pt-3 text-center">
          {t("29082021.confirmar_asistencia")}
          
          <div
            className="flex flex-col justify-center"
            onChange={(e: any) =>
              (assesment.score = e.target.value === "si" ? 1 : 0)
            }
          >
            <div className="pt-3">
              <input
                className="self-center"
                type="radio"
                id="cy"
                name="carnet"
                value="si"
                defaultChecked={true}
              />
              <label className="self-center pl-1" htmlFor="cy">
                {t("29082021.confirmar_yes")}
              </label>
            </div>
            <div className="pt-1">
              <input
                className="self-center"
                type="radio"
                id="cn"
                name="carnet"
                value="no"
                defaultChecked={false}
              />
              <label className="self-center pl-1" htmlFor="cn">
                {t("29082021.confirmar_no")}
              </label>
            </div>
          </div>
        </div>*/}
        <div className="flex mt-6">
          <button
            className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 px-10 rounded-full text-white mx-auto"
            onClick={() => {
              const value = {
                category: "autoevaluation",
                assist: true,
                answered: false,
              };
              if (!loggedUser || !token) return;
              AddAssesment(
                loggedUser.player_id,
                matchData.id,
                JSON.stringify(value),
                token
              );
              
              const matches = GetMatches();

              if (matches) {
                const prevMatch = matches
                .filter(
                  (match) => {
                    let matchAux : Date = new Date(match.date);
                    let matchHourAux = match.hour? match.hour.split(":") : undefined;
                    if(matchHourAux){
                      matchAux.setHours(Number.parseInt(matchHourAux[0]) );
                      matchAux.setMinutes(Number.parseInt(matchHourAux[1]));
                    }
                    return matchAux.getTime() < new Date().getTime() && !match.assessments?.find(
                      (a) => {
                        if(a.player_id === loggedUser.player_id && a.obs !== null && a.obs !== "" && a.obs !== undefined){
                          const parsedData = JSON.parse(a.obs);
                          if(parsedData.category === "autoevaluation" && parsedData.answered === true){
                            return a;
                          }
                        }
                        return undefined;}
                    )}
                )
                  .sort((m1, m2) =>
                    new Date(m1.date).getTime() < new Date(m2.date).getTime()
                      ? 1
                      : -1
                  );

                if(prevMatch && prevMatch.length > 0){
              let prevAssesment = prevMatch[0]?.assessments?.find(
                (a) => {
                  if(a.player_id === loggedUser.player_id && a.obs !== null && a.obs !== "" && a.obs !== undefined){
                    const parsedData = JSON.parse(a.obs);
                    if(parsedData.category === "autoevaluation" && parsedData.answered === true){
                      return a;
                    }
                  }
                  return undefined;}
              );
              if (!prevAssesment) {
                let newAssesment: AssesmentData;
                AddAssesment(
                  loggedUser.player_id,
                  prevMatch[0]?.id,
                  JSON.stringify({
                    category: "autoevaluation",
                    assist: true,
                    answered: false,
                  }),
                  token!
                ).then((e) => {
                  newAssesment = Object.assign({}, e);
                  if (prevMatch && newAssesment) {
                    setModalContent(
                      prevMatchModal(prevMatch[0], newAssesment),
                      t("29082021.valoracion_title")
                    );
                  }
                });
              } else {
                if (
                  prevMatch &&
                  prevAssesment &&
                  JSON.parse(prevAssesment.obs).assist === true &&
                  JSON.parse(prevAssesment.obs).answered === false
                ) {
                  setModalContent(
                    prevMatchModal(prevMatch[0], prevAssesment),
                    t("29082021.valoracion_title")
                  );
                }
              }
            }else{
              setModalContent(undefined);
            }
              } else {
                setModalContent(undefined);
              }
            }}
          >
            <p className="text-center w-full">{t("09022022.enviar_partido")}</p>
          </button>
        </div>
      </div>
    );
  }

  function prevMatchModal(matchData: MatchData, assesment: AssesmentData) {
    let valueData = {
      sentido: "",
      sentido_score: 0,
      equipo: "",
      equipo_score: 0,
      actuacion: "",
      actuacion_score: 0,
      obs: "",
      obs2: "",
    };
    return (
      <div className="grid grid-cols-2 gap-6">
        <div className="border border-gray-200 rounded-md p-2">
          <div>
            {matchData && matchData.date? new Date(matchData.date).toLocaleDateString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }) : ""}
            {matchData && matchData.hour ? "," + matchData.hour : ""}
          </div>
          <div>{matchData?.field || ""}</div>
        </div>
        <div>
          <p>{t("29082021.indica_valoracion")}</p>
          <div className="grid grid-cols-2 gap-1">
            <div className="bg-green-200 rounded-md w-full text-center">
              {t("09022022.muy_bien")}
            </div>
            <div className="bg-yellow-200 rounded-md w-full text-center">
              {t("09022022.mal")}
            </div>
            <div
              className="rounded-md w-full text-center"
              style={{ backgroundColor: "#88DD0033" }}
            >
              {t("09022022.bien")}
            </div>
            <div className="bg-red-200 rounded-md w-full text-center">
              {t("09022022.muy_mal")}
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="flex pb-3">
            <p>{t("29082021.como_sentido")}</p>
            <div className="w-1/12 ml-auto">
              <DataField
                max={10}
                min={0}
                data=""
                type="number"
                onChange={(e) => (valueData.sentido_score = e)}
                placeholder=" "
              />
            </div>
          </div>
          <DataField
            type="textarea"
            placeholder="Observaciones"
            data=""
            onChange={(e) => (valueData.sentido = e)}
          />
        </div>
        <div className="pt-4">
          <div className="flex pb-3">
            <p>{t("29082021.valora_equipo")}</p>
            <div className="w-1/12 ml-auto">
              <DataField
                data=""
                type="number"
                max={10}
                min={0}
                onChange={(e) => (valueData.equipo_score = e)}
                placeholder=" "
              />
            </div>
          </div>
          <DataField
            type="textarea"
            placeholder="Observaciones"
            data=""
            onChange={(e) => (valueData.equipo = e)}
          />
        </div>
        <div className="pt-4">
          <div className="flex pb-3">
            <p>{t("29082021.valora_actuacion")}</p>
            <div className="w-1/12 ml-auto">
              <DataField
                data=""
                type="number"
                max={10}
                min={0}
                onChange={(e) => (valueData.actuacion_score = e)}
                placeholder=" "
              />
            </div>
          </div>
          <DataField
            type="textarea"
            placeholder="Observaciones"
            data=""
            onChange={(e) => (valueData.actuacion = e)}
          />
        </div>
        <div className="pt-4">
          <div className="flex pb-3">
            <p>{t("29082021.otros")}</p>
            <div className="w-1/12 ml-auto"></div>
          </div>
          <DataField
            type="textarea"
            placeholder="Observaciones"
            data=""
            onChange={(e) => (valueData.obs2 = e)}
          />
        </div>
        <div className="flex mt-6">
          <button
            className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 px-10 rounded-full text-white mx-auto"
            onClick={() => {
              if (!token) return;
              let oldAssesment = JSON.parse(assesment.obs);
              oldAssesment.answered = true;
              oldAssesment.values = valueData;

              updateAssesment(
                assesment.id,
                JSON.stringify(oldAssesment),
                token
              ).then(async (e) => {
                await LoadCMSMatches(token);
                const matches = GetMatches();

              if (matches) {
                const prevMatch = matches
                .filter(
                  (match) => {
                    let matchAux : Date = new Date(match.date);
                    let matchHourAux = match.hour? match.hour.split(":") : undefined;
                    if(matchHourAux){
                      matchAux.setHours(Number.parseInt(matchHourAux[0]) );
                      matchAux.setMinutes(Number.parseInt(matchHourAux[1]));
                    }
                    return matchAux.getTime() < new Date().getTime() && !match.assessments?.find(
                      (a) => {
                        if(a.player_id === loggedUser!.player_id && a.obs !== null && a.obs !== "" && a.obs !== undefined){
                          const parsedData = JSON.parse(a.obs);
                          if(parsedData.category === "autoevaluation" && parsedData.answered === true){
                            return a;
                          }
                        }
                        return undefined;}
                    )}
                )
                  .sort((m1, m2) =>
                    new Date(m1.date).getTime() < new Date(m2.date).getTime()
                      ? 1
                      : -1
                  );


                if(prevMatch && prevMatch.length > 0){
              let prevAssesment = prevMatch[0]?.assessments?.find(
                (a) => {
                  if(a.player_id === loggedUser!.player_id && a.obs !== null && a.obs !== "" && a.obs !== undefined){
                    const parsedData = JSON.parse(a.obs);
                    if(parsedData.category === "autoevaluation" && parsedData.answered === true){
                      return a;
                    }
                  }
                  return undefined;}
              );
              if (!prevAssesment) {
                let newAssesment: AssesmentData;
                AddAssesment(
                  loggedUser!.player_id,
                  prevMatch[0]?.id,
                  JSON.stringify({
                    category: "autoevaluation",
                    assist: true,
                    answered: false,
                  }),
                  token!
                ).then((e) => {
                  newAssesment = Object.assign({}, e);
                  if (prevMatch && newAssesment) {
                    setModalContent(
                      prevMatchModal(prevMatch[0], newAssesment),
                      t("29082021.valoracion_title")
                    );
                  }
                });
              } else {
                if (
                  prevMatch &&
                  prevAssesment &&
                  JSON.parse(prevAssesment.obs).assist === true &&
                  JSON.parse(prevAssesment.obs).answered === false
                ) {
                  setModalContent(
                    prevMatchModal(prevMatch[0], prevAssesment),
                    t("29082021.valoracion_title")
                  );
                }
              }
            }else{
              setModalContent(undefined);
            }
              } else {
                setModalContent(undefined);
              }
            });
            }}
          >
            <p className="text-center w-full">{t("29082021.enviar")}</p>
          </button>
        </div>
      </div>
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <div className="flex flex-col h-full z-0">
        <Topbar
          visibleButtons={editable}
          selected={matchSeleccionado}
          createText={t("match.create")}
          onBack={() => props.history.goBack()}
          onFilter={(text: string) => updateFiltro(text)}
          onCreate={() =>
            props.history.push({
              pathname: props.location.pathname + "/create",
              state: {
                data: Object.assign({} as MatchData),
              },
            })
          }
          disabled={!validMatch}
          onDelete={() => borrarPartido()}
          onUpdate={() => {
            actualizarPartido();
            props.history.goBack();
          }}
        />
        <div
          className={
            "mt-8 w-full flex-1 overflow-auto" +
            (matchSeleccionado ? " bg-f2f2f2" : "")
          }
        >
          {matchSeleccionado ? (
            <MatchDetail
              selected={matchSeleccionado}
              uploadImage={() => undefined}
              image={undefined}
              validMatch={(value) => setValidMatch(value)}
            />
          ) : (
            <div className="bg-white w-full p-12 flex-1 overflow-auto flex">
              <div className="w-full mx-auto" style={{ maxWidth: "1280px" }}>
                <FullCalendar
                  displayEventTime={false}
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={{ end: "prev,next" }}
                  locale={i18n.language}
                  events={getPartidos()}
                  firstDay={1}
                  eventClick={(info) => {
                    if (!listaMatches || loggedUser?.role.name === "Player")
                      return;
                    props.history.push({
                      pathname: props.location.pathname + "/detail",
                      state: {
                        data: Object.assign(
                          {},
                          listaMatches[Number(info.event.id)]
                        ),
                      },
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="bg-f2f2f2 mt-20 flex flex-1">
          <div className="m-auto flex flex-col">
            <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
            <p>{t("29082021.loading.generic")}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Match;
