
import { ClubData, cmsBaseUrl } from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
export const cmsClubsUrl: string = cmsApiUrl + "/club";
export const cmsSeasonUrl: string = cmsApiUrl + "/clubBackup";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let clubsList: ClubData[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetClubsFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsClubsUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      clubsList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSClubs: (token: string) => Promise<boolean> =
  GetClubsFromCMS;

async function StartSeasonFromCMS(
  token: string,
  id: string,
  name: string,
  data: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsSeasonUrl, {
    method: "POST",
    body: JSON.stringify({
      backupData: {
        club_id: id,
        name: name,
        data: data,
      },
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();

    //Almacenamos los clubs

    return true;
  } catch (error) {
    return false;
  }
}

export const StartSeason: (
  token: string,
  id: string,
  name: string,
  data: string
) => Promise<boolean> = StartSeasonFromCMS;

async function GetSeasonsFromCMS(
  token: string,
  id: string
): Promise<any> {
  //Lanzamos la petición
  let response = await fetch(cmsSeasonUrl+"?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs

    return result;
  } catch (error) {
    return false;
  }
}

export const GetSeasons: (
  token: string,
  id: string
) => Promise<any> = GetSeasonsFromCMS;

async function RemoveClubsFromCMS(id: number, token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsClubsUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSClubs: (id: number, token: string) => Promise<boolean> =
  RemoveClubsFromCMS;

async function InsertClubsFromCMS(
  club: ClubData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsClubsUrl, {
    method: "POST",
    body: JSON.stringify({ clubData: club }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSClubs: (club: ClubData, token: string) => Promise<number> =
  InsertClubsFromCMS;

async function ModifyClubsFromCMS(
  club: ClubData,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsClubsUrl, {
    method: "PUT",
    body: JSON.stringify({ clubData: club, id: club.id }),
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const UpdateCMSClubs: (
  club: ClubData,
  token: string
) => Promise<boolean> = ModifyClubsFromCMS;

function getClubs(): ClubData[] | undefined {
  if (clubsList === undefined) return undefined;

  return clubsList;
}
export const GetClubs: () => ClubData[] | undefined = getClubs;

async function CreateUserForClub(
  clubData: ClubData,
  token: string
): Promise<boolean> {
  //Si no hay datos de usuario o ya hay uno creado, avisamos de error y devolvemos
  if (
    (clubData.user !== undefined && clubData.user !== null) ||
    clubData.userEmail === "" ||
    clubData.userName === "" ||
    clubData.userPass === ""
  ) {
    console.log("Error creating user for club. One variable is wrong.");
    return false;
  }

  //Lanzamos la petición
  let response = await fetch(cmsApiUrl + "/auth/createAccount", {
    method: "POST",
    body: JSON.stringify({
      userName: clubData.userName,
      userEmail: clubData.userEmail,
      userPassword: clubData.userPass,
      type: "club",
      club_id: clubData.id,
    }),
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });

  return response.ok !== false;
}
export const CreateUserClub: (
  club: ClubData,
  token: string
) => Promise<boolean> = CreateUserForClub;
