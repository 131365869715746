import React, { useState } from "react";
import {
  ExperienceData,
} from "../../CMSUtils/Icms";
import exp_icon from "../../images/icons/soccer-field.svg";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { useTranslation } from "react-i18next";
import { SetFavExperience } from "../../CMSUtils/LoginCalls";
import useStore from "../../store";

const ExperienceItem: React.FC<{
  onClick: () => void;
  datos: ExperienceData;
  index: number;
}> = ({ onClick, datos, index }) => {
  const { i18n } = useTranslation();
  const token = useStore((state) => state.token);
  const loggedUser = useStore((state) => state.loggedUser);
  const [favorita, setFavorita] = useState(
    loggedUser?.fav_experiences.find((e: any) => e.experience_id === datos.id)
      ? true
      : false
  );
  const setLoggedUser = useStore((state) => state.setLoggedUser);
  return (
    <div
      onClick={onClick}
      key={index}
      className={"w-full cursor-pointer font-bold relative bg-white flex flex-col px-4 pb-2"}
    >
      <div
        className={
          "rounded-full w-8 h-8 absolute top-0 left-0 transform -translate-x-5 -translate-y-5 fa fa-star  flex justify-center items-center border border-gray-400 " +
          (favorita
            ? "hover:bg-white bg-sf-mediumblue hover:text-gray-400 text-white"
            : "bg-white hover:bg-sf-mediumblue text-gray-400 hover:text-white")
        }
        onClick={(e) => {
          e.stopPropagation();
          SetFavExperience(token, datos.id).then((r: any) => {
            if (loggedUser) {
              loggedUser.fav_experiences = r.favExperiences;
            }
            setFavorita(!favorita);
            setLoggedUser(loggedUser);
          });
        }}
      ></div>
      <div className="h-12 text-sf-darkblue p-2 text-base">{i18n.language === "es" ? datos.name_es : i18n.language === "ru" ? datos.name_ru : datos.name_en}</div>
      <div
        className={
          "w-full flex-1 relative" + (datos[i18n.language === "es" ? "description_es" : i18n.language === "ru" ? "description_ru" : "description_en"] ? " flipcard" : "")
        }
      >
        {datos.file1 && (
          <img src={cmsStorageURL + datos.file1?.file} className="w-full" alt="placeholder" />
        )}
        {!datos.file1 && (
          <img src={exp_icon} className="h-40 mx-auto opacity-25 py-3" alt="placeholder" />
        )}
        {(datos[i18n.language === "es" ? "description_es" : i18n.language === "ru" ? "description_ru" : "description_en"] ? " flipcard" : "") && (
          <div className="text-sm font-normal w-full text-gray-600 absolute top-0 right-0 bottom-0 left-0 pr-4 whitespace-normal overflow-auto">
            {i18n.language === "es" ? datos.description_es : i18n.language === "ru" ? datos.description_ru : datos.description_en}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExperienceItem;
