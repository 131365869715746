import React, { useState } from "react";
import cross from "../../images/icons/close.svg";
import DataField from "../DataField";
import { StaffData } from "../../CMSUtils/Icms";
import { staff_titles } from "../../data/arrays";
import { useTranslation } from "react-i18next";
export const StaffField: React.FC<{
  job?: string;
  staff?: StaffData[];
  data: any;
  required?: boolean;
  onChangeStaff: (e: number) => void;
  onChangeRole?: (e: string) => void;
  onDelete?: () => void;
  disabled?: boolean;
}> = ({
  job,
  staff,
  data,
  onChangeStaff,
  onChangeRole,
  onDelete,
  disabled = false,
  required = false,
}) => {
  const [selectedStaff, setSelectedStaff] = useState<StaffData | undefined>(
    data?.staff
  );
  const { t } = useTranslation();

  function getNombres() {
    let nombres: { value: number; text: string }[] = [];
    staff?.map((s, i) => nombres.push({ value: s.id, text: s.name }));
    return nombres;
  }
  function setSelected(id: number) {
    setSelectedStaff(staff?.find((s) => s.id === id));
  }

  return (
    <div className="flex w-full py-4">
      <div className="w-3/12 px-6">
        {job ? (
          <div className="self-center">{t("14052021.stafffield." + job)}</div>
        ) : (
          <DataField
            disabled={disabled}
            type="select"
            placeholder={t("14052021.stafffield.rol_selection")}
            data={data.role}
            options={staff_titles}
            onChange={(e) => (onChangeRole ? onChangeRole(e) : undefined)}
            required={required}
          />
        )}
      </div>
      <div className="w-3/12 px-6">
        <DataField
          disabled={disabled}
          type="select"
          placeholder={t("14052021.stafffield.name_placeholder")}
          options={getNombres()}
          data={data?.staff?.id}
          onChange={(e) => {
            setSelected(e);
            onChangeStaff(e);
          }}
          required={required}
          flexible={true}
        />
      </div>
      <div className="w-2/12 px-6 self-center">{selectedStaff?.email}</div>
      <div className="w-1/12 px-6 self-center">
        {selectedStaff?.phone_number}
      </div>
      <div className="w-1/12 px-6">{selectedStaff?.academic_titles}</div>
      <div className="w-1/12 px-6 self-center">
        {selectedStaff?.phone_number}
      </div>
      <div className="w-1/12 px-6">
        {onDelete && (
          <button className={"h-8 w-8  px-1 rounded-full"} onClick={onDelete}>
            <img src={cross} className="h-3 w-3" alt="close" />
          </button>
        )}
      </div>
    </div>
  );
};
