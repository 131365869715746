import React, { useEffect, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  PDFDownloadLink
} from "@react-pdf/renderer";
import loadingGif from "../images/loading.gif";
import {
  GetTeams,
  LoadCMSTeams,
} from "../CMSUtils/TeamCalls";
import logo from "../images/logo-sf.png";
import useStore from "../store";
import Topbar from "../components/Topbar";
import {
  ExperienceData,
  PlayerEvaluation,
  TrainingData,
} from "../CMSUtils/Icms";
import {
  cmsStorageURL,
} from "../CMSUtils/FileCalls";
import {
  AddCMSTrainings,
  DeleteCMSTrainings,
  GetTrainings,
  LoadCMSTrainings,
  UpdateCMSTrainings,
  UpdatePlayerCMSTrainings,
} from "../CMSUtils/TrainingCalls";
import TrainingDetail from "../components/Training/TrainingDetail";
import FullCalendar from "@fullcalendar/react";
import { useTranslation } from "react-i18next";
import {
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";
import DataField from "../components/DataField";

const Training = (props: any) => {
  const { t, i18n } = useTranslation();
  const loggedUser = useStore((state) => state.loggedUser);
  const editable =
    loggedUser?.role.name === "Admin" || loggedUser?.role.name === "Trainer";
    const visualizable = loggedUser?.role.name === "Club";
  const [isLoaded, setIsLoaded] = useState(false);
  const token = useStore((state) => state.token);
  const [cargandoDatos, setCargandoDatos] = useState(false);
  const [PDFready, setPDFready] = useState(false);
  const [listaTraining, setListaTraining] = useState<TrainingData[]>();
  const [validTraining, setValidTraining] = useState<boolean>(false);
  const setModalContent = useStore((state) => state.setModalContent);
  const [trainingSeleccionado, setTrainingSeleccionado] =
    useState<TrainingData>();

  useEffect(() => {
    let isMounted = true;
    setTrainingSeleccionado(
      props.location.state ? props.location?.state.data : undefined
    );

    if (!cargandoDatos) fetchData().then(trainings => {
      if(isMounted){
      setListaTraining(trainings);
      setIsLoaded(true);

      if (loggedUser?.role.name === "Player") {
        const nextTraining = trainings!
          .filter(
            (training) =>
            {
              let trainingAux : Date = new Date(training.date_and_time);
              let trainingHourAux = training.only_time? training.only_time.split(":") : undefined;
              if(trainingHourAux){
                trainingAux.setHours(Number.parseInt(trainingHourAux[0]) );
                trainingAux.setMinutes(Number.parseInt(trainingHourAux[1]));
              }
              return trainingAux.getTime() > new Date().getTime() && training.players.find((p) => p.player_id?.toString() === loggedUser?.player_id?.toString())}

          )
          .sort((m1, m2) =>
            new Date(m1.date_and_time).getTime() >
            new Date(m2.date_and_time).getTime()
              ? 1
              : -1
          )[0];
          let nextTrainingPlayer;
        if(nextTraining)
          nextTrainingPlayer = nextTraining.players?.find(
            (p) =>
              p.player_id?.toString() === loggedUser?.player_id?.toString() &&
              (p.before === undefined || p.before === null)
          );
        if (nextTraining && nextTrainingPlayer) {
          setModalContent(
            trainingModal(nextTraining, nextTrainingPlayer),
            t("29082021.convocatoria_training_title")
          );
        } else {
          const prevTraining = trainings!
            .filter(
              (training) =>
              {
                let trainingAux : Date = new Date(training.date_and_time);
                let trainingHourAux = training.only_time? training.only_time.split(":") : undefined;
                if(trainingHourAux){
                  trainingAux.setHours(Number.parseInt(trainingHourAux[0]) );
                  trainingAux.setMinutes(Number.parseInt(trainingHourAux[1]));
                }
                return trainingAux.getTime() < new Date().getTime() && training.players.find((p) => p.player_id?.toString() === loggedUser?.player_id?.toString())}
            )
            .sort((m1, m2) =>
              new Date(m1.date_and_time).getTime() <
              new Date(m2.date_and_time).getTime()
                ? 1
                : -1
            )[0];
            let prevTrainingPlayer;
            if(prevTraining){
          prevTrainingPlayer = prevTraining.players?.find(
            (p) =>
              p.player_id?.toString() === loggedUser?.player_id?.toString() &&
              (p.after === undefined || p.after === null)
          );}
          if (prevTraining && prevTrainingPlayer) {
            setModalContent(
              prevTrainingModal(prevTraining, prevTrainingPlayer),
              t("09022022.valoracion_title")
            );
          }
        }
      }
      }
    });

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state?.data]);

  async function fetchData() {
    if (!token) return;
    if (!GetTeams()) await LoadCMSTeams(token);
    await LoadCMSTrainings(token);

    let trainings = GetTrainings();
    if (!trainings) trainings = [];
    return trainings;
    //setListaTraining(trainings);
    //setIsLoaded(true);
  }

  async function borrarEntrenamientos() {
    if (!token) return;
    setCargandoDatos(true);

    if (trainingSeleccionado) {
      await DeleteCMSTrainings(trainingSeleccionado.id, token);
      setListaTraining(await fetchData());

      setTrainingSeleccionado(undefined);
      props.history.push("/entrenamiento");
    }
    setCargandoDatos(false);
  }

  function getEntrenamientos() {
    let eventos = listaTraining?.map((e, index) => {
      return {
        title: (e.only_time ? e.only_time + " - " : "")+(e.team?.team_name + (e.num_session? " - " + e.num_session : "")),
        date: e.date_and_time,
        id: index,
        color: "#e6e6e6",
        textColor: "black",
        display: "block",
      };
    });
    return eventos as unknown as [{ title: string; date: string }];
  }

  function completeExperience(experience: ExperienceData){
    let expAux = Object.assign({}, (experience as any).experience ?? experience);

    trainingSeleccionado?.guidelines
      .filter((g) => !g.should_add)
      .forEach((g) => {if(g.experience_id?.toString() === expAux.id?.toString()){
        let guidelineBorrar = expAux.guidelines.find(
          (guideline : any) =>
            guideline.guideline_id?.toString() === g.guideline_id?.toString() &&
            guideline.type === g.type
        );
        
        if (guidelineBorrar)
          expAux.guidelines.splice(
            expAux.guidelines.indexOf(guidelineBorrar),
            1
          );}
      });
      
      trainingSeleccionado?.guidelines
      .filter((g) => g.should_add)
      .forEach((g) => {
        if (g.experience_id?.toString() === expAux.id?.toString()) {
          let guidelineNueva = expAux.guidelines.find(
            (guideline : any) =>
              guideline.guideline_id?.toString() === g.guideline_id?.toString() &&
              guideline.type === g.type
          );
          
          let guidelineName = guidelineNueva?.guideline?.name ?? "";
          if (guidelineNueva) {
            if(!(g as any).guideline)(g as any).guideline = guidelineNueva.guideline;
            expAux.guidelines.splice(
              expAux.guidelines.indexOf(guidelineNueva),
              1
            );
          }
          expAux.guidelines.push({
            experience_id: g.experience_id,
            guideline_id: g.guideline_id,
            type: g.type,
            name: guidelineName,
            checked: g.checked,
            destiny: (g as any).destiny,
            guideline: (g as any).guideline,
          });
        }
      });

    return expAux;
  }

  /*
  function completeExperience(experience: ExperienceData) {
    let exp : any = Object.assign({}, experience);
    console.log(exp.experience);
      (exp.experience ?? exp).guidelines = (exp.experience ?? exp)?.guidelines?.filter((guid:any) => guid.checked === true);

    
    let gToAdd = trainingSeleccionado?.guidelines.filter((g) => g.checked && g.should_add && g.experience_id?.toString() === exp.experience_id?.toString());
    let gToRemove = trainingSeleccionado?.guidelines.filter((g) => (!g.should_add || (g.should_add && !g.checked)) && g.experience_id?.toString() === exp.experience_id?.toString());

    gToAdd?.forEach((g) => {
      let guid = (exp.experience ?? exp).guidelines.find((guid:any) => guid.guideline_id?.toString() === g.guideline_id?.toString());
      
      if(guid){
        let guidIndex = (exp.experience ?? exp).guidelines.findIndex(()=>guid);
        (exp.experience ?? exp).guidelines.splice(guidIndex,1);
      }
      //if(!(exp.experience ?? exp).guidelines.find((guid:any) => guid.guideline_id?.toString() === g.guideline_id?.toString()))
        (exp.experience ?? exp).guidelines.push(g);
    });
    console.log(exp.experience);
    
    gToRemove?.forEach((g) => {
      let guid = (exp.experience ?? exp).guidelines.find((guid:any) => guid.guideline_id?.toString() === g.guideline_id?.toString());
      
      if(guid){
        let guidIndex = (exp.experience ?? exp).guidelines.findIndex(()=>guid);
        (exp.experience ?? exp).guidelines.splice(guidIndex,1);
      }
    });
    console.log(exp.experience);
    return (exp.experience ?? exp);
  }
*/
  async function actualizarEntrenamiento(keepSelected : boolean = false) {
    if (!trainingSeleccionado || !token) return;
    let send_email = 0;
    setCargandoDatos(true);

    

    //Si no está creado en base de datos, lo creamos y guardamos el ID
    if (!trainingSeleccionado.id) {
      const trainingAux = Object.assign({},trainingSeleccionado);
      trainingAux.guidelines = [];
      trainingAux.experiences = [];
      const trainingID = await crearEntrenamiento(trainingAux);
      if (trainingID === -1) return;
      trainingSeleccionado.id = trainingID;
      send_email = 1;
    }

    const updatedData = await UpdateCMSTrainings(
      trainingSeleccionado,
      token,
      i18n.language,
      send_email
    );

    setListaTraining(await fetchData());

    setCargandoDatos(false);
    if(!keepSelected){
      setTrainingSeleccionado(undefined);
    }else{
      if(updatedData){
        console.log(updatedData);
        setTrainingSeleccionado(updatedData);
        return true;
      }else{
        setTrainingSeleccionado(undefined);
      }
    }
    return false;
  }

  // async function actualizarTraining(training: TrainingData) {
  //   if (!token) return;
  //   setCargandoDatos(true);
  //   await UpdateCMSTrainings(training, token);
  //   await fetchData();

  //   setCargandoDatos(false);
  //   setTrainingSeleccionado(undefined);
  // }

  async function crearEntrenamiento(training: TrainingData) {
    if (!token) return -1;

    return await AddCMSTrainings(training, token);
  }

  function parseDest(dest:any){
    let destStr = "";
    destStr+= dest["g"] ? "G," : "";
    destStr += dest["1"] ? "1," : "";
    destStr += dest["2"] ? "2," : "";
    destStr += dest["3"] ? "3," : "";
    destStr += dest["4"] ? "4," : "";
    destStr += dest["5"] ? "5," : "";
    destStr += dest["6"] ? "6," : "";
    destStr += dest["7"] ? "7," : "";
    destStr += dest["8"] ? "8," : "";
    destStr += dest["9"] ? "9," : "";
    destStr = destStr.substring(0, destStr.length-1);
    return destStr;
  }
   

  const MyDocument = () => {
    if (!trainingSeleccionado) return <Document></Document>;
    let teamImage = cmsStorageURL + trainingSeleccionado?.team?.image?.file;
    return (
      <Document>
        {trainingSeleccionado?.experiences?.filter((e) => e.type === "Global")?.length > 0 && <Page size="A4" style={{ padding: 15, flexDirection: "column" }}>
          <View style={{ flexDirection: "row" }}>
            
              <Image
                src={{
                  uri: teamImage,
                  body: "",
                  method: "GET",
                  headers: "",
                }}
                style={{ maxWidth: "50px", maxHeight:"50px", objectFit: "contain" }}
              />
              
            <View
              style={{
                width: "60%",
                borderBottom: 1,
                borderBottomColor: "#E4E4E4",
                borderTop: 1,
                borderTopColor: "#E4E4E4",
                marginVertical: 5,
                fontSize: 9,
                paddingVertical: 5,
                flexDirection: "row",
                marginRight: 10,
              }}
            >
              <View
                style={{
                  width: "33.3333%",
                  paddingHorizontal: 5,
                }}
              >
                <Text style={{color: "#0d7bbf"}}>{t("pdf.nombre_equipo")}</Text>
                <Text style={{ marginTop: 3 }}>
                  {trainingSeleccionado?.team?.team_name}
                </Text>
              </View>
              <View style={{ width: "33.3333%", paddingHorizontal: 5 }}>
                <Text style={{color: "#0d7bbf"}}>{t("pdf.nombre_campo")}</Text>
                <Text style={{ marginTop: 3 }}>
                  {trainingSeleccionado?.camp}
                </Text>
              </View>
              <View style={{ width: "33.3333%", paddingHorizontal: 5 }}>
                <View style={{flexDirection:"row"}}>
                  <Text style={{color: "#0d7bbf"}}>
                    {t("pdf.sesion")}
                  </Text>
                  <Text>
                    {" "} {trainingSeleccionado?.num_session}
                  </Text>
                </View>
                <View style={{flexDirection:"row"}}>
                  <Text style={{color: "#0d7bbf"}}>
                    {t("pdf.hora")}
                  </Text>
                  <Text>
                    {" "}
                    {trainingSeleccionado?.date_and_time?.split("T")[0]}{trainingSeleccionado?.only_time ? " "+trainingSeleccionado?.only_time  : ""}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "20%",
                backgroundColor: "#E4E4E4",
                display: "flex",
                alignSelf: "center",
                alignContent: "center",
                padding: 10,
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 12 }}>
                {t("pdf.global")}
              </Text>
            </View>
            <View style={{alignContent: "center", paddingTop: 5, paddingLeft:10}}>
              <Image
              src={logo} style={{width:"40px", maxHeight:"40px", padding: 5}} />
            </View>
          </View>
          <View style={{ fontSize: 9 }}>
            <View
              style={{
                border: "#E4E4E4",
                borderWidth: 1,
                padding: 5,
                marginBottom: 5,
              }}
            >
              <View style={{ flexDirection: "row", width: "100%", flexWrap:"wrap" }}>
              {trainingSeleccionado?.players?.map((p: any, index) =>
                <Text key={index} style={{ width: "20%", paddingRight: 2 }}>
                  {p.player?.dorsal || "XX"} - {p.player?.name || ""}
                </Text>)}
              </View>
            </View>
            <View
              style={{
                backgroundColor: "#E4E4E4",
                display: "flex",
                alignSelf: "center",
                alignContent: "center",
                padding: 10,
                flexDirection: "row",
                width: "100%",
              }}
            >
              <View style={{ marginTop: 5, marginBottom: 1, width: "70%" }}>
                <Text style={{ fontWeight: "bold", marginBottom: 2,color: "#0d7bbf" }}>
                  {t("pdf.objetivos")}
                </Text>
                <Text style={{paddingRight:10}}>{trainingSeleccionado?.global_obj}</Text>
              </View>
              <View style={{ marginTop: 5, marginBottom: 1, width: "30%" }}>
                <Text style={{ fontWeight: "bold", marginBottom: 2,color: "#0d7bbf" }}>
                  {t("pdf.material")}
                </Text>
                <Text>{trainingSeleccionado?.global_mat}</Text>
              </View>
            </View>
            <View style={{ marginTop: 10, width: "100%" }}>
              {trainingSeleccionado?.experiences
                ?.filter((e) => e.type === "Global")
                .map((e: any, index) => 
                {
                  let guidelinesAux = completeExperience(e).guidelines;

                  return <View
                    style={{
                      flexDirection: "row",
                      borderBottom: 1,
                      borderBottomColor: "#E4E4E4",
                      marginTop: 5,
                      paddingBottom: 5,
                    }}
                    key={index}
                    wrap={false}
                  >
                    <View style={{ width: "25%" }}>
                      <Text>
                        {e.experience
                          ? e.experience["name_" + i18n.language]
                          : e["name_" + i18n.language]}
                      </Text>
                      <Image
                src={{
                  uri: cmsStorageURL + (e.experience? e.experience?.file1?.file : e.file1?.file),
                  body: "",
                  method: "GET",
                  headers: "",
                }}
                style={{ width: "95%" }}
              />
                      <View style={{flexDirection:"row"}}>
                <Text style={{color: "#0d7bbf"}}>TI: </Text>
                <Text>{e.inv_time || "-"}</Text>
                <Text style={{color: "#0d7bbf"}}>{" "} R:</Text>
                <Text>{e.reps || "-"}</Text>
                <Text style={{color: "#0d7bbf"}}>{" "} S:</Text>
                <Text>{e.series || "-"}</Text>
              </View>
                    </View>
                    {e.experience?.generalType === "shared" ||
                    e.generalType === "shared" ? (
                      <View
                        style={{
                          width: "100%",
                          borderBottom: 1,
                          borderBottomColor: "#E4E4E4",
                          marginTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            marginBottom: 5,
                          }}
                        >
                          <Text style={{ width: "50%", textAlign: "center",color: "#0d7bbf" }}>
                            {t("09022022.equipo_con")}
                          </Text>
                          <Text style={{ width: "50%", textAlign: "center",color: "#0d7bbf" }}>
                            {t("09022022.equipo_sin")}
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", width: "100%" }}>
                          <View
                            style={{
                              marginRight: 3,
                              flexDirection: "row",
                              width: "50%",
                              textAlign: "center",
                            }}
                          >
                            <View
                              style={{
                                border: 1,
                                borderColor: "#E4E4E4",
                                marginLeft: 3,
                                flexDirection: "row",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O1":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O1")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O1
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwith" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O2":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O2")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O2
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwith" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O3": g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O3")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O3
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwith" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O4":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O4")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O4
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwith" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O5":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O5")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O5
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              marginLeft: 3,
                              flexDirection: "row",
                              width: "50%",
                            }}
                          >
                            <View
                              style={{
                                border: 1,
                                borderColor: "#E4E4E4",
                                marginRight: 3,
                                flexDirection: "row",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D1":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D1")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D1
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D2":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D2")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D2
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D3":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D3")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D3
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D4":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D4")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D4
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D5":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D5")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D5
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View style={{ marginTop: 5, flexDirection: "row" }}>
                          <View style={{ width: "50%", paddingRight: 5 }}>
                            {guidelinesAux?.map((g: any, ind : any) => { 
                                if(g.checked === true && g.type === "Cwith"){
                                let guidelineAux : string =  g.guideline? g.guideline.name?.split("|")[1]: g.name?.split("|")[1];
                                let dest = null;
                                let destStr = "";
                                if(g.destiny !== null && g.destiny !== undefined && g.destiny.startsWith("{")){
                                  dest = JSON.parse(g.destiny);
                                  destStr = parseDest(dest);
                                }
                                let guidelineName = guidelineAux?.startsWith("{") ? JSON.parse(
                                  guidelineAux
                                 )[i18n.language] : guidelineAux;
                                return guidelineName ? 
                                <View style={{flexDirection: "row"}} key={ind}>
                                  <Text style={{width:"7.5%", paddingRight:"15px",color:(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).startsWith("D") ? "#FF0000" : "#000000"}}>
                                    {(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).substring(0,3)}
                                  </Text>
                                  <Text style={{width:"82.5%", paddingRight:"15px", fontSize:"8px"}}>
                                    {
                                      guidelineName
                                    }
                                  </Text>
                                  
                                  <Text style={{width:"10%"}}>
                                    {destStr}
                                  </Text>
                                </View> : undefined;
                              }
                            })}
                          </View>
                          <View style={{ width: "50%", paddingLeft: 5 }}>
                            {guidelinesAux?.map((g: any) => 
                              { 
                                if(g.checked === true && g.type === "Cwithout"){
                                let guidelineAux : string =  g.guideline? g.guideline.name?.split("|")[1]: g.name?.split("|")[1];
                                let dest = null;
                                let destStr = "";
                                if(g.destiny !== null && g.destiny !== undefined && g.destiny.startsWith("{")){
                                  dest = JSON.parse(g.destiny);
                                  destStr = parseDest(dest);
                                }
                                let guidelineName = guidelineAux?.startsWith("{") ? JSON.parse(
                                  guidelineAux
                                 )[i18n.language] : guidelineAux;
                                return guidelineName ? 
                                <View style={{flexDirection: "row"}} key={index}>
                                  <Text style={{width:"7.5%", paddingRight:"15px", color:(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).startsWith("D") ? "#FF0000" : "#000000"}}>
                                    {(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).substring(0,3)}
                                  </Text>
                                  <Text style={{width:"82.5%", paddingRight:"15px", fontSize:"8px"}}>
                                    {
                                      guidelineName
                                    }
                                  </Text>
                                  
                                  <Text style={{width:"10%"}}>
                                    {destStr}
                                  </Text>
                                </View> : undefined;
                              }
                            }
                            )}
                          </View>
                        </View>
                        {e.obs && <View style={{flexDirection:"row", marginTop: 10, paddingTop:5, borderTop: 1, borderTopColor: "#E4E4E4"}}>
                          <Text style={{color: "#0d7bbf"}}>
                            {t("09022022.distJug")}
                          </Text>
                          <Text>
                            {" " + e.obs}
                          </Text>
                        </View>}
                      </View>
                    ) : (
                      <View
                        style={{
                          width: "100%",
                          borderBottom: 1,
                          borderBottomColor: "#E4E4E4",
                          marginTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            marginBottom: 5,
                          }}
                        >
                          <Text style={{ width: "50%", textAlign: "center",color: "#0d7bbf" }}>
                            {t("pdf.equipo_a")}
                          </Text>
                          <Text style={{ width: "50%", textAlign: "center",color: "#0d7bbf" }}>
                            {t("pdf.equipo_b")}
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", width: "100%" }}>
                          <View
                            style={{
                              marginRight: 3,
                              flexDirection: "row",
                              width: "50%",
                              textAlign: "center",
                            }}
                          >
                            
                            <View
                              style={{
                                border: 1,
                                borderColor: "#E4E4E4",
                                marginLeft: 3,
                                flexDirection: "row",
                                width: "50%",
                                textAlign: "center",
                              }}
                            >
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O1":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O1")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O1
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "A" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O2":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O2")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O2
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "A" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O3": g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O3")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O3
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "A" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O4":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O4")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O4
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "A" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O5":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O5")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O5
                              </Text>
                            </View>
                            <View
                              style={{
                                border: 1,
                                borderColor: "#E4E4E4",
                                marginRight: 3,
                                flexDirection: "row",
                                width: "50%",
                                textAlign: "center",
                              }}
                            >
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "A" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D1":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D1")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D1
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "A" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D2":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D2")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D2
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "A" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D3":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D3")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D3
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "A" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D4":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D4")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D4
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "A" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D5":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D5")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D5
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              marginLeft: 3,
                              flexDirection: "row",
                              width: "50%",
                            }}
                          >
                            
                            <View
                              style={{
                                border: 1,
                                borderColor: "#E4E4E4",
                                marginLeft: 3,
                                flexDirection: "row",
                                width: "50%",
                                textAlign: "center",
                              }}
                            >
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O1":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O1")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O1
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O2":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O2")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O2
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O3":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O3")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O3
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O4":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O4")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O4
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O5":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O5")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O5
                              </Text>
                            </View>
                            <View
                              style={{
                                border: 1,
                                borderColor: "#E4E4E4",
                                marginRight: 3,
                                flexDirection: "row",
                                width: "50%",
                                textAlign: "center",
                              }}
                            >
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D1":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D1")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D1
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D2":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D2")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D2
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D3":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D3")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D3
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D4":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D4")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D4
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type.slice(0, 1) === "B" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D5":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D5")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D5
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View style={{ marginTop: 5, flexDirection: "row" }}>
                          <View style={{ width: "50%", paddingRight: 5 }}>
                            {guidelinesAux?.map((g: any, ind : any) => { 
                                if(g.checked === true && g.type.slice(0, 1) === "A"){
                                let guidelineAux : string =  g.guideline? g.guideline.name?.split("|")[1]: g.name?.split("|")[1];
                                let dest = null;
                                let destStr = "";
                                if(g.destiny !== null && g.destiny !== undefined && g.destiny.startsWith("{")){
                                  dest = JSON.parse(g.destiny);
                                  destStr = parseDest(dest);
                                }
                                let guidelineName = guidelineAux?.startsWith("{") ? JSON.parse(
                                  guidelineAux
                                 )[i18n.language] : guidelineAux;
                                return guidelineName ? 
                                <View style={{flexDirection: "row"}} key={ind}>
                                  <Text style={{width:"7.5%", paddingRight:"15px",color:(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).startsWith("D") ? "#FF0000" : "#000000"}}>
                                    {(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).substring(0,3)}
                                  </Text>
                                  <Text style={{width:"82.5%", paddingRight:"15px", fontSize:"8px"}}>
                                    {
                                      guidelineName
                                    }
                                  </Text>
                                  
                                  <Text style={{width:"10%"}}>
                                    {destStr}
                                  </Text>
                                </View> : undefined;
                              }
                            })}
                          </View>
                          <View style={{ width: "50%", paddingLeft: 5 }}>
                            {guidelinesAux?.map((g: any) => 
                              { 
                                if(g.checked === true && g.type.slice(0, 1) === "B"){
                                let guidelineAux : string =  g.guideline? g.guideline.name?.split("|")[1]: g.name?.split("|")[1];
                                let dest = null;
                                let destStr = "";
                                if(g.destiny !== null && g.destiny !== undefined && g.destiny.startsWith("{")){
                                  dest = JSON.parse(g.destiny);
                                  destStr = parseDest(dest);
                                }
                                let guidelineName = guidelineAux?.startsWith("{") ? JSON.parse(
                                  guidelineAux
                                 )[i18n.language] : guidelineAux;
                                return guidelineName ? 
                                <View style={{flexDirection: "row"}} key={index}>
                                  <Text style={{width:"7.5%", paddingRight:"15px",color:(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).startsWith("D") ? "#FF0000" : "#000000"}}>
                                    {(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).substring(0,3)}
                                  </Text>
                                  <Text style={{width:"82.5%", paddingRight:"15px", fontSize:"8px"}}>
                                    {
                                      guidelineName
                                    }
                                  </Text>
                                  
                                  <Text style={{width:"10%"}}>
                                    {destStr}
                                  </Text>
                                </View> : undefined;
                              }
                            }
                            )}
                          </View>
                        </View>
                        {e.obs && <View style={{flexDirection:"row", marginTop: 10, paddingTop:5, borderTop: 1, borderTopColor: "#E4E4E4"}}>
                          <Text style={{color: "#0d7bbf"}}>
                            {t("09022022.distJug")}
                          </Text>
                          <Text>
                            {" " + e.obs}
                          </Text>
                        </View>}
                      </View>
                    )}
                  </View>
                })}
            </View>
          </View>
        </Page>}
        {trainingSeleccionado?.experiences?.filter((e) => e.type === "Porteros")?.length > 0 && <Page size="A4" style={{ padding: 15, flexDirection: "column" }}>
          <View style={{ flexDirection: "row" }}>
            {
              <Image
                src={{
                  uri: teamImage,
                  body: "",
                  method: "GET",
                  headers: "",
                }}
                style={{ maxWidth: "50px", maxHeight:"50px", objectFit: "contain" }}
              />
            }
            <View
              style={{
                width: "60%",
                borderBottom: 1,
                borderBottomColor: "#E4E4E4",
                borderTop: 1,
                borderTopColor: "#E4E4E4",
                marginVertical: 5,
                fontSize: 10,
                paddingVertical: 5,
                flexDirection: "row",
                marginRight: 10,
              }}
            >
              <View
                style={{
                  width: "33.3333%",
                  paddingHorizontal: 5,
                }}
              >
                <Text style={{color: "#0d7bbf"}}>{t("pdf.nombre_equipo")}</Text>
                <Text style={{ marginTop: 3 }}>
                  {trainingSeleccionado?.team?.team_name}
                </Text>
              </View>
              <View style={{ width: "33.3333%", paddingHorizontal: 5 }}>
                <Text style={{color: "#0d7bbf"}}>{t("pdf.nombre_campo")}</Text>
                <Text style={{ marginTop: 3 }}>
                  {trainingSeleccionado?.camp}
                </Text>
              </View>
              <View style={{ width: "33.3333%", paddingHorizontal: 5 }}>
              <View style={{flexDirection:"row"}}>
                  <Text style={{color: "#0d7bbf"}}>
                    {t("pdf.sesion")}
                  </Text>
                  <Text>
                    {" "} {trainingSeleccionado?.num_session}
                  </Text>
                </View>
                <View style={{flexDirection:"row"}}>
                  <Text style={{color: "#0d7bbf"}}>
                    {t("pdf.hora")}
                  </Text>
                  <Text>
                    {" "}
                    {trainingSeleccionado?.date_and_time?.split("T")[0]}{trainingSeleccionado?.only_time ? " "+trainingSeleccionado?.only_time  : ""}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "20%",
                backgroundColor: "#E4E4E4",
                display: "flex",
                alignSelf: "center",
                alignContent: "center",
                padding: 10,
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 12 }}>
                {t("pdf.porteros")}
              </Text>
            </View>
            <View style={{alignContent: "center", paddingTop: 5, paddingLeft:10}}>
              <Image
              src={logo} style={{width:"40px", maxHeight:"40px", padding: 5}} />
            </View>
          </View>
          <View style={{ fontSize: 9 }}>
            <View
              style={{
                border: "#E4E4E4",
                borderWidth: 1,
                padding: 5,
                marginBottom: 5,
              }}
            >
              <View style={{ flexDirection: "row", width: "100%", flexWrap:"wrap" }}>
              {trainingSeleccionado?.players?.map((p: any, index) =>
                  
                    <Text key={index} style={{ width: "20%", paddingRight: 2 }}>
                        {p.player?.dorsal || "XX"} - {p.player?.name || p.name}
                      </Text>
                /*<View
                  style={{ width: "33.3333%", paddingRight: 1, paddingLeft: 1 }}
                >
                  {trainingSeleccionado?.players?.map((p: any, index) =>
                    index % 2 === 0 && index % 3 !== 0 ? (
                      <Text key={index}>
                        {p.player?.dorsal || "XX"} - {p.player?.name || p.name}
                      </Text>
                    ) : undefined
                  )}
                </View>
                <View
                  style={{ width: "33.3333%", paddingLeft: 2, marginTop: 3 }}
                >
                  {trainingSeleccionado?.players?.map((p: any, index) =>
                    index % 3 === 0 ? (
                      <Text key={index}>
                        {p.player?.dorsal || "XX"} - {p.player?.name || p.name}
                      </Text>
                    ) : undefined
                  )}
                    </View>*/)}
              </View>
            </View>
            <View
              style={{
                backgroundColor: "#E4E4E4",
                display: "flex",
                alignSelf: "center",
                alignContent: "center",
                padding: 10,
                flexDirection: "row",
                width: "100%",
              }}
            >
              <View style={{ marginTop: 5, marginBottom: 1, width: "70%" }}>
                <Text style={{ fontWeight: "bold", marginBottom: 2,color: "#0d7bbf" }}>
                  {t("pdf.objetivos")}
                </Text>
                <Text style={{paddingRight:10}}>{trainingSeleccionado?.goal_obj}</Text>
              </View>
              <View style={{ marginTop: 5, marginBottom: 1, width: "30%" }}>
                <Text style={{ fontWeight: "bold", marginBottom: 2,color: "#0d7bbf" }}>
                  {t("pdf.material")}
                </Text>
                <Text>{trainingSeleccionado?.global_mat}</Text>
              </View>
            </View>
            <View style={{ marginTop: 10, width: "100%" }}>
              {trainingSeleccionado?.experiences
                ?.filter((e) => e.type === "Porteros")
                .map((e: any, index) => {
                  let guidelinesAux = completeExperience(e).guidelines;

                  return <View
                    style={{
                      flexDirection: "row",
                      borderBottom: 1,
                      borderBottomColor: "#E4E4E4",
                      marginTop: 5,
                      paddingBottom: 5,
                    }}
                    key={index}
                    wrap={false}
                  >
                    <View style={{ width: "25%" }}>
                      <Text>
                        {e.experience
                          ? e.experience["name_" + i18n.language]
                          : e["name_" + i18n.language]}
                      </Text>
                      <Image
                src={{
                  uri: cmsStorageURL + (e.experience? e.experience?.file1?.file : e.file1?.file),
                  body: "",
                  method: "GET",
                  headers: "",
                }}
                style={{ width: "95%" }}
              />
              <View style={{flexDirection:"row"}}>
                <Text style={{color: "#0d7bbf"}}>TI: </Text>
                <Text>{e.inv_time || "-"}</Text>
                <Text style={{color: "#0d7bbf"}}>{" "} R:</Text>
                <Text>{e.reps || "-"}</Text>
                <Text style={{color: "#0d7bbf"}}>{" "} S:</Text>
                <Text>{e.series || "-"}</Text>
              </View>
                    </View>
                      <View
                        style={{
                          width: "100%",
                          borderBottom: 1,
                          borderBottomColor: "#E4E4E4",
                          marginTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            marginBottom: 5,
                          }}
                        >
                          <Text style={{ width: "50%", textAlign: "center",color: "#0d7bbf" }}>
                            {t("09022022.equipo_con")}
                          </Text>
                          <Text style={{ width: "50%", textAlign: "center",color: "#0d7bbf" }}>
                            {t("09022022.equipo_sin")}
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", width: "100%" }}>
                          <View
                            style={{
                              marginRight: 3,
                              flexDirection: "row",
                              width: "50%",
                              textAlign: "center",
                            }}
                          >
                            
                            <View
                              style={{
                                border: 1,
                                borderColor: "#E4E4E4",
                                marginLeft: 3,
                                flexDirection: "row",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O1":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O1")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O1
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwith" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O2":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O2")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O2
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwith" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O3": g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O3")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O3
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwith" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O4":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O4")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O4
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwith" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O5":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "O5")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                O5
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              marginLeft: 3,
                              flexDirection: "row",
                              width: "50%",
                            }}
                          >
                            <View
                              style={{
                                border: 1,
                                borderColor: "#E4E4E4",
                                marginRight: 3,
                                flexDirection: "row",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D1":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D1")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D1
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D2":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D2")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D2
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D3":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D3")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D3
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  borderRight: 1,
                                  borderRightColor: "#E4E4E4",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D4":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D4")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D4
                              </Text>
                              <Text
                                style={{
                                  width: "20%",
                                  textAlign: "center",
                                  backgroundColor: guidelinesAux?.find(
                                    (g: any) =>
                                      g.checked === true &&
                                      g.type === "Cwithout" &&
                                      (g.guideline? g.guideline.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D5":g.name
                                        ?.split("|")[0]
                                        .replace(".", "")
                                        .slice(0, 2) === "D5")
                                  )
                                    ? "#E4E4E4"
                                    : "#FFFFFF",
                                }}
                              >
                                D5
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View style={{ marginTop: 5, flexDirection: "row" }}>
                          <View style={{ width: "50%", paddingRight: 5 }}>
                            {guidelinesAux?.map((g: any, ind : any) => { 
                                if(g.checked === true && g.type === "Cwith"){
                                let guidelineAux : string =  g.guideline? g.guideline.name?.split("|")[1]: g.name?.split("|")[1];
                                let dest = null;
                                let destStr = "";
                                if(g.destiny !== null && g.destiny !== undefined && g.destiny.startsWith("{")){
                                  dest = JSON.parse(g.destiny);
                                  destStr = parseDest(dest);
                                }
                                let guidelineName = guidelineAux?.startsWith("{") ? JSON.parse(
                                  guidelineAux
                                 )[i18n.language] : guidelineAux;
                                return guidelineName ? 
                                <View style={{flexDirection: "row"}} key={ind}>
                                  <Text style={{width:"7.5%", paddingRight:"15px",color:(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).startsWith("D") ? "#FF0000" : "#000000"}}>
                                    {(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).substring(0,3)}
                                  </Text>
                                  <Text style={{width:"82.5%", paddingRight:"15px", fontSize:"8px"}}>
                                    {
                                      guidelineName
                                    }
                                  </Text>
                                  
                                  <Text style={{width:"10%"}}>
                                    {destStr}
                                  </Text>
                                </View> : undefined;
                              }
                            })}
                          </View>
                          <View style={{ width: "50%", paddingLeft: 5 }}>
                            {guidelinesAux?.map((g: any) => 
                              { 
                                if(g.checked === true && g.type === "Cwithout"){
                                let guidelineAux : string =  g.guideline? g.guideline.name?.split("|")[1]: g.name?.split("|")[1];
                                let dest = null;
                                let destStr = "";
                                if(g.destiny !== null && g.destiny !== undefined && g.destiny.startsWith("{")){
                                  dest = JSON.parse(g.destiny);
                                  destStr = parseDest(dest);
                                }
                                let guidelineName = guidelineAux?.startsWith("{") ? JSON.parse(
                                  guidelineAux
                                 )[i18n.language] : guidelineAux;
                                return guidelineName ? 
                                <View style={{flexDirection: "row"}} key={index}>
                                  <Text style={{width:"7.5%", paddingRight:"15px", color:(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).startsWith("D") ? "#FF0000" : "#000000"}}>
                                    {(g.guideline? g.guideline.name?.split("|")[2]: g.name?.split("|")[2]).substring(0,3)}
                                  </Text>
                                  <Text style={{width:"82.5%", paddingRight:"15px", fontSize:"8px"}}>
                                    {
                                      guidelineName
                                    }
                                  </Text>
                                  
                                  <Text style={{width:"10%"}}>
                                    {destStr}
                                  </Text>
                                </View> : undefined;
                              }
                            }
                            )}
                          </View>
                        </View>
                        {e.obs && <View style={{flexDirection:"row", marginTop: 10, paddingTop:5, borderTop: 1, borderTopColor: "#E4E4E4"}}>
                          <Text style={{color: "#0d7bbf"}}>
                            {t("09022022.distJug")}
                          </Text>
                          <Text>
                            {" " + e.obs}
                          </Text>
                        </View>}
                      </View>
                    
                  </View>
                })}
            </View>
          </View>
        </Page>}
        {trainingSeleccionado?.experiences?.filter((e) => e.type === "BioPre")?.length > 0 && trainingSeleccionado?.experiences?.filter((e) => e.type === "BioPost")?.length > 0 && <Page size="A4" style={{ padding: 15, flexDirection: "column" }}>
          <View style={{ flexDirection: "row" }}>
            {
              <Image
                src={{
                  uri: teamImage,
                  body: "",
                  method: "GET",
                  headers: "",
                }}
                style={{ maxWidth: "50px", maxHeight:"50px", objectFit: "contain" }}
              />
            }
            <View
              style={{
                width: "60%",
                borderBottom: 1,
                borderBottomColor: "#E4E4E4",
                borderTop: 1,
                borderTopColor: "#E4E4E4",
                marginVertical: 5,
                fontSize: 10,
                paddingVertical: 5,
                flexDirection: "row",
                marginRight: 10,
              }}
            >
              <View
                style={{
                  width: "33.3333%",
                  paddingHorizontal: 5,
                }}
              >
                <Text style={{color: "#0d7bbf"}}>{t("pdf.nombre_equipo")}</Text>
                <Text style={{ marginTop: 3 }}>
                  {trainingSeleccionado?.team?.team_name}
                </Text>
              </View>
              <View style={{ width: "33.3333%", paddingHorizontal: 5 }}>
                <Text style={{color: "#0d7bbf"}}>{t("pdf.nombre_campo")}</Text>
                <Text style={{ marginTop: 3 }}>
                  {trainingSeleccionado?.camp}
                </Text>
              </View>
              <View style={{ width: "33.3333%", paddingHorizontal: 5 }}>
              <View style={{flexDirection:"row"}}>
                  <Text style={{color: "#0d7bbf"}}>
                    {t("pdf.sesion")}
                  </Text>
                  <Text>
                    {" "} {trainingSeleccionado?.num_session}
                  </Text>
                </View>
                <View style={{flexDirection:"row"}}>
                  <Text style={{color: "#0d7bbf"}}>
                    {t("pdf.hora")}
                  </Text>
                  <Text>
                    {" "}
                    {trainingSeleccionado?.date_and_time?.split("T")[0]}{trainingSeleccionado?.only_time ? " "+trainingSeleccionado?.only_time  : ""}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "20%",
                backgroundColor: "#E4E4E4",
                display: "flex",
                alignSelf: "center",
                alignContent: "center",
                padding: 10,
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 12 }}>
                {t("09022022.biologica")}
              </Text>
            </View>
            <View style={{alignContent: "center", paddingTop: 5, paddingLeft:10}}>
              <Image
              src={logo} style={{width:"40px", maxHeight:"40px", padding: 5}} />
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ fontSize: 9 }}>
              <View
                style={{
                  backgroundColor: "#E4E4E4",
                  display: "flex",
                  alignSelf: "center",
                  alignContent: "center",
                  padding: 10,

                  width: "100%",
                }}
              >
                <Text style={{ fontWeight: "bold", fontSize: 12 }}>
                  {t("pdf.pre_bio")}
                </Text>
                <View
                  style={{ flexDirection: "row", marginTop: 10, width: "100%" }}
                >
                  <View style={{ marginBottom: 1, width: "70%" }}>
                    <Text style={{ fontWeight: "bold", marginBottom: 2,color: "#0d7bbf" }}>
                      {t("pdf.objetivos")}
                    </Text>
                    <Text style={{paddingRight:10}}>{trainingSeleccionado?.bio_pre_obj}</Text>
                  </View>
                  <View style={{ marginBottom: 1, width: "30%" }}>
                    <Text style={{ fontWeight: "bold", marginBottom: 2,color: "#0d7bbf" }}>
                      {t("pdf.material")}
                    </Text>
                    <Text>{trainingSeleccionado?.bio_pre_mat}</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginTop: 10,
                  width: "100%",
                  paddingTop: 10,
                  borderTop: 2,
                  borderTopColor: "#E4E4E4",
                }}
              >
                <Text style={{ marginBottom: 10, fontSize: 12 }}>
                  {t("pdf.pre_exp")}
                </Text>
                <View style={{flexDirection: "row", flexWrap:"wrap"}}>
                {trainingSeleccionado?.experiences
                  ?.filter((e) => e.type === "BioPre")
                  .map((e: any, index) => (
                    <View key={index} style={{ width: "33%", marginBottom: 10 }}>
                      <Text>
                        {e.experience
                          ? e.experience["name_" + i18n.language]
                          : e["name_" + i18n.language]}
                      </Text>
                      <Image
                src={{
                  uri: cmsStorageURL + (e.experience? e.experience?.file1?.file : e.file1?.file),
                  body: "",
                  method: "GET",
                  headers: "",
                }}
                style={{ width: "95%" }}
              />
                      <Text>
                        {e.experience
                          ? e.experience["description_" + i18n.language]
                          : e["description_" + i18n.language]}
                      </Text>
                    </View>
                  ))}
                  </View>
              </View>
              <View
                style={{
                  marginTop: 10,
                  width: "100%",
                  paddingTop: 10,
                  borderTop: 2,
                  borderTopColor: "#E4E4E4",
                }}
              >
                <Text style={{ marginBottom: 10, fontSize: 12,color: "#0d7bbf" }}>
                  {t("09022022.pre_players")}
                </Text>
                <View style={{flexDirection: "row", flexWrap:"wrap"}}>
                {trainingSeleccionado?.experiences
                  ?.sort(e => e.player_id).filter((e) => e.type === "PlayerPRE")
                  .map((e: any, index) => {
                    const playerName = trainingSeleccionado?.players?.find(t => t.player_id?.toString() ===e.player_id?.toString())?.name || ""; 
                    return <View key={index} style={{ width: "50%", marginBottom: 10, flexDirection:"row" }}>
                      
                      <Image
                src={{
                  uri: cmsStorageURL + (e.experience? e.experience?.file1?.file : e.file1?.file),
                  body: "",
                  method: "GET",
                  headers: "",
                }}
                style={{ width: "30%", paddingRight: 10 }}
              />
              <View>
              <Text>
                    {playerName}
                  </Text>
                <Text>
                    {e.experience
                      ? e.experience["name_" + i18n.language]
                      : e["name_" + i18n.language]}
                  </Text>
                  <Text>
                    {e.experience
                      ? e.experience["description_" + i18n.language]
                      : e["description_" + i18n.language]}
                  </Text>
                      </View>
                    </View>
                  })}
                  </View>
              </View>
            </View>
            <View style={{ fontSize: 9 }}>
              <View
                style={{
                  backgroundColor: "#E4E4E4",
                  display: "flex",
                  alignSelf: "center",
                  alignContent: "center",
                  padding: 10,
                  width: "100%",
                }}
              >
                <Text style={{ fontWeight: "bold", fontSize: 12 }}>
                  {t("pdf.post_bio")}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    marginTop: 10,
                  }}
                >
                  <View style={{ marginBottom: 1, width: "70%" }}>
                    <Text style={{ fontWeight: "bold", marginBottom: 2,color: "#0d7bbf" }}>
                      {t("pdf.objetivos")}
                    </Text>
                    <Text style={{paddingRight:10}}>{trainingSeleccionado?.bio_post_obj}</Text>
                  </View>
                  <View style={{ marginBottom: 1, width: "30%" }}>
                    <Text style={{ fontWeight: "bold", marginBottom: 2,color: "#0d7bbf" }}>
                      {t("pdf.material")}
                    </Text>
                    <Text>{trainingSeleccionado?.bio_post_mat}</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginTop: 10,
                  width: "100%",
                  paddingTop: 10,
                  borderTop: 2,
                  borderTopColor: "#E4E4E4",
                }}
              >
                <Text style={{ marginBottom: 10, fontSize: 12 }}>
                  {t("pdf.post_exp")}
                </Text>
                <View style={{flexDirection: "row", flexWrap:"wrap"}}>
                {trainingSeleccionado?.experiences
                  ?.filter((e) => e.type === "BioPost")
                  .map((e: any, index) => (
                    <View key={index} style={{ width: "33%" }}>
                      <Text>
                        {e.experience
                          ? e.experience["name_" + i18n.language]
                          : e["name_" + i18n.language]}
                      </Text>
                      <Image
                src={{
                  uri: cmsStorageURL + (e.experience? e.experience?.file1?.file : e.file1?.file),
                  body: "",
                  method: "GET",
                  headers: "",
                }}
                style={{ width: "95%" }}
              />
                      <Text>
                        {e.experience
                          ? e.experience["description_" + i18n.language]
                          : e["description_" + i18n.language]}
                      </Text>
                    </View>
                  ))}
                  </View>
              </View>
              <View
                style={{
                  marginTop: 10,
                  width: "100%",
                  paddingTop: 10,
                  borderTop: 2,
                  borderTopColor: "#E4E4E4",
                }}
              >
                <Text style={{ marginBottom: 10, fontSize: 12,color: "#0d7bbf" }}>
                  {t("09022022.post_players")}
                </Text>
                <View style={{flexDirection: "row", flexWrap:"wrap"}}>
                {trainingSeleccionado?.experiences
                  ?.sort(e => e.player_id).filter((e) => e.type === "PlayerPOST")
                  .map((e: any, index) => {
                    const playerName = trainingSeleccionado?.players?.find(t => t.player_id?.toString() ===e.player_id?.toString())?.name || ""; 
                    return <View key={index} style={{ width: "50%", marginBottom: 10, flexDirection:"row" }}>
                      
                      <Image
                src={{
                  uri: cmsStorageURL + (e.experience? e.experience?.file1?.file : e.file1?.file),
                  body: "",
                  method: "GET",
                  headers: "",
                }}
                style={{ width: "30%", paddingRight: 10 }}
              />
              <View>
              <Text>
                    {playerName}
                  </Text>
                <Text>
                    {e.experience
                      ? e.experience["name_" + i18n.language]
                      : e["name_" + i18n.language]}
                  </Text>
                  <Text>
                    {e.experience
                      ? e.experience["description_" + i18n.language]
                      : e["description_" + i18n.language]}
                  </Text>
                      </View>
                    </View>
                  })}
                  </View>
              </View>
            </View>
          </View>
        </Page>}
      </Document>
    );
  };

  function updateFiltro(busqueda: string) {
    let training = GetTrainings();
    if (!training) return;

    let filteredTrainings = training.filter((p) =>
      p.obs.toLowerCase().includes(busqueda.toLowerCase())
    );
    setListaTraining(filteredTrainings);
  }

  function downloadPDF(){
    setPDFready(false);
    return(<div>
      <p>{t("09022022.pdfDownloadText")}</p>
      <div className="flex">
      {<button
            className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 px-10 rounded-full text-white mx-auto"
            onClick={() => 

               {if(PDFready)setModalContent(undefined);}
            }
          >
            <p className="text-center w-full">
              
            <PDFDownloadLink
                      document={<MyDocument />}
                      fileName={"training detail "+ trainingSeleccionado?.date_and_time.substring(0,10) +".pdf"}
                    >
                      {({ blob, url, loading, error }) =>
                        { if(error)console.log(error?.message);
                          setPDFready(loading);
                          return (loading ? t("09022022.loadingpdf") : t("09022022.downloadpdf"))}
                      }
                    </PDFDownloadLink>
              
            </p>
          </button>}
          <button
            className="flex bg-white hover:bg-sf-lightblue py-1 px-10 rounded-full text-sf-mediumblue border-2 border-sf-mediumblue mx-auto"
            onClick={() => setModalContent(undefined)}
          >
            <p className="text-center w-full">
              {t("09022022.cancelButton")}
            </p>
          </button>

      </div>
      
      
    </div>)
  }

  function printPDF(){
    return(<div>
      <p>{t("09022022.pdfText")}</p>
      <p>{t("09022022.pdfContinue")}</p>
      <div className="flex">
      {trainingSeleccionado && <button
            className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 px-10 rounded-full text-white mx-auto"
            onClick={() => 
               actualizarEntrenamiento(true).then(r => {
                 if(r){
                    setModalContent(downloadPDF())
                 }else{

                 }
               })
            }
          >
            <p className="text-center w-full">
              {t("09022022.continueButton")}
              
            </p>
          </button>}
          <button
            className="flex bg-white hover:bg-sf-lightblue py-1 px-10 rounded-full text-sf-mediumblue border-2 border-sf-mediumblue mx-auto"
            onClick={() => setModalContent(undefined)}
          >
            <p className="text-center w-full">
              {t("09022022.cancelButton")}
            </p>
          </button>

      </div>
      
      
    </div>)
  }

  function trainingModal(trainingData: TrainingData, player: PlayerEvaluation) {
    let answer = { assist: true, score: 0, obs: "" };
    return (
      <div>
        <div className="grid grid-cols-2 gap-6">
          <div className="border border-gray-200 rounded-md p-2">
            <div>
              {new Date(trainingData.date_and_time).toLocaleDateString(
                undefined,
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}{" "}
              {trainingData.only_time}
            </div>
            <div>{trainingData.camp}</div>
          </div>
          <div className="pt-3 text-center">
            {t("29082021.confirmar_asistencia")}
            <div
              className="flex flex-col justify-center"
              onChange={(e: any) =>
                (answer.assist = e.target.value === "si" ? true : false)
              }
            >
              <div className="pt-3">
                <input
                  className="self-center"
                  type="radio"
                  id="cy"
                  name="carnet"
                  value="si"
                  defaultChecked={true}
                />
                <label className="self-center pl-1" htmlFor="cy">
                  {t("29082021.confirmar_yes")}
                </label>
              </div>
              <div className="pt-1">
                <input
                  className="self-center"
                  type="radio"
                  id="cn"
                  name="carnet"
                  value="no"
                  defaultChecked={false}
                />
                <label className="self-center pl-1" htmlFor="cn">
                  {t("29082021.confirmar_no")}
                </label>
              </div>
            </div>
          </div>
          <div>
            <p>{t("29082021.indica_valoracion_training")}</p>
            <p className="py-3 text-sm">{t("29082021.encuentras_training")}</p>
            <div className="flex pb-4">
              <p className="py-3 text-sm">
                {t("29082021.indica_valoracion_training_small")}
              </p>
              <div className="w-1/12 ml-auto my-auto">
                <DataField
                max={10}
                min={0}
                  onChange={(e) => (answer.score = e)}
                  data=""
                  type="numeric"
                  placeholder=" "
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-1">
              <div className="bg-green-200 rounded-md w-full text-center">
                {t("29082021.muy_bien")}
              </div>
              <div className="bg-yellow-200 rounded-md w-full text-center">
                {t("29082021.mal")}
              </div>
              <div
                className="rounded-md w-full text-center"
                style={{ backgroundColor: "#88DD0033" }}
              >
                {t("29082021.bien")}
              </div>
              <div className="bg-red-200 rounded-md w-full text-center">
                {t("29082021.muy_mal")}
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="my-auto w-full">
              <DataField
                type="textarea"
                label="Observaciones"
                placeholder="Observaciones"
                data=""
                onChange={(e) => (answer.obs = e)}
              />
            </div>
          </div>
        </div>
        <div className="flex mt-10">
          <button
            className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 px-10 rounded-full text-white mx-auto"
            onClick={() => {
              player.before = JSON.stringify(answer);
              if(answer.assist){
                player.disp = "disp";
            }else{
                player.disp ="other";
              }
              if (!token) return;
              UpdatePlayerCMSTrainings(player, token);
              const trainings = GetTrainings();
              if (trainings) {
                const prevTraining = trainings
                  .filter(
                    (training) =>
                      new Date(training.date_and_time).getTime() <
                        new Date().getTime() &&
                      training.players.find(
                        (p) => p.player_id === loggedUser?.player_id
                      )
                  )
                  .sort((m1, m2) =>
                    new Date(m1.date_and_time).getTime() <
                    new Date(m2.date_and_time).getTime()
                      ? 1
                      : -1
                  )[0];

                let prevTrainingPlayer = prevTraining.players?.find(
                  (p) =>
                    p.player_id === loggedUser?.player_id &&
                    (p.after === undefined || p.after === null)
                );
                if (prevTraining && prevTrainingPlayer) {
                  setModalContent(
                    prevTrainingModal(prevTraining, prevTrainingPlayer),
                    t("09022022.valoracion_title")
                  );
                } else {
                  setModalContent(undefined);
                }
              }
            }}
          >
            <p className="text-center w-full">
              {t("29082021.enviar_valoracion")}
            </p>
          </button>
        </div>
      </div>
    );
  }

  function prevTrainingModal(
    trainingData: TrainingData,
    player: PlayerEvaluation
  ) {
    let answer = { score: 0, obs: "" };
    return (
      <div>
        <div className="grid grid-cols-2 gap-6">
          <div className="border border-gray-200 rounded-md p-2">
            <div>
              {new Date(trainingData.date_and_time).toLocaleDateString(
                undefined,
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}{" "}
              {trainingData.only_time}
            </div>
            <div>{trainingData.camp}</div>
          </div>
          <div className="pt-3 text-center">{t("29082021.has_asistido")}</div>
          <div>
            <p>{t("09022022.indica_valoracion_training")}</p>
            <p className="py-3 text-sm">{t("training.playerconfig.after")}</p>
            <div className="flex pb-4">
              <p className="py-3 text-sm">
                {t("09022022.indica_valoracion_training_small")}
              </p>
              <div className="w-1/12 ml-auto my-auto">
                <DataField
                max={10}
                min={0}
                  onChange={(e) => (answer.score = e)}
                  type="numeric"
                  data=""
                  placeholder=" "
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-1">
              <div className="bg-green-200 rounded-md w-full text-center">
                {t("29082021.muy_suave")}
              </div>
              <div className="bg-yellow-200 rounded-md w-full text-center">
                {t("29082021.duro")}
              </div>
              <div
                className="rounded-md w-full text-center"
                style={{ backgroundColor: "#88DD0033" }}
              >
                {t("29082021.suave")}
              </div>
              <div className="bg-red-200 rounded-md w-full text-center">
                {t("29082021.muy_duro")}
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="my-auto w-full">
              <DataField
                type="textarea"
                label="Observaciones"
                placeholder="Observaciones"
                data=""
                onChange={(e) => (answer.obs = e)}
              />
            </div>
          </div>
        </div>
        <div className="flex mt-10">
          <button
            className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 px-10 rounded-full text-white mx-auto"
            onClick={() => {
              player.after = JSON.stringify(answer);
              if (!token) return;
              UpdatePlayerCMSTrainings(player, token).then((e) =>
                setModalContent(undefined)
              );
            }}
          >
            <p className="text-center w-full">
              {t("29082021.enviar_valoracion")}
            </p>
          </button>
        </div>
      </div>
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <div className="flex flex-col h-full z-0">
        <Topbar
          visibleButtons={editable}
          selected={trainingSeleccionado}
          createText={t("training.create")}
          onDownload={trainingSeleccionado ? 
            ()=> setModalContent(printPDF(), t("09022022.printPDF")) : undefined
            
          }
          onBack={() => props.history.goBack()}
          onFilter={(text: string) => updateFiltro(text)}
          onCreate={() =>
            props.history.push({
              pathname: props.location.pathname + "/create",
              state: {
                data: Object.assign({
                  guidelines: [],
                  players: [],
                  experiences: [],
                }) as TrainingData,
              },
            })
          }
          onDelete={() => borrarEntrenamientos()}
          onUpdate={() => {
            actualizarEntrenamiento();
            props.history.goBack();
          }}
          disabled={!validTraining}
        />
        <div
          className={
            "mt-8 w-full flex-1 overflow-auto" +
            (trainingSeleccionado ? " bg-f2f2f2" : "")
          }
        >
          {trainingSeleccionado ? (
            <TrainingDetail
              selected={trainingSeleccionado}
              editable={!visualizable}
              validTraining={(value) => setValidTraining(value)}
            />
          ) : (
            <div className="bg-white w-full p-12 flex-1 overflow-auto flex">
              <div className="w-full mx-auto" style={{ maxWidth: "1280px" }}>
                <FullCalendar
                  displayEventTime={false}
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={{ end: "prev,next" }}
                  locale={i18n.language}
                  firstDay={1}
                  events={getEntrenamientos()}
                  eventClick={(info) => {
                    if (!listaTraining || (!visualizable && !editable))
                      return;
                    props.history.push({
                      pathname: props.location.pathname + "/detail",
                      state: {
                        data: Object.assign(
                          {},
                          listaTraining[Number(info.event.id)]
                        ),
                      },
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="bg-f2f2f2 mt-20 flex flex-1">
          <div className="m-auto flex flex-col">
            <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
            <p>{t("29082021.loading.generic")}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Training;
