import React, { useEffect, useState } from "react";
import club_icon from "../../images/icons/clubs-icon.svg";
import add_icon from "../../images/icons/add-icon.svg";
import loadingGif from "../../images/loading.gif";
import "../../styles/fa.css";
import "../../styles/style.css";

import CollapsablePanel from "../CollapsablePanel";
import ImageField from "../ImageField";
import DataField from "../DataField";
import {
  ExperienceData,
  Objective,
} from "../../CMSUtils/Icms";
import useStore from "../../store";
import {
  GetObjectives,
  LoadCMSObjectives,
} from "../../CMSUtils/ObjectivesCalls";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { useTranslation } from "react-i18next";

export const BioExperienceDetail: React.FC<{
  selected: ExperienceData;
  uploadImage: (image: File | undefined) => void;
  valid?: (value: boolean) => void;
  image: File | undefined;
  editable?: boolean;
}> = ({ uploadImage, selected, image, editable = true, valid }) => {
  const { t } = useTranslation();
  const token = useStore((state) => state.token);
  const [listaObjectives, setListaObjectives] = useState<any[]>();
  const setModalContent = useStore((state) => state.setModalContent);
  const [objetivos, setObjetivos] = useState<number[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const [lang, setLang] = useState("ES");
  const loggedUser = useStore((state) => state.loggedUser);
  const permiso_editar = loggedUser?.role.name === "Admin";

  useEffect(() => {
    let isMounted = true;
    fetchData().then(()=> {if(isMounted){
      setListaObjectives(GetObjectives());
      if (selected?.objectives) {
        setObjetivos(JSON.parse(selected.objectives) as number[]);
      }
      setIsLoaded(true);
    }});

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function marcarObjetivo(id: number) {
    if (!objetivos?.find((o) => o === id)) {
      objetivos.push(id);
      setObjetivos(objetivos.map((g) => g));
      selected.objectives = JSON.stringify(objetivos);
    } 
  }
  useEffect(() => {
    if (!selected.name_es)
      (selected.name_es as any) = Object.assign("", selected.name_es);
    if (!selected.name_en)
      (selected.name_en as any) = Object.assign("", selected.name_en);
    if (!selected.name_ru)
      (selected.name_ru as any) = Object.assign("", selected.name_ru);
    if (!selected.material_es)
      (selected.material_es as any) = Object.assign("", selected.material_es);
    if (!selected.material_en)
      (selected.material_en as any) = Object.assign("", selected.material_en);
    if (selected.material_ru)
      (selected.material_ru as any) = Object.assign("", selected.material_ru);
    if (!selected.variants_es)
      (selected.variants_es as any) = Object.assign("", selected.variants_es);
    if (!selected.variants_en)
      (selected.variants_en as any) = Object.assign("", selected.variants_en);
    if (!selected.variants_ru)
      (selected.variants_ru as any) = Object.assign("", selected.variants_ru);
    if (!selected.obs3_es)
      (selected.obs3_es as any) = Object.assign("", selected.obs3_es);
    if (!selected.obs3_en)
      (selected.obs3_en as any) = Object.assign("", selected.obs3_en);
    if (!selected.obs3_ru)
      (selected.obs3_ru as any) = Object.assign("", selected.obs3_ru);
    if (!selected.description_es)
      (selected.description_es as any) = Object.assign(
        "",
        selected.description_es
      );
    if (!selected.description_en)
      (selected.description_en as any) = Object.assign(
        "",
        selected.description_en
      );
    if (!selected.description_ru)
      (selected.description_ru as any) = Object.assign(
        "",
        selected.description_ru
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  function desmarcarObjetivo(id: number) {
    if (objetivos.find((o) => o === id)) {
      objetivos.splice(
        objetivos.findIndex((s) => s === id),
        1
      );
    }
    setObjetivos(objetivos.map((g) => g));
    selected.objectives = JSON.stringify(objetivos);
  }

  async function fetchData() {
    if (!token) return;
    await LoadCMSObjectives(token);
  }

  function getObjectives() {
    return (
      <React.Fragment>
        <div className="pb-6 grid grid-cols-2">
          {listaObjectives?.map((o, index) => (
            <div key={index}>
              <div className="w-8/12 py-2 px-6">
                <p className="self-center pl-3">{o.name}</p>
              </div>
              {o.objectives.map((s: Objective, ind:any) => (
                <table className="w-full table-fixed pb-6 text-sm" key={ind}>
                  <tbody style={{ verticalAlign: "top" }}>
                    <tr>
                      <td className="w-8/12 py-1 px-6">
                        <div className="flex pl-10">
                          <input
                            type="checkbox"
                            className="self-center"
                            onChange={(e) =>
                              e.target.checked
                                ? marcarObjetivo(s.id)
                                : desmarcarObjetivo(s.id)
                            }
                            defaultChecked={objetivos.includes(s.id)}
                          />
                          <p className="self-center pl-3">{s.name}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <div
        onChange={() => {
          setCambiosSinGuardar(true);
          if (valid)
            valid(
              selected.name_ru !== undefined && selected.name_ru.toString() !== "" &&
                selected.name_en !== undefined && selected.name_en.toString() !== "" &&
                selected.name_es !== undefined  && selected.name_es.toString() !== ""
            );
        }}
        onLoad={() => {
          if (valid)
            valid(
              selected.name_ru !== undefined && selected.name_ru.toString() !== "" &&
                selected.name_en !== undefined && selected.name_en.toString() !== "" &&
                selected.name_es !== undefined  && selected.name_es.toString() !== ""
            );
        }}
      >
        <CollapsablePanel
          titulo={t("experience_bio.data.title")}
          colapsado={false}
          multiidioma
          idiomaChange={(idioma) => setLang(idioma)}
        >
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td rowSpan={3} className="w-1/3">
                  <div
                    className="flex flex-col m-auto"
                    style={{ width: "14rem" }}
                  >
                    <ImageField
                      emptyImage={club_icon}
                      uploadedImage={image}
                      uploadImage={uploadImage}
                      defaultImage={
                        selected.file1
                          ? cmsStorageURL + selected.file1.file
                          : undefined
                      }
                      disabled={!editable || !permiso_editar}
                    />
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={
                      selected[
                        `name_${lang.toLowerCase()}` as keyof typeof selected
                      ]
                    }
                    label={t("experience_bio.data.name")}
                    type="textarea"
                    rows={3}
                    onChange={(e) =>
                      ((selected as any)[`name_${lang.toLowerCase()}`] = e)
                    }
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={selected.difficulty_es}
                    label={t("experience_bio.data.diff")}
                    onChange={(e) => (selected.difficulty_es = e)}
                  />
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={
                      selected[
                        `material_${lang.toLowerCase()}` as keyof typeof selected
                      ]
                    }
                    label={t("experience_bio.data.material")}
                    type="textarea"
                    rows={3}
                    onChange={(e) =>
                      ((selected as any)[`material_${lang.toLowerCase()}`] = e)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-fixed border-t-2 border-gray-200">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={
                      selected[
                        `description_${lang.toLowerCase()}` as keyof typeof selected
                      ]
                    }
                    label={t("experience_bio.data.description")}
                    type="textarea"
                    onChange={(e) => {
                      selected[
                        lang === "ES" ? "description_es" : "description_en"
                      ] = e;
                    }}
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={
                      selected[
                        `variants_${lang.toLowerCase()}` as keyof typeof selected
                      ]
                    }
                    type="textarea"
                    label={t("experience_bio.data.variants")}
                    onChange={(e) =>
                      ((selected as any)[`variants_${lang.toLowerCase()}`] = e)
                    }
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={
                      selected[
                        `obs3_${lang.toLowerCase()}` as keyof typeof selected
                      ]
                    }
                    type="textarea"
                    label={t("experience_bio.data.tips")}
                    onChange={(e) =>
                      ((selected as any)[`obs3_${lang.toLowerCase()}`] = e)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel
          titulo={t("experience_bio.obj.title")}
          colapsado={true}
        >
          {objetivos.length > 0 && (
            <div className="grid grid-cols-2">
              {objetivos.map((o, ind) => {
                let objetivo: any;
                listaObjectives?.forEach((objAux) => {
                  let objEncontrado = objAux.objectives.find(
                    (objective: any) => objective.id === o
                  );
                  if (objEncontrado) {
                    objetivo = objEncontrado;
                  }
                });
                return <p className="py-2" key={ind}>{objetivo?.name}</p>;
              })}
            </div>
          )}
          {permiso_editar && (
            <button
              className={
                "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-4"
              }
              onClick={() =>
                setModalContent(getObjectives(), "Añadir objetivos")
              }
            >
              <img src={add_icon} className="h-6 p-1 text-center m-auto" alt="add" />
              <p>{t("experience_bio.obj.add")}</p>
            </button>
          )}
        </CollapsablePanel>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="m-auto flex flex-col">
          <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
          <p>{t("29082021.loading.generic")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BioExperienceDetail;
