import React from "react";
import DataField from "../DataField";
import exp_icon from "../../images/icons/soccer-field.svg";
import { ExperienceData } from "../../CMSUtils/Icms";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { useTranslation } from "react-i18next";
export const ExperienceCard: React.FC<{
  data: ExperienceData;
  onClick?: (estado: boolean) => void;
  seleccionada?: boolean;
  personalizable?: boolean;
  onDataChange?: (data: any) => void;
  extraData?: any;
}> = ({
  data,
  onClick,
  seleccionada,
  personalizable,
  onDataChange,
  extraData,
}) => {
  const { t, i18n } = useTranslation();
  let datosExtra: {
    series: string;
    reps: string;
    inv_time: string;
    ref_time: string;
    obs: string;
    width: string;
  } = extraData;
  return (
    <div
      className={
        "flex w-full shadow-md relative border-2 " +
        (seleccionada ? "border-sf-mediumblue" : "border-gray-300") +
        (onClick ? " cursor-pointer" : "")
      }
      onClick={() => {
        if (onClick && !personalizable) {
          onClick(!seleccionada);
        }
      }}
    >
      
      {personalizable && (
        <input
          type="checkbox"
          className="absolute top-0 leftt-0"
          defaultChecked={seleccionada}
          onChange={() => {
            if (onClick) onClick(!seleccionada);
          }}
        />
      )}
      
      <div
        className={"p-2 flex flex-col " + (personalizable ? "w-1/2" : "w-full")}
      >
        <div className="flex-1">
            <div className={"w-full h-full relative" + ((i18n.language === "es" ? data.description_es : i18n.language === "ru" ? data.description_ru : data.description_en ) ? " flipcard" : "")+ (personalizable?" mt-2":"")}>
              
                  <img
                  src={
                    data.file1 ? cmsStorageURL + data.file1?.file : exp_icon //?? data.experience?.file1?.file)
                  }
                  alt="placeholder"
                  className="max-w-full mx-auto"
                  style={{maxHeight: "150px"}}
                />
                <p className={"pb-1 pt-2"}>
                {i18n.language === "es" ? data.name_es : i18n.language === "ru" ? data.name_ru : data.name_en}
                </p>
              <div className={"text-sm font-normal w-full text-gray-600 absolute top-0 right-0 pr-2 bottom-0 left-0 whitespace-normal overflow-auto" + ((i18n.language === "es" ? data.description_es : i18n.language === "ru" ? data.description_ru : data.description_en )?" hover:bg-white":"")}>
                {i18n.language === "es" ? data.description_es : i18n.language === "ru" ? data.description_ru : data.description_en}
              </div>
            </div>
          </div>
      </div>
      {personalizable && extraData !== null && datosExtra !== null && (
        <div className="w-1/2 p-1">
          <div className="grid grid-cols-2 gap-1">
            <DataField
              data={datosExtra?.series || ""}
              label={t("14052021.card.options.series")}
              onChange={(value) => {
                datosExtra.series = value;
                if (onDataChange) onDataChange(datosExtra);
              }}
            />
            <DataField
              data={datosExtra?.reps || ""}
              label={t("14052021.card.options.repeticiones")}
              onChange={(value) => {
                datosExtra.reps = value;
                if (onDataChange) onDataChange(datosExtra);
              }}
            />
            <DataField
              data={datosExtra?.inv_time || ""}
              label={t("14052021.card.options.trabajo")}
              onChange={(value) => {
                datosExtra.inv_time = value;
                if (onDataChange) onDataChange(datosExtra);
              }}
            />
            <DataField
              data={datosExtra?.ref_time || ""}
              label={t("14052021.card.options.descanso")}
              onChange={(value) => {
                datosExtra.ref_time = value;
                if (onDataChange) onDataChange(datosExtra);
              }}
            />
            <DataField
              data={datosExtra?.width || ""}
              label={t("14052021.card.options.carga")}
              onChange={(value) => {
                datosExtra.width = value;
                if (onDataChange) onDataChange(datosExtra);
              }}
            />
          </div>
          <DataField
            data={datosExtra?.obs || ""}
            label={t("14052021.card.options.observaciones")}
            type="textarea"
            rows={3}
            onChange={(value) => {
              datosExtra.obs = value;
              if (onDataChange) onDataChange(datosExtra);
            }}
          />
        </div>
      )}
    </div>
  );
};
