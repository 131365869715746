import {
  cmsBaseUrl,
  MatchData,
} from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsMatchUrl: string = cmsApiUrl + "/match";
const cmsAssesmentUrl: string = cmsApiUrl + "/matchAssesment";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let matchList: MatchData[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetMatchesFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsMatchUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      matchList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSMatches: (token: string) => Promise<boolean> =
  GetMatchesFromCMS;

async function RemoveMatchesFromCMS(
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsMatchUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSMatches: (id: number, token: string) => Promise<boolean> =
  RemoveMatchesFromCMS;

async function InsertMatchesFromCMS(
  match: MatchData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsMatchUrl, {
    method: "POST",
    body: JSON.stringify({ matchData: match }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSMatches: (
  match: MatchData,
  token: string
) => Promise<number> = InsertMatchesFromCMS;

async function InsertAssesment(
  player_id: number,
  match_id: number,
  info: string,
  token: string
): Promise<any> {
  //Lanzamos la petición
  let response = await fetch(cmsAssesmentUrl, {
    method: "POST",
    body: JSON.stringify({
      matchAssesmentData: {
        match_id: match_id,
        player_id: player_id,
        obs: info,
        score: 0,
      },
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result;
  } catch (error) {
    return -1;
  }
}

export const AddAssesment: (
  player_id: number,
  match_id: number,
  info: string,
  token: string
) => Promise<any> = InsertAssesment;

async function PutAssesment(
  id: number,
  info: string,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsAssesmentUrl, {
    method: "PUT",
    body: JSON.stringify({
      id: id,
      matchAssesmentData: {
        obs: info,
        score: 0,
      },
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const updateAssesment: (
  id: number,
  info: string,
  token: string
) => Promise<number> = PutAssesment;

async function ModifyMatchesFromCMS(
  match: MatchData,
  token: string,
  lang: string,
  send_email: number
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsMatchUrl, {
    method: "PUT",
    body: JSON.stringify({
      matchData: match,
      id: match.id,
      lang: lang,
      send_email: send_email,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const UpdateCMSMatches: (
  match: MatchData,
  token: string,
  lang: string,
  send_email: number
) => Promise<number> = ModifyMatchesFromCMS;

function getMatches(): MatchData[] | undefined {
  if (matchList === undefined) return undefined;

  return matchList;
}
export const GetMatches: () => MatchData[] | undefined = getMatches;
