import React from "react";
import { GetClubs} from "../../CMSUtils/ClubCalls";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { StaffData} from "../../CMSUtils/Icms";
import staff_icon from "../../images/icons/c_tecnico.svg";

const StaffItem: React.FC<{
  onCheck: (e: any) => void;
  onClick: () => void;
  datos: StaffData;
  index: number;
}> = ({ onCheck, onClick, datos, index }) => {

  
  return (
    <div
      onClick={onClick}
      key={index}
      className={
        "w-full cursor-pointer font-bold " +
        ((index + 1) % 2 === 0
          ? " bg-f2f2f2 hover:bg-gray-500"
          : "bg-white text-gray-800 hover:bg-gray-200")
      }
    >
      <div className="w-full xl:w-3/4 flex flex-row mx-auto py-2">
        <div className="w-1/12 flex flex-row px-1 self-center">
          <input
            type="checkbox"
            name="select"
            value="select"
            className="cursor-pointer my-auto transform mx-2"
            onChange={(e) => onCheck(e)}
            onClick={(e) => e.stopPropagation()}
            //checked={clubsMarcados.includes(club.id) || false}
          />
          {datos.image && (
            <img
            alt="placeholder"
              src={cmsStorageURL + datos.image?.file}
              style={{ maxWidth: "35px", maxHeight: "35px", margin: "auto" }}
            />
          )}
          {!datos.image && (
            <img
              src={staff_icon}
              alt="placeholder"
              className="p-1 bg-gray-500 opacity-25"
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                margin: "auto",
              }}
            />
          )}
        </div>
        <div className="w-3/12 px-1 self-center">
          <p>{datos.name}</p>
          <p className="text-xs text-gray-600">{datos.surname}</p>
        </div>
        <div className="w-2/12 px-1 self-center">
          <p>{datos.sport_rol}</p>
        </div>
        <div className={"w-2/12 px-1 self-center"}>{GetClubs()?.find(c => c.id.toString() ===datos.club_id.toString())?.entity_name}</div>
        <div className={"w-2/12 px-1 self-center"}>
          <p>{datos.academic_titles}</p>
        </div>
        <div className={"w-2/12 px-1 self-center"}>
          <p>{datos.projection}</p>
        </div>
      </div>
    </div>
  );
};

export default StaffItem;
