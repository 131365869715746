import React, { useEffect, useState } from "react";
import add_icon from "../../images/icons/add-icon.svg";
import cross_icon from "../../images/icons/close.svg";
import loadingGif from "../../images/loading.gif";

import "../../styles/fa.css";
import "../../styles/style.css";

import CollapsablePanel from "../CollapsablePanel";
import DataField from "../DataField";
import {
  MatchData,
  PlayerData,
  MatchPlayer,
  AssesmentData,
} from "../../CMSUtils/Icms";
import useStore from "../../store";
import { LoadCMSTeams, GetTeams } from "../../CMSUtils/TeamCalls";
import { allRoleNames } from "../../data/ReportHelper";
import { useTranslation } from "react-i18next";

export const MatchDetail: React.FC<{
  selected: MatchData;
  uploadImage: (image: File | undefined) => void;
  image: File | undefined;
  validMatch: (value: boolean) => void;
}> = ({ uploadImage, selected, image, validMatch }) => {
  const { t } = useTranslation();
  const loggedUser = useStore((state) => state.loggedUser);
  const editable =
    loggedUser?.role.name === "Admin" || loggedUser?.role.name === "Trainer";

  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const [listaJugadoresTitulares, setListaJugadoresTitulares] = useState<
    MatchPlayer[]
  >([] as MatchPlayer[]);
  let listaJugadores = [] as PlayerData[];
  const setModalContent = useStore((state) => state.setModalContent);
  const [isLoaded, setIsLoaded] = useState(false);
  const [listaJugadoresSuplentes, setListaJugadoresSuplentes] = useState<
    MatchPlayer[]
  >([] as MatchPlayer[]);
  const [listaJugadoresNoConvocados, setListaJugadoresNoConvocados] = useState<
    MatchPlayer[]
  >([] as MatchPlayer[]);
  const [listaJugadoresRivales, setListaJugadoresRivales] = useState<
    MatchPlayer[]
  >(selected.player_rival_data ?? ([] as MatchPlayer[]));
  if (!selected.assessments) {
    let assesments = [] as AssesmentData[];
    for (let i = 0; i < 10; i++) {
      assesments.push({ player_id: 0 } as AssesmentData);
    }
    selected.assessments = assesments;
  }
  useEffect(() => {
    let isMounted = true;
    fetchData().then(()=>{
      if(isMounted){
        getPlayers(selected.team_id);
        setListaJugadoresTitulares(
          selected.player_data?.filter((j) => j.type === 0)
        );
        setListaJugadoresSuplentes(
          selected.player_data?.filter((j) => j.type === 1)
        );
    
        let assignedPlayers: number[] = selected.player_data?.map(
          (p) => p.player_id
        );
        let players: PlayerData[] =
          GetTeams()?.find((t) => t.id === selected.team_id)?.players ??
          ([] as PlayerData[]);
    
        let unassignedPlayers: MatchPlayer[] = [];
    
        players.forEach((j) => {
          if (!assignedPlayers.includes(j.id))
            unassignedPlayers.push({ player_id: j.id, player: j } as MatchPlayer);
        });
    
        setListaJugadoresNoConvocados(unassignedPlayers);
        setIsLoaded(true);
      }
    });

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSTeams(token);
    return null;
  }

  function getTeamNames() {
    if (!GetTeams()) return [];
    let equipos: any[] = [];
    GetTeams()?.map((e) => equipos.push({ value: e.id, text: e.team_name }));
    return equipos;
  }

  function getPlayers(teamID: number) {
    listaJugadores =
      GetTeams()?.find((t) => t.id === teamID)?.players ?? ([] as PlayerData[]);
    return listaJugadores.map((j) => {
      return { player_id: j.id, player: j } as MatchPlayer;
    });
  }

  function getPlayersAsTitular(teamID: number) {
    listaJugadores =
      GetTeams()?.find((t) => t.id.toString() === teamID.toString())?.players ?? ([] as PlayerData[]);
    return listaJugadores.map((j) => {
      return { player_id: j.id, player: j, type: 0 } as MatchPlayer;
    });
  }

  function ventanaTitulares() {
    return (
      <div className="grid grid-cols-4 gap-3">
        {listaJugadoresNoConvocados.map((j, index) => (
          <div
            className="border-2 border-gray-300 cursor-pointer p-4"
            onClick={() => {
              añadirTitular(index);
              if (listaJugadoresNoConvocados.length > 0) {
                setModalContent(ventanaTitulares());
              } else {
                setModalContent(undefined);
              }
            }}
          >
            {j.player?.name}
          </div>
        ))}
      </div>
    );
  }

  function ventanaSuplentes() {
    return (
      <div className="grid grid-cols-4 gap-3">
        {listaJugadoresNoConvocados.map((j, index) => (
          <div
            className="border-2 border-gray-300 cursor-pointer p-4"
            onClick={() => {
              añadirSuplente(index);
              if (listaJugadoresNoConvocados.length > 0) {
                setModalContent(ventanaSuplentes());
              } else {
                setModalContent(undefined);
              }
            }}
          >
            {j.player?.name}
          </div>
        ))}
      </div>
    );
  }

  function savePlayers() {
    let players: MatchPlayer[] = [];
    listaJugadoresTitulares?.forEach((j) => {
      j.type = 0;
      players.push(j);
    });
    listaJugadoresSuplentes?.forEach((j) => {
      j.type = 1;
      players.push(j);
    });
    selected.player_data = players;
  }

  function saveRivals() {
    selected.player_rival_data = listaJugadoresRivales;
  }

  function quitarTitular(pos: number) {
    if (!listaJugadoresNoConvocados || !listaJugadoresTitulares) return;
    listaJugadoresNoConvocados.push(listaJugadoresTitulares[pos]);
    listaJugadoresTitulares.splice(pos, 1);
    setListaJugadoresNoConvocados(listaJugadoresNoConvocados.map((j) => j));
    setListaJugadoresTitulares(listaJugadoresTitulares.map((j) => j));
    savePlayers();
  }

  function añadirTitular(pos: number) {
    if (!listaJugadoresNoConvocados || !listaJugadoresTitulares) return;
    listaJugadoresTitulares.push(listaJugadoresNoConvocados[pos]);
    listaJugadoresNoConvocados.splice(pos, 1);
    setListaJugadoresNoConvocados(listaJugadoresNoConvocados.map((j) => j));
    setListaJugadoresTitulares(listaJugadoresTitulares.map((j) => j));
    savePlayers();
  }

  function quitarSuplente(pos: number) {
    if (!listaJugadoresNoConvocados || !listaJugadoresSuplentes) return;
    listaJugadoresNoConvocados.push(listaJugadoresSuplentes[pos]);
    listaJugadoresSuplentes.splice(pos, 1);
    setListaJugadoresNoConvocados(listaJugadoresNoConvocados.map((j) => j));
    setListaJugadoresSuplentes(listaJugadoresSuplentes.map((j) => j));
    savePlayers();
  }

  function getAssesments(assesments : AssesmentData[], id: string, id2?:string){
    let value : number = 0;
    let amount = 0;
    assesments.forEach(a => {
      let obs = a.obs ? JSON.parse(a.obs) : undefined;
      if(id !== "autoevaluation"){
        if(obs && (obs.category === id || (id2 && obs.category === id2)) && a.score){
          value += Number.parseInt((a.score || 0).toString()) ;
          amount += 1;
        }
      }else{
        if(obs && (obs.category === id) && id2 && obs.values && obs.values[id2]){
          value = obs.values[id2];
        }
      }
    });
    let result = id !== "autoevaluation" ? ((value / amount) || 0) : value;

    return  result.toFixed(result > 0 && id !== "autoevaluation" ? 2 : 0);
  }

  function añadirSuplente(pos: number) {
    
    if (!listaJugadoresNoConvocados) return;
    let listaSuplentes = listaJugadoresSuplentes || [];
    listaSuplentes.push(listaJugadoresNoConvocados[pos]);
    listaJugadoresNoConvocados.splice(pos, 1);
    setListaJugadoresNoConvocados(listaJugadoresNoConvocados.map((j) => j));
    setListaJugadoresSuplentes(listaSuplentes);
    savePlayers();
  }

  function añadirRivalTitular() {
    if (!listaJugadoresRivales) return;
    listaJugadoresRivales.push({ type: 0 } as MatchPlayer);
    setListaJugadoresRivales(listaJugadoresRivales.map((j) => j));
  }

  function añadirRivalSuplente() {
    if (!listaJugadoresRivales) return;
    listaJugadoresRivales.push({ type: 1 } as MatchPlayer);
    setListaJugadoresRivales(listaJugadoresRivales.map((j) => j));
  }

  function añadirRivalNoConvocado() {
    if (!listaJugadoresRivales) return;
    listaJugadoresRivales.push({ type: 2 } as MatchPlayer);
    setListaJugadoresRivales(listaJugadoresRivales.map((j) => j));
  }

  function eliminarRival(item: MatchPlayer) {
    if (!listaJugadoresRivales) return;
    listaJugadoresRivales.splice(
      listaJugadoresRivales.findIndex((j) => j === item),
      1
    );
    setListaJugadoresRivales(listaJugadoresRivales.map((j) => j));
  }

  function evaluationPanel(
    assesment: AssesmentData[],
    section: string = "trainer"
  ) {
    return (
      <React.Fragment>
        <div className="w-full flex bg-sf-darkblue text-gray-500">
          <div className="w-full grid grid-cols-2 mx-auto text-center">
            <div
              className={
                "cursor-pointer py-4 px-4" +
                (section === "trainer"
                  ? " border-b-2 border-white text-white bg-sf-mediumblue bg-opacity-25"
                  : "")
              }
              onClick={() =>
                setModalContent(evaluationPanel(assesment, "trainer"))
              }
            >
              <p>{t("09022022.valoracionTr")}</p>
            </div>
            {assesment.find(
              (a) => JSON.parse(a.obs).category === "autoevaluation"
            ) && (
              <div
                className={
                  "cursor-pointer py-4 px-4" +
                  (section === "player"
                    ? " border-b-2 border-white text-white bg-sf-mediumblue bg-opacity-25"
                    : "")
                }
                onClick={() =>
                  setModalContent(evaluationPanel(assesment, "player"))
                }
              >
                <p>{t("09022022.autoPl")}</p>
              </div>
            )}
          </div>
        </div>
        {section === "trainer" && (
          <div className="w-full">
            <div className="pb-2 pl-2 pt-4 bg-sf-darkblue text-white">{t("09022022.rolOf")}</div>
            {assesment.map((a, index) => {
              let data = JSON.parse(a.obs);
              if (data.category === "positionWith") {
                return (
                  <div className={"flex py-2 px-2" + (index%2 === 0? " bg-gray-400": "")}>
                    <p className="w-11/12">{t(data.title)}</p>
                    <div className="w-1/12">
                      <DataField
                        disabled={!editable}
                        data={a.score}
                        placeholder="X"
                        max={10}
                        min={0}
                        type="number"
                        onChange={(e) => (a.score = e)}
                      />
                    </div>
                  </div>
                );
              }
              return undefined;
            })}
            <div className="pb-2 pl-2 pt-4 bg-sf-darkblue text-white">{t("09022022.rolDef")}</div>
            {assesment.map((a, index) => {
              let data = JSON.parse(a.obs);
              if (data.category === "positionWithout") {
                return (
                  <div className={"flex py-2 px-2" + (index%2 === 0? " bg-gray-400": "")}>
                    <p className="w-11/12">{t(data.title)}</p>
                    <div className="w-1/12">
                      <DataField
                        disabled={!editable}
                        data={a.score}
                        max={10}
                        min={0}
                        placeholder="X"
                        type="number"
                        onChange={(e) => (a.score = e)}
                      />
                    </div>
                  </div>
                );
              }
              return undefined;
            })}
            <div className="pb-2 pl-2 pt-4 bg-sf-darkblue text-white">{t("09022022.ipis")}</div>
            {assesment.map((a, index) => {
              let data = JSON.parse(a.obs);
              if (data.category === "ipis") {
                return (
                  <div className={"flex py-2 px-2" + (index%2 === 0? " bg-gray-400": "")}>
                    <p className="w-11/12">{data.title}</p>
                    <div className="w-1/12">
                      <DataField
                        disabled={!editable}
                        data={a.score}
                        max={10}
                        min={0}
                        placeholder="X"
                        type="number"
                        onChange={(e) => (a.score = e)}
                      />
                    </div>
                  </div>
                );
              }
              return undefined;
            })}
          </div>
        )}

        {section === "player" && (
          <div className="w-full">
            {assesment.filter(a => {
              let data = JSON.parse(a.obs);
              return data.category === "autoevaluation";
            })?.sort((a1, a2) => a1.id > a2.id ? -1 : 1).map((a, index) => {
              if(index > 0) return undefined;
              let data = JSON.parse(a.obs);
              if (data.category === "autoevaluation") {
                return (
                  <div>
                    <div className="flex py-1">
                      <div className="w-11/12 pr-2">
                        <DataField
                          disabled={true}
                          data={data.values? data.values.sentido : ""}
                          placeholder="Respuesta..."
                          type="textarea"
                          rows={2}
                          label={t("14052021.autoevaluacion.ask_1")}
                          onChange={(e) => undefined}
                        />
                      </div>
                      <div className="w-1/12 pl-2 self-center">
                        <DataField
                          disabled={true}
                          data={data.values? data.values.sentido_score : ""}
                          placeholder="X"
                          type="number"
                          onChange={(e) => undefined}
                        />
                      </div>
                    </div>
                    <div className="flex py-1">
                      <div className="w-11/12 pr-2">
                        <DataField
                          disabled={true}
                          data={data.values?data.values.equipo:""}
                          placeholder="Respuesta..."
                          type="textarea"
                          rows={2}
                          label={t("14052021.autoevaluacion.ask_2")}
                          onChange={(e) => undefined}
                        />
                      </div>
                      <div className="w-1/12 pl-2 self-center">
                        <DataField
                          disabled={true}
                          data={data.values?data.values.equipo_score:""}
                          placeholder="X"
                          type="number"
                          onChange={(e) => undefined}
                        />
                      </div>
                    </div>
                    <div className="flex py-1">
                      <div className="w-11/12 pr-2">
                        <DataField
                          disabled={true}
                          data={data.values?data.values.actuacion:""}
                          placeholder="Respuesta..."
                          type="textarea"
                          rows={2}
                          label={t("14052021.autoevaluacion.ask_3")}
                          onChange={(e) => undefined}
                        />
                      </div>
                      <div className="w-1/12 pl-2 self-center">
                        <DataField
                          disabled={true}
                          data={data.values?data.values.actuacion_score:""}
                          placeholder="X"
                          type="number"
                          onChange={(e) => undefined}
                        />
                      </div>
                    </div>
                    <div className="flex py-1">
                      <div className="w-full">
                        <DataField
                          disabled={true}
                          data={data.values?data.values.obs2:""}
                          placeholder="X"
                          type="textarea"
                          label={t("14052021.autoevaluacion.ask_4")}
                          onChange={(e) => undefined}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
              return undefined;
            })}
          </div>
        )}
      </React.Fragment>
    );
  }

  const token = useStore((state) => state.token);
  return isLoaded ? (
    <React.Fragment>
      <form
        onChange={(e) => {
          setCambiosSinGuardar(true);
          validMatch(e.currentTarget.checkValidity() && selected.date !== undefined &&selected.date !== null);
        }}
        onLoad={(e) => validMatch(e.currentTarget.checkValidity() && selected.date !== undefined &&selected.date !== null)}
      >
        <CollapsablePanel titulo={t("match.data.title")} colapsado={false}>
          <table className="table-fixed w-full">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.team_id}
                    label={t("match.data.team")}
                    type="select"
                    placeholder={t("match.data.team_placeholder")}
                    options={getTeamNames()}
                    onChange={(e) => {
                      selected.team_id = e;
                      selected.player_data = getPlayersAsTitular(e);
                      setListaJugadoresTitulares(selected.player_data);
                    }}
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.enemy}
                    label={t("match.data.rival")}
                    onChange={(e) => (selected.enemy = e)}
                  />
                </td>
                <td className="w-1/3 py-4 px-6"></td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6 ">
                  <div className="flex w-full">
                    <div className="pr-2 w-1/2">
                      <DataField
                        disabled={!editable}
                        data={selected.category}
                        label={t("match.data.cat")}
                        onChange={(e) => (selected.category = e)}
                      />
                    </div>
                    <div className="pl-2 w-1/2">
                      <DataField
                        disabled={!editable}
                        data={selected.group}
                        label={t("match.data.group")}
                        onChange={(e) => (selected.group = e)}
                      />
                    </div>
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.field}
                    label={t("match.data.field")}
                    onChange={(e) => (selected.field = e)}
                  />
                </td>
                <td className="w-1/3 py-4 px-6"></td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-2">
                      <DataField
                        disabled={!editable}
                        data={selected.date}
                        required
                        label={t("match.data.date")}
                        type="date"
                        placeholder="Seleccionar"
                        onChange={(e) => (selected.date = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <DataField
                        disabled={!editable}
                        data={selected.hour}
                        label={t("match.data.hour")}
                        placeholder="Seleccionar"
                        type="time"
                        onChange={(e) => (selected.hour = e)}
                      />
                    </div>
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-2">
                      <DataField
                        disabled={!editable}
                        data={selected.day}
                        label={t("match.data.jor")}
                        onChange={(e) => (selected.day = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <DataField
                        disabled={!editable}
                        data={selected.result}
                        label={t("match.data.res")}
                        placeholder={t("match.data.res_placeholder")}
                        onChange={(e) => (selected.result = e)}
                      />
                    </div>
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6"></td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.referee}
                    label={t("match.data.arb")}
                    placeholder={t("match.data.arb_placeholder")}
                    onChange={(e) => (selected.referee = e)}
                  />
                </td>
                <td colSpan={2} className="w-2/3 py-4 px-6"></td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.assistants}
                    label={t("match.data.assist")}
                    placeholder={t("match.data.assist_placeholder")}
                    type="textarea"
                    rows={2}
                    onChange={(e) => (selected.assistants = e)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.interesting_players}
                    label={t("match.data.interesting_rivals")}
                    type="textarea"
                    rows={2}
                    onChange={(e) => (selected.interesting_players = e)}
                  />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("match.team.title")}>
          <div className="px-6 py-4">
            <h1>{t("match.team.titul")}</h1>
            {editable && (
              <button
                className={
                  "flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2  my-2 rounded-full text-white mr-2"
                }
                type="button"
                onClick={() => setModalContent(ventanaTitulares())}
                disabled={
                  !listaJugadoresNoConvocados ||
                  listaJugadoresNoConvocados.length === 0
                }
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("match.team.titul_add")}</p>
              </button>
            )}
            {listaJugadoresTitulares && listaJugadoresTitulares.length > 0 && (
              <table className="table-fixed w-full">
                <thead>
                  <tr>
                    <td className="w-3/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.titul_player")}
                    </td>
                    <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.titul_minutes")}
                    </td>
                    <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.titul_goals")}
                    </td>
                    <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.titul_yellow")}
                    </td>
                    <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.titul_red")}
                    </td>
                    <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.titul_change")}
                    </td>
                    <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white text-center truncate">
                      {t("match.team.titul_val")}
                    </td>
                  </tr>
                </thead>
                <tbody style={{ verticalAlign: "top" }}>
                  {listaJugadoresTitulares?.map((j, index) => (
                    <tr key={index} className={"border-b border-gray-400" + (index%2 === 1 ? " bg-gray-400": "")}>
                      <td className="w-3/12 py-4 px-6 border-r border-gray-200">{j.player?.name}</td>
                      <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.played_mins}
                          max={999}
                          min={0}
                          type="number"
                          placeholder={t(
                            "match.team.titul_minutes_placeholder"
                          )}
                          onChange={(e) => {
                            savePlayers();
                            j.played_mins = e;
                          }}
                        />
                      </td>
                      <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.goals}
                          max={99}
                          min={0}
                          type="number"
                          placeholder={t("match.team.titul_goals_placeholder")}
                          onChange={(e) => {
                            savePlayers();
                            j.goals = e;
                          }}
                        />
                      </td>
                      <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.yellow_cards}
                          type="select"
                          options={["0","1","2"]}
                          //placeholder={t("match.team.titul_yellow_placeholder")}
                          onChange={(e) => {
                            savePlayers();
                            j.yellow_cards = e;
                          }}
                        />
                      </td>
                      <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.red_cards}
                          type="select"
                          options={["0","1"]}
                          //placeholder={t("match.team.titul_red_placeholder")}
                          onChange={(e) => {
                            savePlayers();
                            j.red_cards = e;
                          }}
                        />
                      </td>
                      <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.num_changes}
                          placeholder={t("match.team.titul_change_placeholder")}
                          onChange={(e) => {
                            savePlayers();
                            j.num_changes = e;
                          }}
                        />
                      </td>
                      <td className="w-1/12 py-4 px-6">
                        <div className="flex w-full">
                          <div className="w-5/6">
                            <DataField
                              disabled={!editable}
                              data={j.score}
                              placeholder={t(
                                "match.team.titul_val_placeholder"
                              )}
                              max={10}
                              min={0}
                              type="number"
                              onChange={(e) => {
                                savePlayers();
                                j.score = e;
                              }}
                            />
                          </div>
                          {editable && (
                            <button
                              className="transform translate-x-12 self-center"
                              onClick={() => quitarTitular(index)}
                              type="button"
                            >
                              <img src={cross_icon} className="w-5 h-5" alt="close" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <h1 className="w-full border-t-2 border-gray-200 pt-2 mt-4">
              {t("match.team.supl")}
            </h1>
            {editable && (
              <button
                className={
                  "flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2  my-2 rounded-full text-white mr-2"
                }
                type="button"
                onClick={() => setModalContent(ventanaSuplentes())}
                disabled={
                  !listaJugadoresNoConvocados ||
                  listaJugadoresNoConvocados.length === 0
                }
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("match.team.supl_add")}</p>
              </button>
            )}
            {listaJugadoresSuplentes && listaJugadoresSuplentes.length > 0 && (
              <table className="table-fixed w-full">
                <thead>
                  <tr>
                    <td className="w-3/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.supl_player")}
                    </td>
                    <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.supl_minutes")}
                    </td>
                    <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.supl_goals")}
                    </td>
                    <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.supl_yellow")}
                    </td>
                    <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.supl_red")}
                    </td>
                    <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                      {t("match.team.supl_change")}
                    </td>
                    <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white text-center truncate">
                      {t("match.team.supl_val")}
                    </td>
                  </tr>
                </thead>
                <tbody style={{ verticalAlign: "top" }}>
                  {listaJugadoresSuplentes?.map((j, index) => (
                    <tr className={"border-b border-gray-400" + (index%2 === 1 ? " bg-gray-400": "")}>
                      <td className="w-3/12 py-4 px-6 border-r border-gray-200">{j.player?.name}</td>
                      <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.played_mins}
                          max={999}
                          min={0}
                          type="number"
                          placeholder={t("match.team.supl_minutes_placeholder")}
                          onChange={(e) => {
                            savePlayers();
                            j.played_mins = e;
                          }}
                        />
                      </td>
                      <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.goals}
                          max={99}
                          min={0}
                          type="number"
                          placeholder={t("match.team.supl_goals_placeholder")}
                          onChange={(e) => {
                            savePlayers();
                            j.goals = e;
                          }}
                        />
                      </td>
                      <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.yellow_cards}
                          type="select"
                          options={["0","1","2"]}
                          //placeholder={t("match.team.supl_yellow_placeholder")}
                          onChange={(e) => {
                            savePlayers();
                            j.yellow_cards = e;
                          }}
                        />
                      </td>
                      <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.red_cards}
                          type="select"
                          options={["0","1"]}
                          //placeholder={t("match.team.supl_red_placeholder")}
                          onChange={(e) => {
                            savePlayers();
                            j.red_cards = e;
                          }}
                        />
                      </td>
                      <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                        <DataField
                          disabled={!editable}
                          data={j.num_changes}
                          placeholder={t("match.team.supl_change_placeholder")}
                          onChange={(e) => {
                            savePlayers();
                            j.num_changes = e;
                          }}
                        />
                      </td>
                      <td className="w-1/12 py-4 px-6">
                        <div className="flex w-full">
                          <div className="w-5/6">
                            <DataField
                              disabled={!editable}
                              data={j.score}
                              max={10}
                          min={0}
                          type="number"
                              placeholder={t("match.team.supl_val_placeholder")}
                              onChange={(e) => {
                                savePlayers();
                                j.score = e;
                              }}
                            />
                          </div>
                          {editable && (
                            <button
                              className="transform translate-x-12 self-center"
                              type="button"
                              onClick={() => quitarSuplente(index)}
                            >
                              <img src={cross_icon} className="h-5 w-5" alt="close" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <h1 className="w-full border-t-2 border-gray-200 pt-2 mt-4">
              {t("match.team.no_conv")}
            </h1>
            <div className="grid grid-cols-4 gap-3 pt-4">
              {listaJugadoresNoConvocados.map((j) => (
                <div>{j.player?.name}</div>
              ))}
            </div>
          </div>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("match.rival.title")}>
          <div className="px-6 py-4">
            <h1 className="w-full">{t("match.rival.titul")}</h1>
            {editable && (
              <button
              type="button"
                className={
                  "flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2  my-2 rounded-full text-white mr-2"
                }
                onClick={() => añadirRivalTitular()}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("match.rival.titul_add")}</p>
              </button>
            )}
            {listaJugadoresRivales &&
              listaJugadoresRivales.filter((j) => j.type === 0).length > 0 && (
                <table className="table-fixed w-full">
                  <thead>
                    <tr>
                      <td className="w-3/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.titul_player")}
                      </td>
                      <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.titul_minutes")}
                      </td>
                      <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.titul_goals")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.titul_yellow")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.titul_red")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.titul_change")}
                      </td>
                      <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white text-center truncate">
                        {t("match.rival.titul_val")}
                      </td>
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "top" }}>
                    {listaJugadoresRivales
                      ?.filter((j) => j.type === 0)
                      .map((j,ind) => (
                        <tr className={"border-b border-gray-400" + (ind%2 === 1 ? " bg-gray-400": "")} key={ind}>
                          <td className="w-3/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.player}
                              placeholder={t(
                                "match.rival.titul_player_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.player = e;
                              }}
                            />
                          </td>
                          <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.played_mins}
                              max={999}
                          min={0}
                          type="number"
                              placeholder={t(
                                "match.rival.titul_minutes_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.played_mins = e;
                              }}
                            />
                          </td>
                          <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.goals}
                              max={99}
                          min={0}
                          type="number"
                              placeholder={t(
                                "match.rival.titul_goals_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.goals = e;
                              }}
                            />
                          </td>
                          <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.yellow_cards}
                              type="select"
                          options={["0","1","2"]}
                              /*placeholder={t(
                                "match.rival.titul_yellow_placeholder"
                              )}*/
                              onChange={(e) => {
                                saveRivals();
                                j.yellow_cards = e;
                              }}
                            />
                          </td>
                          <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.red_cards}
                              type="select"
                          options={["0","1"]}
                              /*placeholder={t(
                                "match.rival.titul_red_placeholder"
                              )}*/
                              onChange={(e) => {
                                saveRivals();
                                j.red_cards = e;
                              }}
                            />
                          </td>
                          <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.num_changes}
                              placeholder={t(
                                "match.rival.titul_change_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.num_changes = e;
                              }}
                            />
                          </td>
                          <td className="w-1/12 py-4 px-6">
                            <div className="flex">
                              <div className="w-5/6">
                                <DataField
                                  disabled={!editable}
                                  data={j.score}
                                  max={10}
                          min={0}
                          type="number"
                                  placeholder={t(
                                    "match.rival.titul_val_placeholder"
                                  )}
                                  onChange={(e) => {
                                    saveRivals();
                                    j.score = e;
                                  }}
                                />
                              </div>
                              {editable && (
                                <button
                                  className="transform translate-x-12"
                                  type="button"
                                  onClick={() => eliminarRival(j)}
                                >
                                  <img src={cross_icon} className="h-4 w-4" alt="close" />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            <h1 className="w-full border-t-2 border-gray-200 mt-4 pt-2">
              {t("match.rival.supl")}
            </h1>
            {editable && (
              <button
              type="button"
                className={
                  "flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2  my-2 rounded-full text-white mr-2"
                }
                onClick={() => añadirRivalSuplente()}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("match.rival.supl_add")}</p>
              </button>
            )}
            {listaJugadoresRivales &&
              listaJugadoresRivales.filter((j) => j.type === 1).length > 0 && (
                <table className="table-fixed w-full">
                  <thead>
                    <tr>
                      <td className="w-3/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.supl_player")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.supl_minutes")}
                      </td>
                      <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.supl_goals")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.supl_yellow")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.supl_red")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.supl_change")}
                      </td>
                      <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white text-center truncate">
                        {t("match.rival.supl_val")}
                      </td>
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "top" }}>
                    {listaJugadoresRivales
                      ?.filter((j) => j.type === 1)
                      .map((j,ind) => (
                        <tr className={"border-b border-gray-400" + (ind%2 === 1 ? " bg-gray-400": "")} key={ind}>
                          <td className="w-3/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.player}
                              placeholder={t(
                                "match.rival.supl_player_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.player = e;
                              }}
                            />
                          </td>
                          <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.played_mins}
                              max={999}
                          min={0}
                          type="number"
                              placeholder={t(
                                "match.rival.supl_minutes_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.played_mins = e;
                              }}
                            />
                          </td>
                          <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.goals}
                              max={99}
                          min={0}
                          type="number"
                              placeholder={t(
                                "match.rival.supl_goals_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.goals = e;
                              }}
                            />
                          </td>
                          <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.yellow_cards}
                              type="select"
                          options={["0","1","2"]}
                              /*placeholder={t(
                                "match.rival.supl_yellow_placeholder"
                              )}*/
                              onChange={(e) => {
                                saveRivals();
                                j.yellow_cards = e;
                              }}
                            />
                          </td>
                          <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.red_cards}
                              type="select"
                          options={["0","1"]}
                              /*placeholder={t(
                                "match.rival.supl_red_placeholder"
                              )}*/
                              onChange={(e) => {
                                saveRivals();
                                j.red_cards = e;
                              }}
                            />
                          </td>
                          <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.num_changes}
                              placeholder={t(
                                "match.rival.supl_change_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.num_changes = e;
                              }}
                            />
                          </td>
                          <td className="w-1/12 py-4 px-6">
                            <div className="flex">
                              <div className="w-5/6">
                                <DataField
                                  disabled={!editable}
                                  data={j.score}
                                  max={10}
                          min={0}
                          type="number"
                                  placeholder={t(
                                    "match.rival.supl_val_placeholder"
                                  )}
                                  onChange={(e) => {
                                    saveRivals();
                                    j.score = e;
                                  }}
                                />
                              </div>
                              {editable && (
                                <button
                                  className="transform translate-x-12"
                                  onClick={() => eliminarRival(j)}
                                >
                                  <img src={cross_icon} className="w-4 h-4" alt="close" />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            <h1 className="w-full border-t-2 border-gray-200 mt-4 pt-2">
              {t("match.rival.noconv")}
            </h1>
            {editable && (
              <button
              type="button"
                className={
                  "flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2  my-2 rounded-full text-white mr-2"
                }
                onClick={() => añadirRivalNoConvocado()}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("match.rival.noconv_add")}</p>
              </button>
            )}
            {listaJugadoresRivales &&
              listaJugadoresRivales.filter((j) => j.type === 2).length > 0 && (
                <table className="table-fixed w-full">
                  <thead>
                    <tr>
                      <td className="w-3/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.noconv_player")}
                      </td>
                      <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.noconv_minutes")}
                      </td>
                      <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.noconv_goals")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.noconv_yellow")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.noconv_red")}
                      </td>
                      <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                        {t("match.rival.noconv_change")}
                      </td>
                      <td className="w-1/12 py-4 px-6 bg-sf-darkblue text-white text-center truncate">
                        {t("match.rival.noconv_val")}
                      </td>
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "top" }}>
                    {listaJugadoresRivales
                      ?.filter((j) => j.type === 2)
                      .map((j, ind) => (
                        <tr className={"border-b border-gray-400" + (ind%2 === 1 ? " bg-gray-400": "")} key={ind}>
                          <td className="w-3/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.player}
                              placeholder={t(
                                "match.rival.noconv_player_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.player = e;
                              }}
                            />
                          </td>
                          <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.played_mins}
                              max={999}
                          min={0}
                          type="number"
                              placeholder={t(
                                "match.rival.noconv_minutes_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.played_mins = e;
                              }}
                            />
                          </td>
                          <td className="w-1/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.goals}
                              max={99}
                          min={0}
                          type="number"
                              placeholder={t(
                                "match.rival.noconv_goals_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.goals = e;
                              }}
                            />
                          </td>
                          <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              type="select"
                          options={["0","1","2"]}
                              data={j.yellow_cards}
                              /*placeholder={t(
                                "match.rival.noconv_yellow_placeholder"
                              )}*/
                              onChange={(e) => {
                                saveRivals();
                                j.yellow_cards = e;
                              }}
                            />
                          </td>
                          <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.red_cards}
                              type="select"
                          options={["0","1","2"]}
                              /*placeholder={t(
                                "match.rival.noconv_red_placeholder"
                              )}*/
                              onChange={(e) => {
                                saveRivals();
                                j.red_cards = e;
                              }}
                            />
                          </td>
                          <td className="w-2/12 py-4 px-6 border-r border-gray-200">
                            <DataField
                              disabled={!editable}
                              data={j.num_changes}
                              placeholder={t(
                                "match.rival.noconv_change_placeholder"
                              )}
                              onChange={(e) => {
                                saveRivals();
                                j.num_changes = e;
                              }}
                            />
                          </td>
                          <td className="w-1/12 py-4 px-6">
                            <div className="flex">
                              <div className="w-5/6">
                                <DataField
                                  disabled={!editable}
                                  data={j.score}
                                  max={10}
                          min={0}
                          type="number"
                                  placeholder={t(
                                    "match.rival.noconv_val_placeholder"
                                  )}
                                  onChange={(e) => {
                                    saveRivals();
                                    j.score = e;
                                  }}
                                />
                              </div>
                              {editable && (
                                <button
                                  className="transform translate-x-12"
                                  onClick={() => eliminarRival(j)}
                                >
                                  <img src={cross_icon} className="w-4 h-4" alt="close" />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
          </div>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("match.values.title")}>
          <div className="px-6 py-4">{t("match.values.team_value")}</div>
          <table className="table-fixed w-full">
            <tbody style={{ verticalAlign: "bottom" }}>
              <tr>
                <td className="w-5/12"></td>
                <td className="w-1/12"></td>
                <td className="w-5/12"></td>
                <td className="w-1/12"></td>
              </tr>
              <tr>
                <td className="w-full px-6 py-4" colSpan={4}>
                  <DataField
                    disabled={!editable}
                    data={selected.overall}
                    label={t("match.data.col_value")}
                    type="textarea"
                    rows={3}
                    onChange={(e) => (selected.overall = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[0]?.obs || ""}
                    type="textarea"
                    label={t("match.values.comp_after_a")}
                    placeholder={t("match.values.comp_after_a_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[0]? selected.assessments!.filter(a => a.player_id === 0)[0]!.obs = e : "")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[0]?.score || ""}
                    label={t("match.values.value_a")}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={t("match.values.value_a_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[0]? selected.assessments!.filter(a => a.player_id === 0)[0]!.score = e : "")}
                  />
                </td>
                <td className="px-6 py-4 border-l-2 border-gray-500">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[1]?.obs || ""}
                    label={t("match.values.comp_after_b")}
                    type="textarea"
                    placeholder={t("match.values.comp_after_b_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[1]? selected.assessments!.filter(a => a.player_id === 0)[1]!.obs = e: "")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[1]?.score || ""}
                    min={0}
                    max={10}
                    type="number"
                    label={t("match.values.value_b")}
                    placeholder={t("match.values.value_b_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[1]? selected.assessments!.filter(a => a.player_id === 0)[1]!.score = e : "")}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[2]?.obs || ""}
                    label={t("match.values.const_game")}
                    type="textarea"
                    placeholder={t("match.values.const_game_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[2]? selected.assessments!.filter(a => a.player_id === 0)[2]!.obs = e : "")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[2]?.score || ""}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={t("match.values.const_game_values")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[2]?selected.assessments!.filter(a => a.player_id === 0)[2]!.score = e : "")}
                  />
                </td>
                <td className="px-6 py-4 border-l-2 border-gray-500">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[3]?.obs || ""}
                    type="textarea"
                    label={t("match.values.neutr_const_game")}
                    placeholder={t("match.values.neutr_const_game_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[3]? selected.assessments!.filter(a => a.player_id === 0)[3]!.obs = e : "")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[3]?.score || ""}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={t("match.values.neutr_const_game_values")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[3]?selected.assessments!.filter(a => a.player_id === 0)[3]!.score = e : "")}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[4]?.obs || ""}
                    label={t("match.values.end_game")}
                    type="textarea"
                    placeholder={t("match.values.end_game_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[4]?selected.assessments!.filter(a => a.player_id === 0)[4]!.obs = e : "")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[4]?.score || ""}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={t("match.values.end_game_values")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[4]?selected.assessments!.filter(a => a.player_id === 0)[4]!.score = e : "")}
                  />
                </td>
                <td className="px-6 py-4 border-l-2 border-gray-500">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[5]?.obs || ""}
                    label={t("match.values.neutr_end_game")}
                    type="textarea"
                    placeholder={t("match.values.neutr_end_game_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[5]?selected.assessments!.filter(a => a.player_id === 0)[5]!.obs = e : "")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[5]?.score || ""}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={t("match.values.neutr_end_game_values")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[5]?selected.assessments!.filter(a => a.player_id === 0)[5]!.score = e : "")}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[6]?.obs || ""}
                    label={t("match.values.of")}
                    type="textarea"
                    placeholder={t("match.values.of_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[6]?selected.assessments!.filter(a => a.player_id === 0)[6]!.obs = e : "")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[6]?.score || ""}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={t("match.values.of_values")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[6]?selected.assessments!.filter(a => a.player_id === 0)[6]!.score = e : "")}
                  />
                </td>
                <td className="px-6 py-4 border-l-2 border-gray-500">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[7]?.obs || ""}
                    label={t("match.values.def")}
                    type="textarea"
                    placeholder={t("match.values.def_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[7]?selected.assessments!.filter(a => a.player_id === 0)[7]!.obs = e :"")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[7]?.score || ""}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={t("match.values.def_values")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[7]?selected.assessments!.filter(a => a.player_id === 0)[7]!.score = e:"")}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[8]?.obs || ""}
                    label={t("match.values.abp_of")}
                    type="textarea"
                    placeholder={t("match.values.abp_of_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[8]?selected.assessments!.filter(a => a.player_id === 0)[8]!.obs = e:"")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[8]?.score}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={t("match.values.abp_of_values")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[8]?selected.assessments!.filter(a => a.player_id === 0)[8]!.score = e:"")}
                  />
                </td>
                <td className="px-6 py-4 border-l-2 border-gray-500">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[9]?.obs || ""}
                    label={t("match.values.abp_def")}
                    type="textarea"
                    placeholder={t("match.values.abp_def_placeholder")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[9]?selected.assessments!.filter(a => a.player_id === 0)[9]!.obs = e:"")}
                  />
                </td>
                <td className="px-6 py-4 align-middle">
                  <DataField
                    disabled={!editable}
                    data={selected.assessments?.filter(a => a.player_id === 0)[9]?.score || ""}
                    min={0}
                    max={10}
                    type="number"
                    placeholder={t("match.values.abp_def_values")}
                    onChange={(e) => (selected.assessments?.filter(a => a.player_id === 0)[9]?selected.assessments!.filter(a => a.player_id === 0)[9]!.score = e : "")}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {((listaJugadoresTitulares && listaJugadoresTitulares.length > 0) ||
            (listaJugadoresSuplentes &&
              listaJugadoresSuplentes.length > 0)) && (
            <React.Fragment>
              <div className="px-6 py-4">{t("match.values.individual")}</div>
              <div className="px-6">
                <table className="table-fixed w-full">
                  <thead>
                    <tr className="bg-sf-darkblue text-white">
                      <td className="w-2/12 px-6 py-4">
                        {t("match.values.ind_player")}
                      </td>
                      <td className="w-2/12 px-6 py-4">
                        {t("match.values.ind_avg")}
                      </td>
                      <td className="w-1/12 px-6 py-4 h-full border-r-2 border-gray-300">
                        {t("match.values.ind_ipis")}
                      </td>
                      <td className="w-2/12 px-6 py-4">
                        {t("match.values.ind_personal")}
                      </td>
                      <td className="w-2/12 px-6 py-4">
                        {t("match.values.ind_team_value")}
                      </td>
                      <td className="w-2/12 px-6 py-4">
                        {t("match.values.ind_ind_value")}
                      </td>
                      <td className="w-1/12 px-6 py-4">
                        {t("match.values.ind_details")}
                      </td>
                    </tr>
                  </thead>
                  <tbody
                    className="text-center"
                    style={{ verticalAlign: "bottom" }}
                  >
                    {listaJugadoresTitulares?.map((j, index) => (
                      <tr key={index} className="border-t-2 border-gray-300">
                        <td className="w-2/12 px-6 py-4">{j.player.name}</td>
                        <td className="w-2/12 px-6 py-4">{Number.parseFloat(getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"positionWith", "positionWithout"))}</td>
                        <td className="w-1/12 px-6 py-4 h-full border-r-2 border-gray-300">
                        {getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"ipis")}
                        </td>
                        <td className="w-2/12 px-6 py-4">{getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"autoevaluation", "sentido_score")}</td>
                        <td className="w-2/12 px-6 py-4">{getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"autoevaluation", "equipo_score")}</td>
                        <td className="w-2/12 px-6 py-4">{getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"autoevaluation", "actuacion_score")}</td>
                        <td className="w-1/12 px-6 py-4">
                          <button
                            className={
                              "flex bg-white border-2 border-sf-mediumblue py-1 px-1 rounded-full m-auto"
                            }
                            onClick={() => {
                              
                              if (
                                !selected.assessments.find(
                                  (a) => {
                                    if(a.player_id === j.player_id && a.obs){
                                      let observaciones = JSON.parse(a.obs);
                                      if(observaciones.category === "positionWith"){
                                        return true;
                                      }
                                    }
                                     return false;
                                    }
                                ) ||
                                selected.assessments.filter(
                                  (a) => {
                                    if(a.player_id === j.player_id && a.obs){
                                      let observaciones = JSON.parse(a.obs);
                                      if(observaciones.category === "positionWith"){
                                        return true;
                                      }
                                    }
                                     return false;
                                    }
                                ).length <= 1
                              ) {
                                let positionAssessmentsWith =
                                  allRoleNames["lateral"][0];
                                let positionAssessmentsWithout =
                                  allRoleNames["lateral"][1];
                                let ipiAssesment =
                                  j.player.evaluations[0].ipis.filter(
                                    (i) => i.checked === false
                                  );
                                positionAssessmentsWith.forEach((p) =>
                                  selected.assessments.push({
                                    player_id: j.player_id,
                                    obs: JSON.stringify({
                                      category: "positionWith",
                                      title: p,
                                    }),
                                  } as AssesmentData)
                                );
                                positionAssessmentsWithout.forEach((p) =>
                                  selected.assessments.push({
                                    player_id: j.player_id,
                                    obs: JSON.stringify({
                                      title: p,
                                      category: "positionWithout",
                                    }),
                                  } as AssesmentData)
                                );
                                ipiAssesment.forEach((p) =>
                                  selected.assessments.push({
                                    player_id: j.player_id,
                                    obs: JSON.stringify({
                                      category: "ipis",
                                      title: p.name,
                                    }),
                                  } as AssesmentData)
                                );
                              }
                              setCambiosSinGuardar(true);
                              setModalContent(
                                evaluationPanel(
                                  selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  )
                                )
                              );
                            }}
                            type="button"
                          >
                            <p className="text-sm">
                              {t("match.values.view_details")}
                            </p>
                          </button>
                        </td>
                      </tr>
                    ))}
                    {listaJugadoresSuplentes?.map((j) => (
                      <tr className="border-t-2 border-gray-300">
                        <td className="w-2/12 px-6 py-4">{j.player.name}</td>
                        <td className="w-2/12 px-6 py-4">{Number.parseFloat(getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"positionWith", "positionWithout"))}</td>
                        <td className="w-1/12 px-6 py-4 h-full border-r-2 border-gray-300">{getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"ipis")}</td>
                        <td className="w-2/12 px-6 py-4">{getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"autoevaluation", "sentido_score")}</td>
                        <td className="w-2/12 px-6 py-4">{getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"autoevaluation", "equipo_score")}</td>
                        <td className="w-2/12 px-6 py-4">{getAssesments(selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  ),"autoevaluation", "actuacion_score")}</td>
                        <td className="w-1/12 px-6 py-4">
                          <button
                            className={
                              "flex bg-white border-2 border-sf-mediumblue py-1 px-1 rounded-full m-auto"
                            }
                            onClick={() => {
                              if (
                                !selected.assessments.find(
                                  (a) => {
                                    let observaciones = a.obs? JSON.parse(a.obs) : undefined;

                                     return a.player_id === j.player_id && observaciones.category === "positionWith"
                                    }
                                )
                              ) {
                                let positionAssessmentsWith =
                                  allRoleNames["lateral"][0];
                                let positionAssessmentsWithout =
                                  allRoleNames["lateral"][1];
                                let ipiAssesment =
                                  j.player.evaluations[0].ipis.filter(
                                    (i) => i.checked === false
                                  );
                                positionAssessmentsWith.forEach((p) =>
                                  selected.assessments.push({
                                    player_id: j.player_id,
                                    obs: JSON.stringify({
                                      category: "positionWith",
                                      title: p,
                                    }),
                                  } as AssesmentData)
                                );
                                positionAssessmentsWithout.forEach((p) =>
                                  selected.assessments.push({
                                    player_id: j.player_id,
                                    obs: JSON.stringify({
                                      title: p,
                                      category: "positionWithout",
                                    }),
                                  } as AssesmentData)
                                );
                                ipiAssesment.forEach((p) =>
                                  selected.assessments.push({
                                    player_id: j.player_id,
                                    obs: JSON.stringify({
                                      category: "ipis",
                                      title: p.name,
                                    }),
                                  } as AssesmentData)
                                );
                              }
                              setCambiosSinGuardar(true);
                              setModalContent(
                                evaluationPanel(
                                  selected.assessments.filter(
                                    (a) => a.player_id === j.player_id
                                  )
                                )
                              );
                            }}
                            type="button"
                          >
                            <p className="text-sm">{t("match.values.view_details")}</p>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}
        </CollapsablePanel>
      </form>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="m-auto flex flex-col">
          <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
          <p>{t("29082021.loading.generic")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MatchDetail;
