import React, { useEffect, useState } from "react";
import club_icon from "../../images/icons/clubs-icon.svg";
import add_icon from "../../images/icons/add-icon.svg";
import cross from "../../images/icons/close.svg";
import user_icon from "../../images/icons/user.svg";
import { useTranslation } from "react-i18next";
import loadingGif from "../../images/loading.gif";

import "../../styles/fa.css";
import "../../styles/style.css";

import CollapsablePanel from "../CollapsablePanel";
import ImageField from "../ImageField";
import DataField from "../DataField";

import {
  ClubData,
  Evaluation,
  ExperienceData,
  Injurie,
  Ipi,
  MatchPlayer,
  PlayerData,
  Report,
  StaffData,
  TeamData,
} from "../../CMSUtils/Icms";

import { StaffField } from "../Staff/StaffField";
import { ExperienceCard } from "../Experience/ExperienceCard";
import useStore from "../../store";
import { GetClubs, LoadCMSClubs } from "../../CMSUtils/ClubCalls";
import {
  GetStaffByClubID,
  GetStaffByID,
  LoadCMSStaff,
} from "../../CMSUtils/StaffCalls";
import {
  GetGlobalExperiences,
  LoadCMSExperiences,
} from "../../CMSUtils/ExperienceCalls";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";

export const TeamDetail: React.FC<{
  selected: TeamData;
  validForm: (state: boolean) => void;
  uploadImage: (image: File | undefined) => void;
  image: File | undefined;
  props: any;
}> = ({ uploadImage, selected, image, validForm, props }) => {
  const { t } = useTranslation();
  const loggedUser = useStore((state) => state.loggedUser);
  const editable =
    loggedUser?.role.name === "Admin" ||
    loggedUser?.role.name === "Club" ||
    loggedUser?.role.name === "Coordinator";
  const token = useStore((state) => state.token);
  const [isLoaded, setIsLoaded] = useState(false);
  const setModalContent = useStore((state) => state.setModalContent);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const [listaJugadoresAsignados, setListaJugadoresAsignados] = useState<
    PlayerData[]
  >(selected.players);
  const [listaStaffs, setListaStaffs] = useState<StaffData[]>();
  const [listaStaffsEquipo, setListaStaffsEquipo] = useState<StaffData[]>(
    selected.staffs
  );
  const [listaExperienciasAsignadas, setListaExperienciasAsignadas] =
    useState<ExperienceData[]>();
  let jugadoresSinEquipo: PlayerData[] = [];
  const [listaJugadoresLibres, setListaJugadoresLibres] =
    useState<PlayerData[]>(jugadoresSinEquipo);

// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let isMounted = true;
    if (loggedUser?.role.name === "Club") {
      selected.club_id = loggedUser?.club_id;
    }
    fetchData().then(()=>{
      if(isMounted){
        setListaStaffs(
          (selected.club_id
            ? GetStaffByClubID(selected.club_id)
            : undefined) as StaffData[]
        );
    
        getJugadoresSinEquipo();
        getJugadoresRecienCreados();
        setIsLoaded(true);
      }
    });
    selected.experiences = selected.experiences?.map((e: any) => e.experience);
    setListaExperienciasAsignadas(selected.experiences?.map((e) => e));

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSClubs(token);
    await LoadCMSStaff(token);
    await LoadCMSExperiences(token);
    if (loggedUser && loggedUser.role.name === "Coordinator") {
      let coord = GetStaffByID(loggedUser.staff_id)?.club_id;
      if (coord) {
        selected.club_id = coord;
      }
    }
    return null;
    
  }

  function getJugadoresRecienCreados() {
    if (!GetClubs() || !selected.club_id) return;
    let clubSeleccionado: ClubData | undefined = GetClubs()?.find(
      (c) => c.id === selected.club_id
    );

    if (!clubSeleccionado) return;
    let jEquipo: PlayerData[] = clubSeleccionado.club_players.filter(
      (j) => j.team_id === selected.id
    );

    jEquipo.forEach((j) => {
      if (!listaJugadoresAsignados.find((jug) => j.id === jug.id)) {
        listaJugadoresAsignados.push(j);
        setListaJugadoresAsignados(
          listaJugadoresAsignados.map((jugador) => jugador)
        );
      }
    });
  }

  function getClubNames() {
    if (!GetClubs()) return [];
    let clubs: any[] = [];
    GetClubs()?.map((c) => clubs.push({ value: c.id, text: c.entity_name }));
    return clubs;
  }

  function getPlayerMatchData(matches: MatchPlayer[]) {
    let result: MatchPlayer = {
      played_mins: 0,
      goals: 0,
      yellow_cards: 0,
      red_cards: 0,
      score: 0,
    } as MatchPlayer;

    matches?.forEach((m) => {
      result.played_mins += Number(m.played_mins);
      result.goals += Number(m.goals);
      result.yellow_cards += Number(m.yellow_cards);
      result.red_cards += Number(m.red_cards);
      result.score += Number(m.score);
    });
    return result;
  }

  function changeStaff(i: number, staff_id: number) {
    if (!selected.staffs[i]) selected.staffs[i] = {} as StaffData;
    if (i === 0) selected.staffs[i].role = "Entrenador";
    selected.staffs[i].staff_id = staff_id;
  }

  function changeStaffRole(i: number, role: string) {
    selected.staffs[i].role = role;
  }

  function deleteStaff(i: number) {
    selected.staffs.splice(i, 1);
    setListaStaffsEquipo(selected.staffs.map((s) => s));
  }

  function clearStaffs() {
    selected.staffs = [{ role: "Entrenador" }];
    setListaStaffsEquipo(selected.staffs.map((s) => s));
  }

  function getJugadoresSinEquipo() {
    if (!GetClubs() || !selected.club_id) return;
    let clubSeleccionado: ClubData | undefined = GetClubs()?.find(
      (c) => c.id === selected.club_id
    );

    if (!clubSeleccionado) return;
    let jSin: PlayerData[] = clubSeleccionado.club_players.filter(
      (j) => !j.team_id || j.team_id === 0
    );

    setListaJugadoresLibres(jSin.map((j) => j));
  }

  function addPlayer(index: number, player: PlayerData) {
    listaJugadoresLibres.splice(index, 1).map((j) => j);
    listaJugadoresAsignados.push(player);

    jugadoresSinEquipo = listaJugadoresLibres;
    selected.players = listaJugadoresAsignados;

    setListaJugadoresAsignados(listaJugadoresAsignados.map((j) => j));

    setListaJugadoresLibres(listaJugadoresLibres.map((j) => j));
    setModalContent(getUnassignedPlayers(), t("14052021.team.add_title"));
  }

  async function removePlayer(index: number, player: PlayerData) {
    listaJugadoresLibres.push(player);
    listaJugadoresAsignados.splice(index, 1).map((j) => j);

    jugadoresSinEquipo = listaJugadoresLibres;
    selected.players = listaJugadoresAsignados;

    setListaJugadoresAsignados(listaJugadoresAsignados.map((j) => j));
    setListaJugadoresLibres(listaJugadoresLibres.map((j) => j));

    setModalContent(getUnassignedPlayers(), t("14052021.team.add_title"));
  }

  function getExperiencesWindow(experience: ExperienceData[]) {
    return (
      <React.Fragment>
        <div className="w-1/2">
          <DataField
            data={""}
            placeholder={t("14052021.team.window_filtrar")}
            onChange={(e) => {
              let xpList =
                e !== ""
                  ? GetGlobalExperiences()?.filter((exp) =>
                      exp.name_es.includes(e)
                    )
                  : GetGlobalExperiences();
              if (!xpList) xpList = [];
              setModalContent(
                getExperiencesWindow(xpList),
                t("14052021.team.global_config")
              );
            }}
          />
        </div>
        <div className="grid gap-3 grid-cols-4 pt-4">
          {experience?.map((e, index) => {
            return (
              <ExperienceCard
                key={index}
                data={e}
                seleccionada={
                  selected.experiences.find((ex) => ex.id === e.id)
                    ? true
                    : false
                }
                onClick={(v) => {
                  if (v) {
                    if (!selected.experiences) selected.experiences = [];
                    if (
                      selected.experiences?.filter((ex) => ex.id === e.id)
                        .length === 0
                    ) {
                      selected.experiences?.push(e);
                    }
                  } else {
                    if (
                      selected.experiences?.filter((ex) => ex.id === e.id)
                        .length > 0
                    ) {
                      selected.experiences?.splice(
                        selected.experiences?.findIndex((ex) => ex.id === e.id),
                        1
                      );
                    }
                  }

                  setListaExperienciasAsignadas(
                    selected.experiences?.map((exp) => exp)
                  );
                  setModalContent(
                    getExperiencesWindow(experience),
                    t("14052021.team.global_config")
                  );
                }}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  function getUnassignedPlayers(filter?: string) {
    return (
      <div className="flex flex-col" style={{ minWidth: "825px" }}>
        {listaJugadoresAsignados && listaJugadoresAsignados.length > 0 && (
          <div style={{ height: "10rem" }}>
            <div className="grid grid-cols-3">
              {listaJugadoresAsignados?.map((p: PlayerData, i) => (
                <div className="flex justify-between px-2 border-b border-gray-500 py-1">
                  <p>{p.name + (p.surname ? " " + p.surname : "")}</p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      removePlayer(i, p);
                    }}
                  >
                    <img src={cross} className="h-3 w-3" alt="close" />
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        {listaJugadoresLibres.length > 0 && (
          <React.Fragment>
            <div className="pt-3 pb-6 w-1/2">
              <DataField
                data=""
                onChange={(v) => {
                  setModalContent(
                    getUnassignedPlayers(v),
                    t("14052021.team.add_title")
                  );
                }}
                placeholder={t("14052021.team.window_search")}
              />
            </div>
            <table className="w-full flex-auto border-2 border-gray-300">
              <thead className="bg-blue-900 text-white">
                <tr>
                  <td className="w-4/12 py-2 px-6">
                    {t("14052021.team.window_name")}
                  </td>
                  <td className="w-2/12 py-2 px-6">
                    {t("14052021.team.window_team")}
                  </td>
                  <td className="w-1/12 py-2 px-6">
                    {t("14052021.team.window_pos")}
                  </td>
                  <td className="w-3/12 py-2 px-6">
                    {t("14052021.team.window_birth")}
                  </td>
                  <td className="w-2/12 py-2 px-6">
                    {t("14052021.team.window_ID")}
                  </td>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "top" }}>
                {listaJugadoresLibres
                  ?.filter((p) =>
                    p.name.toLowerCase().includes(filter?.toLowerCase() || "")
                  )
                  ?.map((p: PlayerData, i) => (
                    <tr
                      className={
                        "cursor-pointer " +
                        (i % 2 === 0
                          ? "bg-gray-200 hover:bg-gray-300"
                          : "bg-white hover:bg-gray-100")
                      }
                      onClick={() => addPlayer(i, p)}
                    >
                      <td className="w-4/12 py-2 px-6">{p.name}</td>
                      <td className="w-2/12 py-2 px-6">{p.team?.team_name}</td>
                      <td className="w-1/12 py-2 px-6">{p.position}</td>
                      <td className="w-3/12 py-2 px-6">
                        {Intl.DateTimeFormat("es-ES", {
                          year: "numeric",
                          month: "numeric",
                          day: "2-digit",
                        }).format(new Date(p.birthday))}
                      </td>
                      <td className="w-2/12 py-2 px-6">{p.dni}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </React.Fragment>
        )}
      </div>
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <form
        onSubmit={(e) => e.preventDefault()}
        onChange={(e) => {
          setCambiosSinGuardar(true);
          validForm(
            e.currentTarget.checkValidity() && selected.club_id !== null && selected.club_id !== undefined
          );
        }}
        onLoad={(e) =>
          validForm(
            e.currentTarget.checkValidity() &&
              selected.club_id !== null && selected.club_id !== undefined &&
              
              listaStaffsEquipo.find(
                (s: any) =>
                  !s.role ||
                  !s.staff_id ||
                  s.role === "default" ||
                  s.staff_id === "default"
              ) === undefined
          )
        }
      >
        <CollapsablePanel titulo={t("team.data.title")} colapsado={false}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td rowSpan={3} className="w-1/3">
                  <div
                    className="flex flex-col m-auto"
                    style={{ width: "14rem" }}
                  >
                    <ImageField
                      emptyImage={club_icon}
                      disabled={!editable}
                      uploadedImage={image}
                      uploadImage={uploadImage}
                      defaultImage={
                        selected.image
                          ? cmsStorageURL + selected.image.file
                          : undefined
                      }
                    />
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.team_name}
                    label={t("team.data.name")}
                    onChange={(e) => (selected.team_name = e)}
                    disabled={!editable}
                    required
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  {loggedUser?.role.name === "Admin" && (
                    <DataField
                      data={selected.club_id}
                      type="select"
                      label={t("team.data.club")}
                      options={getClubNames()}
                      placeholder={t("team.data.club_placeholder")}
                      onChange={(e) => {
                        selected.club_id = e;
                        fetchData();
                        selected.players = [];
                        setListaJugadoresAsignados(
                          selected.players.map((j) => j)
                        );
                        clearStaffs();
                      }}
                      required
                      disabled={!editable}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.category}
                    label={t("team.data.category")}
                    type="select"
                    flexible
                    onChange={(e) => (selected.category = e)}
                    disabled={!editable}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.modality}
                    type="select"
                    flexible
                    label={t("team.data.modality")}
                    options={["F5", "F7", "F8", "F9", "F11"]}
                    placeholder={t("01122021.select_placeholders.modality")}
                    onChange={(e) => (selected.modality = e)}
                    disabled={!editable}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-1">
                      <DataField
                        data={selected.duration}
                        label={t("team.data.duration")}
                        type="number"
                        onChange={(e) => (selected.duration = e)}
                        disabled={!editable}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        data={selected.periods}
                        label={t("team.data.periods")}
                        onChange={(e) => (selected.periods = e)}
                        disabled={!editable}
                      />
                    </div>
                  </div>
                </td>
                <td className="pt-4 pb-8 px-6"></td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.temp_start}
                    label={t("team.data.season_start")}
                    type="date"
                    onChange={(e) => (selected.temp_start = e)}
                    required
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.comp_start}
                    label={t("team.data.competition_start")}
                    type="date"
                    onChange={(e) => (selected.comp_start = e)}
                  />
                </td>
                <td rowSpan={2} className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.competitions}
                    label={t("team.data.competitions")}
                    type="textarea"
                    onChange={(e) => (selected.competitions = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.temp_end}
                    label={t("team.data.season_end")}
                    type="date"
                    onChange={(e) => (selected.temp_end = e)}
                    required
                  />
                </td>
                <td className="pt-4 pb-8 px-6"></td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>

        <CollapsablePanel titulo={t("team.staff.title")}>
          <table className="w-full table-fixed my-2">
            <tbody style={{ verticalAlign: "top" }}>
              {listaStaffs && listaStaffs.length > 0 ? (
                <React.Fragment>
                  <tr>
                    <td>
                      <StaffField
                        disabled={
                          !editable ||
                          listaStaffs.filter(
                            (s) =>
                              s.sport_rol === "Trainer" ||
                              s.sport_rol === "SecondTrainer"
                          ).length === 0
                        }
                        job="Entrenador"
                        staff={listaStaffs.filter(
                          (s) =>
                            s.sport_rol === "Trainer" ||
                            s.sport_rol === "SecondTrainer"
                        )}
                        data={selected.staffs[0]}
                        onChangeStaff={(e) => changeStaff(0, e)}
                      />
                    </td>
                  </tr>
                  {listaStaffsEquipo?.map((s, i) => {
                    if (i > 0)
                      return (
                        <tr key={i}>
                          <td>
                            <StaffField
                              disabled={!editable}
                              staff={listaStaffs}
                              data={selected.staffs[i]}
                              onChangeStaff={(e) => changeStaff(i, e)}
                              onDelete={() => deleteStaff(i)}
                              onChangeRole={(e) => changeStaffRole(i, e)}
                            />
                          </td>
                        </tr>
                      );
                  })}
                </React.Fragment>
              ) : (
                <tr>
                  <td className="text-gray-500 text-sm px-6 py-4">
                    {t("team.staff.no_staff")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {listaStaffs && listaStaffs.length > 0 && (
            <div className="px-6 py-4">
              <button
                className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
                onClick={() => {
                  selected.staffs.push({} as StaffData);
                  setListaStaffsEquipo(selected.staffs.map((s) => s));
                }}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("team.staff.add_profile")}</p>
              </button>
            </div>
          )}
        </CollapsablePanel>

        <CollapsablePanel titulo={t("team.player.title")}>
          <div className="px-6 py-4">
            <div className="flex py-2">
              {/*<button className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2">
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">Crear jugador</p>
            </button> */}
              {((listaJugadoresAsignados &&
                listaJugadoresAsignados.length > 0) ||
                listaJugadoresLibres.length > 0) &&
              editable ? (
                <button
                  onClick={() =>
                    setModalContent(
                      getUnassignedPlayers(),
                      t("14052021.team.add_title")
                    )
                  }
                  className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
                  disabled={
                    !listaJugadoresAsignados &&
                    listaJugadoresLibres.length === 0 &&
                    listaJugadoresAsignados === 0
                  }
                >
                  <img
                    src={user_icon}
                    alt="Añadir"
                    className="h-5 transform scale-75"
                    style={{ filter: "invert()" }}
                  />
                  <p className="pl-3">{t("team.player.add_players")}</p>
                </button>
              ) : (
                <p className="text-xs text-gray-600 self-center pr-4">
                  {t("team.player.no_players")}
                </p>
              )}
              {editable && selected.id && (
                <button
                  onClick={() => {
                    props.history.replace({ state: { data: selected } });
                    props.history.push({
                      pathname: "/jugadores/create",
                      state: {
                        data: Object.assign({
                          club_id: selected.club_id,
                          team_id: selected.id,
                          evaluations: [
                            Object.assign({
                              injuries: [] as Injurie[],
                              ipis: [] as Ipi[],
                              reports: [] as Report[],
                            } as Evaluation),
                          ],
                        } as PlayerData),
                      },
                    });
                  }}
                  className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
                >
                  <img
                    src={add_icon}
                    alt="Añadir"
                    className="h-6 transform scale-50"
                    style={{ filter: "invert()" }}
                  />
                  <p className="pl-2">{t("team.player.create_player")}</p>
                </button>
              )}
            </div>

            {listaJugadoresAsignados && listaJugadoresAsignados.length > 0 && (
              <table className="w-full table-fixed">
                <thead className="text-sm border-b-2 border-gray-300">
                  <tr>
                    <td className="w-2/12 text-center py-2">
                      {t("team.player.player")}
                    </td>
                    <td className="w-1/12 text-center py-2">
                      {t("team.player.position")}
                    </td>
                    <td className="w-2/12 text-center py-2">
                      {t("team.player.inclusion")}
                    </td>
                    <td className="w-2/12 text-center py-2">
                      {t("team.player.exclusion")}
                    </td>
                    <td className="w-1/12 text-center py-2">
                      {t("team.player.played")}
                    </td>
                    <td className="w-1/12 text-center py-2">
                      {t("team.player.goal")}
                    </td>
                    <td className="w-1/12 text-center py-2">
                      {t("team.player.yellow")}
                    </td>
                    <td className="w-1/12 text-center py-2">
                      {t("team.player.red")}
                    </td>
                    <td className="w-1/12 text-center py-2">
                      {t("team.player.average")}
                    </td>
                  </tr>
                </thead>
                <tbody style={{ verticalAlign: "top" }}>
                  {selected.players?.map((p) => {
                    let matchData = getPlayerMatchData(p.matches);
                    return (
                      <tr className="border-b border-gray-400">
                        <td className="w-2/12 py-2 truncate">{p.name}</td>
                        <td className="w-1/12 py-2">{p.position}</td>
                        <td className="text-center w-2/12 py-2">
                          {p.inc_date}
                        </td>
                        <td className="text-center w-2/12 py-2">
                          {p.cesion_data}
                        </td>
                        <td className="text-center w-1/12 py-2">
                          {matchData.played_mins}
                        </td>
                        <td className="text-center w-1/12 py-2">
                          {matchData.goals}
                        </td>
                        <td className="text-center w-1/12 py-2">
                          {matchData.yellow_cards}
                        </td>
                        <td className="text-center w-1/12 py-2">
                          {matchData.red_cards}
                        </td>
                        <td className="text-center w-1/12 py-2">
                          {matchData.score}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("team.experience.title")}>
          <div className="px-6 py-4">
            {editable && (
              <button
                onClick={() =>
                  setModalContent(
                    getExperiencesWindow(GetGlobalExperiences() ?? []),
                    t("14052021.team.global_config")
                  )
                }
                className="flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
                disabled={GetGlobalExperiences()?.length === 0}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("team.experience.add_experience")}</p>
              </button>
            )}
            {(!selected.experiences || selected.experiences?.length === 0) && (
              <div className="w-full pt-4 text-gray-600 text-sm">
                {t("team.experience.all_experiences")}
              </div>
            )}
            <div className="grid grid-cols-3 gap-4 py-4">
              {listaExperienciasAsignadas &&
                listaExperienciasAsignadas.length > 0 &&
                listaExperienciasAsignadas.map((e) => (
                  <ExperienceCard data={e} />
                ))}
            </div>
          </div>
        </CollapsablePanel>
      </form>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="m-auto flex flex-col">
          <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
          <p>{t("29082021.loading.generic")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TeamDetail;
