import React, { useEffect, useState } from "react";
import loadingGif from "../images/loading.gif";
import {
  AddCMSClubs,
  CreateUserClub,
  DeleteCMSClubs,
  GetClubs,
  LoadCMSClubs,
  UpdateCMSClubs,
} from "../CMSUtils/ClubCalls";
import { ClubDetail } from "../components/Club/ClubDetail";
import useStore from "../store";
import Topbar from "../components/Topbar";
import ClubItem from "../components/Club/ClubItem";
import { ClubData } from "../CMSUtils/Icms";
import { AddCMSFile, DeleteCMSFile } from "../CMSUtils/FileCalls";
import {
  AddCMSPerson,
  DeleteCMSPerson,
  UpdateCMSPerson,
} from "../CMSUtils/PersonCalls";
import { useTranslation } from "react-i18next";

const Clubs = (props: any) => {
  const { t } = useTranslation();
  const filteredClub = useStore((state) => state.filteredClub);
  const [cargandoDatos, setCargandoDatos] = useState(false);
  const filterClub = useStore((state) => state.filterClub);
  const setSeasons = useStore((state) => state.setSeasons);
  const token = useStore((state) => state.token);
  const [listaClubs, setListaClubs] = useState<ClubData[]>();
  const [clubSeleccionado, setClubSeleccionado] = useState<ClubData>();
  const [clubsMarcados, setClubsMarcados] = useState<number[]>([]);
  const [validClub, setValidClub] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setClubSeleccionado(
      props.location.state ? props.location?.state.data : undefined
    );
    if (!cargandoDatos) fetchData().then(c => {if(isMounted)setListaClubs(c);});

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state?.data]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSClubs(token);

    let clubs = GetClubs();
    if (!clubs) clubs = [];

    return clubs;
    //setListaClubs(clubs);
  }

  async function borrarClubs() {
    if (!token) return;
    setCargandoDatos(true);

    if (clubSeleccionado) {
      await DeleteCMSClubs(clubSeleccionado.id, token);
      setListaClubs(await fetchData());

      setClubSeleccionado(undefined);
      props.history.push("/clubs");
    } else {
      if (clubsMarcados.length > 0) {
        for (let i = 0; i < clubsMarcados.length; i++) {
          const clubID = clubsMarcados[i];
          await DeleteCMSClubs(clubID, token);
        }

        setListaClubs(await fetchData());
      }
    }
    setClubsMarcados([]);
    setCargandoDatos(false);
  }

  async function actualizarClub() {
    if (!clubSeleccionado || !token) return;

    setCargandoDatos(true);

    //Si no está creado en base de datos, lo creamos y guardamos el ID
    if (!clubSeleccionado.id) {
      const clubID = await crearClub(clubSeleccionado);
      if (clubID === -1) return;
      clubSeleccionado.id = clubID;
    }

    //Si no hay un usuario creado pero hay datos de usuario, creamos el usuario
    if (
      (clubSeleccionado.user === undefined || clubSeleccionado.user === null) &&
      clubSeleccionado.userEmail !== "" &&
      clubSeleccionado.userEmail !== undefined &&
      clubSeleccionado.userName !== "" &&
      clubSeleccionado.userName !== undefined &&
      clubSeleccionado.userPass !== "" &&
      clubSeleccionado.userPass !== undefined
    )
      await CreateUserClub(clubSeleccionado, token);

    if (imagen) {
      const imageID = await AddCMSFile(imagen, generateUniqueString(), token);
      await DeleteCMSFile(clubSeleccionado.club_image_id, token);

      clubSeleccionado.club_image_id = imageID;
    }

    await updatePersonas(token);
    await UpdateCMSClubs(clubSeleccionado, token);
    setListaClubs(await fetchData());

    setImagen(undefined);
    setClubSeleccionado(undefined);
    setCargandoDatos(false);
  }

  async function updatePersonas(token: string) {
    if (!clubSeleccionado) return;

    if (clubSeleccionado.entity_contact1) {
      if (!clubSeleccionado.entity_contact_1_id)
        clubSeleccionado.entity_contact_1_id = await AddCMSPerson(
          clubSeleccionado.entity_contact1,
          token
        );
      else
        await UpdateCMSPerson(
          clubSeleccionado.entity_contact1,
          clubSeleccionado.entity_contact_1_id,
          token
        );
    }

    if (clubSeleccionado.entity_contact2) {
      if (!clubSeleccionado.entity_contact_2_id)
        clubSeleccionado.entity_contact_2_id = await AddCMSPerson(
          clubSeleccionado.entity_contact2,
          token
        );
      else
        await UpdateCMSPerson(
          clubSeleccionado.entity_contact2,
          clubSeleccionado.entity_contact_2_id,
          token
        );
    } else {
      if (clubSeleccionado.entity_contact_2_id) {
        await DeleteCMSPerson(clubSeleccionado.entity_contact_2_id, token);
        clubSeleccionado.entity_contact_2_id = 0;
      }
    }

    if (clubSeleccionado.entity_contact3) {
      if (!clubSeleccionado.entity_contact_3_id)
        clubSeleccionado.entity_contact_3_id = await AddCMSPerson(
          clubSeleccionado.entity_contact3,
          token
        );
      else
        await UpdateCMSPerson(
          clubSeleccionado.entity_contact3,
          clubSeleccionado.entity_contact_3_id,
          token
        );
    } else {
      if (clubSeleccionado.entity_contact_3_id) {
        await DeleteCMSPerson(clubSeleccionado.entity_contact_3_id, token);
        clubSeleccionado.entity_contact_3_id = 0;
      }
    }
  }

  async function crearClub(club: ClubData) {
    if (!token) return -1;

    return await AddCMSClubs(club, token);
  }

  function marcarClub(index: number) {
    if (!clubsMarcados.includes(index)) {
      clubsMarcados.push(index);
      setClubsMarcados(clubsMarcados.map((c) => c));
    } else {
      console.log("Intentando marcar un club ya marcado");
    }
  }

  function desmarcarClub(index: number) {
    if (clubsMarcados.includes(index)) {
      clubsMarcados.splice(
        clubsMarcados.findIndex((e) => e === index),
        1
      );
      setClubsMarcados(clubsMarcados.map((c) => c));
    } else {
      console.log("Intentando desmarcar un club no marcado");
    }
  }

  async function updateFiltro(busqueda: string) {
    let clubs = GetClubs();
    if (!clubs) return;

    let filteredClubs = clubs.filter((c) =>
      c.entity_name.toLowerCase().includes(busqueda.toLowerCase())
    );
    setListaClubs(filteredClubs);
  }

  /*     OLD DATA     */
  const [imagen, setImagen] = useState<File>();

  function generateUniqueString() {
    var ts = String(new Date().getTime()),
      i = 0,
      out = "";

    for (i = 0; i < ts.length; i += 2) {
      out += Number(ts.substr(i, 2)).toString(36);
    }

    return ts + "-" + out;
  }

  const uploadImage = (image: File | undefined) => {
    setImagen(image);
  };

  return (
    <React.Fragment>
      <div className="flex flex-col h-full z-0">
        <Topbar
          marked={clubsMarcados.length}
          selected={clubSeleccionado}
          createText={t("club.info.new")}
          onBack={() => props.history.goBack()}
          onFilter={(text: string) => updateFiltro(text)}
          onCreate={() => {
            props.history.push({
              pathname: props.location.pathname + "/create",
              state: {
                data: Object.assign({} as ClubData),
              },
            });
          }}
          disabled={!validClub}
          onDelete={() => borrarClubs()}
          onUpdate={() => {
            actualizarClub();
            props.history.goBack();
          }}
        />
        <div
          className={
            "w-full flex-1 overflow-auto" +
            (clubSeleccionado ? " bg-f2f2f2 mt-8" : "")
          }
        >
          {clubSeleccionado ? (
            <ClubDetail
              selected={clubSeleccionado}
              uploadImage={uploadImage}
              image={imagen}
              validForm={(e) => setValidClub(e)}
            />
          ) : (
            <div className="mx-auto flex flex-col w-full h-full">
              <div className="w-full font-bold text-white pb-2 shadow-md border border-solid border-b-1 border-t-0 border-l-0 border-r-0 border-gray-500">
                <div className="w-full xl:w-3/4 flex flex-row mx-auto">
                  <div className="w-1/12 self-center"></div>
                  <div className="w-3/12 self-center text-center">
                    {t("club.info.name")}
                  </div>
                  <div className="w-1/12 self-center text-center">
                    {t("club.info.country")}
                  </div>
                  <div className="w-3/12 self-center text-center">
                    {t("club.info.contact")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("club.info.contract")}
                  </div>
                  <div className="w-1/12 self-center text-center">
                    {t("club.info.status")}
                  </div>
                  <div className="w-1/12 self-center"></div>
                </div>
              </div>
              <div className="bg-f2f2f2 flex-1">
                {listaClubs && !cargandoDatos ? (
                  listaClubs.length > 0 ? (
                    listaClubs.map((club: ClubData, i: number) => {
                      //checkboxSelectedClubs[club.id] = false;
                      return (
                        <ClubItem
                          key={i}
                          onClick={() => {
                            setClubsMarcados([]);
                            props.history.push({
                              pathname: props.location.pathname + "/detail",
                              state: {
                                data: Object.assign({}, club),
                              },
                            });
                          }}
                          onCheck={(e) => {
                            if (e.target.checked) {
                              marcarClub(club.id);
                            } else {
                              desmarcarClub(club.id);
                            }
                          }}
                          onFilter={() => {
                            if (filteredClub === club.id) {
                              filterClub(undefined);
                              setSeasons([]);
                            } else {
                              filterClub(club.id);
                              setSeasons(club.backups);
                            }
                          }}
                          datos={club}
                          filtro={filteredClub}
                          index={i}
                        />
                      );
                    })
                  ) : (
                    <div className="flex h-full w-full">
                      <div className="m-auto">{t("29082021.no_content")}</div>
                    </div>
                  )
                ) : (
                  <div className="flex h-full w-full">
                    <div className="m-auto flex flex-col">
                      <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
                      <p>{t("29082021.loading.generic")}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Clubs;
