export const cmsBaseUrl: string = "https://cms-smartfootball.imascono.com";
//export const cmsBaseUrl: string = "http://cms-imascono:8000";

//Datos devueltos de usuario
export interface UserData {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
  trainer_id: number;
  player_id: number;
  coordinator_id: number;
  club_id: number;
  staff_id: number;
  role_id: number;
  phone_number: string;
  title: string;
  languages: string;
  fav_experiences: [];
  role: {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
  };
}

//Datos descargados del CMS
export interface ClubData {
  id: number;
  created_at: string;
  updated_at: string;
  active: boolean;
  club_image_id: number;

  backups: { id: number; name: string }[];

  //Para la creación / mostrado de usuarios
  userName: string;
  userPass: string;
  userEmail: string;
  user: UserData | undefined;

  //Datos de Entidad
  entity_name: string;
  entity_abr: string;
  entity_country_code: string;
  entity_city: string;
  entity_headquarters: string;
  entity_sports_city: string;
  entity_stadium: string;
  entity_id: string;
  entity_vat_number: string;
  entity_account_number: string;
  entity_web: string;
  entity_facebook: string;
  entity_twitter: string;
  entity_instagram: string;
  entity_other: string;
  entity_contact_1_id: number;
  entity_contact1: PersonData;
  entity_contact_2_id: number;
  entity_contact2: PersonData;
  entity_contact_3_id: number;
  entity_contact3: PersonData;
  entity_contract_start: string;
  entity_contract_end: string;

  //Datos deportivos
  sport_category: string;
  sport_club_category: string;
  sport_quality_control: string;
  sport_obs: string;
  sports_teams: string;
  sports_players: string;

  //Otros
  creation_year: number;
  num_partners: number;
  fan_name: string;
  palmares: string;
  historic_players: string;
  rival_teams: string;
  ref_trainers: string;
  club_players: PlayerData[];
  club_teams: TeamData[];
  image: ImageData;
}

export interface PersonData {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  email: string;
  phone_number: string;
  title: string;
  languages: string;
}

export interface PlayerData {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  surname: string;
  birthday: string;
  dni: string;
  matches: MatchPlayer[];
  agent: PersonData;
  job_languages: string;
  school_contact: PersonData;
  school_languages: string;
  dni_expiration_date: string;
  birth_country: string;
  email: string;
  phone_number: string;
  extra_position: string;
  addres: string;
  actual_addres: string;
  social_media: string;
  core_composition: string;
  father_id: number;
  mother_id: number;
  tutor_id: number;
  extra_family: string;
  extra_family_id: string;
  comentary: string;
  school: string;
  school_address: string;
  school_contact_rol: string;
  school_contact_id: number;
  performance: string;
  school_comentary: string;
  languages: string;
  sport_name: string;
  position: string;
  leg: string;
  dorsal: number;
  height: string;
  weight: string;
  projection: string;
  inc_date: string;
  procedence_club: string;
  club_id: number;
  end_contract: string;
  international: string;
  agent_id: number;
  sport_comentary: string;
  club_temps: string;
  cesion_data: string;
  contact_1: PersonData;
  contact_2: PersonData;
  contact_3: PersonData;
  contact_1_id: number;
  contact_2_id: number;
  contact_3_id: number;
  job_site: string;
  own_car: boolean;
  incl_date: string;
  excl_date: string;
  previous_trainers: string;
  cesion_club: string;
  cesion_trainer: string;
  cesion_tlf: string;
  cesion_email: string;
  cesion_comentary: string;
  job_comentary: string;
  cesion_to: string;
  driving_license: boolean;
  cesion_from: string;
  contact_1_type: string;
  contact_2_type: string;
  contact_3_type: string;
  job_type: string;
  team_id: number;
  team: TeamData;
  image_id: number;
  image: ImageData;
  evaluations: Evaluation[];
  instagram: string;
  facebook: string;
  twitter: string;
  web: string;
  other_socials: string;

  //Para la creación / mostrado de usuarios
  userName: string;
  userPass: string;
  userEmail: string;
  user: UserData | undefined;
}

export interface TeamData {
  id: number;
  created_at: string;
  updated_at: string;
  team_image_id: number;
  team_name: string;
  club_id: number;
  category: string;
  modality: string;
  duration: number;
  periods: string;
  temp_start: string;
  comp_start: string;
  temp_end: string;
  competitions: string;
  trainer_id: number;
  trainer: StaffData;
  trainer2_id: number;
  goalkeeper_trainer_id: number;
  biologic_id: number;
  analyist_id: number;
  delegate_id: number;
  medic_id: number;
  fisio_id: number;
  psicologist_id: number;
  image: ImageData;
  staffs: any[];
  players: PlayerData[];
  experiences: ExperienceData[];
  club: ClubData;
}
export interface ImageData {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  file: string;
}

export interface Idioma {
  value: string;
  level: string;
}

export interface Hito {
  season: string;
  rol: string;
  club: string;
}

export interface StaffData {
  id: number;
  created_at: string;
  updated_at: string;
  team_id: number;
  name: string;
  club_id: number;
  surname: string;
  birthday: string;
  birth_country: string;
  dni: string;
  dni_expiration_date: string;
  email: string;
  phone_number: string;
  addres: string;
  actual_addres: string;
  driving_license: boolean;
  own_car: boolean;
  license_points: number;
  instagram: string;
  facebook: string;
  twitter: string;
  web: string;
  other_socials: string;
  core_composition: string;
  contact_info: string;
  civil_state: string;
  partner_name: string;
  contact_1_id: number;
  contact_1_type: string;
  contact_2_id: number;
  contact_2_type: string;
  comentary: string;
  club_excl: boolean;
  main_job: string;
  job_site: string;
  job_addres: string;
  training_title: string;
  academic_titles: string;
  job_languages: string;
  job_comentary: string;
  sport_name: string;
  sport_rol: string;
  sport_aspiring: string;
  projection: string;
  sport_history: string;
  inc_date: string;
  end_contract: string;
  procedence_club: string;
  club_temps: string;
  previous_coordinators: string;
  agent_id: number;
  internal_relations: string;
  sport_comentary: string;
  contact_1: PersonData;
  contact_2: PersonData;
  agent: PersonData;
  image_id: number;
  image: ImageData;
  evaluations: Evaluation[];

  //Para la creación / mostrado de usuarios
  userName: string;
  userPass: string;
  userEmail: string;
  user: UserData | undefined;
}
export interface InfoExperience {
  ec: string;
  ef: string;
  obs: string;
}
export interface ExperienceData {
  id: number;
  created_at: string;
  updated_at: string;
  type: string;
  category: string;
  generalType: string;
  numPlayers: number;
  name_en: string;
  name_es: string;
  name_ru: string;
  material_es: string;
  material_en: string;
  material_ru: string;
  ec_es: string;
  ef_es: string;
  ec_ru: string;
  ef_ru: string;
  description_es: string;
  description_ru: string;
  variants_es: string;
  variants_ru: string;
  ec_en: string;
  ef_en: string;
  description_en: string;
  variants_en: string;
  obs1_es: string;
  obs2_es: string;
  obs3_es: string;
  obs1_en: string;
  obs2_en: string;
  obs3_en: string;
  obs1_ru: string;
  obs2_ru: string;
  obs3_ru: string;
  difficulty_es: string;
  difficulty_en: string;
  difficulty_ru: string;
  objectives: string;
  file_1_id: number;
  file_2_id: number;
  team_a_es: string;
  team_b_es: string;
  team_a_en: string;
  team_b_en: string;
  team_a_ru: string;
  team_b_ru: string;
  file1: ImageData;
  file2: ImageData;
  guidelines: any[];
}

export interface Guideline {
  id: number;
  name: string;
  withoutBall: boolean;
  objectives: Objective[];
}

export interface Event {
  id: number;
  type: string;
  from: string;
  to: string;
  content: { title: string; content: string };
  content_2: string;
  team_id: number;
}
export interface TrainingExperiences {
  id: number;
  experience_id: number;
  player_id: number;
  order: number;
  name: string;
  type: string;
  obs: string;
  series: number;
  reps: number;
  inv_time: number;
  ref_time: number;
  width: number;
  long: number;
}

export interface ExperienceGuideline {
  id: number;
  type: string;
  name: string;
}

export interface TrainingGuideline {
  type: string;
  guideline_id: number;
  experience_id: number;
  should_add: boolean;
  checked: boolean;
}

export interface Objective {
  id: number;
  name: string;
  type_id: number;
}

export interface Ipi {
  id: number;
  evaluation_id: number;
  type: string;
  name: string;
  checked: boolean;
}

export interface TrainingData {
  id: number;
  created_at: string;
  updated_at: string;
  only_time: string;
  temp: string;
  club_id: number;
  date_and_time: string;
  session: string;
  team_id: number;
  trainer_id: number;
  trainer_2_id: number;
  e_goalkeepers: string;
  num_session: string;
  e_bio: string;
  camp: string;
  week: string;
  obs: string;
  audio_obj: string;
  audio_mat: string;
  audio_anot_pre: string;
  audio_anot_post: string;
  bio_pre_mat: string;
  bio_pre_obj: string;
  bio_pre_anot: string;
  bio_post_mat: string;
  bio_post_obj: string;
  bio_post_anot: string;
  goal_mat: string;
  goal_obj: string;
  goal_anot: string;
  global_mat: string;
  global_obj: string;
  global_anot: string;
  team: TeamData;
  trainer: StaffData;
  trainer2: StaffData;
  guests: TrainingGuest[];
  players: PlayerEvaluation[];
  guidelines: TrainingGuideline[];
  experiences: TrainingExperiences[];
}

export interface TrainingGuest {
  name: string;
  position: string;
  obs: string;
}

export interface PlayerEvaluation {
  id: number;
  player_id: number;
  name: string;
  disp: string;
  position: string;
  risk: string;
  before: string;
  after: string;
  obs: string;
  ipis: { name: string; checked: boolean }[];
}

export interface Evaluation {
  id: number;
  created_at: string;
  updated_at: string;
  player_id: number;
  staff_id: number;
  fav_position: string;
  played_positions: string;
  fav_team: string;
  ref_players: string;
  injury_history: string;
  player_def: string;
  person_def: string;
  strengths: string;
  prof_obj: string;
  improve: string;
  contribute: string;
  season_obj: string;
  environment: string;
  ipis: Ipi[];
  injuries: Injurie[];
  reports: Report[];
  name: string;
}

export interface Injurie {
  id: number;
  backup_id: number;
  backupName: string;
  evaluation_id: number;
  name: string;
  treatment: string;
  lesion_date: string;
  health_date: string;
  sport_date: string;
}

export interface Report {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  evaluation_id: number;
  data: ReportData[];
  deletePrevious: boolean | undefined;
}

export interface ReportData {
  id: number;
  report_id: number;
  type: string;
  score: number;
  date: string;
  text_1: string;
  text_2: string;
}

export interface MatchPlayer {
  id: number;
  player_id: number;
  player: PlayerData;
  match: MatchData;
  type: number;
  played_mins: number;
  score: number;
  yellow_cards: number;
  red_cards: number;
  num_changes: number;
  goals: number;
}
export interface AssesmentData {
  id: number;
  match_id: number;
  player_id: number;
  obs: string;
  score: number;
}

export interface MatchData {
  id: number;
  player_rival_data: MatchPlayer[];
  created_at: string;
  updated_at: string;
  date: string;
  image_id: number;
  hour: string;
  category: string;
  group: string;
  day: string;
  assessments: AssesmentData[];
  result: string;
  player_data: MatchPlayer[];
  field: string;
  referee: string;
  assistants: string;
  overall: string;
  interesting_players: string;
  escudo: string;
  team_id: number;
  team: TeamData;
  enemy: string;
}
