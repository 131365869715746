import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Topbar from "../components/Topbar";
import useStore from "../store";
import DataField from "../components/DataField";
import {
  AddCMSEvents,
  DeleteCMSEvents,
  GetWeeklyObj,
  LoadCMSEvents,
  UpdateCMSEvents,
} from "../CMSUtils/EventCalls";
import loadingGif from "../images/loading.gif";
import minus_icon from "../images/icons/papelera-icon.svg";
import {
  Event,
  TeamData,
  ClubData,
  Guideline,
} from "../CMSUtils/Icms";
import { GetClubs, LoadCMSClubs } from "../CMSUtils/ClubCalls";
import { GetTeams, LoadCMSTeams } from "../CMSUtils/TeamCalls";
import { useTranslation } from "react-i18next";
import { GetGuidelines, LoadCMSGuidelines } from "../CMSUtils/GuidelinesCalls";
import add_icon from "../images/icons/add-icon.svg";

const Objectives = (props: any) => {
  const { t, i18n } = useTranslation();
  const [listaEquipos, setListaEquipos] = useState<TeamData[]>();
  const [listaClubs, setListaClubs] = useState<ClubData[]>();
  const loggedUser = useStore((state) => state.loggedUser);
  const [isLoaded, setIsLoaded] = useState(false);
  const editable =
    loggedUser?.role.name === "Admin" ||
    loggedUser?.role.name === "Coordinator" ||
    loggedUser?.role.name === "Trainer";
  const setModalContent = useStore((state) => state.setModalContent);
  const [listaEventos, setListaEventos] = useState<Event[]>();
  const token = useStore((state) => state.token);
  const [listaGuidelines, setListaGuidelines] = useState<Guideline[]>();
  const onModalBack = useStore((state) => state.onModalBack);

  let evento : Event = newEvent();
  let objetivos : number[] = [];

  function newEvent(){
    return {
      content: { content: "", title: "" },
      type: "obj",
    } as Event;
  }

  useEffect(() => {
    let isMounted = true;

    fetchData().then(o=> {
      if(isMounted){
        console.log(loggedUser);
        setListaClubs(GetClubs() as ClubData[]);
        setListaEquipos(GetTeams() as TeamData[]);
        setListaGuidelines(GetGuidelines() as Guideline[]);
        setListaEventos(o);
        setIsLoaded(true);
      }
    });

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSEvents(token);
    await LoadCMSClubs(token);
    await LoadCMSTeams(token);
    
    let objectives = GetWeeklyObj();
    if (!objectives) objectives = [];
    await LoadCMSGuidelines(token);
    return objectives;
    //setListaEventos(objectives);
    //setIsLoaded(true);
  }

  function getObjectives(title: string, search: string = "", eventoActualizar?: Event) {
    setListaGuidelines(GetGuidelines());
    onModalBack(() => setModalContent(crearObj(false)));
    return (
      <React.Fragment>
        <div className="w-1/3 py-5">
          <DataField
            data={search || ""}
            placeholder={t("14052021.obj.obj_search")}
            onChange={(e) => {
              setModalContent(getObjectives(title, e || ""), title);
              /*
              let listaFiltrada = GetGuidelines()?.filter((g) =>
                g.name.includes(e)
              );*/
            }}
          />
        </div>
        <table className="w-full table-fixed border-gray-500 border-2">
          <thead>
            <tr className="bg-sf-darkblue text-white">
              <td className="w-9/12 py-2 px-6">{t("14052021.obj.obj_desc")}</td>
              <td className="w-3/12 py-2 px-6">{t("14052021.obj.obj_obj")}</td>
            </tr>
          </thead>
          <tbody style={{ verticalAlign: "top" }}>
            {listaGuidelines
              ?.filter((g) =>
              JSON.parse(g.name
                  .split("|")[1])[i18n.language]
                  .toLowerCase()
                  .includes(search.toLowerCase())
              )
              .map((g: Guideline, index) => (
                <tr
                key={index}
                  className={
                    index % 2 === 0
                      ? "bg-sf-extralightblue bg-opacity-25"
                      : "bg-white"
                  }
                >
                  <td className="w-9/12 py-3 px-6">
                    <div className="flex">
                      <input
                        type="checkbox"
                        className="self-center"
                        defaultChecked={objetivos?.includes(g.id)}
                        onChange={(e) => {
                          if(objetivos === null || objetivos === undefined)objetivos =[];
                          if (e.currentTarget.checked) {
                            objetivos?.push(g.id);
                          } else {
                            objetivos?.splice(objetivos?.indexOf(g.id), 1);
                          }
                          setModalContent(getObjectives(title, search), title);
                        }}
                      />
                      <p className="self-center pl-3">
                        {JSON.parse(g.name.split("|")[1])[i18n.language]}
                      </p>
                    </div>
                  </td>
                  <td className="w-3/12 py-3 px-6">{g.name.split("|")[0]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  function getClubNames() {
    if (!GetClubs()) return [];
    let clubs: any[] = [];
    GetClubs()?.map((c) => clubs.push({ value: c.id, text: c.entity_name }));
    return clubs;
  }

  async function saveEvent() {
    if (!token) return;

    if(!evento.id){
      let id= await AddCMSEvents(evento, token);
      evento.id = id;
    }
    updateEvent();
  }
  async function updateEvent() {
    if (!token) return;

    (evento.content as any) = JSON.stringify(evento.content);
    evento.content_2 = JSON.stringify(objetivos);
    await UpdateCMSEvents(evento, token);

    setListaEventos(await fetchData());
    evento = newEvent();
    objetivos = [];
  }

  async function deleteEvent(id: number) {
    if (!token) return;

    await DeleteCMSEvents(id, token);

    setListaEventos(await fetchData());
  }

  function getEventos() {
    let eventos = listaEventos?.map((e: any, index) => {
      try {
        e.content = JSON.parse(e.content);
      } catch (e) {}
      console.log(listaEquipos);
      return {
        title: listaEquipos?.find((evento) => e.team_id?.toString() === evento.id?.toString())?.team_name + (e.content.title? " - " + e.content.title : ""),
        start: e.from,
        end: e.to,
        id: index,
        color: "white",
        borderColor: "black",
        textColor: "black",
        display: "block",
      };
    });
    return eventos as unknown as [{ title: string; date: string }];
  }

  function checkValid(){
    return evento.from !== null && evento.from !== undefined && evento.to !== null && evento.to !== undefined && evento.content.title !== null &&evento.content.title !== undefined && evento.content.title !== "" &&evento.team_id !== null &&evento.team_id !== undefined && evento.team_id !== 0;
  }

  function crearObj(ok: boolean, club_id?: number) {
    if (loggedUser?.role.name === "Club" || loggedUser?.role.name === "Trainer") {
      club_id = loggedUser?.club_id || (loggedUser as any).staff.club_id;
      
    }
    let clubid = club_id;
    let equipos;
    if(loggedUser?.role.name !== "Trainer"){
      equipos = listaClubs
      ?.find((club) => club.id?.toString() === (club_id?.toString() ||
        listaEquipos?.find((e) => e.id?.toString() === evento.team_id?.toString())
          ?.club_id.toString()))
      ?.club_teams.map((t) => {
        return { value: t.id, text: t.team_name };
      });
    }else{
      equipos = listaEquipos?.map((t) => {
        return { value: t.id, text: t.team_name };
      });
    }
      console.log(equipos);
    return (
      <React.Fragment>
        <form
          onSubmit={(e) => e.preventDefault()}
        >
          <table className="w-full table-fixed my-2">
            <tbody>
              <tr>
                <td className="px-6 py-4">
                  {loggedUser?.role.name === "Admin" && (
                    <DataField
                      disabled={!editable}
                      data={
                        club_id ||
                        listaEquipos?.find((e) => e.id === evento.team_id)
                          ?.club_id
                      }
                      label={t("weekly_obj.club")}
                      type="select"
                      options={getClubNames()}
                      placeholder={t("weekly_obj.club_placeholder")}
                      onChange={(e) => {
                        let club = listaClubs?.find((club) => club.id?.toString() === e?.toString());
                        if (!club) return;
                        setListaEquipos(club.club_teams.map((t) => t));
                        evento.team_id = 0;
                        clubid = club.id;
                        setModalContent(
                          crearObj(checkValid(), clubid),
                          t("14052021.obj.title")
                        );
                      }}
                    />
                  )}
                </td>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable || equipos === null || equipos === undefined || equipos?.length === 0}
                    data={evento.team_id}
                    label={t("weekly_obj.team")}
                    type="select"
                    options={equipos}
                    flexible
                    placeholder={t("weekly_obj.team_placeholder")}
                    onChange={(e) => {evento.team_id = e; setModalContent(
                      crearObj(checkValid(), clubid),
                      t("14052021.obj.title")
                    );}}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                  disabled={!editable}
                    data={evento.from}
                    label={t("weekly_obj.from")}
                    type="date"
                    onChange={(e) => {evento.from = e;
                      setModalContent(
                      crearObj(checkValid(), clubid),
                      t("14052021.obj.title")
                    );}}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                  disabled={!editable}
                    data={evento.to}
                    label={t("weekly_obj.to")}
                    type="date"
                    onChange={(e) => {evento.to = e; setModalContent(
                      crearObj(checkValid(), clubid),
                      t("14052021.obj.title")
                    );}}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  {((objetivos && objetivos?.length > 0)  || editable) && <p>{t("14052021.obj.modelo")}</p>}
                  {editable && <button
                    className={
                      "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full mr-2 mt-3"
                    }
                    onClick={() =>
                      setModalContent(
                        getObjectives("Objetivos"),
                        t("14052021.obj.title")
                      )
                    }
                  >
                    <img
                      src={add_icon}
                      alt="add"
                      className="h-6 p-1 text-center m-auto"
                    />
                    <p>{t("experience_global.shared_obj.add_with")}</p>
                  </button>}
                  {objetivos && objetivos?.length > 0 && (
                    <div className="mt-3 border border-sf-mediumblue rounded-md">
                      {objetivos?.map((o, index) => {
                        let data: Guideline | undefined = listaGuidelines?.find(
                          (g) => g.id === o
                        );
                        return (
                          <div
                          key={index}
                            className={
                              "p-2 " +
                              (index % 2 !== 0
                                ? "bg-sf-extralightblue bg-opacity-25"
                                : "")
                            }
                          >
                            {(data?.name.split("|")[0] || "") +" "+  JSON.parse(data?.name.split("|")[1] || "")[i18n.language]}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </td>
                <td className="px-6 py-4">
                <DataField
                disabled={!editable}
                    data={evento.content.title}
                    label={t("09022022.objtitle")}
                    required
                    onChange={(e) => {evento.content.title = e; setModalContent(
                      crearObj(checkValid(), clubid),
                      t("14052021.obj.title")
                    );}}
                  />
                  <DataField
                  disabled={!editable}
                    data={evento.content.content}
                    label={t("weekly_obj.other")}
                    type="textarea"
                    onChange={(e) => (evento.content.content = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button
            className={
              "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mx-auto"
            }
            disabled={!ok}
            onClick={() => {
              if(!ok)return;
              if(editable){
              if(evento.id === null || evento.id === undefined)saveEvent();else updateEvent();}
              setModalContent(undefined);
            }}
          >
            <p className="pl-2">{editable? t("weekly_obj.save") : t("09022022.weekly_obj") }</p>
          </button>
          {evento.id && editable &&  <button
              className={"bg-red-500 h-8 w-8  px-1 rounded-full"}
              onClick={() => {
                deleteEvent(evento.id);
                setModalContent(undefined);
              }}
            >
              <img
                src={minus_icon}
                alt="Borrar"
                className="h-6 w-6"
                style={{ filter: "invert()" }}
              />
            </button>}
        </form>
      </React.Fragment>
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <div className="flex flex-col h-full z-0">
        <Topbar
          visibleButtons={editable}
          createText={t("weekly_obj.create")}
          onBack={() => props.history.goBack()}
          onCreate={(value) =>{
            evento = newEvent();
            setModalContent(crearObj(false), t("14052021.obj.title"));}
          }
        />
        <div className="mt-8 bg-white w-full p-12 flex-1 overflow-auto flex">
          <div className="w-full mx-auto" style={{ maxWidth: "1280px" }}>
            <FullCalendar
              plugins={[dayGridPlugin]}
              headerToolbar={{ end: "prev,next" }}
              initialView="dayGridMonth"
              locale={i18n.language}
              firstDay={1}
              events={getEventos()}
              eventClick={(info) => {
                if (!listaEventos) return;
                evento = Object.assign ({}, listaEventos[Number(info.event.id)]);
                objetivos = JSON.parse(evento.content_2);
                setModalContent(
                  
                  crearObj(true),
                  t("14052021.obj.title")
                );
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="bg-f2f2f2 mt-20 flex flex-1">
          <div className="m-auto flex flex-col">
            <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
            <p>{t("29082021.loading.generic")}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Objectives;
