import { ReportData } from "../CMSUtils/Icms";

const interaccion: string[] = [
  "interaccion.pregunta",
  "interaccion.feedback",
  "interaccion.tiempo_reflexion",
  "interaccion.reflexion",
  "interaccion.observar",
  "interaccion.escuchar",
  "interaccion.exigir",
  "interaccion.permisivo",
  "interaccion.motivacion",
  "interaccion.miedo",
  "interaccion.herramientas",
  "interaccion.corrige",
];
const gestion: string[] = [
  "gestion.equipo",
  "gestion.roles",
  "gestion.fluidez",
  "gestion.activo",
  "gestion.padres",
];

const comprension: string[] = [
  "comprension.entiende",
  "comprension.propone",
];
const entrenamiento: string[] = [
  "entrenamiento.anticipacion",
  "entrenamiento.combina_experiencia",
  "entrenamiento.coordinacion_tecnico",
  "entrenamiento.tiempo",
  "entrenamiento.espacio",
  "entrenamiento.preparacion",
  "entrenamiento.planificacion",
];
const valores: string[] = [
  "valores.respeto",
  "valores.convivencia",
  "valores.bondad",
  "valores.sinceridad",
  "valores.empatia",
  "valores.paciencia",
  "valores.gratitud",
  "valores.perdon",
  "valores.humildad",
  "valores.responsabilidad",
  "valores.solidaridad",
];
const emociones: string[] = [
  "emociones.alegria",
  "emociones.tristeza",
  "emociones.enfado",
  "emociones.envidia",
  "emociones.ansiedad",
  "emociones.miedo",
  "emociones.calma",
  "emociones.triunfo",
];
const verbal: string[] = [
  "verbal.jugadores",
  "verbal.rivales_arbitro",
  "verbal.adecuado",
  "verbal.periodista",
];
const noVerbal: string[] = [
  "noVerbal.gestos_despectivos",
  "noVerbal.postura_activa",
];
const competicion: string[] = [
  "competicion.participacion",
  "competicion.observa_rival",
  "competicion.observa_propio",
  "competicion.comportamiento",
  "competicion.analiza_rival",
  "competicion.analiza_propio",
  "competicion.crea_ejecuta",
];
const experiencias: string[] = [
  "experiencias.necesidades",
  "experiencias.relaciones_numericas",
  "experiencias.variabilidad",
];

export function getReportData() {
  let reportData: ReportData[] = [];
  interaccion.forEach((i) =>
    reportData.push({ type: `experiences|${i}` } as ReportData)
  );
  gestion.forEach((i) => reportData.push({ type: `group|${i}` } as ReportData));
  comprension.forEach((i) =>
    reportData.push({ type: `game|${i}` } as ReportData)
  );
  entrenamiento.forEach((i) =>
    reportData.push({ type: `training|${i}` } as ReportData)
  );
  valores.forEach((i) =>
    reportData.push({ type: `values|${i}` } as ReportData)
  );
  emociones.forEach((i) =>
    reportData.push({ type: `feelings|${i}` } as ReportData)
  );
  verbal.forEach((i) => reportData.push({ type: `verbal|${i}` } as ReportData));
  noVerbal.forEach((i) =>
    reportData.push({ type: `nonverbal|${i}` } as ReportData)
  );
  competicion.forEach((i) =>
    reportData.push({ type: `competition|${i}` } as ReportData)
  );
  experiencias.forEach((i) =>
    reportData.push({ type: `selection|${i}` } as ReportData)
  );
  return reportData;
}
