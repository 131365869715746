import React, { Suspense, useEffect} from "react";
import close from "./images/icons/close.svg";
import "./App.css";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Clubs from "./sections/Clubs";
import Players from "./sections/Players";
import Teams from "./sections/Teams";
import useStore from "./store";
import { routes } from "./routes";
import Login from "./sections/Login";
import Staffs from "./sections/Staffs";
import Modal from "react-modal";
import Events from "./sections/Events";
import GlobalExperiences from "./sections/GlobalExperiences";
import GoalkeeperExperiences from "./sections/GoalkeeperExperiences";
import BioExperiences from "./sections/BioExperiences";
import AudiovisualExperiences from "./sections/AudiovisualExperiences";
import Training from "./sections/Training";
import Match from "./sections/Match";
import Methodology from "./sections/Methodology";
import Tutorial from "./sections/Tutorial";
import WatchedPlayers from "./sections/WatchedPlayers";
import Objectives from "./sections/Objectives";
import Calendar from "./sections/Calendar";
import { GetCMSUsers } from "./CMSUtils/LoginCalls";
import { GetSeasons } from "./CMSUtils/ClubCalls";

function App() {
  const showMenu = useStore((state) => state.showMenu);
  const token = useStore((state) => state.token);
  const modalContent = useStore((state) => state.modalContent);
  const modalTitle = useStore((state) => state.modalTitle);
  const setModalContent = useStore((state) => state.setModalContent);
  const modalBack = useStore((state) => state.modalBack);
  const setToken = useStore((state) => state.setToken);
  const setLoggedUser = useStore((state) => state.setLoggedUser);
  const loggedUser = useStore((state) => state.loggedUser);
  const onModalBack = useStore((state) => state.onModalBack);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const setSeasons = useStore((state) => state.setSeasons);

  function getCookie(name: string): string | null {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(";")
        .map((c) => c.trim())
        .filter((cookie) => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map((cookie) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }

// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setSeasons([]);
    if (getCookie("tk")) {
      GetCMSUsers(getCookie("tk")).then((result) => {
        if (result) {
          setToken(getCookie("tk"));
          setLoggedUser(result);
          if(result.role.name === "Club" && getCookie("tk") !== null){
            GetSeasons(getCookie("tk")!,result.club_id.toString()).then(r => setSeasons(r.map((s:any) => {return{id: s.id, name: s.name}})));

          }
        } else {
          document.cookie =
            "tk=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
        setIsLoaded(true);
      });
    } else {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <React.Fragment>
      <Suspense fallback={true}>
        <div className="z-50">
          <Modal
            isOpen={modalContent ? true:false}
            onAfterOpen={() => undefined}
            onRequestClose={() => setModalContent(undefined)}
            className="bg-white m-auto px-3 pb-3 pt-1 max-h-full popup overflow-y-hidden flex flex-col"
            ariaHideApp={false}
          >
            <div
              className={
                "text-xl pl-6 overflow-y-hidden pr-2 pt-2 pb-4 flex " +
                (modalTitle ? "justify-between" : "justify-end")
              }
              style={{ minHeight: "50px", minWidth: "400px" }}
            >
              {modalBack && (
                <button
                  onClick={() => {
                    modalBack();
                    onModalBack(undefined);
                  }}
                  className="bg-sf-mediumblue rounded-full px-4 py-1 text-white"
                >
                  Atrás
                </button>
              )}
              {modalTitle && (
                <h1 className="flex-1 text-center">{modalTitle}</h1>
              )}
              <button onClick={() => setModalContent(undefined)}>
                <img src={close} className="h-4 w-4" alt="close" />
              </button>
            </div>
            <div className="p-6 flex-1 overflow-y-auto overflow-x-hidden">
              {modalContent}
            </div>
          </Modal>
        </div>
        <BrowserRouter>
          {!token && isLoaded ? (
            <Login />
          ) : (
            token &&
            isLoaded && (
              <React.Fragment>
                <Route component={Navbar} />
                <div
                  className={
                    "pt-20 absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out " +
                    (showMenu ? "pl-64" : "pl-20")
                  }
                >
                  <Switch>
                    <Route exact path="/">
                      {loggedUser?.role.name === "Admin" && (
                        <Redirect to={routes["clubs"].path} />
                      )}
                      {(loggedUser?.role.name === "Club" ||
                        loggedUser?.role.name === "Coordinator" ||
                        loggedUser?.role.name === "Trainer" ||
                        loggedUser?.role.name === "Goal_Bio_Trainer") && (
                        <Redirect to={routes["equipos"].path} />
                      )}
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                    </Route>
                    <Route path={routes["clubs"].path}>
                      <Route component={Clubs} />
                      {(loggedUser?.role.name === "Club" ||
                        loggedUser?.role.name === "Coordinator" ||
                        loggedUser?.role.name === "Trainer" ||
                        loggedUser?.role.name === "Goal_Bio_Trainer") && (
                        <Redirect to={routes["equipos"].path} />
                      )}
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                    </Route>
                    <Route path={routes["equipos"].path}>
                      <Route component={Teams} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                    </Route>
                    <Route path={routes["jugadores"].path}>
                      <Route component={Players} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                    </Route>
                    <Route path={routes["entrenadores"].path}>
                      <Route component={Staffs} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                    </Route>
                    <Route path={routes["eventos"].path}>
                      <Route component={Events} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                    </Route>
                    <Route path={routes["objetivos"].path}>
                      <Route component={Objectives} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                    </Route>
                    <Route path={routes["calendario"].path}>
                      <Route component={Calendar} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                    </Route>
                    <Route path={routes["entrenamiento"].path}>
                      <Route component={Training} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                    </Route>
                    <Route path={routes["partido"].path}>
                      <Route component={Match} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                    </Route>
                    <Route path={routes["captacion"].path}>
                      <Route component={WatchedPlayers} />
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                      {(loggedUser?.role.name === "Coordinator" ||
                        loggedUser?.role.name === "Trainer" ||
                        loggedUser?.role.name === "Goal_Bio_Trainer") && (
                        <Redirect to={routes["equipos"].path} />
                      )}
                    </Route>
                    <Route
                      path={routes["metodologia"].path}
                      component={Methodology}
                    />
                    <Route
                      path={routes["tutorial"].path}
                      component={Tutorial}
                    />
                    <Route path={routes["experiencias_globales"].path}>
                      <Route component={GlobalExperiences} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                    </Route>
                    <Route path={routes["experiencias_porteros"].path}>
                      <Route component={GoalkeeperExperiences} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                    </Route>
                    <Route path={routes["experiencias_biologicas"].path}>
                      <Route component={BioExperiences} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                    </Route>
                    <Route path={routes["experiencias_audiovisuales"].path}>
                      <Route component={AudiovisualExperiences} />
                      {loggedUser?.role.name === "Scouter" && (
                        <Redirect to={routes["captacion"].path} />
                      )}
                      {loggedUser?.role.name === "Player" && (
                        <Redirect to={routes["calendario"].path} />
                      )}
                    </Route>
                  </Switch>
                </div>
              </React.Fragment>
            )
          )}
        </BrowserRouter>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
