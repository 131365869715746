import React, { useEffect, useState } from "react";
import { PersonDetail } from "../Person/PersonDetail";
import club_icon from "../../images/icons/clubs-icon.svg";
import add_icon from "../../images/icons/add-icon.svg";
import loadingGif from "../../images/loading.gif";

import "../../styles/fa.css";
import "../../styles/style.css";

import CollapsablePanel from "../CollapsablePanel";
import ImageField from "../ImageField";
import cross_icon from "../../images/icons/close.svg";
import DataField from "../DataField";
import {
  Idioma,
  PersonData,
  PlayerData,
} from "../../CMSUtils/Icms";
import useStore from "../../store";

import { LoadCMSTeams} from "../../CMSUtils/TeamCalls";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { positions } from "../../data/arrays";
import { useTranslation } from "react-i18next";

export const WatchedPlayerDetail: React.FC<{
  selected: PlayerData;

  uploadImage: (image: File | undefined) => void;
  image: File | undefined;
  validForm: (state: boolean) => void;
}> = ({ uploadImage, selected, image, validForm }) => {
  const { t } = useTranslation();
  const token = useStore((state) => state.token);
  const [isLoaded, setIsLoaded] = useState(false);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const [listaIdiomasEscolares, setListaIdiomasEscolares] = useState<Idioma[]>(
    selected.school_languages ? JSON.parse(selected.school_languages) : []
  );
  const [listaIdiomasLaborales, setListaIdiomasLaborales] = useState<Idioma[]>(
    selected.job_languages ? JSON.parse(selected.job_languages) : []
  );

  useEffect(() => {
    let isMounted = true;
    fetchData().then(()=>{
      if(isMounted){
        setIsLoaded(true);
      }
    });
    if (!selected.school_contact) {
      selected.school_contact = {} as PersonData;
    }
    if (!selected.agent) {
      selected.agent = {} as PersonData;
    }

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSTeams(token);
    return null;
  }

  return isLoaded ? (
    <React.Fragment>
      <form
        onSubmit={(e) => e.preventDefault()}
        onChange={(e) => {
          setCambiosSinGuardar(true);
          validForm(e.currentTarget.checkValidity());
        }}
        onLoad={(e) => validForm(e.currentTarget.checkValidity())}
      >
        <CollapsablePanel titulo={"Datos personales"} colapsado={false}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td rowSpan={3} className="w-1/3">
                  <div
                    className="flex flex-col m-auto"
                    style={{ width: "14rem" }}
                  >
                    <ImageField
                      emptyImage={club_icon}
                      uploadedImage={image}
                      uploadImage={uploadImage}
                      defaultImage={
                        selected.image
                          ? cmsStorageURL + selected.image.file
                          : undefined
                      }
                    />
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.name}
                    label="Nombre"
                    onChange={(e) => (selected.name = e)}
                    required
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.surname}
                    label="Apellidos"
                    onChange={(e) => (selected.surname = e)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.birthday}
                    label="Fecha de nacimiento"
                    type="date"
                    onChange={(e) => (selected.birthday = e)}
                    required
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.birth_country}
                    type="country"
                    label="Nacionalidad"
                    onChange={(e) => (selected.birth_country = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.dni}
                    label="DNI / Pasaporte"
                    placeholder="XXXXXXXX-X"
                    onChange={(e) => (selected.dni = e)}
                    required
                  />
                </td>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.dni_expiration_date}
                    label="Fecha de caducidad"
                    type="date"
                    onChange={(e) => (selected.dni_expiration_date = e)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="py-4 px-6">
                  <DataField
                    data={selected.email}
                    label="E-mail"
                    onChange={(e) => (selected.email = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.phone_number}
                    label="Teléfono"
                    onChange={(e) => (selected.phone_number = e)}
                  />
                </td>
                <td className="py-4 px-6"></td>
              </tr>
              <tr>
                <td colSpan={3} className="pt-4 pb-8 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-1">
                      <DataField
                        data={selected.addres}
                        label="Dirección"
                        type="textarea"
                        onChange={(e) => (selected.addres = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        data={selected.actual_addres}
                        label="Domicilio actual"
                        type="textarea"
                        onChange={(e) => (selected.actual_addres = e)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="py-4 px-6">
                  <DataField
                    data={selected.instagram}
                    label="Instagram"
                    placeholder="@cuentadeIG"
                    onChange={(e) => (selected.instagram = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.facebook}
                    label="Facebook"
                    placeholder="cuentadeFacebook"
                    onChange={(e) => (selected.facebook = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.twitter}
                    label="Twitter"
                    placeholder="@cuentadeTwitter"
                    onChange={(e) => (selected.twitter = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.web}
                    label="Página web"
                    placeholder="www"
                    onChange={(e) => (selected.web = e)}
                  />
                </td>
                <td colSpan={2} className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.other_socials}
                    label="Otros"
                    placeholder="Tik Tok,"
                    onChange={(e) => (selected.other_socials = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>

        <CollapsablePanel titulo={"Datos familiares"}>
          <table className="w-full table-fixed my-2">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="py-4 pl-6 pr-1">
                  <DataField
                    data={selected.core_composition}
                    label="Composición del núcleo familiar"
                    type="textarea"
                    onChange={(e) => (selected.core_composition = e)}
                  />
                </td>
                <td colSpan={2} className="py-4 pr-6 pl-1">
                  <DataField
                    data={selected.comentary}
                    label="Comentarios"
                    type="textarea"
                    onChange={(e) => (selected.comentary = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex">
            <div className="w-1/3">
              <PersonDetail
                tipo={selected.contact_1_type}
                person={selected.contact_1}
                onUpdate={(e, t) => {
                  selected.contact_1 = e as any;
                  selected.contact_1_type = t as any;
                }}
                hasTipo
              />
            </div>
            <div className="w-1/3">
              <PersonDetail
                tipo={selected.contact_2_type}
                person={selected.contact_2}
                onUpdate={(e, t) => {
                  selected.contact_2 = e as any;
                  selected.contact_2_type = t as any;
                }}
                hasTipo
              />
            </div>
            <div className="w-1/3">
              <PersonDetail
                tipo={selected.contact_3_type}
                person={selected.contact_3}
                onUpdate={(e, t) => {
                  selected.contact_3 = e as any;
                  selected.contact_3_type = t as any;
                }}
                hasTipo
              />
            </div>
          </div>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.school.title")}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.school}
                    label={t("player.school.school")}
                    onChange={(e) => (selected.school = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4 border-r border-l border-gray-400">
                  <DataField
                    data={selected.performance}
                    label={t("player.school.performance")}
                    type="select"
                    placeholder={t("player.school.performance_placeholder")}
                    options={[
                      {
                        value: "low",
                        text: t(
                          "14052021.watchedplayer.performance.options.low"
                        ),
                      },
                      {
                        value: "medium",
                        text: t(
                          "14052021.watchedplayer.performance.options.medium"
                        ),
                      },
                      {
                        value: "high",
                        text: t(
                          "14052021.watchedplayer.performance.options.high"
                        ),
                      },
                    ]}
                    onChange={(e) => (selected.performance = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.school_contact?.name}
                    label={t("player.school.school_contact")}
                    onChange={(e) => (selected.school_contact.name = e)}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.school_address}
                    label={t("player.school.school_address")}
                    type="textarea"
                    onChange={(e) => (selected.school_address = e)}
                  />
                </td>
                <td
                  rowSpan={4}
                  className="w-1/3 px-6 py-4 border-r border-l border-gray-400"
                >
                  {listaIdiomasEscolares.map((i, index) => (
                    <div className="flex justify-between">
                      <div className="w-3/6 pr-1">
                        <DataField
                          data={i.value}
                          label={t("player.school.lang")}
                          onChange={(e) => {
                            i.value = e;
                            selected.school_languages = JSON.stringify(
                              listaIdiomasEscolares
                            );
                          }}
                          required
                        />
                      </div>
                      <div className="w-2/6 pl-2">
                        <DataField
                          data={i.level}
                          label={t("player.school.level")}
                          type="select"
                          options={[
                            {
                              value: "lvl_low",
                              text: t("player.school.level_low"),
                            },
                            {
                              value: "lvl_medium",
                              text: t("player.school.level_medium"),
                            },
                            {
                              value: "lvl_high",
                              text: t("player.school.level_high"),
                            },
                            {
                              value: "lvl_max",
                              text: t("player.school.level_max"),
                            },
                          ]}
                          onChange={(e) => {
                            i.level = e;
                            selected.school_languages = JSON.stringify(
                              listaIdiomasEscolares
                            );
                          }}
                          required
                        />
                      </div>
                      <div
                        className="pl-1 flex-1 flex cursor-pointer pt-4"
                        onClick={() => {
                          listaIdiomasEscolares.splice(index, 1);
                          setListaIdiomasEscolares(
                            listaIdiomasEscolares.map((idioma) => idioma)
                          );
                          selected.school_languages = JSON.stringify(
                            listaIdiomasEscolares
                          );
                        }}
                      >
                        <p className="self-center ml-auto">
                          <img src={cross_icon} className="h-3 w-3" alt="close" />
                        </p>
                      </div>
                    </div>
                  ))}

                  <button
                    className={
                      "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full mt-4"
                    }
                    onClick={() => {
                      listaIdiomasEscolares.push({} as Idioma);
                      setListaIdiomasEscolares(
                        listaIdiomasEscolares.map((i) => i)
                      );
                    }}
                  >
                    <img
                      src={add_icon}
                      alt="add"
                      className="h-6 p-1 text-center m-auto"
                    />
                    <p className="pl-2">{t("player.school.add_lang")}</p>
                  </button>
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.school_contact_rol}
                    label={t("player.school.role")}
                    type="select"
                    placeholder={t("player.school.role_placeholder")}
                    options={[
                      {
                        value: "role_dir",
                        text: t("player.school.role_director"),
                      },
                      {
                        value: "role_chief",
                        text: t("player.school.role_chief"),
                      },
                      {
                        value: "role_tutor",
                        text: t("player.school.role_tutor"),
                      },
                    ]}
                    onChange={(e) => (selected.school_contact_rol = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.school_contact?.email}
                    label={t("player.school.email")}
                    onChange={(e) => (selected.school_contact.email = e)}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.school_comentary}
                    label={t("player.school.comments")}
                    type="textarea"
                    onChange={(e) => (selected.school_comentary = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.school_contact?.phone_number}
                    label={t("player.school.phone")}
                    onChange={(e) => (selected.school_contact.phone_number = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.job.title")}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.job_type}
                    label={t("player.job.type")}
                    onChange={(e) => (selected.job_type = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <div className="flex justify-between">
                    <div className="pr-1">
                      {t("player.job.driving")}
                      <div
                        className="flex justify-center"
                        onChange={(e: any) =>
                          (selected.driving_license =
                            e.target.value === "si" ? true : false)
                        }
                      >
                        <input
                          className="self-center"
                          type="radio"
                          id="cy"
                          name="carnet"
                          value="si"
                          defaultChecked={selected.driving_license === true}
                        />
                        <label className="self-center pr-5 pl-1" htmlFor="cy">
                          {t("player.job.driving_yes")}
                        </label>
                        <input
                          className="self-center pl-5"
                          type="radio"
                          id="cn"
                          name="carnet"
                          value="no"
                          defaultChecked={selected.driving_license === false}
                        />
                        <label className="self-center pl-1" htmlFor="cn">
                          {t("player.job.driving_no")}
                        </label>
                      </div>
                    </div>
                    <div className="1/2 pl-1">
                      {t("player.job.own_car")}
                      <div
                        className="flex justify-center"
                        onChange={(e: any) =>
                          (selected.own_car =
                            e.target.value === "si" ? true : false)
                        }
                      >
                        <input
                          className="self-center"
                          type="radio"
                          id="cpy"
                          name="coche"
                          value="si"
                          defaultChecked={selected.own_car === true}
                        />
                        <label className="self-center pl-1 pr-5" htmlFor="cpy">
                          {t("player.job.own_car_yes")}
                        </label>
                        <input
                          className="self-center pl-5"
                          type="radio"
                          id="cpn"
                          name="coche"
                          value="no"
                          defaultChecked={selected.own_car === false}
                        />
                        <label className="self-center pl-1" htmlFor="cpn">
                          {t("player.job.own_car_no")}
                        </label>
                      </div>
                    </div>
                  </div>
                </td>
                <td rowSpan={3} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.job_comentary}
                    type="textarea"
                    label={t("player.job.comments")}
                    onChange={(e) => (selected.job_comentary = e)}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.job_site}
                    label={t("player.job.place")}
                    type="textarea"
                    onChange={(e) => (selected.job_site = e)}
                  />
                </td>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  {listaIdiomasLaborales.map((i, index) => (
                    <div className="flex justify-between">
                      <div className="w-3/6 pr-1">
                        <DataField
                          data={i.value}
                          label={t("player.job.lang")}
                          onChange={(e) => {
                            i.value = e;
                            selected.job_languages = JSON.stringify(
                              listaIdiomasLaborales
                            );
                          }}
                          required
                        />
                      </div>
                      <div className="w-2/6 pl-1">
                        <DataField
                          data={i.level}
                          label={t("player.job.level")}
                          type="select"
                          options={[
                            {
                              value: "lvl_low",
                              text: t("player.job.level_low"),
                            },
                            {
                              value: "lvl_medium",
                              text: t("player.job.level_medium"),
                            },
                            {
                              value: "lvl_high",
                              text: t("player.job.level_high"),
                            },
                            {
                              value: "lvl_max",
                              text: t("player.job.level_max"),
                            },
                          ]}
                          onChange={(e) => {
                            i.level = e;
                            selected.job_languages = JSON.stringify(
                              listaIdiomasLaborales
                            );
                          }}
                          required
                        />
                      </div>
                      <div
                        className="pl-1 flex-1 flex cursor-pointer pt-4"
                        onClick={() => {
                          listaIdiomasLaborales.splice(index, 1);
                          setListaIdiomasLaborales(
                            listaIdiomasLaborales.map((idioma) => idioma)
                          );
                          selected.job_languages = JSON.stringify(
                            listaIdiomasLaborales
                          );
                        }}
                      >
                        <p className="self-center ml-auto">
                          <img src={cross_icon} className="h-3 w-3" alt="close" />
                        </p>
                      </div>
                    </div>
                  ))}
                  <button
                    className={
                      "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full mt-4"
                    }
                    onClick={() => {
                      listaIdiomasLaborales.push({} as Idioma);
                      setListaIdiomasLaborales(
                        listaIdiomasLaborales.map((i) => i)
                      );
                    }}
                  >
                    <img
                      src={add_icon}
                      alt="add"
                      className="h-6 p-1 text-center m-auto"
                    />
                    <p>{t("player.job.add_lang")}</p>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={"Datos deportivos"}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.sport_name}
                    label="Nombre deportivo"
                    onChange={(e) => (selected.sport_name = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <div className="flex">
                    <div className="w-1/2 pr-1">
                      <DataField
                        data={selected.leg}
                        label={t("14052021.watchedplayer.handed.label")}
                        type="select"
                        options={[
                          {
                            value: "amb_handed",
                            text: t(
                              "14052021.watchedplayer.handed.options.amb_handed"
                            ),
                          },
                          {
                            value: "rigth_handed",
                            text: t(
                              "14052021.watchedplayer.handed.options.rigth_handed"
                            ),
                          },
                          {
                            value: "left_handed",
                            text: t(
                              "14052021.watchedplayer.handed.options.left_handed"
                            ),
                          },
                        ]}
                        onChange={(e) => (selected.leg = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        data={selected.dorsal}
                        label="Dorsal"
                        type="number"
                        onChange={(e) => (selected.dorsal = e)}
                      />
                    </div>
                  </div>
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.projection}
                    label="Nivel de proyección"
                    onChange={(e) => (selected.projection = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    data={selected.position}
                    label="Posición"
                    placeholder="Seleccionar posición"
                    type="select"
                    options={positions}
                    onChange={(e) => (selected.position = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <div className="flex">
                    <div className="w-1/2 pr-1">
                      <DataField
                        data={selected.height}
                        label="Altura (cm)"
                        type="number"
                        onChange={(e) => (selected.height = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        data={selected.weight}
                        label="Peso (kg)"
                        type="number"
                        onChange={(e) => (selected.weight = e)}
                      />
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4"></td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.team}
                    label="Equipo"
                    onChange={(e) => (selected.team = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.inc_date}
                    label="Fecha de incorporación"
                    type="date"
                    onChange={(e) => (selected.inc_date = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.end_contract}
                    label="Contrato hasta"
                    type="date"
                    onChange={(e) => (selected.end_contract = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4">
                  <DataField
                    data={selected.incl_date}
                    label="Fecha de inclusion"
                    type="date"
                    onChange={(e) => (selected.incl_date = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                    data={selected.excl_date}
                    label="Fecha de exclusion"
                    type="date"
                    onChange={(e) => (selected.excl_date = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    data={selected.procedence_club}
                    label="Club de procedencia"
                    onChange={(e) => (selected.procedence_club = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                    data={selected.club_temps}
                    label="Temporadas en este club"
                    type="number"
                    onChange={(e) => (selected.club_temps = e)}
                  />
                </td>
                <td className="px-6 py-4"></td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    data={selected.international}
                    label="Internacional"
                    onChange={(e) => (selected.international = e)}
                  />
                </td>
                <td colSpan={2} className="px-6 py-4 pb-8">
                  <DataField
                    data={selected.previous_trainers}
                    label="Entrenadores anteriores"
                    onChange={(e) => (selected.previous_trainers = e)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="px-6 py-4">
                  <DataField
                    data={selected.agent?.name}
                    label="Agente"
                    onChange={(e) => (selected.agent.name = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                    data={selected.agent?.phone_number}
                    label="Teléfono"
                    onChange={(e) => (selected.agent.phone_number = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                    data={selected.agent?.email}
                    label="Email"
                    onChange={(e) => (selected.agent.email = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={"Datos de cesión"}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.cesion_club}
                    label="Club"
                    onChange={(e) => (selected.cesion_club = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.cesion_from}
                    label="Desde"
                    type="date"
                    onChange={(e) => (selected.cesion_from = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.cesion_to}
                    label="Hasta"
                    type="date"
                    onChange={(e) => (selected.cesion_to = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.cesion_trainer}
                    label="Entrenador"
                    onChange={(e) => (selected.cesion_trainer = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.cesion_tlf}
                    label="Teléfono"
                    onChange={(e) => (selected.cesion_tlf = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.cesion_email}
                    label="Email"
                    onChange={(e) => (selected.cesion_email = e)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td colSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    data={selected.cesion_comentary}
                    label="Comentarios"
                    type="textarea"
                    onChange={(e) => (selected.cesion_comentary = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4"></td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
      </form>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="m-auto">
          <img src={loadingGif} alt="loading"/>
          <p>{t("29082021.loading.generic")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WatchedPlayerDetail;
