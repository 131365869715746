import { cmsBaseUrl, PersonData } from "./Icms";

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsPersonUrl: string = cmsApiUrl + "/person";
/*      EQUIPOS   */

async function RemovePersonFromCMS(
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsPersonUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSPerson: (id: number, token: string) => Promise<boolean> =
  RemovePersonFromCMS;

async function GetPersonFromCMS(): Promise<PersonData | null> {
  //Lanzamos la petición
  let response = await fetch(cmsPersonUrl, {
    method: "GET",
  });

  if (response.ok === false) return null;

  try {
    //Cogemos los datos
    let result = await response.json();

    return result;
  } catch (error) {
    return null;
  }
}

export const LoadCMSPerson: () => Promise<PersonData | null> = GetPersonFromCMS;

async function InsertPersonFromCMS(
  person: PersonData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsPersonUrl, {
    method: "POST",
    body: JSON.stringify({ personData: person }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();

    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSPerson: (
  person: PersonData,
  token: string
) => Promise<number> = InsertPersonFromCMS;

async function ModifyPersonFromCMS(
  person: PersonData,
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsPersonUrl, {
    method: "PUT",
    body: JSON.stringify({ personData: person, id: id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const UpdateCMSPerson: (
  person: PersonData,
  id: number,
  token: string
) => Promise<boolean> = ModifyPersonFromCMS;
