export const routes: { [id: string]: { path: string; title: string } } = {};
routes["/"] = { path: "/", title: "login" };
routes["clubs"] = { path: "/clubs", title: "club" };
routes["equipos"] = { path: "/equipos", title: "team" };
routes["jugadores"] = { path: "/jugadores", title: "player" };
routes["entrenadores"] = { path: "/entrenadores", title: "staff" };
routes["eventos"] = { path: "/eventos", title: "event" };
routes["entrenamiento"] = { path: "/entrenamiento", title: "training" };
routes["partido"] = { path: "/partido", title: "match" };
routes["captacion"] = { path: "/captacion", title: "watched" };
routes["metodologia"] = { path: "/metodologia", title: "methodology" };
routes["objetivos"] = { path: "/objetivos", title: "weekly_obj" };
routes["calendario"] = { path: "/calendario", title: "calendar" };
routes["tutorial"] = { path: "/tutorial", title: "tutorial" };
routes["experiencias_globales"] = {
  path: "/experiencias/globales",
  title: "experience_global",
};
routes["experiencias_porteros"] = {
  path: "/experiencias/porteros",
  title: "experience_goalkeeper",
};
routes["experiencias_biologicas"] = {
  path: "/experiencias/biologicas",
  title: "experience_bio",
};
routes["experiencias_audiovisuales"] = {
  path: "/experiencias/audiovisuales",
  title: "experience_audiovisual",
};
