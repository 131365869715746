import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import useStore from "../store";
import { GetAllEvents, LoadCMSEvents } from "../CMSUtils/EventCalls";
import { GetTrainings, LoadCMSTrainings } from "../CMSUtils/TrainingCalls";
import { MatchData, TrainingData, Event } from "../CMSUtils/Icms";
import { GetMatches, LoadCMSMatches } from "../CMSUtils/MatchCalls";
import { useTranslation } from "react-i18next";

const Calendar = (props: any) => {
  const { i18n } = useTranslation();
  const [listaEventos, setListaEventos] = useState<Event[]>();
  const [listaTraining, setListaTraining] = useState<TrainingData[]>();
  const [listaMatches, setListaMatches] = useState<MatchData[]>();
  const token = useStore((state) => state.token);

  useEffect(() => {
    let isMounted = true;

    fetchData().then(()=> {
      if(isMounted){
      let matches = GetMatches();
      if (!matches) matches = [];
      setListaMatches(matches);

      let events = GetAllEvents();
      if (!events) events = [];
      setListaEventos(events);

      let trainings = GetTrainings();
      if (!trainings) trainings = [];
      setListaTraining(trainings);
    }
  }
    );

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSEvents(token);
    await LoadCMSTrainings(token);
    await LoadCMSMatches(token);

    return null;
  }

  function getEventos() {
    let eventos = listaEventos?.map((e: any) => {
      try {
        e.content = JSON.parse(e.content);
      } catch (e) {}
console.log(e);
      return {
        title: e.content.title ?? e.content.content ?? "Sin título",
        start: e.from,
        end: e.to,
        color: "white",
        borderColor: e.type === "event" ? "#0e7bc0" : "black",
        textColor: e.type === "event" ? "#0e7bc0" : "black",
        display: "block",
      };
    });
    if (listaTraining && listaTraining.length > 0) {
      listaTraining
        ?.map((e) => {
          return {
            title:  (e.only_time ? e.only_time + " - " : "") + e.team?.team_name + (e.num_session? " - " + e.num_session : ""),
            start: e.date_and_time,
            end: e.date_and_time,
            color: "#e6e6e6",
            borderColor: "",
            textColor: "black",
            display: "block",
          };
        })
        .forEach((e) => eventos?.push(e));
    }
    if (listaMatches && listaMatches.length > 0) {
      listaMatches
        ?.map((e) => {
          return {
            title: ( e.hour? e.hour +" - " : "") + (e.team?.team_name ?? (e as any).team_name ?? "Sin titulo"),
            start: e.date,
            end: e.date,
            color: "#0e7bc0",
            borderColor: "",
            textColor: "white",
            display: "block",
          };
        })
        .forEach((e) => eventos?.push(e));
    }
    return eventos as unknown as [{ title: string; date: string }];
  }

  return (
    <React.Fragment>
      <div className="flex flex-col h-full z-0 pt-20">
        <div className="bg-white w-full p-12 flex-1 overflow-auto flex">
          <div className="w-full mx-auto" style={{ maxWidth: "1280px" }}>
            <FullCalendar
              displayEventTime={false}
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              locale={i18n.language}
              headerToolbar={{ end: "prev,next" }}
              firstDay={1}
              events={getEventos()}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Calendar;
