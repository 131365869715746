import { cmsBaseUrl, PlayerData } from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsPlayersUrl: string = cmsApiUrl + "/player";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let playerList: PlayerData[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetPlayersFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsPlayersUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      playerList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSPlayers: (token: string) => Promise<boolean> =
  GetPlayersFromCMS;

async function RemovePlayersFromCMS(
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsPlayersUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSPlayers: (id: number, token: string) => Promise<boolean> =
  RemovePlayersFromCMS;

async function InsertPlayersFromCMS(
  player: PlayerData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsPlayersUrl, {
    method: "POST",
    body: JSON.stringify({ playerData: player }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSPlayers: (
  player: PlayerData,
  token: string
) => Promise<number> = InsertPlayersFromCMS;

async function ModifyPlayersFromCMS(
  player: PlayerData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsPlayersUrl, {
    body: JSON.stringify({ playerData: player, id: player.id }),
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const UpdateCMSPlayers: (
  player: PlayerData,
  token: string
) => Promise<number> = ModifyPlayersFromCMS;

function getPlayers(): PlayerData[] | undefined {
  if (playerList === undefined) return undefined;

  return playerList;
}
export const GetPlayers: () => PlayerData[] | undefined = getPlayers;

function getPlayersByClubID(id: number): PlayerData[] | undefined {
  if (playerList === undefined) return undefined;

  return playerList.filter((p) => p.club_id === id);
}
export const GetPlayersByClubID: (id: number) => PlayerData[] | undefined =
  getPlayersByClubID;

async function CreateUserForPlayer(
  playerData: PlayerData,
  token: string
): Promise<boolean> {
  //Si no hay datos de usuario o ya hay uno creado, avisamos de error y devolvemos
  if (
    (playerData.user !== undefined && playerData.user !== null) ||
    playerData.userEmail === "" ||
    playerData.userName === "" ||
    playerData.userPass === ""
  ) {
    console.log("Error creating user for player. One variable is wrong.");
    return false;
  }

  //Lanzamos la petición
  let response = await fetch(cmsApiUrl + "/auth/createAccount", {
    method: "POST",
    body: JSON.stringify({
      userName: playerData.userName,
      userEmail: playerData.userEmail,
      userPassword: playerData.userPass,
      type: "player",
      player_id: playerData.id,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  return response.ok !== false;
}
export const CreateUserPlayer: (
  playerData: PlayerData,
  token: string
) => Promise<boolean> = CreateUserForPlayer;
