import React, { useEffect, useState } from "react";
import club_icon from "../../images/icons/clubs-icon.svg";
import add_icon from "../../images/icons/add-icon.svg";
import cross_icon from "../../images/icons/close.svg";
import loadingGif from "../../images/loading.gif";
import "../../styles/fa.css";
import "../../styles/style.css";

import CollapsablePanel from "../CollapsablePanel";
import ImageField from "../ImageField";
import DataField from "../DataField";
import {
  ExperienceData,
  ExperienceGuideline,
  Guideline,
  InfoExperience,
} from "../../CMSUtils/Icms";
import useStore from "../../store";
import {
  GetGuidelines,
  LoadCMSGuidelines,
} from "../../CMSUtils/GuidelinesCalls";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export const AudiovisualExperienceDetail: React.FC<{
  selected: ExperienceData;
  uploadImage: (image: File | undefined) => void;
  image: File | undefined;
  uploadImage2: (image: File | undefined) => void;
  onGuidelineAdd?: (guideline: any) => void;
  onCheckedUpdate?: (guideline: any) => void;
  onGuidelineRemove?: (guideline: any) => void;
  onDestinyUpdate?: (guideline: any) => void;
  valid?: (value: boolean) => void;
  image2: File | undefined;
  editable?: boolean;
  selectedObj?: {
    team: string;
    guidelines: Guideline[];
  }[];
}> = ({
  uploadImage,
  selected,
  image,
  uploadImage2,
  onGuidelineAdd,
  onCheckedUpdate,
  onGuidelineRemove,
  onDestinyUpdate,
  valid,
  image2,
  editable = true,
  selectedObj
}) => {
  const { t } = useTranslation();
  const token = useStore((state) => state.token);
  const [listaGuidelines, setListaGuidelines] = useState<Guideline[]>();
  const setModalContent = useStore((state) => state.setModalContent);
  const [isLoaded, setIsLoaded] = useState(false);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const [lang, setLang] = useState("ES");
  const loggedUser = useStore((state) => state.loggedUser);
  const permiso_editar = loggedUser?.role.name === "Admin";
  const onModalBack = useStore((state) => state.onModalBack);

  const [objectives, setObjectives] = useState<
    {
      team: string;
      guidelines: Guideline[];
    }[]
  >([
    { team: "Awith", guidelines: [] },
    { team: "Awithout", guidelines: [] },
    { team: "Bwith", guidelines: [] },
    { team: "Bwithout", guidelines: [] },
    { team: "Cwith", guidelines: [] },
    { team: "Cwithout", guidelines: [] },
  ]);

  const [selectedGuidelines, setSelectedGuidelines] = useState<any[]>(
    selected.guidelines
  );
  const [experienceInfo, setExperienceInfo] = useState<InfoExperience[]>(
    selected.ec_es
      ? JSON.parse(selected.ec_es)
      : [
          { ec: "EC1", ef: "R1", obs: "" },
          { ec: "EC1", ef: "R1", obs: "" },
          { ec: "EC1", ef: "R1", obs: "" },
          { ec: "EC1", ef: "R1", obs: "" },
        ]
  );
  const [experienceInfoEng, setExperienceInfoEng] = useState<InfoExperience[]>(
    selected.ec_en
      ? JSON.parse(selected.ec_en)
      : [
          { ec: "EC1", ef: "R1", obs: "" },
          { ec: "EC1", ef: "R1", obs: "" },
          { ec: "EC1", ef: "R1", obs: "" },
          { ec: "EC1", ef: "R1", obs: "" },
        ]
  );
  const [experienceInfoRu, setExperienceInfoRu] = useState<InfoExperience[]>(
    selected.ec_ru
      ? JSON.parse(selected.ec_ru)
      : [
          { ec: "EC1", ef: "R1", obs: "" },
          { ec: "EC1", ef: "R1", obs: "" },
          { ec: "EC1", ef: "R1", obs: "" },
          { ec: "EC1", ef: "R1", obs: "" },
        ]
  );
  useEffect(() => {
    let isMounted = true;
    onModalBack(undefined);
    if (!listaGuidelines) {
      fetchData().then(() => {if(isMounted){
        setListaGuidelines(GetGuidelines() as Guideline[]);
        setIsLoaded(true);
      }});
    } else {
      if (selected.guidelines) {
        selected.guidelines = selected.guidelines.map((g) => {
          return {
            id: g.guideline_id ? g.guideline_id : g.id,
            type: g.type,
            name: g.guideline ? g.guideline.name : g.name,
            parent: g.guideline ? g.guideline.type_id : g.parent,
            checked: g.checked,
            destiny: g.destiny
          };
        });
        setSelectedGuidelines(selected.guidelines.map((g) => g));
        selected.guidelines.forEach((g) => {
          let parent = listaGuidelines.find((lg) => lg.id?.toString() === g.parent?.toString());
          if (
            parent &&
            !objectives
              .find((o) => o.team === getObjTypes(g.type))
              ?.guidelines.includes(parent)
          )
            objectives
              .find((o) => o.team === getObjTypes(g.type))
              ?.guidelines.push(parent);
        });
        if (selectedObj) {
          setObjectives(selectedObj.map((o) => o));
        } else {
          setObjectives(objectives.map((o) => o));
        }
      }
    }

    return () => { isMounted = false };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaGuidelines]);

  function updateExpInfo() {
    setExperienceInfo(experienceInfo.map((e) => e));
    setExperienceInfoEng(experienceInfoEng.map((e) => e));
    setExperienceInfoRu(experienceInfoRu.map((e) => e));
    selected.ec_es = JSON.stringify(experienceInfo);
    selected.ec_en = JSON.stringify(experienceInfoEng);
    selected.ec_ru = JSON.stringify(experienceInfoRu);
  }
  function printGuidelines(obj: any[] | undefined) {
    return (
      obj &&
      obj.length > 0 && (
        <div className="w-full rounded-md border-sf-mediumblue border mt-3">
          {obj.map((g: any, index) => (
            <p
            key={index}
              className={
                "p-2 " +
                (index % 2 !== 0 ? "bg-sf-extralightblue bg-opacity-25" : "")
              }
            >
              {JSON.parse(g.name.split("|")[1])[i18n.language]}
            </p>
          ))}
        </div>
      )
    );
  }
  function getObjTypes(type: string) {
    let typeAux = type;
    if (type === "AwithWithBall" || type === "AwithWithoutBall")
      typeAux = "Awith";
    if (type === "AwithoutWithBall" || type === "AwithoutWithoutBall")
      typeAux = "Awithout";
    if (type === "BwithWithBall" || type === "BwithWithoutBall")
      typeAux = "Bwith";
    if (type === "BwithoutWithBall" || type === "BwithoutWithoutBall")
      typeAux = "Bwithout";
    return typeAux;
  }

  function marcarGuideline(name: string, guideline: Guideline) {
    const obj: any = objectives.find((o) => o.team === name);
    if (obj?.guidelines.lastIndexOf(guideline) === -1) {
      obj.guidelines.push(guideline);
      setObjectives(objectives.map((o) => o));
    } 
  }

  useEffect(() => {
    if (!selected.name_es)
      (selected.name_es as any) = Object.assign("", selected.name_es);
    if (!selected.name_en)
      (selected.name_en as any) = Object.assign("", selected.name_en);
    if (!selected.name_ru)
      (selected.name_ru as any) = Object.assign("", selected.name_ru);
    if (!selected.variants_es)
      (selected.variants_es as any) = Object.assign("", selected.variants_es);
    if (!selected.variants_en)
      (selected.variants_en as any) = Object.assign("", selected.variants_en);
    if (!selected.variants_ru)
      (selected.variants_ru as any) = Object.assign("", selected.variants_ru);
    if (!selected.obs3_es)
      (selected.obs3_es as any) = Object.assign("", selected.obs3_es);
    if (!selected.obs3_en)
      (selected.obs3_en as any) = Object.assign("", selected.obs3_en);
    if (!selected.obs3_ru)
      (selected.obs3_ru as any) = Object.assign("", selected.obs3_ru);
    if (!selected.description_es)
      (selected.description_es as any) = Object.assign(
        "",
        selected.description_es
      );
    if (!selected.description_en)
      (selected.description_en as any) = Object.assign(
        "",
        selected.description_en
      );
    if (!selected.description_ru)
      (selected.description_ru as any) = Object.assign(
        "",
        selected.description_ru
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  function desmarcarGuideline(name: string, guideline: Guideline) {
    const obj: any = objectives.find((o) => o.team === name);
    const i = obj?.guidelines.lastIndexOf(guideline);
    selectedGuidelines.forEach((g) => {
      if (g.parent === guideline.id) {
        selected.guidelines.splice(
          selected.guidelines.findIndex((s) => s === g),
          1
        );
        if (onGuidelineRemove) {
          onGuidelineRemove(g);
        }
      }
    });
    setSelectedGuidelines(selected.guidelines.map((g) => g));
    if (i !== -1) {

      /*
      selected.guidelines.find((g, index) => {
        if (obj.guidelines[i]?.id === g.id) {
          selected.guidelines.splice(index, 1);
        }
      });*/

      //Quitamos el objetivo
      obj.guidelines.splice(i, 1);
      setObjectives(objectives.map((o) => o));
    }
  }

  function marcarObjetivo(name: string, objective: any) {
    if (
      !selected.guidelines?.find((o) => o.id?.toString() === objective.id?.toString() && o.type === name)
    ) {
      selected.guidelines.push({
        type: name,
        id: objective.id,
        name: objective.name,
        parent: objective.type_id,
        checked: false,
      } as ExperienceGuideline);
      if (onGuidelineAdd) {
        onGuidelineAdd({
          type: name,
          id: objective.id,
          name: objective.name,
          parent: objective.type_id,
          guideline: objective,
          checked: false,
        } as ExperienceGuideline);
      }
      setSelectedGuidelines(selected.guidelines.map((g) => g));
    }
  }

  function desmarcarObjetivo(name: string, guideline_id: number) {
    selectedGuidelines.find((o) => {
      if (o.id === guideline_id && o.type === name) {
        selected.guidelines.splice(
          selected.guidelines.findIndex((s) => s === o),
          1
        );
        if (onGuidelineRemove) {
          onGuidelineRemove(o);
        }
      }
      return undefined;
    });
    setSelectedGuidelines(selected.guidelines.map((g) => g));
  }

  async function fetchData() {
    if (!token) return;
    await LoadCMSGuidelines(token);
    return null;
  }

  function checkGuidelines(team: string) {
    let obj = objectives.find((o) => o.team === team);
    return obj?.guidelines && obj.guidelines.length > 0;
  }

  function getGuidelines(
    team: string,
    title: string,
    nameToSave?: string,
    filter?: string
  ) {
    if (!editable) {
      onModalBack(() =>
        setModalContent(
          <AudiovisualExperienceDetail
            selected={selected}
            selectedObj={objectives}
            image={image}
            image2={image2}
            uploadImage={uploadImage}
            uploadImage2={uploadImage2}
            editable={editable}
            onGuidelineAdd={onGuidelineAdd}
            onGuidelineRemove={onGuidelineRemove}
            onDestinyUpdate={onDestinyUpdate}
          />
        )
      );
    }else{
      onModalBack(undefined);
    }
    return (
      <React.Fragment>
        {selected.guidelines.filter(g => g.type === team).length > 0 && (
          <div className="p-3 border-b border-gray-400 h-32 overflow-auto mb-3">
            {selected.guidelines.filter(g => g.type === team).map((g, index) => (
              <div
              key={index}
                className={
                  "flex py-1 " +
                  (index % 2 !== 0 ? "bg-sf-extralightblue bg-opacity-25" : "")
                }
              >
                <p className="w-7/12 px-3">
                  {JSON.parse(g.name.split("|")[1])[i18n.language]}
                </p>
                <p className="w-2/12">{g.name.split("|")[2]}</p>
                <p className="w-1/12">{g.name.split("|")[3]}</p>
                <p className="w-1/12">{g.name.split("|")[4]}</p>
                <p
                  className="w-1/12 ml-auto cursor-pointer"
                  onClick={() => {
                    desmarcarObjetivo(nameToSave ?? team, g.id);
                    setModalContent(
                      getGuidelines(team, title, nameToSave, filter),
                      title
                    );
                  }}
                >
                  <img src={cross_icon} alt="close" className="w-3 h-3" />
                </p>
              </div>
            ))}
          </div>
        )}
        <DataField
          data={filter}
          placeholder="Buscar pauta"
          onChange={(v) =>
            setModalContent(getGuidelines(team, title, nameToSave, v), title)
          }
        />

        {objectives
          .find((o) => o.team === team)
          ?.guidelines.map((g: Guideline, ind) => (
            <React.Fragment key={ind}>
              <div className="w-full py-3 text-lg font-bold">
                {JSON.parse(g.name.split("|")[1])[i18n.language]}
              </div>
              <table className="w-full table-fixed border-gray-500 border-2">
                <thead>
                  <tr className="bg-sf-darkblue text-white">
                    <td className="w-9/12 py-2 px-6">{"Descripción"}</td>
                    <td className="w-1/12 py-2 px-6 text-center">Objetivo</td>
                    <td className="w-2/12 py-2 px-6 text-center">{"Tipo"}</td>
                  </tr>
                </thead>
                <tbody style={{ verticalAlign: "top" }}>
                  {g.objectives &&
                    g.objectives.length > 0 &&
                    g.objectives
                      .filter((o) =>
                        o.name
                          .toLowerCase()
                          .includes(filter?.toLowerCase() || "")
                      )
                      .map((o, index) => {
                        let obj = o.name.split("|");
                        return (
                          <tr
                          key={index}
                            className={
                              "px-6 " +
                              (index % 2 === 0
                                ? "bg-sf-extralightblue bg-opacity-25"
                                : "")
                            }
                          >
                            <td className="w-9/12 py-3 pl-3">
                              <div className="flex">
                                <input
                                  type="checkbox"
                                  className="self-center"
                                  defaultChecked={selected.guidelines?.find(
                                    (g) =>
                                      o.id?.toString() === g.id?.toString() &&
                                      g.type === (nameToSave ?? team)
                                  )}
                                  onChange={(e) => {
                                    e.target.checked
                                      ? marcarObjetivo(nameToSave ?? team, o)
                                      : desmarcarObjetivo(
                                          nameToSave ?? team,
                                          o.id
                                        );
                                    setModalContent(
                                      getGuidelines(
                                        team,
                                        title,
                                        nameToSave,
                                        filter
                                      ),
                                      title
                                    );
                                  }}
                                />
                                <p className="px-3 self-center">
                                  {JSON.parse(obj[1])[i18n.language]}
                                </p>
                              </div>
                            </td>
                            <td className="w-1/12 text-center py-3">
                              {obj[3]}
                            </td>
                            <td className="w-2/12 text-center py-3">
                              {obj[4]}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  }

  function setDestiny(guideline: any, title: string) {
    if (!editable) {
      onModalBack(() =>
        setModalContent(
          <AudiovisualExperienceDetail
            selected={selected}
            selectedObj={objectives}
            image={image}
            image2={image2}
            uploadImage={uploadImage}
            uploadImage2={uploadImage2}
            editable={editable}
            onGuidelineAdd={onGuidelineAdd}
            onGuidelineRemove={onGuidelineRemove}
            onDestinyUpdate={onDestinyUpdate}
          />
        )
      );
    }else{
      onModalBack(undefined);
    }
    interface destination {
      1: boolean;
      2: boolean;
      3: boolean;
      4: boolean;
      5: boolean;
      6: boolean;
      7: boolean;
      8: boolean;
      9: boolean;
      g: boolean;
    }
    let destinyAux = {} as destination;

    if (guideline.destiny) {
      destinyAux = JSON.parse(guideline.destiny);
    }

    return (
      <React.Fragment>
        <div className="flex w-full justify-center py-2">
          <input
            type="checkbox"
            className="self-center"
            checked={destinyAux.g || false}
            onChange={(e) => {
              destinyAux[1] = false;
              destinyAux[2] = false;
              destinyAux[3] = false;
              destinyAux[4] = false;
              destinyAux[5] = false;
              destinyAux[6] = false;
              destinyAux[7] = false;
              destinyAux[8] = false;
              destinyAux[9] = false;
              destinyAux.g = e.currentTarget.checked;
              guideline.destiny = JSON.stringify(destinyAux);
              if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
            }}
          />
          <p className="pl-3 self-center">G</p>
        </div>
        <div className="grid grid-cols-3">
          <div className="flex py-2">
            <input
              type="checkbox"
              className="self-center"
              checked={destinyAux[1] || false}
              onChange={(e) => {
                destinyAux.g = false;
                destinyAux[1] = e.currentTarget.checked;
                guideline.destiny = JSON.stringify(destinyAux);
                if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
              }}
            />
            <p className="pl-3 self-center">1</p>
          </div>
          <div className="flex py-2">
            <input
              type="checkbox"
              className="self-center"
              checked={destinyAux[2] || false}
              onChange={(e) => {
                destinyAux.g = false;
                destinyAux[2] = e.currentTarget.checked;
                guideline.destiny = JSON.stringify(destinyAux);
                if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
              }}
            />
            <p className="pl-3 self-center">2</p>
          </div>
          <div className="flex py-2">
            <input
              type="checkbox"
              className="self-center"
              checked={destinyAux[3] || false}
              onChange={(e) => {
                destinyAux.g = false;
                destinyAux[3] = e.currentTarget.checked;
                guideline.destiny = JSON.stringify(destinyAux);
                if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
              }}
            />
            <p className="pl-3 self-center">3</p>
          </div>
          <div className="flex py-2">
            <input
              type="checkbox"
              className="self-center"
              checked={destinyAux[4] || false}
              onChange={(e) => {
                destinyAux.g = false;
                destinyAux[4] = e.currentTarget.checked;
                guideline.destiny = JSON.stringify(destinyAux);
                if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
              }}
            />
            <p className="pl-3 self-center">4</p>
          </div>
          <div className="flex py-2">
            <input
              type="checkbox"
              className="self-center"
              checked={destinyAux[5] || false}
              onChange={(e) => {
                destinyAux.g = false;
                destinyAux[5] = e.currentTarget.checked;
                guideline.destiny = JSON.stringify(destinyAux);
                if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
              }}
            />
            <p className="pl-3 self-center">5</p>
          </div>
          <div className="flex py-2">
            <input
              type="checkbox"
              className="self-center"
              checked={destinyAux[6] || false}
              onChange={(e) => {
                destinyAux.g = false;
                destinyAux[6] = e.currentTarget.checked;
                guideline.destiny = JSON.stringify(destinyAux);
                if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
              }}
            />
            <p className="pl-3 self-center">6</p>
          </div>
          <div className="flex py-2">
            <input
              type="checkbox"
              className="self-center"
              checked={destinyAux[7] || false}
              onChange={(e) => {
                destinyAux.g = false;
                destinyAux[7] = e.currentTarget.checked;
                guideline.destiny = JSON.stringify(destinyAux);
                if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
              }}
            />
            <p className="pl-3 self-center">7</p>
          </div>
          <div className="flex py-2">
            <input
              type="checkbox"
              className="self-center"
              checked={destinyAux[8] || false}
              onChange={(e) => {
                destinyAux.g = false;
                destinyAux[8] = e.currentTarget.checked;
                guideline.destiny = JSON.stringify(destinyAux);
                if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
              }}
            />
            <p className="pl-3 self-center">8</p>
          </div>
          <div className="flex py-2">
            <input
              type="checkbox"
              className="self-center"
              checked={destinyAux[9] || false}
              onChange={(e) => {
                destinyAux.g = false;
                destinyAux[9] = e.currentTarget.checked;
                guideline.destiny = JSON.stringify(destinyAux);
                if(onDestinyUpdate){
                onDestinyUpdate(guideline);
              }
              setModalContent(setDestiny(guideline, title),title);
              }}
            />
            <p className="pl-3 self-center">9</p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  function getName(g: any, pos:number){
    let name : string = g.guideline?g.guideline.name.split("|")[pos] : g.name.split("|")[pos]
    return name !== null && name !== undefined && name.startsWith("{") && pos === 1 ? JSON.parse(name)[i18n.language] : name
  }

  function getObjectives(team: string, title: string, search: string = "") {
    setListaGuidelines(GetGuidelines());
    if (!editable) {
      onModalBack(() =>
        setModalContent(
          <AudiovisualExperienceDetail
            selected={selected}
            selectedObj={objectives}
            image={image}
            image2={image2}
            uploadImage={uploadImage}
            uploadImage2={uploadImage2}
            editable={editable}
            onGuidelineAdd={onGuidelineAdd}
            onGuidelineRemove={onGuidelineRemove}
            onDestinyUpdate={onDestinyUpdate}
          />
        )
      );
    }else{
      onModalBack(undefined);
    }
    return (
      <React.Fragment>
        {(objectives.find((o) => o.team === team)?.guidelines.length || 0) >
          0 && (
          <div className={"pb-6 border-gray-400 border-b-2"}>
            <table className="w-full table-fixed pb-6 text-sm">
              <tbody style={{ verticalAlign: "top" }}>
                {listaGuidelines?.map((g: Guideline, index) => {
                  if (
                    objectives
                      .find((o) => o.team === team)
                      ?.guidelines.lastIndexOf(g) !== -1
                  ) {
                    return (
                      <tr key={index}>
                        <td className="w-8/12 py-1 px-6">
                          <p className="self-center pl-3">
                            {JSON.parse(g.name.split("|")[1])[i18n.language]}
                          </p>
                        </td>
                        <td className="w-2/12 py-1 px-6">
                          {g.name.split("|")[0]}
                        </td>
                        <td className="w-2/12 py-1 px-6"></td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        )}

        <div className="w-1/3 py-5">
          <DataField
            data={undefined}
            placeholder="Buscar pauta"
            onChange={(e) => {
              setModalContent(getObjectives(team, title, e || ""), title);
              /*
              let listaFiltrada = GetGuidelines()?.filter((g) =>
                g.name.includes(e)
              );*/
            }}
          />
        </div>
        <table className="w-full table-fixed border-gray-500 border-2">
          <thead>
            <tr className="bg-sf-darkblue text-white">
              <td className="w-9/12 py-2 px-6">{"Descripción"}</td>
              <td className="w-3/12 py-2 px-6">Objetivo</td>
            </tr>
          </thead>
          <tbody style={{ verticalAlign: "top" }}>
            {listaGuidelines
              ?.filter((g) =>
                team === "Awith" || team === "Bwith" || team === "Cwith"
                  ? g.name.substr(0, 1) === "O"
                  : g.name.substr(0, 1) === "D"
              )
              .filter((g) =>
                g.name
                  .split("|")[1]
                  .toLowerCase()
                  .includes(search.toLowerCase())
              )
              .map((g: Guideline, index) => (
                <tr
                key={index}
                  className={
                    index % 2 === 0
                      ? "bg-sf-extralightblue bg-opacity-25"
                      : "bg-white"
                  }
                >
                  <td className="w-9/12 py-3 px-6">
                    <div className="flex">
                      <input
                        type="checkbox"
                        className="self-center"
                        defaultChecked={
                          objectives
                            .find((o) => o.team === team)
                            ?.guidelines.find((o) => o.id?.toString() === g.id?.toString())
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          e.target.checked
                            ? marcarGuideline(team, g)
                            : desmarcarGuideline(team, g);
                          setModalContent(
                            getObjectives(team, title, search),
                            title
                          );
                        }}
                      />
                      <p className="self-center pl-3">
                        {JSON.parse(g.name.split("|")[1])[i18n.language]}
                      </p>
                    </div>
                  </td>
                  <td className="w-3/12 py-3 px-6">{g.name.split("|")[0]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <div
        onChange={() => {
          setCambiosSinGuardar(true);
          if (valid)
            valid(
              selected.name_ru !== undefined && selected.name_ru.toString() !== "" &&
                selected.name_en !== undefined && selected.name_en.toString() !== "" &&
                selected.name_es !== undefined  && selected.name_es.toString() !== ""
            );
        }}
        onLoad={() => {
          if (valid)
            valid(
              selected.name_ru !== undefined && selected.name_ru.toString() !== "" &&
                selected.name_en !== undefined && selected.name_en.toString() !== "" &&
                selected.name_es !== undefined  && selected.name_es.toString() !== ""
            );
        }}
      >
        <CollapsablePanel
          titulo={t("experience_audiovisual.data.title")}
          colapsado={false}
          multiidioma
          idiomaChange={(idioma) => setLang(idioma)}
        >
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td rowSpan={3} className="w-1/3 py-4">
                  <div
                    className="flex flex-col m-auto"
                    style={{ width: "14rem" }}
                  >
                    <ImageField
                      disabled={!editable || !permiso_editar}
                      emptyImage={club_icon}
                      uploadedImage={image}
                      uploadImage={uploadImage}
                      defaultImage={
                        selected.file1
                          ? cmsStorageURL + selected.file1.file
                          : undefined
                      }
                    />
                  </div>
                </td>
                <td rowSpan={3} className="w-1/3 py-4">
                  <ImageField
                    disabled={!editable || !permiso_editar}
                    emptyImage={club_icon}
                    id="files2"
                    uploadedImage={image2}
                    uploadImage={uploadImage2}
                    defaultImage={
                      selected.file2
                        ? cmsStorageURL + selected.file2.file
                        : undefined
                    }
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={
                      selected[
                        `name_${lang.toLowerCase()}` as keyof typeof selected
                      ]
                    }
                    label={t("experience_audiovisual.data.name")}
                    type="textarea"
                    rows={3}
                    onChange={(e) =>
                      ((selected as any)[`name_${lang.toLowerCase()}`] = e)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-fixed border-t-2 border-gray-200">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/2 py-4 px-6">
                  <div className="flex">
                    <div className="w-1/3 pr-2">
                      <DataField
                        disabled={!editable || !permiso_editar}
                        data={experienceInfo[0].ec}
                        label={t("experience_audiovisual.data.ex_cognitive")}
                        type="select"
                        flexible
                        options={[
                          t("experience_audiovisual.data.EC1"),
                          t("experience_audiovisual.data.EC2"),
                          t("experience_audiovisual.data.EC3"),
                        ]}
                        placeholder={t("01122021.select_placeholders.level")}
                        onChange={(e) => {
                          experienceInfo[0].ec = e;
                          updateExpInfo();
                        }}
                      />
                    </div>
                    <div className="w-1/3 pr-2">
                      <DataField
                        disabled={!editable || !permiso_editar}
                        data={experienceInfo[0].ef}
                        label={t("experience_audiovisual.data.ex_physic")}
                        type="select"
                        flexible
                        placeholder={t("01122021.select_placeholders.level")}
                        options={[
                          t("experience_audiovisual.data.R1"),
                          t("experience_audiovisual.data.R2"),
                          t("experience_audiovisual.data.R3"),
                          t("experience_audiovisual.data.R4"),
                          t("experience_audiovisual.data.R5"),
                          t("experience_audiovisual.data.R6"),
                        ]}
                        onChange={(e) => {
                          experienceInfo[0].ef = e;
                          updateExpInfo();
                        }}
                      />
                    </div>
                    <div className="w-1/3 pr-2">
                      <DataField
                        disabled={!editable || !permiso_editar}
                        data={
                          lang === "ES"
                            ? experienceInfo[0].obs
                            : lang === "EN"
                            ? experienceInfoEng[0].obs
                            : experienceInfoRu[0].obs
                        }
                        label={t("experience_audiovisual.data.obs")}
                        onChange={(e) => {
                          switch (lang) {
                            default:
                            case "ES":
                              experienceInfo[0].obs = e;
                              break;
                            case "EN":
                              experienceInfoEng[0].obs = e;
                              break;
                            case "RU":
                              experienceInfoRu[0].obs = e;
                              break;
                          }
                          updateExpInfo();
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-fixed border-t-2 border-gray-200">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={
                      selected[
                        `description_${lang.toLowerCase()}` as keyof typeof selected
                      ]
                    }
                    label={t("experience_audiovisual.data.description")}
                    type="textarea"
                    onChange={(e) => {
                      (selected as any)[`description_${lang.toLowerCase()}`] =
                        e;
                    }}
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={
                      selected[
                        `variants_${lang.toLowerCase()}` as keyof typeof selected
                      ]
                    }
                    type="textarea"
                    label={t("experience_audiovisual.data.variants")}
                    onChange={(e) =>
                      ((selected as any)[`variants_${lang.toLowerCase()}`] = e)
                    }
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable || !permiso_editar}
                    data={
                      selected[
                        `obs3_${lang.toLowerCase()}` as keyof typeof selected
                      ]
                    }
                    type="textarea"
                    label={t("experience_audiovisual.data.tips")}
                    onChange={(e) =>
                      ((selected as any)[`obs3_${lang.toLowerCase()}`] = e)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
          <CollapsablePanel
            titulo={t("experience_audiovisual.obj.title")}
            colapsado={true}
          >
            <table className="w-full table-fixed">
              <tbody style={{ verticalAlign: "top" }}>
                <tr>
                  <td className="w-1/3 py-4 px-6">
                    {t("experience_audiovisual.obj.team_with")}
                  </td>
                  <td className="w-2/3 py-4 px-6"></td>
                </tr>
                <tr>
                  <td className="w-1/3 py-4 px-6">
                    <div className="flex items-center">
                      {t("experience_audiovisual.obj.obj")}
                      {permiso_editar && (
                        <button
                          className={
                            "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full ml-auto mr-2"
                          }
                          onClick={() =>
                            setModalContent(
                              getObjectives(
                                "Cwith",
                                "Objetivos con balón - JUGADORES"
                              ),
                              "Objetivos con balón - JUGADORES"
                            )
                          }
                        >
                          <img
                            src={add_icon}
                            className="h-6 p-1 text-center m-auto"
                            alt="Add"
                          />
                          <p>{t("experience_audiovisual.obj.add")}</p>
                        </button>
                      )}
                    </div>
                    {printGuidelines(
                      objectives.find((o) => o.team === "Cwith")?.guidelines
                    )}
                  </td>
                  <td className="w-2/3 py-4 px-6">
                    <div className="flex items-center">
                      {t("experience_audiovisual.obj.pautas")}
                      {permiso_editar && (
                        <button
                          className={
                            "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full ml-auto mr-2"
                          }
                          onClick={() =>
                            setModalContent(
                              getGuidelines(
                                "Cwith",
                                "Pautas reflexivas con balón - JUGADORES"
                              ),
                              "Pautas reflexivas con balón - JUGADORES"
                            )
                          }
                          disabled={!checkGuidelines("Cwith")}
                        >
                          <img
                            src={add_icon}
                            alt="add"
                            className="h-6 p-1 text-center m-auto"
                          />
                          <p>{t("experience_audiovisual.obj.pautas_add")}</p>
                        </button>
                      )}
                    </div>
                    {selectedGuidelines.filter((g) => g.type === "Cwith")
                      .length > 0 && (
                      <div className="w-full rounded-md border-sf-mediumblue border mt-3">
                        {selectedGuidelines?.map((g: any, index) => {
                          if (g.type === "Cwith") {
                            return (
                              <table className="w-full" key={index}>
                                <tbody>
                                <tr
                                  className={
                                    index % 2 === 0
                                      ? "bg-sf-extralightblue bg-opacity-25"
                                      : ""
                                  }
                                >
                                  <td className="w-1/12 px-3">
                                    <input
                                      type="checkbox"
                                      checked={g.checked || false}
                                      onChange={(e) => {
                                        if (
                                          selectedGuidelines.filter(
                                            (guid) => guid.checked
                                          ).length < 8 ||
                                          !e.currentTarget.checked
                                        ) {
                                          g.checked = e.currentTarget.checked;
                                          if (onCheckedUpdate){
                                            onCheckedUpdate(g);}
                                          setSelectedGuidelines(
                                            selectedGuidelines.map((g) => g)
                                          );
                                        } else {
                                          alert(t("14052021.pautas.alert"));
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="w-6/12 px-2 py-2">
                                  {getName(g, 1)}
                                  </td>
                                  <td className="w-1/12">
                                  {getName(g, 2)}
                                  </td>
                                  <td className="w-1/12">
                                  {getName(g, 3)}
                                  </td>
                                  <td className="w-1/12">
                                  {getName(g, 4)}
                                  </td>
                                  <td className="w-2/12 py-2">
                                    <button
                                      className={
                                        "flex bg-white border-2 border-sf-mediumblue py-1 pr-2 pl-2 rounded-full ml-auto mr-2 text-sm"
                                      }
                                      onClick={() =>
                                        setModalContent(
                                          setDestiny(
                                            g,
                                            "Configurar destino de pauta"
                                          ),
                                          "Configurar destino de pauta"
                                        )
                                      }
                                      disabled={!checkGuidelines("Cwith")}
                                    >
                                      <p>{t("14052021.pautas.configurar")}</p>
                                    </button>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            );
                          }
                        })}
                      </div>
                    )}
                  </td>
                </tr>

                <tr className="border-t-2 border-gray-200">
                  <td className="w-1/2 py-4 px-6">
                    {t("experience_audiovisual.obj.team_without")}
                  </td>
                  <td className="w-1/2 py-4 px-6"></td>
                </tr>
                <tr>
                  <td className="w-1/3 py-4 px-6">
                    <div className="flex items-center">
                      {t("experience_audiovisual.obj.obj_without")}

                      {permiso_editar && (
                        <button
                          className={
                            "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full ml-auto mr-2"
                          }
                          onClick={() =>
                            setModalContent(
                              getObjectives(
                                "Cwithout",
                                "Objetivos sin balón - JUGADORES"
                              ),
                              "Objetivos sin balón - JUGADORES"
                            )
                          }
                        >
                          <img
                            src={add_icon}
                            alt="add"
                            className="h-6 p-1 text-center m-auto"
                          />
                          <p>{t("experience_audiovisual.obj.add_without")}</p>
                        </button>
                      )}
                    </div>
                    {printGuidelines(
                      objectives.find((o) => o.team === "Cwithout")?.guidelines
                    )}
                  </td>
                  <td className="w-2/3 py-4 px-6">
                    <div className="flex items-center">
                      {t("experience_audiovisual.obj.pautas_without")}
                      <button
                        className={
                          "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full ml-auto mr-2"
                        }
                        onClick={() =>
                          setModalContent(
                            getGuidelines(
                              "Cwithout",
                              "Pautas reflexivas sin balón - JUGADORES"
                            ),
                            "Pautas reflexivas sin balón - JUGADORES"
                          )
                        }
                        disabled={!checkGuidelines("Cwithout")}
                      >
                        <img
                          src={add_icon}
                          alt="add"
                          className="h-6 p-1 text-center m-auto"
                        />
                        <p>
                          {t("experience_audiovisual.obj.pautas_without_add")}
                        </p>
                      </button>
                    </div>
                    {selectedGuidelines.filter((g) => g.type === "Cwithout")
                      .length > 0 && (
                      <div className="w-full rounded-md border-sf-mediumblue border mt-3">
                        {selectedGuidelines?.map((g: any, index) => {
                          if (g.type === "Cwithout") {
                            return (
                              <table className="w-full">
                                <tr
                                  className={
                                    index % 2 === 0
                                      ? "bg-sf-extralightblue bg-opacity-25"
                                      : ""
                                  }
                                >
                                  <td className="w-1/12 px-3">
                                    <input
                                      type="checkbox"
                                      checked={g.checked || false}
                                      onChange={(e) => {
                                        if (
                                          selectedGuidelines.filter(
                                            (guid) => guid.checked
                                          ).length < 8 ||
                                          !e.currentTarget.checked
                                        ) {
                                          g.checked = e.currentTarget.checked;
                                          if (onCheckedUpdate)
                                            onCheckedUpdate(g);
                                          setSelectedGuidelines(
                                            selectedGuidelines.map((g) => g)
                                          );
                                        } else {
                                          alert(t("14052021.pautas.alert"));
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="w-6/12 px-2 py-2">
                                  {getName(g, 1)}
                                  </td>
                                  <td className="w-1/12">
                                  {getName(g, 2)}
                                  </td>
                                  <td className="w-1/12">
                                  {getName(g, 3)}
                                  </td>
                                  <td className="w-1/12">
                                  {getName(g, 4)}
                                  </td>
                                  <td className="w-2/12 py-2">
                                    <button
                                      className={
                                        "flex bg-white border-2 border-sf-mediumblue py-1 pr-2 pl-2 rounded-full ml-auto mr-2 text-sm"
                                      }
                                      onClick={() =>
                                        setModalContent(
                                          setDestiny(
                                            g,
                                            "Configurar destino de pauta"
                                          ),
                                          "Configurar destino de pauta"
                                        )
                                      }
                                      disabled={!checkGuidelines("Cwith")}
                                    >
                                      <p>{t("14052021.pautas.configurar")}</p>
                                    </button>
                                  </td>
                                </tr>
                              </table>
                            );
                          }
                        })}
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </CollapsablePanel>
        
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="m-auto flex flex-col">
          <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
          <p>{t("29082021.loading.generic")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AudiovisualExperienceDetail;
