import { cmsBaseUrl, Evaluation } from "./Icms";

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsEvaluationsUrl: string = cmsApiUrl + "/evaluation";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let evaluationsList: Evaluation[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetEvaluationsFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsEvaluationsUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      evaluationsList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSEvaluations: (token: string) => Promise<boolean> =
  GetEvaluationsFromCMS;

async function RemoveEvaluationsFromCMS(
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsEvaluationsUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSEvaluations: (
  id: number,
  token: string
) => Promise<boolean> = RemoveEvaluationsFromCMS;

async function InsertEvaluationsFromCMS(
  evaluation: Evaluation,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsEvaluationsUrl, {
    method: "POST",
    body: JSON.stringify({ evaluationData: evaluation }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSEvaluations: (
  evaluation: Evaluation,
  token: string
) => Promise<number> = InsertEvaluationsFromCMS;

async function ModifyEvaluationsFromCMS(
  evaluation: Evaluation,
  token: string
): Promise<number> {
  //Lanzamos la petición

  let response = await fetch(cmsEvaluationsUrl, {
    method: "PUT",
    body: JSON.stringify({ evaluationData: evaluation, id: evaluation.id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const UpdateCMSEvaluations: (
  evaluation: Evaluation,
  token: string
) => Promise<number> = ModifyEvaluationsFromCMS;

function getEvaluations(): Evaluation[] | undefined {
  if (evaluationsList === undefined) return undefined;

  return evaluationsList;
}
export const GetEvaluations: () => Evaluation[] | undefined = getEvaluations;
