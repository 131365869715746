import React, { useState } from "react";
import foto_icon from "../images/icons/foto-icon.svg";
import papelera_icon from "../images/icons/papelera-icon.svg";
import { useTranslation } from "react-i18next";

const ImageField: React.FC<{
  uploadImage: (image: File | undefined) => void;
  uploadedImage: File | undefined;
  defaultImage: string | undefined;
  emptyImage: string;
  id?: string;
  format?: string;
  disabled?: boolean;
}> = ({
  uploadImage,
  uploadedImage,
  defaultImage,
  emptyImage,
  id = "files",
  format = "image",
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [thumb, setThumb] = useState("");

  function setThumbnail(imagen: File) {
    if (imagen) {
      let r = new FileReader();
      r.readAsDataURL(imagen);
      r.onload = function (e) {
        if (e && e.target && e.target.result) {
          setThumb(e.target.result.toString());
        }
      };
    } else {
      return "";
    }
  }

  return (
    <div className="w-full h-48 rounded-md bg-gray-200 relative">
      {defaultImage || uploadedImage ? (
        <img
          src={uploadedImage ? thumb : defaultImage}
          alt="Escudo"
          className="mx-auto h-full pb-12 pt-3"
          style={{ maxWidth: "100%" }}
        />
      ) : (
        <img
          src={emptyImage}
          alt="escudo"
          className={"mx-auto h-40 p-6 opacity-25"}
          style={{ maxWidth: "100%" }}
        />
      )}
      <input
        id={id}
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            uploadImage(e.target.files[0]);
            setThumbnail(e.target.files[0]);
          }
        }}
        accept={format + "/*"}
        className="hidden"
      />
      {!disabled && (
        <div className="flex absolute bottom-0 w-full pb-3">
          <div className="m-auto flex">
            <label
              htmlFor={id}
              className="cursor-pointer flex text-xs bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
            >
              <img src={foto_icon} className="h-5 p-1" alt="placeholder" />
              <p className="self-center pl-1 whitespace-no-wrap">{t("ui.upload_pic")}</p>
            </label>
            <button
              className={
                "flex bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2" +
                (uploadedImage ? "" : " hidden")
              }
              onClick={() => {
                uploadImage(undefined);
              }}
            >
              <img
                src={papelera_icon}
                alt="delete"
                className="h-6 p-1 text-center m-auto"
                style={{ filter: "invert()" }}
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageField;
