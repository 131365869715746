import React from "react";
import { useTranslation } from "react-i18next";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { PlayerData} from "../../CMSUtils/Icms";
import player_icon from "../../images/icons/camiseta.svg";

const PlayerItem: React.FC<{
  onCheck: (e: any) => void;
  onClick: () => void;
  datos: PlayerData;
  index: number;
}> = ({ onCheck, onClick, datos, index }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      key={index}
      className={
        "w-full cursor-pointer font-bold " +
        ((index + 1) % 2 === 0
          ? " bg-f2f2f2 hover:bg-gray-500"
          : "bg-white text-gray-800 hover:bg-gray-200")
      }
    >
      <div className="w-full xl:w-3/4 flex flex-row mx-auto py-2">
        <div className="w-1/12 flex flex-row px-1 self-center">
          <input
            type="checkbox"
            name="select"
            value="select"
            className="cursor-pointer my-auto transform mx-2"
            onChange={(e) => onCheck(e)}
            onClick={(e) => e.stopPropagation()}
            //checked={clubsMarcados.includes(club.id) || false}
          />
          {datos.image && (
            <img
              src={cmsStorageURL + datos.image?.file}
              alt="placeholder"
              style={{ maxWidth: "35px", maxHeight: "35px", margin: "auto" }}
            />
          )}
          {!datos.image && (
            <img
              src={player_icon}
              className="p-1 bg-gray-500 opacity-25"
              alt="placeholder"
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                margin: "auto",
              }}
            />
          )}
        </div>
        <div className="w-3/12 px-1 self-center">
          <p className="truncate">{datos.name}</p>
          <p className="truncate text-xs text-gray-600">{datos.surname}</p>
        </div>
        <div className="truncate w-2/12 px-1 self-center">
          <p>{datos.position}</p>
          <p className="text-xs text-gray-600">{datos.leg}</p>
        </div>
        <div className={"w-2/12 px-1 self-center"}>
          {datos.team_id
            ? t("14052021.player.plantilla")
            : t("14052021.player.no_plantilla")}
        </div>
        <div className={"truncate w-2/12 px-1 self-center"}>
          <p>{datos.team?.team_name}</p>
        </div>
        <div className={"w-2/12 px-1 self-center"}>
          <p>{datos.projection}</p>
        </div>
      </div>
    </div>
  );
};

export default PlayerItem;
