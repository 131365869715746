import {
  cmsBaseUrl,
  Guideline,
} from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsGuidelinesUrl: string = cmsApiUrl + "/guidelines";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let guidelinesList: Guideline[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetGuidelinesFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsGuidelinesUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      guidelinesList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSGuidelines: (
  token: string
) => Promise<boolean> = GetGuidelinesFromCMS;

function getGuidelines(): Guideline[] | undefined {
  if (guidelinesList === undefined) return undefined;

  return guidelinesList;
}
export const GetGuidelines: () => Guideline[] | undefined = getGuidelines;
