import React, { useEffect, useState } from "react";
import useStore from "../store";
import Topbar from "../components/Topbar";
import loadingGif from "../images/loading.gif";
import {
  Evaluation,
  Injurie,
  Ipi,
  Report,
  StaffData,
} from "../CMSUtils/Icms";
import { AddCMSFile, DeleteCMSFile } from "../CMSUtils/FileCalls";
import {
  AddCMSStaff,
  CreateUserStaff,
  DeleteCMSStaff,
  GetStaff,
  GetStaffByClubID,
  LoadCMSStaff,
  UpdateCMSStaff,
} from "../CMSUtils/StaffCalls";
import StaffItem from "../components/Staff/StaffItem";
import StaffDetail from "../components/Staff/StaffDetail";
import {
  AddCMSPerson,
  DeleteCMSPerson,
  UpdateCMSPerson,
} from "../CMSUtils/PersonCalls";
import {
  AddCMSEvaluations,
  UpdateCMSEvaluations,
} from "../CMSUtils/EvaluationCalls";
import { AddCMSReports, UpdateCMSReports } from "../CMSUtils/ReportCalls";
import { useTranslation } from "react-i18next";
import { LoadCMSClubs } from "../CMSUtils/ClubCalls";

const Players = (props: any) => {
  const { t } = useTranslation();
  const filteredClub = useStore((state) => state.filteredClub);
  const token = useStore((state) => state.token);
  const [cargandoDatos, setCargandoDatos] = useState(false);
  const [listaStaff, setListaStaff] = useState<StaffData[]>();
  const [staffSeleccionado, setStaffSeleccionado] = useState<StaffData>();
  const [staffsMarcados, setStaffsMarcados] = useState<number[]>([]);
  const [validStaff, setValidStaff] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setStaffSeleccionado(
      props.location.state ? props.location?.state.data : undefined
    );
    if (!cargandoDatos) fetchData().then(s => {
      if(isMounted){
        setListaStaff(s);
      }
    });

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state?.data]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSStaff(token);
    await LoadCMSClubs(token);

    let staffs = filteredClub ? GetStaffByClubID(filteredClub) : GetStaff();
    if (!staffs) staffs = [];
    return staffs;
    //setListaStaff(staffs);
  }

  async function borrarStaff() {
    if (!token) return;
    setCargandoDatos(true);

    if (staffSeleccionado) {
      await DeleteCMSStaff(staffSeleccionado.id, token);
      setListaStaff(await fetchData());

      setStaffSeleccionado(undefined);
      props.history.push("/entrenadores");
    } else {
      if (staffsMarcados.length > 0) {
        for (let i = 0; i < staffsMarcados.length; i++) {
          const staffID = staffsMarcados[i];
          await DeleteCMSStaff(staffID, token);
        }

        setListaStaff(await fetchData());
      }
    }
    setStaffsMarcados([]);
    setCargandoDatos(false);
  }

  async function actualizarStaff() {
    if (!staffSeleccionado || !token) return;
    const evaluacionAux = Object.assign({}, staffSeleccionado.evaluations[0]);

    setCargandoDatos(true);
    let jugAux: StaffData = Object.assign({}, staffSeleccionado);
    jugAux.evaluations = [];

    //Si no está creado en base de datos, lo creamos y guardamos el ID
    if (!staffSeleccionado.id) {
      const staffID = await crearStaff(staffSeleccionado);
      if (staffID === -1) return;
      staffSeleccionado.id = staffID;
    }

    //Si no hay un usuario creado pero hay datos de usuario, creamos el usuario
    if (
      (staffSeleccionado.user === undefined ||
        staffSeleccionado.user === null) &&
      staffSeleccionado.userEmail !== "" &&
      staffSeleccionado.userEmail !== undefined &&
      staffSeleccionado.userName !== "" &&
      staffSeleccionado.userName !== undefined &&
      staffSeleccionado.userPass !== "" &&
      staffSeleccionado.userPass !== undefined
    )
      await CreateUserStaff(staffSeleccionado, token);

    if (imagen) {
      const imageID = await AddCMSFile(imagen, generateUniqueString(), token);
      await DeleteCMSFile(staffSeleccionado.image_id, token);

      staffSeleccionado.image_id = imageID;
    }

    await updatePersonas(token);
    await UpdateCMSStaff(staffSeleccionado, token);

    jugAux = Object.assign({}, staffSeleccionado);
    jugAux.evaluations[0].reports = [];
    jugAux.evaluations[0].ipis = [];
    jugAux.evaluations[0].injuries = [];

    for (let i = 0; i < jugAux.evaluations.length; i++) {
      const evaluation = jugAux.evaluations[i];
      evaluation.staff_id = staffSeleccionado.id;
      if (!evaluation.id) {
        const newEvaluationID = await AddCMSEvaluations(evaluation, token);
        evaluation.id = newEvaluationID;
      }
      evaluation.ipis = Object.assign([], evaluacionAux.ipis);
      evaluation.injuries = Object.assign([], evaluacionAux.injuries);

      await UpdateCMSEvaluations(evaluation, token);

      evaluation.reports = Object.assign([], evaluacionAux.reports);

      for (let j = 0; j < evaluation.reports.length; j++) {
        const report = evaluation.reports[j];
        const dataAux = evaluacionAux.reports[j].data;
        report.data = [];

        report.evaluation_id = evaluation.id;
        if (!report.id || report.id === -1) {
          const newReportID = await AddCMSReports(report as Report, token);
          report.id = newReportID;
        }

        dataAux.forEach((dataIndex) => {
          dataIndex.report_id = report.id;
        });

        report.data = dataAux;
        await UpdateCMSReports(report, token);
      }
    }

    setListaStaff(await fetchData());

    setImagen(undefined);
    setCargandoDatos(false);
    setStaffSeleccionado(undefined);
  }

  async function updatePersonas(token: string) {
    if (!staffSeleccionado) return;

    if (staffSeleccionado.contact_1) {
      if (!staffSeleccionado.contact_1_id)
        staffSeleccionado.contact_1_id = await AddCMSPerson(
          staffSeleccionado.contact_1,
          token
        );
      else
        await UpdateCMSPerson(
          staffSeleccionado.contact_1,
          staffSeleccionado.contact_1_id,
          token
        );
    } else {
      if (staffSeleccionado.contact_1_id) {
        await DeleteCMSPerson(staffSeleccionado.contact_1_id, token);
        staffSeleccionado.contact_1_id = 0;
      }
    }

    if (staffSeleccionado.contact_2) {
      if (!staffSeleccionado.contact_2_id)
        staffSeleccionado.contact_2_id = await AddCMSPerson(
          staffSeleccionado.contact_2,
          token
        );
      else
        await UpdateCMSPerson(
          staffSeleccionado.contact_2,
          staffSeleccionado.contact_2_id,
          token
        );
    } else {
      if (staffSeleccionado.contact_2_id) {
        await DeleteCMSPerson(staffSeleccionado.contact_2_id, token);
        staffSeleccionado.contact_2_id = 0;
      }
    }

    if (staffSeleccionado.agent) {
      if (!staffSeleccionado.agent_id)
        staffSeleccionado.agent_id = await AddCMSPerson(
          staffSeleccionado.agent,
          token
        );
      else
        await UpdateCMSPerson(
          staffSeleccionado.agent,
          staffSeleccionado.agent_id,
          token
        );
    }
  }

  async function crearStaff(staff: StaffData) {
    if (!token) return -1;

    return await AddCMSStaff(staff, token);
  }

  function marcarClub(index: number) {
    if (!staffsMarcados.includes(index)) {
      staffsMarcados.push(index);
      setStaffsMarcados(staffsMarcados.map((c) => c));
    } else {
      console.log("Intentando marcar un staff ya marcado");
    }
  }

  function desmarcarClub(index: number) {
    if (staffsMarcados.includes(index)) {
      staffsMarcados.splice(
        staffsMarcados.findIndex((e) => e === index),
        1
      );
      setStaffsMarcados(staffsMarcados.map((c) => c));
    } else {
      console.log("Intentando desmarcar un staff no marcado");
    }
  }

  function updateFiltro(busqueda: string) {
    let staff = GetStaff();
    if (!staff) return;

    let filteredStaff = staff.filter((p) =>
      p.name.toLowerCase().includes(busqueda.toLowerCase())
    );
    setListaStaff(filteredStaff);
  }

  /*     OLD DATA     */
  const [imagen, setImagen] = useState<File>();

  function generateUniqueString() {
    var ts = String(new Date().getTime()),
      i = 0,
      out = "";

    for (i = 0; i < ts.length; i += 2) {
      out += Number(ts.substr(i, 2)).toString(36);
    }

    return ts + "-" + out;
  }

  const uploadImage = (image: File | undefined) => {
    setImagen(image);
  };

  return (
    <React.Fragment>
      <div className="flex flex-col h-full z-0">
        <Topbar
          marked={staffsMarcados.length}
          selected={staffSeleccionado}
          createText={t("staff.info.new")}
          onBack={() => props.history.goBack()}
          onFilter={(text: string) => updateFiltro(text)}
          disabled={!validStaff}
          onCreate={() =>
            props.history.push({
              pathname: props.location.pathname + "/create",
              state: {
                data: Object.assign({
                  evaluations: [
                    Object.assign({
                      injuries: [] as Injurie[],
                      ipis: [] as Ipi[],
                      reports: [] as Report[],
                    } as Evaluation),
                  ],
                } as StaffData),
              },
            })
          }
          onDelete={() => borrarStaff()}
          onUpdate={() => {
            actualizarStaff();
            props.history.goBack();
          }}
        />
        <div
          className={
            "w-full flex-1 overflow-auto" +
            (staffSeleccionado ? " bg-f2f2f2 mt-8" : "")
          }
        >
          {staffSeleccionado ? (
            <StaffDetail
              selected={staffSeleccionado}
              uploadImage={uploadImage}
              image={imagen}
              validForm={(e) => setValidStaff(e)}
            />
          ) : (
            <div className="mx-auto flex flex-col w-full h-full">
              <div className="w-full font-bold text-white pb-2 shadow-md border border-solid border-b-1 border-t-0 border-l-0 border-r-0 border-gray-500">
                <div className="w-full xl:w-3/4 flex flex-row mx-auto">
                  <div className="w-1/12 self-center"></div>
                  <div className="w-3/12 self-center text-center">
                    {t("staff.info.name")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("staff.info.role")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("staff.info.team")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("staff.info.max_cat")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("staff.info.projection")}
                  </div>
                </div>
              </div>
              <div className="bg-f2f2f2 flex-1">
                {listaStaff && !cargandoDatos ? (
                  listaStaff.length > 0 ? (
                    listaStaff.map((staff: StaffData, i: number) => {
                      //checkboxSelectedClubs[club.id] = false;
                      return (
                        <StaffItem
                          key={i}
                          onClick={() => {
                            props.history.push({
                              pathname: props.location.pathname + "/detail",
                              state: {
                                data: Object.assign({}, staff),
                              },
                            });
                            setStaffsMarcados([]);
                          }}
                          onCheck={(e) => {
                            if (e.target.checked) {
                              marcarClub(staff.id);
                            } else {
                              desmarcarClub(staff.id);
                            }
                          }}
                          datos={staff}
                          index={i}
                        />
                      );
                    })
                  ) : (
                    <div className="flex h-full w-full">
                      <div className="m-auto">{t("29082021.no_content")}</div>
                    </div>
                  )
                ) : (
                  <div className="flex h-full w-full">
                    <div className="m-auto flex flex-col">
                      <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
                      <p>{t("29082021.loading.generic")}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Players;
