import create from "zustand";
import { UserData } from "./CMSUtils/Icms";
type IStore = {
  showMenu: boolean;
  toggleShowMenu: () => void;
  updatedClubs: boolean;
  updateClubs: () => void;
  filteredClub: number | undefined;
  filterClub: (id: number | undefined) => void;
  token: string | null;
  setToken: (value: string | null) => void;
  modalContent: any;
  modalTitle: any;
  setModalContent: (content: any, title?: string) => void;
  cambiosSinGuardar: boolean;
  setCambiosSinGuardar: (value: any) => void;
  loggedUser: UserData | undefined;
  setLoggedUser: (user: UserData | undefined) => void;
  selectedSeason: number | undefined;
  setSelectedSeason: (id: number | undefined) => void;
  seasons: { id: number; name: string }[] | [];
  setSeasons: (seasons: { id: number; name: string }[] | []) => void;
  modalBack: any;
  onModalBack: (onBack: any) => void;
};

const useStore = create<IStore>((set) => ({
  loggedUser: undefined,
  setLoggedUser: (user: UserData | undefined) => set({ loggedUser: user }),
  showMenu: true,
  toggleShowMenu: () => set((state) => ({ showMenu: !state.showMenu })),
  updatedClubs: false,
  updateClubs: () => set((state) => ({ updatedClubs: !state.updatedClubs })),
  filteredClub: undefined,
  filterClub: (id: number | undefined) => set({ filteredClub: id }),
  token: "",
  setToken: (value: string | null) => set({ token: value }),
  modalContent: undefined,
  modalTitle: undefined,
  setModalContent: (content: any, title?: string) =>
    set({ modalContent: content, modalTitle: title }),
  cambiosSinGuardar: false,
  setCambiosSinGuardar: (value: any) => set({ cambiosSinGuardar: value }),
  selectedSeason: undefined,
  setSelectedSeason: (id: number | undefined) => set({ selectedSeason: id }),
  seasons: [],
  setSeasons: (seasons: { id: number; name: string }[] | []) =>
    set({ seasons: seasons }),
  modalBack: undefined,
  onModalBack: (onBack: any) => set({ modalBack: onBack }),
}));

export default useStore;
