import React, { useEffect, useState } from "react";
import useStore from "../store";
import Topbar from "../components/Topbar";
import loadingGif from "../images/loading.gif";
import {
  Evaluation,
  Injurie,
  Ipi,
  PlayerData,
  Report,
} from "../CMSUtils/Icms";
import { AddCMSFile, DeleteCMSFile } from "../CMSUtils/FileCalls";
import {
  AddCMSPlayers,
  CreateUserPlayer,
  DeleteCMSPlayers,
  GetPlayers,
  GetPlayersByClubID,
  LoadCMSPlayers,
  UpdateCMSPlayers,
} from "../CMSUtils/PlayerCalls";
import PlayerItem from "../components/Player/PlayerItem";
import PlayerDetail from "../components/Player/PlayerDetail";
import {
  AddCMSEvaluations,
  UpdateCMSEvaluations,
} from "../CMSUtils/EvaluationCalls";
import { AddCMSReports, UpdateCMSReports } from "../CMSUtils/ReportCalls";
import {
  AddCMSPerson,
  DeleteCMSPerson,
  UpdateCMSPerson,
} from "../CMSUtils/PersonCalls";
import { useTranslation } from "react-i18next";

const Players = (props: any) => {
  const { t } = useTranslation();
  const filteredClub = useStore((state) => state.filteredClub);
  const token = useStore((state) => state.token);
  const [cargandoDatos, setCargandoDatos] = useState(false);
  const [listaJugadores, setListaJugadores] = useState<PlayerData[]>();
  const [jugadorSeleccionado, setJugadorSeleccionado] = useState<PlayerData>();
  const [jugadoresMarcados, setJugadoresMarcados] = useState<number[]>([]);
  const [validPlayer, setValidPlayer] = useState(true);
  const loggedUser = useStore((state) => state.loggedUser);
  const editable =
    loggedUser?.role.name === "Admin" ||
    loggedUser?.role.name === "Club" ||
    loggedUser?.role.name === "Coordinator" ||
    loggedUser?.role.name === "Trainer";

  useEffect(() => {
    let isMounted = true;
    setJugadorSeleccionado(
      props.location.state ? props.location?.state.data : undefined
    );
    if (!cargandoDatos) fetchData().then(p => {if(isMounted)setListaJugadores(p);});

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state?.data]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSPlayers(token);

    let players = filteredClub
      ? GetPlayersByClubID(filteredClub)
      : GetPlayers();
    if (!players) players = [];
    return players;
    //setListaJugadores(players);
  }

  async function borrarJugadores() {
    if (!token) return;
    setCargandoDatos(true);

    if (jugadorSeleccionado) {
      await DeleteCMSPlayers(jugadorSeleccionado.id, token);
      setListaJugadores(await fetchData());

      setJugadorSeleccionado(undefined);
      props.history.push("/jugadores");
    } else {
      if (jugadoresMarcados.length > 0) {
        for (let i = 0; i < jugadoresMarcados.length; i++) {
          const playerID = jugadoresMarcados[i];
          await DeleteCMSPlayers(playerID, token);
        }

        setListaJugadores(await fetchData());
      }
      setJugadoresMarcados([]);
    }

    setCargandoDatos(false);
  }
  async function updatePersonas(token: string) {
    if (!jugadorSeleccionado) return;
    if (jugadorSeleccionado.contact_1) {
      if (!jugadorSeleccionado.contact_1_id)
        jugadorSeleccionado.contact_1_id = await AddCMSPerson(
          jugadorSeleccionado.contact_1,
          token
        );
      else
        await UpdateCMSPerson(
          jugadorSeleccionado.contact_1,
          jugadorSeleccionado.contact_1_id,
          token
        );
    } else {
      if (jugadorSeleccionado.contact_1_id) {
        await DeleteCMSPerson(jugadorSeleccionado.contact_1_id, token);
        jugadorSeleccionado.contact_1_id = 0;
      }
    }

    if (jugadorSeleccionado.contact_2) {
      if (!jugadorSeleccionado.contact_2_id)
        jugadorSeleccionado.contact_2_id = await AddCMSPerson(
          jugadorSeleccionado.contact_2,
          token
        );
      else
        await UpdateCMSPerson(
          jugadorSeleccionado.contact_2,
          jugadorSeleccionado.contact_2_id,
          token
        );
    } else {
      if (jugadorSeleccionado.contact_2_id) {
        await DeleteCMSPerson(jugadorSeleccionado.contact_2_id, token);
        jugadorSeleccionado.contact_2_id = 0;
      }
    }

    if (jugadorSeleccionado.contact_3) {
      if (!jugadorSeleccionado.contact_3_id)
        jugadorSeleccionado.contact_3_id = await AddCMSPerson(
          jugadorSeleccionado.contact_3,
          token
        );
      else
        await UpdateCMSPerson(
          jugadorSeleccionado.contact_3,
          jugadorSeleccionado.contact_3_id,
          token
        );
    } else {
      if (jugadorSeleccionado.contact_3_id) {
        await DeleteCMSPerson(jugadorSeleccionado.contact_3_id, token);
        jugadorSeleccionado.contact_3_id = 0;
      }
    }

    if (jugadorSeleccionado.school_contact) {
      if (!jugadorSeleccionado.school_contact_id)
        jugadorSeleccionado.school_contact_id = await AddCMSPerson(
          jugadorSeleccionado.school_contact,
          token
        );
      else
        await UpdateCMSPerson(
          jugadorSeleccionado.school_contact,
          jugadorSeleccionado.school_contact_id,
          token
        );
    }
    if (jugadorSeleccionado.agent) {
      if (!jugadorSeleccionado.agent_id)
        jugadorSeleccionado.agent_id = await AddCMSPerson(
          jugadorSeleccionado.agent,
          token
        );
      else
        await UpdateCMSPerson(
          jugadorSeleccionado.agent,
          jugadorSeleccionado.agent_id,
          token
        );
    }
  }

  async function actualizarJugador() {
    if (!jugadorSeleccionado || !token) return;
    
    const evaluacionAux = Object.assign({}, jugadorSeleccionado.evaluations[0]);

    setCargandoDatos(true);
    let jugAux: PlayerData = Object.assign({}, jugadorSeleccionado);

    jugAux.evaluations = [];
    //Si no está creado en base de datos, lo creamos y guardamos el ID
    if (!jugAux.id) {
      const playerID = await crearJugador(jugAux);
      if (playerID === -1) return;
      jugAux.id = playerID;
      jugadorSeleccionado.id = playerID;
    }

    //Si no hay un usuario creado pero hay datos de usuario, creamos el usuario
    if (
      (jugadorSeleccionado.user === undefined ||
        jugadorSeleccionado.user === null) &&
      jugadorSeleccionado.userEmail !== "" &&
      jugadorSeleccionado.userEmail !== undefined &&
      jugadorSeleccionado.userName !== "" &&
      jugadorSeleccionado.userName !== undefined &&
      jugadorSeleccionado.userPass !== "" &&
      jugadorSeleccionado.userPass !== undefined
    )
      await CreateUserPlayer(jugadorSeleccionado, token);

    if (imagen) {
      const imageID = await AddCMSFile(imagen, generateUniqueString(), token);
      await DeleteCMSFile(jugadorSeleccionado.image_id, token);

      jugAux.image_id = imageID;
    }

    await updatePersonas(token);
    jugAux.contact_1_id = jugadorSeleccionado.contact_1_id;
    jugAux.contact_2_id = jugadorSeleccionado.contact_2_id;
    jugAux.contact_3_id = jugadorSeleccionado.contact_3_id;
    jugAux.school_contact_id = jugadorSeleccionado.school_contact_id;
    jugAux.agent_id = jugadorSeleccionado.agent_id;
    
    jugAux.contact_1_type = jugadorSeleccionado.contact_1_type;
    jugAux.contact_2_type = jugadorSeleccionado.contact_2_type;
    jugAux.contact_3_type = jugadorSeleccionado.contact_3_type;
  
    await UpdateCMSPlayers(jugAux, token);

    jugAux = Object.assign({}, jugadorSeleccionado);
    
    jugAux.evaluations[0].reports = [];
    jugAux.evaluations[0].ipis = [];
    jugAux.evaluations[0].injuries = [];

    for (let i = 0; i < jugAux.evaluations.length; i++) {
      const evaluation = jugAux.evaluations[i];
      
      evaluation.player_id = jugadorSeleccionado.id;
      if (!evaluation.id) {
        const newEvaluationID = await AddCMSEvaluations(evaluation, token);
        evaluation.id = newEvaluationID;
      }
      evaluation.ipis = Object.assign([], evaluacionAux.ipis);
      evaluation.injuries = Object.assign([], evaluacionAux.injuries);

      await UpdateCMSEvaluations(evaluation, token);

      evaluation.reports = Object.assign([], evaluacionAux.reports);

      for (let j = 0; j < evaluation.reports.length; j++) {
        const report = evaluation.reports[j];
        const dataAux = evaluacionAux.reports[j].data;
        report.data = [];

        report.evaluation_id = evaluation.id;
        if (!report.id || report.id === -1) {
          const newReportID = await AddCMSReports(report as Report, token);
          report.id = newReportID;
        }

        dataAux.forEach((dataIndex) => {
          dataIndex.report_id = report.id;
        });

        report.data = dataAux;
        await UpdateCMSReports(report, token);
      }
    }

    setImagen(undefined);
    setListaJugadores(await fetchData());
    setCargandoDatos(false);
    setJugadorSeleccionado(undefined);
  }

  async function crearJugador(player: PlayerData) {
    if (!token) return -1;

    return await AddCMSPlayers(player, token);
  }

  function marcarClub(index: number) {
    if (!jugadoresMarcados.includes(index)) {
      jugadoresMarcados.push(index);
      setJugadoresMarcados(jugadoresMarcados.map((c) => c));
    } else {
      console.log("Intentando marcar un jugador ya marcado");
    }
  }

  function desmarcarClub(index: number) {
    if (jugadoresMarcados.includes(index)) {
      jugadoresMarcados.splice(
        jugadoresMarcados.findIndex((e) => e === index),
        1
      );
      setJugadoresMarcados(jugadoresMarcados.map((c) => c));
    } else {
      console.log("Intentando desmarcar un jugador no marcado");
    }
  }

  function updateFiltro(busqueda: string) {
    let players = GetPlayers();
    if (!players) return;

    let filteredPlayers = players.filter((p) =>
      p.name.toLowerCase().includes(busqueda.toLowerCase())
    );
    setListaJugadores(filteredPlayers);
  }

  /*     OLD DATA     */
  const [imagen, setImagen] = useState<File>();

  function generateUniqueString() {
    var ts = String(new Date().getTime()),
      i = 0,
      out = "";

    for (i = 0; i < ts.length; i += 2) {
      out += Number(ts.substr(i, 2)).toString(36);
    }

    return ts + "-" + out;
  }

  const uploadImage = (image: File | undefined) => {
    setImagen(image);
  };

  return (
    <React.Fragment>
      <div className="flex flex-col h-full z-0">
        <Topbar
          visibleButtons={editable}
          marked={jugadoresMarcados.length}
          selected={jugadorSeleccionado}
          createText={t("player.info.new")}
          onBack={() => props.history.goBack()}
          onFilter={(text: string) => updateFiltro(text)}
          disabled={!validPlayer}
          onCreate={() =>
            props.history.push({
              pathname: props.location.pathname + "/create",
              state: {
                data: Object.assign({
                  evaluations: [
                    Object.assign({
                      injuries: [] as Injurie[],
                      ipis: [] as Ipi[],
                      reports: [] as Report[],
                    } as Evaluation),
                  ],
                } as PlayerData),
              },
            })
          }
          onDelete={() => borrarJugadores()}
          onUpdate={() => {
            actualizarJugador();
            props.history.goBack();
          }}
        />
        <div
          className={
            "w-full flex-1 overflow-auto" +
            (jugadorSeleccionado ? " bg-f2f2f2 mt-8" : "")
          }
        >
          {jugadorSeleccionado ? (
            <PlayerDetail
              selected={jugadorSeleccionado}
              uploadImage={uploadImage}
              image={imagen}
              validForm={(e) => setValidPlayer(e)}
            />
          ) : (
            <div className="mx-auto flex flex-col w-full h-full">
              <div className="w-full font-bold text-white pb-2 shadow-md border border-solid border-b-1 border-t-0 border-l-0 border-r-0 border-gray-500">
                <div className="w-full xl:w-3/4 flex flex-row mx-auto">
                  <div className="w-1/12 self-center"></div>
                  <div className="w-3/12 self-center text-center">
                    {t("player.info.name")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("player.info.position")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("player.info.status")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("player.info.team")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("player.info.projection")}
                  </div>
                </div>
              </div>
              <div className="bg-f2f2f2 flex-1">
                {listaJugadores && !cargandoDatos ? (
                  listaJugadores.length > 0 ? (
                    listaJugadores.map((player: PlayerData, i: number) => {
                      //checkboxSelectedClubs[club.id] = false;
                      return (
                        <PlayerItem
                          key={i}
                          onClick={() => {
                            props.history.push({
                              pathname: props.location.pathname + "/detail",
                              state: {
                                data: Object.assign({}, player),
                              },
                            });
                            setJugadoresMarcados([]);
                          }}
                          onCheck={(e) => {
                            if (e.target.checked) {
                              marcarClub(player.id);
                            } else {
                              desmarcarClub(player.id);
                            }
                          }}
                          datos={player}
                          index={i}
                        />
                      );
                    })
                  ) : (
                    <div className="flex h-full w-full">
                      <div className="m-auto">{t("29082021.no_content")}</div>
                    </div>
                  )
                ) : (
                  <div className="flex h-full w-full">
                    <div className="m-auto flex flex-col">
                      <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
                      <p>{t("29082021.loading.generic")}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Players;
