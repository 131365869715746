import { ClubData, cmsBaseUrl, UserData } from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsAuth: string = cmsApiUrl + "/auth";
const cmsLogin: string = cmsAuth + "/login";
const cmsUser: string = cmsAuth + "/user";
const resetPass : string = cmsAuth +"/requestPasswordReset";
const changePassword : string = cmsAuth +"/changePassword";
//const cmsClubUrl: string = cmsBaseUrl + "/club";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let clubsList: ClubData[] = [];

async function CMSLogin(username: string, password: string) {
  //Lanzamos la petición
  let response = await fetch(cmsLogin, {
    method: "POST",
    body: JSON.stringify({ email: username, password: password }),

    headers: {
      Origin: "*",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });

  if (response.ok === false) return undefined;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result;
  } catch (error) {
    return undefined;
  }
}

export const LoginCMS = CMSLogin;

async function GetUserFromCMS(
  token: string | null
): Promise<UserData | undefined> {
  try {
    //Lanzamos la petición
    let response = await fetch(cmsUser, {
      method: "GET",
      headers: {
        Authorization: token ?? "",
      },
    });

    if (response.ok === false) return undefined;

    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (!result) return undefined;
    return result;
  } catch (error) {
    return undefined;
  }
}

export const GetCMSUsers: (
  token: string | null
) => Promise<UserData | undefined> = GetUserFromCMS;

async function setFavExperience(
  token: string | null,
  id: number
): Promise<UserData | undefined> {
  try {
    //Lanzamos la petición
    let response = await fetch(cmsAuth + "/changeFavExperience", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        Authorization: token ?? "",
        "Content-Type": "application/json",
      },
    });

    if (response.ok === false) return undefined;

    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (!result) return undefined;
    return result;
  } catch (error) {
    return undefined;
  }
}

export const SetFavExperience: (
  token: string | null,
  id: number
) => Promise<UserData | undefined> = setFavExperience;

async function CMSResetPass(email: string) {
  //Lanzamos la petición
  let response = await fetch(resetPass, {
    method: "POST",
    body: JSON.stringify({ userEmail: email }),

    headers: {
      Origin: "*",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });

  if (response.ok === false) return undefined;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result;
  } catch (error) {
    return undefined;
  }
}

export const ResetPassCMS = CMSResetPass;

async function changePassCMS(
  token: string | null,
  email: string,
  oldPassword: string,
  newPassword: string
): Promise<any> {
  try {

    let obj = { oldPassword: oldPassword, newPassword:newPassword } as any;
    if(email !== "")obj.userEmail = email;
    //Lanzamos la petición
    let response = await fetch(changePassword, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        Authorization: token ?? "",
        "Content-Type": "application/json",
      },
    });

    if (response.ok === false) return undefined;

    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (!result) return undefined;
    return result;
  } catch (error) {
    return undefined;
  }
}

export const CMSChangePass: (
  token: string | null,
  email: string,
  oldPassword: string,
  newPassword: string
) => Promise<any> = changePassCMS;
