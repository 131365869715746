import { cmsBaseUrl, PlayerData } from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsPlayersUrl: string = cmsApiUrl + "/watchedPlayer";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let watchedPlayerList: PlayerData[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetWatchedPlayersFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsPlayersUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      watchedPlayerList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSWatchedPlayers: (token: string) => Promise<boolean> =
  GetWatchedPlayersFromCMS;

async function RemoveWatchedPlayersFromCMS(
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsPlayersUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSWatchedPlayers: (
  id: number,
  token: string
) => Promise<boolean> = RemoveWatchedPlayersFromCMS;

async function InsertWatchedPlayersFromCMS(
  player: PlayerData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsPlayersUrl, {
    method: "POST",
    body: JSON.stringify({ playerData: player }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSWatchedPlayers: (
  player: PlayerData,
  token: string
) => Promise<number> = InsertWatchedPlayersFromCMS;

async function ModifyWatchedPlayersFromCMS(
  player: PlayerData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsPlayersUrl, {
    method: "PUT",
    body: JSON.stringify({ playerData: player, id: player.id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const UpdateCMSWatchedPlayers: (
  player: PlayerData,
  token: string
) => Promise<number> = ModifyWatchedPlayersFromCMS;

function getWatchedPlayers(): PlayerData[] | undefined {
  if (watchedPlayerList === undefined) return undefined;

  return watchedPlayerList;
}
export const GetWatchedPlayers: () => PlayerData[] | undefined =
  getWatchedPlayers;
