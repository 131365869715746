import React, { useEffect, useState } from "react";
import {
  AddCMSTeams,
  DeleteCMSTeams,
  GetTeams,
  GetTeamsByClubID,
  LoadCMSTeams,
  UpdateCMSTeams,
} from "../CMSUtils/TeamCalls";
import useStore from "../store";
import Topbar from "../components/Topbar";
import { TeamData } from "../CMSUtils/Icms";
import { AddCMSFile, DeleteCMSFile } from "../CMSUtils/FileCalls";
import { TeamDetail } from "../components/Team/TeamDetail";
import TeamItem from "../components/Team/TeamItem";
import { useTranslation } from "react-i18next";
import loadingGif from "../images/loading.gif";

const Teams = (props: any) => {
  const { t } = useTranslation();
  const filteredClub = useStore((state) => state.filteredClub);
  const token = useStore((state) => state.token);
  const [listaEquipos, setListaEquipos] = useState<TeamData[]>();
  const [cargandoDatos, setCargandoDatos] = useState(false);
  const [equipoSeleccionado, setEquipoSeleccionado] = useState<TeamData>();
  const [equiposMarcados, setEquiposMarcados] = useState<number[]>([]);
  const [validPlayer, setValidPlayer] = useState(true);
  const loggedUser = useStore((state) => state.loggedUser);
  const editable =
    loggedUser?.role.name === "Admin" ||
    loggedUser?.role.name === "Club" ||
    loggedUser?.role.name === "Coordinator";

  useEffect(() => {
    let isMounted=true;
    setEquipoSeleccionado(
      props.location.state ? props.location?.state.data : undefined
    );
    if (!cargandoDatos) fetchData().then(t => {if(isMounted)setListaEquipos(t);});

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state?.data]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSTeams(token);

    let teams = filteredClub ? GetTeamsByClubID(filteredClub) : GetTeams();
    if (!teams) teams = [];
    return teams;
    //setListaEquipos(teams);
  }

  async function borrarEquipos() {
    if (!token) return;
    setCargandoDatos(true);

    if (equipoSeleccionado) {
      await DeleteCMSTeams(equipoSeleccionado.id, token);
      setListaEquipos(await fetchData());

      setEquipoSeleccionado(undefined);
      props.history.push("/equipos");
    } else {
      if (equiposMarcados.length > 0) {
        for (let i = 0; i < equiposMarcados.length; i++) {
          const teamID = equiposMarcados[i];
          await DeleteCMSTeams(teamID, token);
        }

        setListaEquipos(await fetchData());
      }
    }
    setEquiposMarcados([]);

    setCargandoDatos(false);
  }

  async function actualizarEquipo() {
    if (!equipoSeleccionado || !token) return;

    setCargandoDatos(true);

    //Si no está creado en base de datos, lo creamos y guardamos el ID
    if (!equipoSeleccionado.id) {
      const teamID = await crearEquipo(equipoSeleccionado);
      if (teamID === -1) return;
      equipoSeleccionado.id = teamID;
    }

    if (imagen) {
      const imageID = await AddCMSFile(imagen, generateUniqueString(), token);
      await DeleteCMSFile(equipoSeleccionado.team_image_id, token);

      equipoSeleccionado.team_image_id = imageID;
    }

    await UpdateCMSTeams(equipoSeleccionado, token);
    setListaEquipos(await fetchData());

    setImagen(undefined);
    setCargandoDatos(false);
    setEquipoSeleccionado(undefined);
  }

  async function crearEquipo(team: TeamData) {
    if (!token) return -1;

    let equipoAux = Object.assign({}, team);
    equipoAux.staffs = [];
    equipoAux.experiences = [];

    return await AddCMSTeams(equipoAux, token);
  }

  function marcarClub(index: number) {
    if (!equiposMarcados.includes(index)) {
      equiposMarcados.push(index);
      setEquiposMarcados(equiposMarcados.map((c) => c));
    } else {
      console.log("Intentando marcar un equipo ya marcado");
    }
  }

  function desmarcarClub(index: number) {
    if (equiposMarcados.includes(index)) {
      equiposMarcados.splice(
        equiposMarcados.findIndex((e) => e === index),
        1
      );
      setEquiposMarcados(equiposMarcados.map((c) => c));
    } else {
      console.log("Intentando desmarcar un equipo no marcado");
    }
  }

  function updateFiltro(busqueda: string) {
    let teams = GetTeams();
    if (!teams) return;

    let filteredTeams = teams.filter((t) =>
      t.team_name.toLowerCase().includes(busqueda.toLowerCase())
    );
    setListaEquipos(filteredTeams);
  }

  /*     OLD DATA     */
  const [imagen, setImagen] = useState<File>();

  function generateUniqueString() {
    var ts = String(new Date().getTime()),
      i = 0,
      out = "";

    for (i = 0; i < ts.length; i += 2) {
      out += Number(ts.substr(i, 2)).toString(36);
    }

    return ts + "-" + out;
  }

  const uploadImage = (image: File | undefined) => {
    setImagen(image);
  };

  return (
    <React.Fragment>
      <div className="flex flex-col h-full z-0">
        <Topbar
          visibleButtons={editable}
          marked={equiposMarcados.length}
          selected={equipoSeleccionado}
          createText={t("team.info.new")}
          onBack={() => props.history.goBack()}
          onFilter={(text: string) => updateFiltro(text)}
          onCreate={() => {
            props.history.push({
              pathname: props.location.pathname + "/create",
              state: {
                data: Object.assign({
                  staffs: [{ role: "Entrenador" } as any],
                } as TeamData),
              },
            });
            setValidPlayer(false);
          }}
          disabled={!validPlayer}
          onDelete={() => borrarEquipos()}
          onUpdate={() => {
            actualizarEquipo();
            props.history.goBack();
          }}
        />
        <div
          className={
            "w-full flex-1 overflow-auto" +
            (equipoSeleccionado ? " bg-f2f2f2 mt-8" : "")
          }
        >
          {equipoSeleccionado ? (
            <TeamDetail
              selected={equipoSeleccionado}
              validForm={(e) => setValidPlayer(e)}
              uploadImage={uploadImage}
              image={imagen}
              props={props}
            />
          ) : (
            <div className="mx-auto flex flex-col w-full h-full">
              <div className="w-full font-bold text-white pb-2 shadow-md border border-solid border-b-1 border-t-0 border-l-0 border-r-0 border-gray-500">
                <div className="w-full xl:w-3/4 flex flex-row mx-auto">
                  <div className="w-1/12 self-center"></div>
                  <div className="w-3/12 self-center text-center">
                    {t("team.info.team")}
                  </div>
                  <div className="w-1/12 self-center text-center">
                    {t("team.info.category")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("team.info.club")}
                  </div>
                  <div className="w-3/12 self-center text-center">
                    {t("team.info.trainer")}
                  </div>
                  <div className="w-2/12 self-center text-center">
                    {t("team.info.season")}
                  </div>
                </div>
              </div>
              <div className="bg-f2f2f2 flex-1">
                {listaEquipos && !cargandoDatos ? (
                  listaEquipos.length > 0 ? (
                    listaEquipos.map((equipo: TeamData, i: number) => {
                      //checkboxSelectedClubs[club.id] = false;
                      return (
                        <TeamItem
                          key={i}
                          onClick={() => {
                            //setEquipoSeleccionado(Object.assign({}, equipo));
                            setEquiposMarcados([]);
                            props.history.push({
                              pathname: props.location.pathname + "/detail",
                              state: {
                                data: Object.assign({}, equipo),
                              },
                            });
                          }}
                          onCheck={(e) => {
                            if (e.target.checked) {
                              marcarClub(equipo.id);
                            } else {
                              desmarcarClub(equipo.id);
                            }
                          }}
                          datos={equipo}
                          index={i}
                        />
                      );
                    })
                  ) : (
                    <div className="flex h-full w-full">
                      <div className="m-auto">{t("29082021.no_content")}</div>
                    </div>
                  )
                ) : (
                  <div className="flex h-full w-full">
                    <div className="m-auto flex flex-col">
                      <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
                      <p>{t("29082021.loading.generic")}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Teams;
