import { cmsBaseUrl, Event } from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsEventsUrl: string = cmsApiUrl + "/event";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let eventList: Event[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetEventsFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsEventsUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      eventList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSEvents: (token: string) => Promise<boolean> =
  GetEventsFromCMS;

async function RemoveEventsFromCMS(
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsEventsUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSEvents: (id: number, token: string) => Promise<boolean> =
  RemoveEventsFromCMS;

async function InsertEventsFromCMS(event: any, token: string): Promise<number> {
  //Lanzamos la petición
  event.content = JSON.stringify(event.content);
  let response = await fetch(cmsEventsUrl, {
    method: "POST",
    body: JSON.stringify({ eventData: event }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSEvents: (event: Event, token: string) => Promise<number> =
  InsertEventsFromCMS;

async function ModifyEventsFromCMS(
  event: Event,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsEventsUrl, {
    method: "PUT",
    body: JSON.stringify({ eventData: event, id: event.id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const UpdateCMSEvents: (event: Event, token: string) => Promise<number> =
  ModifyEventsFromCMS;

function getEvents(): Event[] | undefined {
  if (eventList === undefined) return undefined;

  return eventList.filter((e) => e.type === "event");
}
export const GetEvents: () => Event[] | undefined = getEvents;

function getWeeklyObj(): Event[] | undefined {
  if (eventList === undefined) return undefined;

  return eventList.filter((e) => e.type === "obj");
}
export const GetWeeklyObj: () => Event[] | undefined = getWeeklyObj;

function getAllEvents(): Event[] | undefined {
  if (eventList === undefined) return undefined;

  return eventList;
}
export const GetAllEvents: () => Event[] | undefined = getAllEvents;
