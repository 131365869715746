import React from "react";
//import { useTranslation } from "react-i18next";

const Tutorial = (props: any) => {
  //const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="flex flex-col h-full z-0 pt-20">
        <div className="bg-white w-full p-12 flex-1 overflow-auto">
          {"Texto de tutorial"}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Tutorial;
