export const positions = [
  { value: "P", text: "14052021.fields.portero" },
  { value: "LD", text: "14052021.fields.lateral_derecho" },
  { value: "LI", text: "14052021.fields.lateral_izquierdo" },
  { value: "DFC", text: "14052021.fields.central" },
  { value: "DFCD", text: "14052021.fields.central_derecho" },
  { value: "DFCI", text: "14052021.fields.central_izquierdo" },
  { value: "CAD", text: "14052021.fields.carrilero_derecho" },
  { value: "CAI", text: "14052021.fields.carrilero_izquierdo" },
  { value: "MCD", text: "14052021.fields.mediocentro_defensivo" },
  { value: "MC", text: "14052021.fields.mediocentro" },
  { value: "MCO", text: "14052021.fields.mediocentro_ofensivo" },
  { value: "MD", text: "14052021.fields.medio_derecho" },
  { value: "MI", text: "14052021.fields.medio_izquierdo" },
  { value: "ED", text: "14052021.fields.extremo_derecho" },
  { value: "EI", text: "14052021.fields.extremo_izquierdo" },
  { value: "DC", text: "14052021.fields.delantero_centro" },
];

export const staff_titles = [
  { value: "Trainer", text: "14052021.fields.trainer" },
  { value: "SecondTrainer", text: "14052021.fields.second_trainer" },
  { value: "GoalkeeperTrainer", text: "14052021.fields.goalkeeper_trainer" },
  { value: "BiologicTrainer", text: "14052021.fields.biologic_trainer" },
  { value: "Fisio", text: "14052021.fields.fisio" },
  { value: "Scouter", text: "14052021.fields.scouter" },
  { value: "Analyst", text: "14052021.fields.analyst" },
  { value: "Del", text: "14052021.fields.del" },
  { value: "Psico", text: "14052021.fields.psico" },
  { value: "Read", text: "14052021.fields.read" },
  { value: "Medi", text: "14052021.fields.medi" },
  { value: "Utill", text: "14052021.fields.utill" },
  { value: "Coordinator", text: "14052021.fields.coordinator" },
];

export const disponibilidad = [
  { value: "disp", text: "14052021.fields.disponible" },
  { value: "les", text: "14052021.fields.lesionado" },
  { value: "enf", text: "14052021.fields.enfermo" },
  { value: "selecc", text: "14052021.fields.seleccion" },
  { value: "other", text: "14052021.fields.otros" },
];

export const riesgo = [
  { value: "low", text: "14052021.fields.bajo" },
  { value: "medium", text: "14052021.fields.medio" },
  { value: "high", text: "14052021.fields.alto" },
];

export const audiovisualCategories = [
  {
    category: "",
    subcategories: [
      {
        value: "EA.O1",
        code: "EA.O1",
        text: "14052021.categorias.eao1",
      },
    ],
  },
  {
    category: "14052021.categorias.eao2",
    subcategories: [
      { value: "O2.1", code: "EA.O2.1", text: "14052021.categorias.eao21" },
      {
        value: "O2.2",
        code: "EA.O2.2",
        text: "14052021.categorias.eao22",
      },
      {
        value: "O2.3",
        code: "EA.O2.3",
        text: "14052021.categorias.eao23",
      },
    ],
  },
  {
    category: "14052021.categorias.eao3",
    subcategories: [
      { value: "O3.1", code: "EA.O3.1", text: "14052021.categorias.eao31" },
      {
        value: "O3.2",
        code: "EA.O3.2",
        text: "14052021.categorias.eao32",
      },
    ],
  },
  {
    category: "",
    subcategories: [
      {
        value: "EA.O4",
        code: "EA.O4",
        text: "ABP",
      },
    ],
  },
  {
    category: "",
    subcategories: [
      { value: "EA.O5", code: "EA.O5", text: "14052021.categorias.eao5" },
    ],
  },
  {
    category: "",
    subcategories: [
      {
        value: "EA.D1",
        code: "EA.D1",
        text: "14052021.categorias.ead1",
      },
    ],
  },
  {
    category: "14052021.categorias.ead2",
    subcategories: [
      { value: "D2.1", code: "EA.D2.1", text: "14052021.categorias.ead21" },
      {
        value: "D2.2",
        code: "EA.D2.2",
        text: "14052021.categorias.ead22",
      },
      {
        value: "D2.3",
        code: "EA.D2.3",
        text: "14052021.categorias.ead23",
      },
    ],
  },
  {
    category: "14052021.categorias.ead3",
    subcategories: [
      { value: "D3.1", code: "EA.D3.1", text: "14052021.categorias.ead31" },
      {
        value: "D3.2",
        code: "EA.D3.2",
        text: "14052021.categorias.ead32",
      },
    ],
  },
  {
    category: "",
    subcategories: [
      {
        value: "EA.D4",
        code: "EA.D4",
        text: "ABP",
      },
    ],
  },
  {
    category: "",
    subcategories: [
      { value: "EA.D5", code: "EA.D5", text: "14052021.categorias.ead5" },
    ],
  },
];
export const bioCategories = [
  {
    category: "14052021.categorias.eb1",
    subcategories: [
      { value: "1.1", code: "EB 1.1", text: "14052021.categorias.eb11" },
      {
        value: "1.2",
        code: "EB 1.2",
        text: "14052021.categorias.eb12",
      },
      {
        value: "1.3",
        code: "EB 1.3",
        text: "14052021.categorias.eb13",
      },
      {
        value: "1.4",
        code: "EB 1.4",
        text: "14052021.categorias.eb14",
      },
    ],
  },
  {
    category: "14052021.categorias.eb2",
    subcategories: [
      { value: "2.1", code: "EB 2.1", text: "14052021.categorias.eb21" },
      {
        value: "2.2",
        code: "EB 2.2",
        text: "14052021.categorias.eb22",
      },
      { value: "2.3", code: "EB 2.3", text: "14052021.categorias.eb23" },
    ],
  },
  {
    category: "14052021.categorias.eb3",
    subcategories: [
      { value: "3.1", code: "EB 3.1", text: "14052021.categorias.eb31" },
      {
        value: "3.2",
        code: "EB 3.2",
        text: "14052021.categorias.eb32",
      },
      { value: "3.3", code: "EB 3.3", text: "14052021.categorias.eb33" },
    ],
  },
  {
    category: "14052021.categorias.eb4",
    subcategories: [
      { value: "4.1", code: "EB 4.1", text: "14052021.categorias.eb41" },
      {
        value: "4.2",
        code: "EB 4.2",
        text: "14052021.categorias.eb42",
      },
    ],
  },
];
export const goalkeeperCategories = [
  {
    category: "1",
    subcategories: [
      { value: "1.1", code: "E 1.1", text: "1.1" },
      {
        value: "1.2",
        code: "E 1.2",
        text: "1.2",
      },
      { value: "1.3", code: "E 1.3", text: "1.3" },
      {
        value: "1.4",
        code: "E 1.4",
        text: "1.4",
      },
    ],
  },
  {
    category: "2",
    subcategories: [
      { value: "2.1", code: "E 2.1", text: "2.1" },
      {
        value: "2.2",
        code: "E 2.2",
        text: "2.2",
      },
      { value: "2.3", code: "E 2.3", text: "2.3" },
      {
        value: "2.4",
        code: "E 2.4",
        text: "2.4",
      },
    ],
  },
];
export const globalCategories = [
  {
    category: "",
    subcategories: [
      {
        value: "activacion",
        code: "EA",
        text: "14052021.categorias.activacion",
      },
      {
        value: "iniciacion",
        code: "EI",
        text: "14052021.categorias.iniciacion",
      },
    ],
  },
  {
    category: "14052021.categorias.e1",
    subcategories: [
      { value: "cuad_cons", code: "E 1.1", text: "14052021.categorias.e11" },
      {
        value: "cuad_cons_trans",
        code: "E 1.2",
        text: "14052021.categorias.e12",
      },
      { value: "cuad_dir", code: "E 1.3", text: "14052021.categorias.e13" },
      {
        value: "cuad_dir_trans",
        code: "E 1.4",
        text: "14052021.categorias.e14",
      },
    ],
  },
  {
    category: "14052021.categorias.e2",
    subcategories: [
      {
        value: "jue_cons",
        code: "E 2.1",
        text: "14052021.categorias.e21",
      },
      {
        value: "jue_dir",
        code: "E 2.2",
        text: "14052021.categorias.e22",
      },
      {
        value: "jue_dir_est",
        code: "E 2.3",
        text: "14052021.categorias.e23",
      },
    ],
  },
  {
    category: "14052021.categorias.e3",
    subcategories: [
      {
        value: "sit_fin",
        code: "E 3.1",
        text: "14052021.categorias.e31",
      },
      {
        value: "sit_fin_est",
        code: "E 3.2",
        text: "14052021.categorias.e32",
      },
    ],
  },
  {
    category: "14052021.categorias.e4",
    subcategories: [
      {
        value: "jue_real",
        code: "E 4.1",
        text: "14052021.categorias.e41",
      },
      {
        value: "part_cond",
        code: "E 4.2",
        text: "14052021.categorias.e42",
      },
    ],
  },
  {
    category: "14052021.categorias.abp",
    subcategories: [
      { value: "faltas", code: "", text: "14052021.categorias.faltas" },
      {
        value: "corners",
        text: "14052021.categorias.corners",
      },
      { value: "saques", code: "", text: "14052021.categorias.saques" },
    ],
  },
];
export const groups = [
  "G",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
