import { cmsBaseUrl, ExperienceData} from "./Icms";

//URL del CMS

const cmsApiUrl: string = cmsBaseUrl + "/api";
const cmsExperiencesUrl: string = cmsApiUrl + "/experience";

//Datos que hemos descargado (privado, hay funciones para obtener los datos necesarios)
export let experiencesList: ExperienceData[] = [];

/*    CLUBS     */
//Función que descarga los datos de la API
async function GetExperiencesFromCMS(token: string): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsExperiencesUrl, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    let result = await response.json();

    //Almacenamos los clubs
    if (result) {
      experiencesList = result;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export const LoadCMSExperiences: (token: string) => Promise<boolean> =
  GetExperiencesFromCMS;

async function RemoveExperiencesFromCMS(
  id: number,
  token: string
): Promise<boolean> {
  //Lanzamos la petición
  let response = await fetch(cmsExperiencesUrl + "?id=" + id, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });

  if (response.ok === false) return false;

  try {
    //Cogemos los datos
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}

export const DeleteCMSExperiences: (
  id: number,
  token: string
) => Promise<boolean> = RemoveExperiencesFromCMS;

async function InsertExperiencesFromCMS(
  player: ExperienceData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsExperiencesUrl, {
    method: "POST",
    body: JSON.stringify({ experienceData: player }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const AddCMSExperiences: (
  player: ExperienceData,
  token: string
) => Promise<number> = InsertExperiencesFromCMS;

async function ModifyExperiencesFromCMS(
  player: ExperienceData,
  token: string
): Promise<number> {
  //Lanzamos la petición
  let response = await fetch(cmsExperiencesUrl, {
    method: "PUT",
    body: JSON.stringify({ experienceData: player, id: player.id }),
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  if (response.ok === false) return -1;

  try {
    //Cogemos los datos
    let result = await response.json();
    return result.id;
  } catch (error) {
    return -1;
  }
}

export const UpdateCMSExperiences: (
  player: ExperienceData,
  token: string
) => Promise<number> = ModifyExperiencesFromCMS;

function getExperiences(): ExperienceData[] | undefined {
  if (experiencesList === undefined) return undefined;

  return experiencesList;
}
export const GetExperiences: () => ExperienceData[] | undefined =
  getExperiences;

function getGlobalExperiences(): ExperienceData[] | undefined {
  if (experiencesList === undefined) return undefined;

  return experiencesList.filter((e) => e.type === "general");
}
export const GetGlobalExperiences: () => ExperienceData[] | undefined =
  getGlobalExperiences;

function getGoalkeeperExperiences(): ExperienceData[] | undefined {
  if (experiencesList === undefined) return undefined;

  return experiencesList.filter((e) => e.type === "goalkeeper");
}
export const GetGoalkeeperExperiences: () => ExperienceData[] | undefined =
  getGoalkeeperExperiences;

function getBioExperiences(): ExperienceData[] | undefined {
  if (experiencesList === undefined) return undefined;

  return experiencesList.filter((e) => e.type === "bio");
}
export const GetBioExperiences: () => ExperienceData[] | undefined =
  getBioExperiences;

function getAudiovisualExperiences(): ExperienceData[] | undefined {
  if (experiencesList === undefined) return undefined;

  return experiencesList.filter((e) => e.type === "audiovisual");
}
export const GetAudiovisualExperiences: () => ExperienceData[] | undefined =
  getAudiovisualExperiences;
