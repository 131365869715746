import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Topbar from "../components/Topbar";
import useStore from "../store";
import DataField from "../components/DataField";
import loadingGif from "../images/loading.gif";
import {
  AddCMSEvents,
  DeleteCMSEvents,
  GetEvents,
  LoadCMSEvents,
  UpdateCMSEvents,
} from "../CMSUtils/EventCalls";
import minus_icon from "../images/icons/papelera-icon.svg";
import {
  Event,
  ClubData,
  TeamData,
} from "../CMSUtils/Icms";
import { GetClubs, LoadCMSClubs } from "../CMSUtils/ClubCalls";
import { GetTeams, LoadCMSTeams } from "../CMSUtils/TeamCalls";
import { useTranslation } from "react-i18next";

const Events = (props: any) => {
  const { t, i18n } = useTranslation();
  const [listaEquipos, setListaEquipos] = useState<TeamData[]>();
  const [listaClubs, setListaClubs] = useState<ClubData[]>();

  const loggedUser = useStore((state) => state.loggedUser);
  const editable =
    loggedUser?.role.name === "Admin" ||
    loggedUser?.role.name === "Club" ||
    loggedUser?.role.name === "Coordinator" ||
    loggedUser?.role.name === "Trainer";
  const setModalContent = useStore((state) => state.setModalContent);
  const [evento, setEvento] = useState<Event | undefined>({
    content: { title: "", content: "" },
    type: "event",
  } as Event);
  const [listaEventos, setListaEventos] = useState<Event[]>();
  const token = useStore((state) => state.token);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    fetchData().then(e => {
      if(isMounted){
        setListaClubs(GetClubs() as ClubData[]);
        setListaEquipos(GetTeams() as TeamData[]);
        setListaEventos(e);
        setIsLoaded(true);
      }
    });

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function getClubNames() {
    if (!GetClubs()) return [];
    let clubs: any[] = [];
    GetClubs()?.map((c) => clubs.push({ value: c.id, text: c.entity_name }));
    return clubs;
  }

  async function fetchData() {
    if (!token) return;
    await LoadCMSEvents(token);
    await LoadCMSClubs(token);
    
    await LoadCMSTeams(token);
    
    let events = GetEvents();
    if (!events) events = [];
    return events;
    //setListaEventos(events);
    //setIsLoaded(true);
  }

  async function saveEvent() {
    if (!token || !evento) return;

    await AddCMSEvents(evento, token);
    setEvento({
      content: { title: "", content: "" },
      type: "event",
    } as Event);

    setListaEventos(await fetchData());
  }

  async function updateEvent(eventoActualizar: any) {
    if (!token) return;

    eventoActualizar.content = JSON.stringify(eventoActualizar.content);
    await UpdateCMSEvents(eventoActualizar, token);

    setListaEventos(await fetchData());
  }

  async function deleteEvent(id: number) {
    if (!token) return;

    await DeleteCMSEvents(id, token);

    setListaEventos(await fetchData());
  }

  function getEventos() {
    let eventos = listaEventos?.map((e: any, index) => {
      try {
        e.content = JSON.parse(e.content);
      } catch (e) {}

      return {
        title: e.content.title ?? "Sin título",
        start: e.from,
        id: index,
        end: e.to,
        color: "white",
        borderColor: "#0e7bc0",
        textColor: "#0e7bc0",
        display: "block",
      };
    });
    return eventos as unknown as [{ title: string; date: string }];
  }

  function crearEvento(ok: boolean, club_id?: number) {
    if (!evento) return;
    if (loggedUser?.role.name === "Club") {
      club_id = loggedUser?.club_id;
    }
    let clubid = club_id;
    let equipos = listaClubs
      ?.find((club) => club.id === club_id)
      ?.club_teams.map((t) => {
        return { value: t.id, text: t.team_name };
      });
    return (
      <React.Fragment>
        <form
          onSubmit={(e) => e.preventDefault()}
          onChange={(e) => {
            setModalContent(
              crearEvento(e.currentTarget.checkValidity(), clubid),
              t("14052021.event.title")
            );
          }}
        >
          {loggedUser?.role.name === "Admin" && (
            <div className="py-2">
              <DataField
                disabled={!editable}
                data={
                  club_id ||
                  listaEquipos?.find((e) => e.id?.toString() === evento.team_id?.toString())?.club_id
                }
                label={t("event.club")}
                type="select"
                options={getClubNames()}
                placeholder={t("event.club_placeholder")}
                onChange={(e) => {
                  let club = listaClubs?.find((club) => club.id?.toString() === e?.toString());
                  if (!club) return;
                  setListaEquipos(club.club_teams.map((t) => t));
                  evento.team_id = 0;
                  clubid = club.id;
                  setModalContent(
                    crearEvento(ok, clubid),
                    t("14052021.event.title")
                  );
                }}
              />
            </div>
          )}
          <div className="py-2">
            <DataField
              disabled={!editable || equipos === null || equipos === undefined || equipos?.length === 0}
              data={evento.team_id}
              label={t("event.team")}
              type="select"
              flexible
              options={equipos}
              placeholder={t("event.team_placeholder")}
              onChange={(e) => (evento.team_id = e)}
            />
          </div>
          <div className="py-2">
            <DataField
              data={evento.content.title}
              label={t("event.title")}
              onChange={(e) => (evento.content.title = e)}
              required
            />
          </div>
          <div className="py-2">
            <DataField
              data={evento.from}
              label={t("event.from")}
              type="date"
              onChange={(e) => (evento.from = e)}
              required
            />
          </div>
          <div className="py-2">
            <DataField
              data={evento.to}
              label={t("event.to")}
              type="date"
              onChange={(e) => (evento.to = e)}
              required
            />
          </div>
          <div className="py-2">
            <DataField
              data={evento.content.content}
              label={t("event.content")}
              type="textarea"
              onChange={(e) => (evento.content.content = e)}
              required
            />
          </div>
          <button
            className={
              "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 mt-3 rounded-full text-white mx-auto"
            }
            onClick={() => {
              saveEvent();
              setModalContent(undefined);
            }}
            disabled={!ok}
          >
            <p className="pl-2">{t("event.save")}</p>
          </button>
        </form>
      </React.Fragment>
    );
  }

  function modificarEvento(
    ok: boolean,
    eventoActualizar: Event,
    club_id?: number
  ) {
    if (loggedUser?.role.name === "Club") {
      club_id = loggedUser?.club_id;
    }
    let clubid = club_id;
    clubid = clubid
      ? clubid
      : GetTeams()?.find((t) => t.id === eventoActualizar.team_id)?.club_id;
    let equipos = listaClubs
      ?.find((club) => club.id === clubid)
      ?.club_teams.map((t) => {
        return { value: t.id, text: t.team_name };
      });
    return (
      <React.Fragment>
        <form
          onSubmit={(e) => e.preventDefault()}
          onChange={(e) => {
            setModalContent(
              modificarEvento(
                e.currentTarget.checkValidity(),
                eventoActualizar,
                clubid
              ),
              t("14052021.event.title")
            );
          }}
        >
          {loggedUser?.role.name === "Admin" && (
            <div className="py-2">
              <DataField
                disabled={!editable}
                data={
                  club_id ||
                  listaEquipos?.find((e) => e.id === eventoActualizar.team_id)
                    ?.club_id
                }
                label={t("event.club")}
                type="select"
                options={getClubNames()}
                placeholder={t("event.club_placeholder")}
                onChange={(e) => {
                  let club = listaClubs?.find((club) => club.id === e);
                  if (!club) return;
                  setListaEquipos(club.club_teams.map((t) => t));
                  eventoActualizar.team_id = 0;
                  clubid = club.id;
                  setModalContent(
                    modificarEvento(ok, eventoActualizar, clubid),
                    t("14052021.event.title")
                  );
                }}
              />
            </div>
          )}
          <div className="py-2">
            <DataField
              disabled={!editable}
              data={eventoActualizar.team_id}
              label={t("event.team")}
              type="select"
              options={equipos}
              placeholder={t("event.team_placeholder")}
              onChange={(e) => (eventoActualizar.team_id = e)}
            />
          </div>
          <div className="py-2">
            <DataField
              disabled={!editable}
              data={eventoActualizar.content.title}
              label={t("event.title")}
              onChange={(e) => (eventoActualizar.content.title = e)}
              required
            />
          </div>
          <div className="py-2">
            <DataField
              disabled={!editable}
              data={eventoActualizar.from}
              label={t("event.from")}
              type="date"
              onChange={(e) => (eventoActualizar.from = e)}
              required
            />
          </div>
          <div className="py-2">
            <DataField
              disabled={!editable}
              data={eventoActualizar.to}
              label={t("event.to")}
              type="date"
              onChange={(e) => (eventoActualizar.to = e)}
              required
            />
          </div>
          <div className="py-2">
            <DataField
              disabled={!editable}
              data={eventoActualizar.content.content}
              label={t("event.content")}
              type="textarea"
              onChange={(e) => (eventoActualizar.content.content = e)}
              required
            />
          </div>
          {editable && (
            <div className="flex">
              <button
                className={
                  "flex bg-sf-mediumblue disa disabled:bg-gray-300 hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
                }
                onClick={() => {
                  updateEvent(eventoActualizar);
                  setModalContent(undefined);
                }}
                disabled={!ok}
              >
                <p className="pl-2">{t("event.save")}</p>
              </button>
              <button
                className={"bg-red-500 h-8 w-8 px-1 rounded-full"}
                onClick={() => {
                  deleteEvent(eventoActualizar.id);
                  setModalContent(undefined);
                }}
              >
                <img
                  src={minus_icon}
                  alt="Borrar"
                  className="h-6 w-6"
                  style={{ filter: "invert()" }}
                />
              </button>
            </div>
          )}
        </form>
      </React.Fragment>
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <div className="flex flex-col h-full z-0">
        <Topbar
          visibleButtons={editable}
          createText={t("event.create")}
          onBack={() => props.history.goBack()}
          onCreate={(value) =>
            setModalContent(crearEvento(false), t("14052021.event.title"))
          }
        />
        <div className="mt-8 bg-white w-full p-12 flex-1 overflow-auto flex">
          <div className="w-full mx-auto" style={{ maxWidth: "1280px" }}>
            <FullCalendar
              plugins={[dayGridPlugin]}
              headerToolbar={{ end: "prev,next" }}
              initialView="dayGridMonth"
              firstDay={1}
              locale={i18n.language}
              events={getEventos()}
              eventClick={(info) => {
                if (!listaEventos) return;
                setModalContent(
                  modificarEvento(true, listaEventos[Number(info.event.id)]),
                  t("14052021.event.title")
                );
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="bg-f2f2f2 mt-20 flex flex-1">
          <div className="m-auto flex flex-col">
            <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
            <p>{t("29082021.loading.generic")}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Events;
