import { ReportData } from "../CMSUtils/Icms";

export interface Report {
  //Id del reporte. Lo almacenamos para poder acceder a él de forma fácil
  reportId: number;

  //Datos de juego
  gameData: ReportGame;

  //Datos de cuerpo
  bodyData: ReportBody;

  //Datos de entorno
  environmentData: ReportEnvironment;

  //Datos de emociones
  feelingsData: ReportFeelings;

  //Datos de valores
  valuesData: ReportValues;
}

export interface ReportGame {
  //Datos de aprendizaje con balon
  learningWith: ReportData[];

  //Datos de aprendizaje sin balón
  learningWithout: ReportData[];

  //Roles por posición con balón
  rolesWith: ReportData[];

  //Roles por posición sin balón
  rolesWithout: ReportData[];

  //Inteligencia
  intelligence: ReportData[];

  //Creatividad
  creativity: ReportData[];
}

//Nombres para los dos tipos de aprendizaje
const learningNames = [
  [
    "learningNames.recuperacion.comportamiento",
    "learningNames.recuperacion.salida",
    "learningNames.recuperacion.posicional",
    "learningNames.recuperacion.no_posicional",
    "learningNames.recuperacion.acceso_finalizacion",
    "learningNames.recuperacion.finalizacion",
    "learningNames.recuperacion.abp",
    "learningNames.recuperacion.equilibrios",
  ],
  [
    "learningNames.perdida.comportamiento",
    "learningNames.perdida.salida",
    "learningNames.perdida.posicional",
    "learningNames.perdida.no_posicional",
    "learningNames.perdida.acceso_finalizacion",
    "learningNames.perdida.finalizacion",
    "learningNames.perdida.abp",
    "learningNames.perdida.equilibrios",
  ],
];

//Nombres para todos los tipos de roles
const rolesNames = {
  lateral: [
    [
      "rolesNames.recuperacion.rol",
      "rolesNames.recuperacion.salida",
      "rolesNames.recuperacion.progresion",
      "rolesNames.recuperacion.perfiles",
      "rolesNames.recuperacion.dentro",
      "rolesNames.recuperacion.apoyo",
      "rolesNames.recuperacion.incorpora",
      "rolesNames.recuperacion.rompe_lineas",
      "rolesNames.recuperacion.conduce",
      "rolesNames.recuperacion.domina",
      "rolesNames.recuperacion.explosivo",
      "rolesNames.recuperacion.zonas_finalizacion",
      "rolesNames.recuperacion.amplitud_profundidad",
      "rolesNames.recuperacion.ventaja",
      "rolesNames.recuperacion.continuidad",
      "rolesNames.recuperacion.ritmo",
      "rolesNames.recuperacion.equilibrio",
    ],
    [
      "rolesNames.perdida.rol",
      "rolesNames.perdida.comunica",
      "rolesNames.perdida.anticipa",
      "rolesNames.perdida.corregir",
      "rolesNames.perdida.adapta_distancias",
      "rolesNames.perdida.bascula",
      "rolesNames.perdida.duelos",
      "rolesNames.perdida.perfil_corporal",
      "rolesNames.perdida.temporiza",
      "rolesNames.perdida.rapido",
      "rolesNames.perdida.reubica",
    ],
  ],
  portero: [
    [
      "portero.recuperacion.comportamiento",
      "portero.recuperacion.inicio",
      "portero.recuperacion.salida",
      "portero.recuperacion.riesgos",
      "portero.recuperacion.ritmo",
      "portero.recuperacion.encuentra",
      "portero.recuperacion.relacion_corto",
      "portero.recuperacion.relacion_largo",
      "portero.recuperacion.continuidad",
      "portero.recuperacion.facilita",
      "portero.recuperacion.equilibrio",
    ],
    [
      "portero.perdida.comunicacion",
      "portero.perdida.mov_neutralizadores",
      "portero.perdida.posicion_entorno",
      "portero.perdida.posicion_tiro",
      "portero.perdida.despejes",
      "portero.perdida.blacajes",
      "portero.perdida.reflejos",
      "portero.perdida.desplazamiento",
      "portero.perdida.duelo",
      "portero.perdida.aereo",
      "portero.perdida.ultima_linea",
      "portero.perdida.perfil_corporal",
      "portero.perdida.balones_divididos",
      "portero.perdida.abp",
      "portero.perdida.anticipa",
    ],
  ],
  central: [
    [
      "central.recuperacion.comportamiento",
      "central.recuperacion.salida",
      "central.recuperacion.progresion",
      "central.recuperacion.riesgos",
      "central.recuperacion.portero",
      "central.recuperacion.perfiles",
      "central.recuperacion.ritmo",
      "central.recuperacion.hombres_libres",
      "central.recuperacion.relacion_corto",
      "central.recuperacion.relacion_largo",
      "central.recuperacion.incorpora_lineas",
      "central.recuperacion.rompe_lineas",
      "central.recuperacion.conduce",
      "central.recuperacion.distancias",
      "central.recuperacion.aereo",
      "central.recuperacion.apoyo",
      "central.recuperacion.altura_linea",
      "central.recuperacion.equilibrio",
    ],
    [
      "central.perdida.comportamiento",
      "central.perdida.comunicacion",
      "central.perdida.anticipa",
      "central.perdida.corregir",
      "central.perdida.distancias",
      "central.perdida.linea",
      "central.perdida.coberturas",
      "central.perdida.marcajes",
      "central.perdida.entradas",
      "central.perdida.cuerpo",
      "central.perdida.despeja",
      "central.perdida.perfil_corporal",
      "central.perdida.temporiza",
      "central.perdida.reubica",
      "central.perdida.duelos",
      "central.perdida.duelos_aereos",
      "central.perdida.obstaculiza",
    ],
  ],
  mediocentro_def: [
    [
      "mediocentro_def.recuperacion.comportamiento",
      "mediocentro_def.recuperacion.salida",
      "mediocentro_def.recuperacion.juego_progresion",
      "mediocentro_def.recuperacion.ubica",
      "mediocentro_def.recuperacion.reubica",
      "mediocentro_def.recuperacion.intervalos",
      "mediocentro_def.recuperacion.valora",
      "mediocentro_def.recuperacion.relacion_corto",
      "mediocentro_def.recuperacion.relacion_largo",
      "mediocentro_def.recuperacion.perfil_corporal",
      "mediocentro_def.recuperacion.fusion_lineas",
      "mediocentro_def.recuperacion.incorpora_lineas",
      "mediocentro_def.recuperacion.filtra_pases",
      "mediocentro_def.recuperacion.supera_lineas",
      "mediocentro_def.recuperacion.posicion_ayuda",
      "mediocentro_def.recuperacion.ritmo",
      "mediocentro_def.recuperacion.comunicacion",
      "mediocentro_def.recuperacion.equilibrio",
    ],
    [
      "mediocentro_def.perdida.comunicacion",
      "mediocentro_def.perdida.perfil_corporal",
      "mediocentro_def.perdida.presion",
      "mediocentro_def.perdida.repliegue",
      "mediocentro_def.perdida.necesidad",
      "mediocentro_def.perdida.peligro",
      "mediocentro_def.perdida.linea_defensiva",
      "mediocentro_def.perdida.espacio_interior",
      "mediocentro_def.perdida.recuperar",
      "mediocentro_def.perdida.duelos",
      "mediocentro_def.perdida.timing_intensidad",
      "mediocentro_def.perdida.cuerpo",
      "mediocentro_def.perdida.duelos_aereos",
      "mediocentro_def.perdida.coberturas",
      "mediocentro_def.perdida.reubica",
      "mediocentro_def.perdida.temporiza",
      "mediocentro_def.perdida.distancias",
      "mediocentro_def.perdida.anticipa",
    ],
  ],
  mediocentro: [
    [
      "mediocentro.recuperacion.comportamiento",
      "mediocentro.recuperacion.salida",
      "mediocentro.recuperacion.juego_pregresion",
      "mediocentro.recuperacion.ubica",
      "mediocentro.recuperacion.reubica",
      "mediocentro.recuperacion.intervalos",
      "mediocentro.recuperacion.riesgos",
      "mediocentro.recuperacion.relacion_corto",
      "mediocentro.recuperacion.relación_largo",
      "mediocentro.recuperacion.perfil_corporal",
      "mediocentro.recuperacion.lineas_posteriores",
      "mediocentro.recuperacion.filtra_pases",
      "mediocentro.recuperacion.supera_lineas",
      "mediocentro.recuperacion.ritmo",
      "mediocentro.recuperacion.acelera",
      "mediocentro.recuperacion.filtra",
      "mediocentro.recuperacion.llegadas",
      "mediocentro.recuperacion.tiro_fuera_area",
      "mediocentro.recuperacion.regate",
      "mediocentro.recuperacion.anticipa",
    ],
    [
      "mediocentro.perdida.presion",
      "mediocentro.perdida.entradas",
      "mediocentro.perdida.receptores",
      "mediocentro.perdida.repliegue",
      "mediocentro.perdida.necesidades",
      "mediocentro.perdida.peligro",
      "mediocentro.perdida.protege",
      "mediocentro.perdida.recupera",
      "mediocentro.perdida.duelo",
      "mediocentro.perdida.cuerpo",
      "mediocentro.perdida.cobertura",
      "mediocentro.perdida.desbordad",
      "mediocentro.perdida.temporiza",
      "mediocentro.perdida.adapta",
      "mediocentro.perdida.adapta2",
      "mediocentro.perdida.caidas",
      "mediocentro.perdida.perdida",
    ],
  ],
  mediocentro_of: [
    [
      "mediocentro_of.recuperacion.comportamiento",
      "mediocentro_of.recuperacion.juego_progresion",
      "mediocentro_of.recuperacion.juego_finalizacion",
      "mediocentro_of.recuperacion.ubica",
      "mediocentro_of.recuperacion.reubica",
      "mediocentro_of.recuperacion.intervalos",
      "mediocentro_of.recuperacion.relacion_corto",
      "mediocentro_of.recuperacion.perfil_corporal",
      "mediocentro_of.recuperacion.filtra_pases",
      "mediocentro_of.recuperacion.supera_lineas",
      "mediocentro_of.recuperacion.adapta",
      "mediocentro_of.recuperacion.acelera",
      "mediocentro_of.recuperacion.pase_acceso",
      "mediocentro_of.recuperacion.llegada",
      "mediocentro_of.recuperacion.ventaja",
      "mediocentro_of.recuperacion.tiro_fuera_area",
      "mediocentro_of.recuperacion.remate",
      "mediocentro_of.recuperacion.desmarque",
      "mediocentro_of.recuperacion.regate",
      "mediocentro_of.recuperacion.cuerpo",
      "mediocentro_of.recuperacion.atrevido",
      "mediocentro_of.recuperacion.anticipa",
    ],
    [
      "mediocentro_of.perdida.domina_presion",
      "mediocentro_of.perdida.salta_presion",
      "mediocentro_of.perdida.acompaña_presion",
      "mediocentro_of.perdida.necesidades",
      "mediocentro_of.perdida.espacios_libres",
      "mediocentro_of.perdida.recupera",
      "mediocentro_of.perdida.duelo",
      "mediocentro_of.perdida.cuerpo",
      "mediocentro_of.perdida.cobertura",
      "mediocentro_of.perdida.distancia",
      "mediocentro_of.perdida.distancia2",
      "mediocentro_of.perdida.caida",
      "mediocentro_of.perdida.equilibrio",
    ],
  ],
  medio: [
    [
      "medio.recuperacion.comportamiento",
      "medio.recuperacion.juego_progresion",
      "medio.recuperacion.juego_finalizacion",
      "medio.recuperacion.ofrece",
      "medio.recuperacion.interpreta",
      "medio.recuperacion.perfiles",
      "medio.recuperacion.ubica",
      "medio.recuperacion.intervalos",
      "medio.recuperacion.relacion_corto",
      "medio.recuperacion.atrevido",
      "medio.recuperacion.recursos",
      "medio.recuperacion.finalizacion",
      "medio.recuperacion.cuerpo",
      "medio.recuperacion.domina",
      "medio.recuperacion.capacidad_finalizacion",
      "medio.recuperacion.ventaja",
      "medio.recuperacion.anticipa",
      "medio.recuperacion.dinamico",
      "medio.recuperacion.rapido",
      "medio.recuperacion.acelera",
      "medio.recuperacion.ventaja_compañeros",
    ],
    [
      "medio.perdida.domina",
      "medio.perdida.salta_presion",
      "medio.perdida.acompaña_presion",
      "medio.perdida.ayuda",
      "medio.perdida.necesidades",
      "medio.perdida.duelo",
      "medio.perdida.nascula",
      "medio.perdida.adapta",
      "medio.perdida.timing",
      "medio.perdida.caida",
      "medio.perdida.sacrificio",
      "medio.perdida.lineas",
      "medio.perdida.trayectoria",
      "medio.perdida.trayectoria2",
      "medio.perdida.perfil_corporal",
      "medio.perdida.temporiza",
      "medio.perdida.reubica",
    ],
  ],
  extremo: [
    [
      "extremo.recuperacion.comportamiento",
      "extremo.recuperacion.juego_progresion",
      "extremo.recuperacion.juego_finalizacion",
      "extremo.recuperacion.ofrece",
      "extremo.recuperacion.interpreta",
      "extremo.recuperacion.perfiles",
      "extremo.recuperacion.ubica",
      "extremo.recuperacion.intervalos",
      "extremo.recuperacion.relacion",
      "extremo.recuperacion.atrevido",
      "extremo.recuperacion.recursos",
      "extremo.recuperacion.finalizacion",
      "extremo.recuperacion.cuerpo",
      "extremo.recuperacion.domina",
      "extremo.recuperacion.capacidad_finalizacion",
      "extremo.recuperacion.ventaja",
      "extremo.recuperacion.anticipa",
      "extremo.recuperacion.dinamico",
      "extremo.recuperacion.ritmo",
      "extremo.recuperacion.acceso",
      "extremo.recuperacion.compañeros",
    ],
    [
      "extremo.perdida.domina_presion",
      "extremo.perdida.salta_presion",
      "extremo.perdida.acompaña_presion",
      "extremo.perdida.ayuda",
      "extremo.perdida.necesidades",
      "extremo.perdida.duelo",
      "extremo.perdida.nascula",
      "extremo.perdida.adapta",
      "extremo.perdida.adapta2",
      "extremo.perdida.caidas",
      "extremo.perdida.equilibrio",
      "extremo.perdida.sacraficio",
      "extremo.perdida.tapa1",
      "extremo.perdida.tapa2",
      "extremo.perdida.tapa3",
      "extremo.perdida.perfil_corporal",
      "extremo.perdida.temporiza",
      "extremo.perdida.reubica",
    ],
  ],
  delantero: [
    [
      "delantero.recuperacion.comportamiento",
      "delantero.recuperacion.alternativas",
      "delantero.recuperacion.protege",
      "delantero.recuperacion.fija_linea",
      "delantero.recuperacion.participa",
      "delantero.recuperacion.relacion",
      "delantero.recuperacion.perfil_progresar",
      "delantero.recuperacion.perfil_corporal",
      "delantero.recuperacion.interpreta",
      "delantero.recuperacion.desmarca",
      "delantero.recuperacion.ubica",
      "delantero.recuperacion.reubica",
      "delantero.recuperacion.intervalos",
      "delantero.recuperacion.cuerpo",
      "delantero.recuperacion.acelera",
      "delantero.recuperacion.fuera_juego",
      "delantero.recuperacion.recursos_tecnicos",
      "delantero.recuperacion.ventaja",
      "delantero.recuperacion.zona_remate",
      "delantero.recuperacion.balones_aereos",
      "delantero.recuperacion.tiro_finalizacion",
      "delantero.recuperacion.tiro_fuera_area",
      "delantero.recuperacion.remate",
      "delantero.recuperacion.caida",
      "delantero.recuperacion.rechace",
      "delantero.recuperacion.ritmo",
      "delantero.recuperacion.atrevido",
      "delantero.recuperacion.filtra_pases",
    ],
    [
      "delantero.perdida.domina_presion",
      "delantero.perdida.salta_presion",
      "delantero.perdida.acompaña_presion",
      "delantero.perdida.necesidades",
      "delantero.perdida.espacios_libres",
      "delantero.perdida.adapta",
      "delantero.perdida.adapta2",
      "delantero.perdida.ayuda",
      "delantero.perdida.tapa1",
      "delantero.perdida.tapa2",
      "delantero.perdida.tapa3",
      "delantero.perdida.perfil_corporal",
      "delantero.perdida.temporiza",
      "delantero.perdida.reubica",
      "delantero.perdida.equilibrio",
      "delantero.perdida.comprometido",
    ],
  ],
};

export const allRoleNames = rolesNames;

//Nombres para inteligencia
const intelligenceNames = [
  "intelligenceNames.comprension",
  "intelligenceNames.soluciones",
  "intelligenceNames.apredizaje",
  "intelligenceNames.eficacia",
  "intelligenceNames.potenciar",
  //"intelligenceNames.creatividad",
];

//Nombres para creatividad
const creativityNames = [
  "creativityNames.resolutivo",
  "creativityNames.patrones",
  "creativityNames.soluciones",
  "creativityNames.valentia",
  "creativityNames.tolerancia",
  "creativityNames.temerario",
];

export interface ReportBody {
  //Datos varios de somatotipo (SÓLO los constantes)
  somatotipeConstData: ReportData[];

  //Dato de somatotipo usado para la media. Sólo hay uno
  somatotipeData: ReportData;

  //Datos de fuerza
  strenghtData: ReportData[];

  //Datos de velocidad
  velocityData: ReportData[];

  //Datos de resistencia
  resistenceData: ReportData[];

  //Datos de flexibilidad
  flexibilityData: ReportData[];

  //Datos de movilidad
  movilityData: ReportData[];

  //Datos de control neuromuscular
  controlData: ReportData[];

  //Datos de lesiones
  lesionData: ReportData[];
}

//Nombres de somatotipos constantes
const somatotipeNames = [
  "somatotipeNames.grasa",
  "somatotipeNames.musculo",
  "somatotipeNames.oseo",
  "somatotipeNames.altura",
  "somatotipeNames.peso",
  "somatotipeNames.imc",
  "somatotipeNames.cintura_cadera",
  "somatotipeNames.6_pliegues",
  "somatotipeNames.8_pliegues",
];

export interface ReportEnvironment {
  //Tenemos sólo 6 campos, los almacenamos en un array y listo
  reportData: ReportData[];
}

//Nombres de los campos de environment
const environmentNames = [
  "environmentNames.colegio",
  "environmentNames.familia",
  "environmentNames.amigos",
  "environmentNames.agente",
  "environmentNames.habitos",
  "environmentNames.redes",
];

export interface ReportFeelings {
  //Lo mismo que Environment
  reportData: ReportData[];
}

//Nombres de los campos de emociones
const feelingsNames = [
  "feelingsNames.alegria",
  //"feelingsNames.trsiteza",
  "feelingsNames.enfado",
  //"feelingsNames.envidia",
  //"feelingsNames.ansiedad",
  "feelingsNames.miedo",
  "feelingsNames.calma",
  //"feelingsNames.triunfo",
];

export interface ReportValues {
  //Y otra vez lo mismo
  reportData: ReportData[];
}

//Nombres de los campos de valores
const valuesNames = [
  "valuesNames.respeto",
  //"valuesNames.convicencia",
  //"valuesNames.bondad",
  //"valuesNames.sinceridad",
  "valuesNames.empatia",
  //"valuesNames.paciencia",
  //"valuesNames.gratitud",
  //"valuesNames.perdon",
  "valuesNames.humildad",
  "valuesNames.responsabilidad",
  //"valuesNames.solidaridad",
  "valuesNames.esfuerzo",
  "valuesNames.resistencia",
];

//Funciones para ayudar en la creación de reportes NUEVOS
function CreateNewReportGameData(playerRole: string): ReportGame {
  let outputData: ReportGame = {
    learningWith: [],
    learningWithout: [],
    rolesWith: [],
    rolesWithout: [],
    intelligence: [],
    creativity: [],
  };

  //Añadimos los learningWith
  learningNames[0].forEach((name) =>
    outputData.learningWith.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  //Los learning without
  learningNames[1].forEach((name) =>
    outputData.learningWithout.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  //Obtenemos ahora el string con los nombres para roles
  let rolesNamesTemp: string[][] = [[], []];
  switch (playerRole) {
    case "lateral":
      rolesNamesTemp = rolesNames.lateral;
      break;
    case "portero":
      rolesNamesTemp = rolesNames.portero;
      break;
    case "central":
      rolesNamesTemp = rolesNames.central;
      break;
    case "mediocentro_def":
      rolesNamesTemp = rolesNames.mediocentro_def;
      break;
    case "mediocentro":
      rolesNamesTemp = rolesNames.mediocentro;
      break;
    case "mediocentro_of":
      rolesNamesTemp = rolesNames.mediocentro_of;
      break;
    case "medio":
      rolesNamesTemp = rolesNames.medio;
      break;
    case "extremo":
      rolesNamesTemp = rolesNames.extremo;
      break;
    case "delantero":
      rolesNamesTemp = rolesNames.delantero;
      break;
  }

  //Añadimos ahora los rolesWith
  rolesNamesTemp[0].forEach((name) =>
    outputData.rolesWith.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  //Y los without
  rolesNamesTemp[1].forEach((name) =>
    outputData.rolesWithout.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  //Añadimos los de inteligencia
  intelligenceNames.forEach((name) =>
    outputData.intelligence.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  //Añadimos los de creatividad
  creativityNames.forEach((name) =>
    outputData.creativity.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  return outputData;
}

function CreateNewReportBodyData(): ReportBody {
  let outputData: ReportBody = {
    somatotipeConstData: [],
    somatotipeData: {
      id: -1,
      report_id: -1,
      type: "somatotipeData",
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    },
    strenghtData: [],
    velocityData: [],
    resistenceData: [],
    flexibilityData: [],
    movilityData: [],
    controlData: [],
    lesionData: [],
  };

  //Este es muy sencillo, ya que los únicos valores por defecto son los cosntantes de somatotipo
  somatotipeNames.forEach((name) =>
    outputData.somatotipeConstData.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  return outputData;
}

function CreateNewReportEnvironmentData(): ReportEnvironment {
  let outputData: ReportEnvironment = {
    reportData: [],
  };

  //Este es muy sencillo, ya que todos los valores por defecto van en un array, y listo
  environmentNames.forEach((name) =>
    outputData.reportData.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  return outputData;
}

function CreateNewReportFeelingsData(): ReportFeelings {
  let outputData: ReportFeelings = {
    reportData: [],
  };

  //Este es muy sencillo, ya que todos los valores por defecto van en un array, y listo
  feelingsNames.forEach((name) =>
    outputData.reportData.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  return outputData;
}

function CreateNewReportValuesData(): ReportValues {
  let outputData: ReportValues = {
    reportData: [],
  };

  //Este es muy sencillo, ya que todos los valores por defecto van en un array, y listo
  valuesNames.forEach((name) =>
    outputData.reportData.push({
      id: -1,
      report_id: -1,
      type: name,
      score: -1,
      date: "",
      text_1: "",
      text_2: "",
    })
  );

  return outputData;
}

//Función que crea un reporte NUEVO
export function CreateNewReport(playerRole: string, reportID = -1): Report {
  let report: Report = {
    gameData: CreateNewReportGameData(playerRole),
    bodyData: CreateNewReportBodyData(),
    environmentData: CreateNewReportEnvironmentData(),
    feelingsData: CreateNewReportFeelingsData(),
    valuesData: CreateNewReportValuesData(),
    reportId: reportID,
  };

  return report;
}

//Función que convierte un array de ReportData a un reporte
export function ConvertReportDataArrayToReport(
  reportData: ReportData[],
  playerRole: string
): Report {
  //Primero, creamos un report vacío
  let report = CreateNewReport(playerRole);

  //Si no nos han pasado datos, devolvemos
  if (reportData.length === 0) return report;

  //Si no, obtenemos el ID de cualquier reporte
  report.reportId = reportData[0].report_id;

  //Y ahora recorremos todos los reportes
  for (let i = 0; i < reportData.length; i++) {
    const currentReport = reportData[i];

    //Obtenemos el tipo de reporte
    let reportTypes = currentReport.type.split("|");

    //Si no conocemos el tipo, mostramos error por consola y devolvemos
    if (reportTypes.length === 0) {
      console.log(
        "Couldn't process report. Type not acepted. " + currentReport
      );
      continue;
    }

    //Diferenciamos los diferentes tipos
    let reportIndex: number;
    switch (reportTypes[0]) {
      case "game":
        //Todos tienen una longitud de 3
        if (reportTypes.length !== 3) {
          console.log(
            "Couldn't process report. Type not acepted. " + currentReport
          );
          continue;
        }

        //Separamos por el segundo tipo
        switch (reportTypes[1]) {
          case "learningWith":
            reportIndex = report.gameData.learningWith.findIndex(
              (data) => data.type === reportTypes[2]
            );
            if (reportIndex !== -1) {
              report.gameData.learningWith[reportIndex] = currentReport;
              report.gameData.learningWith[reportIndex].type = reportTypes[2];
            }
            break;
          case "learningWithout":
            reportIndex = report.gameData.learningWithout.findIndex(
              (data) => data.type === reportTypes[2]
            );
            if (reportIndex !== -1) {
              report.gameData.learningWithout[reportIndex] = currentReport;
              report.gameData.learningWithout[reportIndex].type =
                reportTypes[2];
            }
            break;
          case "rolesWith":
            reportIndex = report.gameData.rolesWith.findIndex(
              (data) => data.type === reportTypes[2]
            );
            if (reportIndex !== -1) {
              report.gameData.rolesWith[reportIndex] = currentReport;
              report.gameData.rolesWith[reportIndex].type = reportTypes[2];
            } else {
              report.gameData.rolesWith.push(currentReport);
              report.gameData.rolesWith[
                report.gameData.rolesWith.length - 1
              ].type = reportTypes[2];
            }
            break;
          case "rolesWithout":
            reportIndex = report.gameData.rolesWithout.findIndex(
              (data) => data.type === reportTypes[2]
            );
            if (reportIndex !== -1) {
              report.gameData.rolesWithout[reportIndex] = currentReport;
              report.gameData.rolesWithout[reportIndex].type = reportTypes[2];
            } else {
              report.gameData.rolesWithout.push(currentReport);
              report.gameData.rolesWithout[
                report.gameData.rolesWithout.length - 1
              ].type = reportTypes[2];
            }
            break;
          case "intelligence":
            reportIndex = report.gameData.intelligence.findIndex(
              (data) => data.type === reportTypes[2]
            );
            if (reportIndex !== -1) {
              report.gameData.intelligence[reportIndex] = currentReport;
              report.gameData.intelligence[reportIndex].type = reportTypes[2];
            }
            break;
          case "creativity":
            reportIndex = report.gameData.creativity.findIndex(
              (data) => data.type === reportTypes[2]
            );
            if (reportIndex !== -1) {
              report.gameData.creativity[reportIndex] = currentReport;
              report.gameData.creativity[reportIndex].type = reportTypes[2];
            }
            break;

          default:
            console.log(
              "Couldn't process report. Type not acepted. " + currentReport
            );
            break;
        }

        break;
      case "body":
        //Mínimo tienen una longitud de 2. Pueden tener de 3
        if (reportTypes.length < 2) {
          console.log(
            "Couldn't process report. Type not acepted. " + currentReport
          );
          continue;
        }

        //Separamos por el segundo tipo
        switch (reportTypes[1]) {
          case "somatotipeConstData":
            //Tiene que tener una longitud de 3
            if (reportTypes.length !== 3) {
              console.log(
                "Couldn't process report. Type not acepted. " + currentReport
              );
              continue;
            }

            reportIndex = report.bodyData.somatotipeConstData.findIndex(
              (data) => data.type === reportTypes[2]
            );
            if (reportIndex !== -1) {
              report.bodyData.somatotipeConstData[reportIndex] = currentReport;
              report.bodyData.somatotipeConstData[reportIndex].type =
                reportTypes[2];
            }
            break;
          case "somatotipeData":
            //Este es el más sencillo
            report.bodyData.somatotipeData = currentReport;
            report.bodyData.somatotipeData.type = reportTypes[2];
            break;
          case "strenghtData":
            report.bodyData.strenghtData.push(currentReport);
            report.bodyData.strenghtData[
              report.bodyData.strenghtData.length - 1
            ].type = reportTypes[2];
            break;
          case "velocityData":
            report.bodyData.velocityData.push(currentReport);
            report.bodyData.velocityData[
              report.bodyData.velocityData.length - 1
            ].type = reportTypes[2];
            break;
          case "resistenceData":
            report.bodyData.resistenceData.push(currentReport);
            report.bodyData.resistenceData[
              report.bodyData.resistenceData.length - 1
            ].type = reportTypes[2];
            break;
          case "flexibilityData":
            report.bodyData.flexibilityData.push(currentReport);
            report.bodyData.flexibilityData[
              report.bodyData.flexibilityData.length - 1
            ].type = reportTypes[2];
            break;
          case "movilityData":
            report.bodyData.movilityData.push(currentReport);
            report.bodyData.movilityData[
              report.bodyData.movilityData.length - 1
            ].type = reportTypes[2];
            break;
          case "controlData":
            report.bodyData.controlData.push(currentReport);
            report.bodyData.controlData[
              report.bodyData.controlData.length - 1
            ].type = reportTypes[2];
            break;
          case "lesionData":
            report.bodyData.lesionData.push(currentReport);
            report.bodyData.lesionData[
              report.bodyData.lesionData.length - 1
            ].type = reportTypes[2];
            break;

          default:
            console.log(
              "Couldn't process report. Type not acepted. " + currentReport
            );
            break;
        }
        break;
      case "environment":
        //Simplemente añadimos
        report.environmentData.reportData.push(currentReport);
        report.environmentData.reportData[
          report.environmentData.reportData.length - 1
        ].type = "";
        break;
      case "feelings":
        //Simplemente añadimos
        report.feelingsData.reportData.push(currentReport);
        report.feelingsData.reportData[
          report.feelingsData.reportData.length - 1
        ].type = "";
        break;
      case "values":
        //Simplemente añadimos
        report.valuesData.reportData.push(currentReport);
        report.valuesData.reportData[
          report.valuesData.reportData.length - 1
        ].type = "";
        break;

      default:
        console.log(
          "Couldn't process report. Type not acepted. " + currentReport
        );
        break;
    }
  }

  return report;
}

//Función que convierte un reporte en un array de ReportData
export function ConvertReportToReportDataArray(
  inputReport: Report
): ReportData[] {
  let outputData: ReportData[] = [];

  //Obtenemos los de juegos
  GetReportDataFromReportGame(inputReport.gameData).forEach((report) => {
    report.report_id = inputReport.reportId;
    outputData.push(report);
  });

  //Obtenemos los de cuerpos
  GetReportDataFromReportBody(inputReport.bodyData).forEach((report) => {
    report.report_id = inputReport.reportId;
    outputData.push(report);
  });

  //Obtenemos los de environment
  GetReportDataFromReportEnvironment(inputReport.environmentData).forEach(
    (report) => {
      report.report_id = inputReport.reportId;
      outputData.push(report);
    }
  );

  //Obtenemos los de feelings
  GetReportDataFromReportFeelings(inputReport.feelingsData).forEach(
    (report) => {
      report.report_id = inputReport.reportId;
      outputData.push(report);
    }
  );

  //Obtenemos los de values
  GetReportDataFromReportValues(inputReport.valuesData).forEach((report) => {
    report.report_id = inputReport.reportId;
    outputData.push(report);
  });

  return outputData;
}

//Función que devuelve todos los reportes bien colocados de game
function GetReportDataFromReportGame(report: ReportGame): ReportData[] {
  let output: ReportData[] = [];

  //Metemos los de learningWith
  report.learningWith.forEach((report) => {
    report.type = "game|learningWith|" + report.type;
    output.push(report);
  });

  //Metemos los de learningWithout
  report.learningWithout.forEach((report) => {
    report.type = "game|learningWithout|" + report.type;
    output.push(report);
  });

  //Metemos los de roleWith
  report.rolesWith.forEach((report) => {
    report.type = "game|rolesWith|" + report.type;
    output.push(report);
  });

  //Metemos los de rolesWithout
  report.rolesWithout.forEach((report) => {
    report.type = "game|rolesWithout|" + report.type;
    output.push(report);
  });

  //Metemos los de intelligence
  report.intelligence.forEach((report) => {
    report.type = "game|intelligence|" + report.type;
    output.push(report);
  });

  //Metemos los de creativity
  report.creativity.forEach((report) => {
    report.type = "game|creativity|" + report.type;
    output.push(report);
  });

  return output;
}

//Función que devuelve los reportes de body
function GetReportDataFromReportBody(report: ReportBody): ReportData[] {
  let output: ReportData[] = [];

  //Metemos los de somatotipeConstData
  report.somatotipeConstData.forEach((report) => {
    report.type = "body|somatotipeConstData|" + report.type;
    output.push(report);
  });

  //Metemos el especial de somatotipo
  report.somatotipeData.type = "body|somatotipeData";

  //Metemos los de strenghtData
  report.strenghtData.forEach((report) => {
    report.type = "body|strenghtData|" + report.type;
    output.push(report);
  });

  //Metemos los de velocityData
  report.velocityData.forEach((report) => {
    report.type = "body|velocityData|" + report.type;
    output.push(report);
  });

  //Metemos los de resistenceData
  report.resistenceData.forEach((report) => {
    report.type = "body|resistenceData|" + report.type;
    output.push(report);
  });

  //Metemos los de flexibilityData
  report.flexibilityData.forEach((report) => {
    report.type = "body|flexibilityData|" + report.type;
    output.push(report);
  });

  //Metemos los de movilityData
  report.movilityData.forEach((report) => {
    report.type = "body|movilityData|" + report.type;
    output.push(report);
  });

  //Metemos los de controlData
  report.controlData.forEach((report) => {
    report.type = "body|controlData|" + report.type;
    output.push(report);
  });

  //Metemos los de lesionData
  report.lesionData.forEach((report) => {
    report.type = "body|lesionData|" + report.type;
    output.push(report);
  });

  return output;
}

//Función que devuelve los reportes de Environment
function GetReportDataFromReportEnvironment(
  report: ReportEnvironment
): ReportData[] {
  let output: ReportData[] = [];

  //Metemos los datos
  report.reportData.forEach((report) => {
    report.type = "environment|" + report.type;
    output.push(report);
  });

  return output;
}

//Función que devuelve los reportes de Feelings
function GetReportDataFromReportFeelings(report: ReportFeelings): ReportData[] {
  let output: ReportData[] = [];

  //Metemos los datos
  report.reportData.forEach((report) => {
    report.type = "feelings|" + report.type;
    output.push(report);
  });

  return output;
}

//Función que devuelve los reportes de Values
function GetReportDataFromReportValues(report: ReportValues): ReportData[] {
  let output: ReportData[] = [];

  //Metemos los datos
  report.reportData.forEach((report) => {
    report.type = "values|" + report.type;
    output.push(report);
  });

  return output;
}

//Funciones para ayudar a calcular la MEDIA de los reportes. Devolvemos un array con 7 números, siendo los 6 primeros las medias individuales, y el 7 la media global
export function CalculateReportGameAverage(reportData: ReportGame): number[] {
  let tempCount = 0;
  let tempScore = 0;
  let outputData = [0, 0, 0, 0, 0, 0, 0];

  //Calculamos la media de learning withs
  reportData.learningWith.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[0] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de learning withouts
  reportData.learningWithout.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[1] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de roles with
  reportData.rolesWith.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[2] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de roles without
  reportData.rolesWithout.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[3] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de intelligence
  reportData.intelligence.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[4] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de creatividad
  reportData.creativity.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[5] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Y la media global
  outputData[6] =
    (outputData[0] +
      outputData[1] +
      outputData[2] +
      outputData[3] +
      outputData[4] +
      outputData[5]) /
    6;

  return outputData;
}

//De cuerpo devuelve 9 números, ordenados de la misma forma que antes
export function CalculateReportBodyAverage(reportData: ReportBody): number[] {
  let tempCount = 0;
  let tempScore = 0;
  let outputData = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  //La media de somatotipo es muy sencilla, ya que es el valor inicial y ya
  outputData[0] =
    reportData.somatotipeData.score >= 0 ? reportData.somatotipeData.score : 0;

  //Calculamos la media de fuerza
  reportData.strenghtData.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[1] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de velocidad
  reportData.velocityData.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[2] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de resistencia
  reportData.resistenceData.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[3] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de flexibilidad
  reportData.flexibilityData.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[4] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de movilidad
  reportData.movilityData.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[5] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de control
  reportData.controlData.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[6] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Calculamos la media de lesion
  reportData.lesionData.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData[7] = tempScore / tempCount;
  tempCount = 0;
  tempScore = 0;

  //Y la media global
  outputData[8] =
    (outputData[0] +
      outputData[1] +
      outputData[2] +
      outputData[3] +
      outputData[4] +
      outputData[5] +
      outputData[6] +
      outputData[7]) /
    8;

  return outputData;
}

//Tanto de environment, feelings y values, devolvemos un sólo número
export function CalculateReportSimpleAverage(
  reportData: ReportEnvironment | ReportFeelings | ReportValues
): number {
  let tempCount = 0;
  let tempScore = 0;
  let outputData = 0;

  reportData.reportData.forEach((report) => {
    if (report.score >= 0) {
      tempCount++;
      tempScore += report.score;
    }
  });
  if (tempCount !== 0) outputData = tempScore / tempCount;

  return outputData;
}
