import React, { useEffect, useState } from "react";
import { PersonDetail } from "../Person/PersonDetail";
import club_icon from "../../images/icons/clubs-icon.svg";
import add_icon from "../../images/icons/add-icon.svg";
import cross_icon from "../../images/icons/close.svg";

import "../../styles/fa.css";
import "../../styles/style.css";

import CollapsablePanel from "../CollapsablePanel";
import ImageField from "../ImageField";
import loadingGif from "../../images/loading.gif";
import DataField from "../DataField";
import {
  Evaluation,
  Idioma,
  Injurie,
  Ipi,
  MatchPlayer,
  PersonData,
  PlayerData,
  Report,
  ReportData,
} from "../../CMSUtils/Icms";
import useStore from "../../store";
import {
  GetStaffByID,
} from "../../CMSUtils/StaffCalls";

import { Radar } from "react-chartjs-2";
import {
  LoadCMSTeams,
  GetTeams,
  GetTeamsByClubID,
} from "../../CMSUtils/TeamCalls";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { GetClubs, LoadCMSClubs } from "../../CMSUtils/ClubCalls";
import {
  ConvertReportToReportDataArray,
  CreateNewReport,
} from "../../data/ReportHelper";
import { positions } from "../../data/arrays";
import { useTranslation } from "react-i18next";
import { CMSChangePass } from "../../CMSUtils/LoginCalls";

export const PlayerDetail: React.FC<{
  selected: PlayerData;

  uploadImage: (image: File | undefined) => void;
  image: File | undefined;
  validForm: (state: boolean) => void;
}> = ({ uploadImage, selected, image, validForm }) => {
  const { t } = useTranslation();
  const token = useStore((state) => state.token);
  const [injuries, setInjuries] = useState<Injurie[]>(
    selected.evaluations[0]?.injuries ?? []
  );
  const [ipis, setIpis] = useState<Ipi[]>(selected.evaluations[0]?.ipis ?? []);
  const [informes, setInformes] = useState<Report[]>(
    selected.evaluations[0].reports ?? []
  );
  const [listaIdiomasEscolares, setListaIdiomasEscolares] = useState<Idioma[]>(
    selected.school_languages ? JSON.parse(selected.school_languages) : []
  );
  const [listaIdiomasLaborales, setListaIdiomasLaborales] = useState<Idioma[]>(
    selected.job_languages ? JSON.parse(selected.job_languages) : []
  );
  //const [tempLesional, setTempLesional] = useState("");
  const [nombreInforme, setNombreInforme] = useState("");
  const setModalContent = useStore((state) => state.setModalContent);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const [isLoaded, setIsLoaded] = useState(false);
  const [teamsList, setTeamsList] = useState<any[]>();
  const [injurieSeason, setInjurieSeason] = useState<number>(0);

  const loggedUser = useStore((state) => state.loggedUser);
  const editable =
    loggedUser?.role.name === "Admin" ||
    loggedUser?.role.name === "Club" ||
    loggedUser?.role.name === "Coordinator" ||
    loggedUser?.role.name === "Trainer";

  const [estadisticas, setEstadisticas] = useState<MatchPlayer>();
  useEffect(() => {
    let isMounted = true;
    console.log(selected);
    if (loggedUser?.role.name === "Club") {
      selected.club_id = loggedUser?.club_id;
    }
    fetchData().then(m =>{
      if(isMounted){
        setEstadisticas(m);
        setIsLoaded(true);
        refreshTeams();
      }
    });
    if (!selected.school_contact) {
      selected.school_contact = {} as PersonData;
    }
    if (!selected.agent) {
      selected.agent = {} as PersonData;
    }

    if (!selected.evaluations || selected.evaluations.length === 0) {
      selected.evaluations = [
        Object.assign({
          injuries: [] as Injurie[],
          ipis: [] as Ipi[],
          reports: [] as Report[],
        } as Evaluation),
      ];
    }

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function fetchData() {
    if (!token) return;
    await LoadCMSTeams(token);
    await LoadCMSClubs(token);
    if (
      loggedUser &&
      (loggedUser.role.name === "Coordinator" ||
        loggedUser.role.name === "Trainer")
    ) {
      let coord = GetStaffByID(loggedUser.staff_id)?.club_id;
      if (coord) {
        selected.club_id = coord;
      }
    }

    let media: MatchPlayer = {
      played_mins: 0,
      goals: 0,
      yellow_cards: 0,
      red_cards: 0,
      score: 0,
    } as MatchPlayer;
    selected.matches?.forEach((m) => {
      media.played_mins += Number(m.played_mins);
      media.goals += Number(m.goals);
      media.yellow_cards += Number(m.yellow_cards);
      media.red_cards += Number(m.red_cards);
      media.score += Number(m.score);
    });
    return media;
  }

  function getTeamNames() {
    if (!GetTeams()) return [];
    let teams: any[] = [];
    (selected.club_id ? GetTeamsByClubID(selected.club_id) : [])?.map(
      (c) => teams.push({ value: c.id, text: c.team_name })
    );
    if (!teams.find((t) => t?.value === selected.team_id)) {
      selected.team_id = 0;
    }
    return teams;
  }
  
  function refreshTeams(){
    setTeamsList(getTeamNames() as any[]);
  }

  function getClubNames() {
    if (!GetClubs()) return [];
    let clubs: any[] = [];
    GetClubs()?.map((c) => clubs.push({ value: c.id, text: c.entity_name }));
    return clubs;
  }

  function saveReports() {
    informes.forEach((informe) => {
      try {
        informe.data = ConvertReportToReportDataArray(informe.data as any);
      } catch (e) {}
    });
    

    //selected.evaluations[0].reports = informes;
    selected.evaluations[0].reports = Object.assign([], informes);
  }

  function getAverage(report: Report, type: string) {
    let avgScore: number = 0;
    let count = 0;
    report.data.forEach((r) => {
      if ((r.type.startsWith(`${type}|`) || r.type === type) && r.score > 0) {
        count++;
        avgScore += Number(r.score);
      }
    });

    let avg = Math.round((avgScore / count) * 100) / 100;

    let avgData = report.data.find((r) => r.type === `${type}Average`);
    if (avgData) {
      avgData.score = avg;
    } else {
      report.data.push({
        type: `${type}Average`,
        score: avg,
      } as ReportData);
    }
  }

  function getGlobalAverage(report: Report, type: string) {
    let avgScore: number = 0;
    let count = 0;
    report.data.forEach((r) => {
      if (
        r.type.startsWith(`${type}|`) &&
        r.type.includes("Average") &&
        r.score > 0
      ) {
        count++;
        avgScore += Number(r.score);
      }
    });
    let avg = Math.round((avgScore / count) * 100) / 100;
    let avgData = report.data.find((r) => r.type === `${type}Average`);
    if (avgData) {
      avgData.score = avg;
    } else {
      report.data.push({
        type: `${type}Average`,
        score: avg,
      } as ReportData);
    }
  }
  function getGlobalAverageNoPipes(report: Report, type: string) {
    let avgScore: number = 0;
    let count = 0;
    report.data.forEach((r) => {
      if (
        r.type.startsWith(type) &&
        r.type.includes("Average") &&
        r.score > 0
      ) {
        count++;
        avgScore += Number(r.score);
      }
    });
    let avg = Math.round((avgScore / count) * 100) / 100;
    let avgData = report.data.find((r) => r.type === `${type}Average`);
    if (avgData) {
      avgData.score = avg;
    } else {
      report.data.push({
        type: `${type}Average`,
        score: avg,
      } as ReportData);
    }
  }

  function reportDetail(
    report: Report,
    title: string,
    section: string = "game",
    subsection: string = "learningWith"
  ) {
    getGlobalAverage(report, "game");
    getGlobalAverage(report, "body");
    getGlobalAverageNoPipes(report, "environment");
    getGlobalAverageNoPipes(report, "feelings");
    getGlobalAverageNoPipes(report, "values");

    if (!report.data.find((r) => r.type === "body|somatotipeConstData|tipo")) {
      report.data.push({
        type: `body|somatotipeConstData|tipo`,
      } as ReportData);
    }
    return (
      <div>
        <div className="w-full flex">
          <div className="w-full grid grid-cols-5 mx-auto text-center bg-sf-darkblue text-gray-400">
            <div
              className={
                "cursor-pointer py-3" +
                (section === "game" ? " border-b-2 border-white text-white bg-sf-mediumblue bg-opacity-50" : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, "game", "learningWith"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_game")}</p>
              <p className="pt-1">
                {(report.data.find((r) => r.type === "gameAverage")?.score ??
                  "") >= 0
                  ? report.data.find((r) => r.type === "gameAverage")?.score ??
                    ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3" +
                (section === "body" ? " border-b-2 border-white text-white bg-sf-mediumblue bg-opacity-50" : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, "body", "somatotipeConstData"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_body")}</p>
              <p className="pt-1">
                {(report.data.find((r) => r.type === "bodyAverage")?.score ??
                  "") >= 0
                  ? report.data.find((r) => r.type === "bodyAverage")?.score ??
                    ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3" +
                (section === "environment" ? " border-b-2 border-white text-white bg-sf-mediumblue bg-opacity-50" : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, "environment", ""),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_env")}</p>
              <p className="pt-1">
                {(report.data.find((r) => r.type === "environmentAverage")
                  ?.score ?? "") >= 0
                  ? report.data.find((r) => r.type === "environmentAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3" +
                (section === "feelings" ? " border-b-2 border-white text-white bg-sf-mediumblue bg-opacity-50" : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, "feelings", ""),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_emotions")}</p>
              <p className="pt-1">
                {(report.data.find((r) => r.type === "feelingsAverage")
                  ?.score ?? "") >= 0
                  ? report.data.find((r) => r.type === "feelingsAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-3" +
                (section === "values" ? " border-b-2 border-white text-white bg-sf-mediumblue bg-opacity-50" : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, "values", ""),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_val")}</p>
              <p className="pt-1">
                {(report.data.find((r) => r.type === "valuesAverage")?.score ??
                  "") >= 0
                  ? report.data.find((r) => r.type === "valuesAverage")
                      ?.score ?? ""
                  : ""}
              </p>
            </div>
          </div>
        </div>
        {section === "game" && (
          <div className="w-full grid grid-cols-6 mx-auto text-center bg-sf-darkblue bg-opacity-75 text-gray-400">
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "learningWith"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "learningWith"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_learn_with")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "game|learningWithAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "learningWithout"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "learningWithout"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_learn_without")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find(
                  (r) => r.type === "game|learningWithoutAverage"
                )?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "rolesWith"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "rolesWith"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_roles_with")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "game|rolesWithAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "rolesWithout"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "rolesWithout"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_roles_without")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "game|rolesWithoutAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "intelligence"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "intelligence"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_int")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "game|intelligenceAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "creativity"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "creativity"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_creat")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "game|creativityAverage")
                  ?.score || ""}
              </p>
            </div>
          </div>
        )}
        {section === "body" && (
          <div className="w-full grid grid-cols-8 mx-auto text-center bg-sf-darkblue bg-opacity-75 text-gray-400">
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "somatotipeConstData"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "somatotipeConstData"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_soma")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find(
                  (r) => r.type === "body|somatotipeConstDataAverage"
                )?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "strength"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "strength"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_str")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "body|strengthAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "speed"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "speed"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_spd")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "body|speedAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "stamina"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "stamina"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_res")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "body|staminaAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "flexibility"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "flexibility"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_flex")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "body|flexibilityAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "mobility"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "mobility"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_mov")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "body|mobilityAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "neuromuscular"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "neuromuscular"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_neuro")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "body|neuromuscularAverage")
                  ?.score || ""}
              </p>
            </div>
            <div
              className={
                "cursor-pointer py-2 flex flex-col" +
                (subsection === "injuries"
                  ? " bg-sf-darkblue bg-opacity-50 text-white"
                  : "")
              }
              onClick={() =>
                setModalContent(
                  reportDetail(report, title, section, "injuries"),
                  title
                )
              }
            >
              <p>{t("14052021.player.report_inj")}</p>
              <p className="pt-1 mt-auto">
                {report.data.find((r) => r.type === "body|injuriesAverage")
                  ?.score || ""}
              </p>
            </div>
          </div>
        )}
        {section === "body" && subsection !== "somatotipeConstData" && (
          <button
            className={
              "flex bg-sf-mediumblue hover:bg-sf-lightblue ml-6 mt-3 py-1 pr-4 pl-2 rounded-full text-white mr-2"
            }
            onClick={() => {
              report.data.push({
                type: `${section}|${subsection}`,
              } as ReportData);
              setModalContent(
                reportDetail(report, title, section, subsection),
                title
              );
            }}
          >
            <img
              src={add_icon}
              alt="Añadir"
              className="h-6 transform scale-50"
              style={{ filter: "invert()" }}
            />
            <p className="pl-2">{t("14052021.player.report_add")}</p>
          </button>
        )}
        <div className="relative">
          {report.data.map((r, index) => {
            let category = r.type.split("|");
            if (category[0] === section) {
              if (category[1] === subsection) {
                if (
                  section === "game" &&
                  (subsection === "learningWith" ||
                    subsection === "learningWithout")
                ) {
                  return (
                    <div key={index} className={"w-full flex px-2 py-2 border-b border-gray-400" + (index%2===0?" bg-gray-400":"")}>
                      <div className="w-3/12 pr-1 self-center">
                        {t(category[2])}
                      </div>
                      <div className="w-1/12 px-1 self-center">
                        <DataField
                          data={r.score >= 0 ? r.score : ""}
                          placeholder="1-10"
                          max={10}
                          min={0}
                          type="number"
                          onChange={(e) => {
                            r.score = e;
                              
                            getAverage(report, "game|learningWith");
                            getAverage(report, "game|learningWithout");
                            setModalContent(
                              reportDetail(report, title, section, subsection),
                              title
                            );
                          }}
                        />
                      </div>
                      <div className="w-4/12 px-1 self-center">
                        <DataField
                          disabled={!editable}
                          data={r.text_1}
                          placeholder={t("14052021.player.report_response")}
                          type="textarea"
                          rows={2}
                          onChange={(e) => (r.text_1 = e)}
                        />
                      </div>
                      <div className="w-4/12 pl-1 self-center">
                        <DataField
                          disabled={!editable}
                          data={r.text_2}
                          rows={2}
                          type="textarea"
                          placeholder={t("14052021.player.report_response")}
                          onChange={(e) => (r.text_2 = e)}
                        />
                      </div>
                    </div>
                  );
                }
                if (
                  section === "game" &&
                  (subsection === "rolesWith" || subsection === "rolesWithout")
                ) {
                  return (
                    <div key={index} className={"w-full flex px-2 py-2 border-b border-gray-400" + (index%2===0?" bg-gray-400":"")}>
                      <div className="w-4/12 pr-1 self-center">
                        {t(category[2])}
                      </div>
                      <div className="w-1/12 px-1 self-center">
                        <DataField
                          disabled={!editable}
                          data={r.score >= 0 ? r.score : ""}
                          placeholder="1-10"
                          max={10}
                          min={0}
                          type="number"
                          onChange={(e) => {
                            r.score = e;

                            getAverage(report, "game|rolesWith");
                            getAverage(report, "game|rolesWithout");
                            setModalContent(
                              reportDetail(report, title, section, subsection),
                              title
                            );
                          }}
                        />
                      </div>
                      <div className="w-7/12 px-1 self-center">
                        <DataField
                          disabled={!editable}
                          data={r.text_1}
                          type="textarea"
                          rows={2}
                          placeholder={t("14052021.player.report_response")}
                          onChange={(e) => (r.text_1 = e)}
                        />
                      </div>
                    </div>
                  );
                }
                if (
                  section === "game" &&
                  (subsection === "intelligence" || subsection === "creativity")
                ) {
                  return (
                    <div key={index} className={"w-1/2 flex py-2 border-b border-gray-400" + (index%2===0?" bg-gray-400":"")}>
                      <div className="w-10/12 pr-1 self-center">
                        {t(category[2])}
                      </div>
                      <div className="w-2/12 px-1 self-center">
                        <DataField
                          disabled={!editable}
                          data={r.score >= 0 ? r.score : ""}
                          placeholder="1-10"
                          max={10}
                          min={0}
                          type="number"
                          onChange={(e) => {
                            r.score = e;
                            getAverage(report, "game|intelligence");
                            getAverage(report, "game|creativity");
                            setModalContent(
                              reportDetail(report, title, section, subsection),
                              title
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                }

                if (
                  section === "body" &&
                  subsection === "somatotipeConstData"
                ) {
                  return (
                    <div key={index} className="flex">
                      {category[2] !== "tipo" && (
                        <div className={"w-1/2 flex py-2 border-b border-gray-400" + (index%2===0?" bg-gray-400":"")}>
                          <div className="w-10/12 pr-1 self-center">
                            {t(category[2])}
                          </div>
                          <div className="w-2/12 px-1 self-center">
                            <DataField
                              disabled={!editable}
                              data={r.score >= 0 ? r.score : ""}
                              placeholder="1-10"
                              max={10}
                              min={0}
                              type="number"
                              onChange={(e) => {
                                r.score = e;

                                getAverage(report, "body|somatotipeConstData");
                                setModalContent(
                                  reportDetail(
                                    report,
                                    title,
                                    section,
                                    subsection
                                  ),
                                  title
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {category[2] === "tipo" && (
                        <div className="w-1/2 pl-8 py-2 absolute top-0 right-0">
                          <div className="flex">
                            <div className="w-10/12 pr-1 self-center">
                              <DataField
                                disabled={!editable}
                                data={r.text_1}
                                type="select"
                                flexible
                                label={t(
                                  "14052021.player.somatotype.label"
                                )}
                                options={[
                                  {
                                    value: "type_1",
                                    text: t(
                                      "14052021.player.somatotype.options.type_1"
                                    ),
                                  },
                                  {
                                    value: "type_2",
                                    text: t(
                                      "14052021.player.somatotype.options.type_2"
                                    ),
                                  },
                                  {
                                    value: "type_3",
                                    text: t(
                                      "14052021.player.somatotype.options.type_3"
                                    ),
                                  },
                                ]}
                                placeholder={t(
                                  "14052021.player.report_type_select"
                                )}
                                onChange={(e) => {
                                  r.text_1 = e;
                                }}
                              />
                            </div>
                            <div className="w-2/12 px-1 self-center">
                              <DataField
                                disabled={!editable}
                                label={t(
                                  "14052021.player.report_valoracion1-10"
                                )}
                                data={r.score >= 0 ? r.score : ""}
                                placeholder="XX"
                                max={10}
                                min={0}
                                type="number"
                                onChange={(e) => {
                                  r.score = e;

                                  getAverage(
                                    report,
                                    "body|somatotipeConstData"
                                  );
                                  setModalContent(
                                    reportDetail(
                                      report,
                                      title,
                                      section,
                                      subsection
                                    ),
                                    title
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <DataField
                            disabled={!editable}
                            data={r.text_2}
                            type="textarea"
                            label={t("14052021.player.report_evaluation")}
                            onChange={(e) => {
                              r.text_2 = e;
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                }
                if (
                  section === "body" &&
                  subsection !== "somatotipeConstData"
                ) {
                  return (
                    <div className={"w-full flex py-2 px-2 border-gray-400 border-b" + (index%2===0?" bg-gray-400":"")}>
                      <div className="w-4/12 pr-1 self-center">
                        <DataField
                          disabled={!editable}
                          data={r.text_1}
                          placeholder="Test"
                          type="textarea"
                          rows={2}
                          onChange={(e) => (r.text_1 = e)}
                        />
                      </div>
                      <div className="w-2/12 pr-1 self-center">
                        <DataField
                          disabled={!editable}
                          data={r.date}
                          type="date"
                          onChange={(e) => (r.date = e)}
                        />
                      </div>
                      <div className="w-2/12 pr-1 self-center">
                        <DataField
                          disabled={!editable}
                          data={r.score}
                          max={10}
                          min={0}
                          placeholder={t("14052021.player.report_valoration")}
                          type="number"
                          onChange={(e) => {
                            r.score = e;

                            getAverage(report, "body|strength");
                            getAverage(report, "body|speed");
                            getAverage(report, "body|stamina");
                            getAverage(report, "body|flexibility");
                            getAverage(report, "body|mobility");
                            getAverage(report, "body|neuromuscular");
                            getAverage(report, "body|injuries");
                            setModalContent(
                              reportDetail(report, title, section, subsection),
                              title
                            );
                          }}
                        />
                      </div>
                      <div className="w-4/12 px-1 self-center">
                        <DataField
                          disabled={!editable}
                          data={r.text_2}
                          type="textarea"
                          rows={2}
                          placeholder="Evaluacion"
                          onChange={(e) => (r.text_2 = e)}
                        />
                      </div>
                    </div>
                  );
                }
              }
            }

            if (category[0] === "environment" && section === "environment") {
              return (
                <div key={index} className={"w-full flex py-2 border-b border-gray-400" + (index%2===0?" bg-gray-400":"")}>
                  <div className="w-4/12 pr-1 self-center">
                    {t(category[1])}
                  </div>
                  <div className="w-1/12 pr-1 self-center">
                    <DataField
                      disabled={!editable}
                      data={r.score >= 0 ? r.score : ""}
                      max={10}
                      min={0}
                      type="number"
                      placeholder="1-10"
                      onChange={(e) => {
                        r.score = e;

                        getAverage(report, "environment");
                        setModalContent(
                          reportDetail(report, title, section, subsection),
                          title
                        );
                      }}
                    />
                  </div>
                  <div className="w-7/12 pr-1 self-center">
                    <DataField
                      disabled={!editable}
                      data={r.text_1}
                      placeholder={t("14052021.player.report_valoration")}
                      onChange={(e) => (r.text_1 = e)}
                    />
                  </div>
                </div>
              );
            }
            if (category[0] === "feelings" && section === "feelings") {
              return (
                <div key={index} className={"w-1/2 flex py-2 border-b border-gray-400" + (index%2===0?" bg-gray-400":"")}>
                  <div className="w-10/12 pr-1 self-center">
                    {t(category[1])}
                  </div>
                  <div className="w-2/12 pr-1 self-center">
                    <DataField
                      disabled={!editable}
                      data={r.score >= 0 ? r.score : ""}
                      max={10}
                      min={0}
                      type="number"
                      placeholder="0-10"
                      onChange={(e) => {
                        r.score = e;

                        getAverage(report, "feelings");
                        setModalContent(
                          reportDetail(report, title, section, subsection),
                          title
                        );
                      }}
                    />
                  </div>
                </div>
              );
            }
            if (category[0] === "values" && section === "values") {
              return (
                <div key={index} className={"w-1/2 flex py-2 border-b border-gray-400" + (index%2===0?" bg-gray-400":"")}>
                  <div className="w-10/12 pr-1 self-center">
                    {t(category[1])}
                  </div>
                  <div className="w-2/12 pr-1 self-center">
                    <DataField
                      disabled={!editable}
                      data={r.score >= 0 ? r.score : ""}
                      max={10}
                      min={0}
                      type="number"
                      placeholder="0-10"
                      onChange={(e) => {
                        r.score = e;

                        getAverage(report, "values");
                        setModalContent(
                          reportDetail(report, title, section, subsection),
                          title
                        );
                      }}
                    />
                  </div>
                </div>
              );
            }
            return undefined;
          })}
        </div>
      </div>
    );
  }

  return isLoaded ? (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          e.currentTarget.reset();
          validForm(
            e.currentTarget.checkValidity() && selected.club_id !== null&& selected.club_id !== undefined
          );
          e.preventDefault();
        }}
        onChange={(e) => {
          setCambiosSinGuardar(true);
          validForm(
            e.currentTarget.checkValidity() && selected.club_id !== null&& selected.club_id !== undefined
          );
        }}
        onLoad={(e) =>
          validForm(e.currentTarget.checkValidity() && selected.club_id !== null)
        }
      >
        <CollapsablePanel titulo={t("player.credentials.title")}>
          <table className="table-fixed w-full">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-3/12 py-4 px-6">
                  <DataField
                    data={selected.user?.name ?? selected.userName}
                    label={t("player.credentials.username")}
                    onChange={(e) => (selected.userName = e)}
                    disabled={selected.user ? true : false}
                  />
                </td>
                <td className="w-4/12 py-4 px-6">
                  <DataField
                    data={selected.user?.email ?? selected.userEmail}
                    label={t("player.credentials.email")}
                    onChange={(e) => (selected.userEmail = e)}
                    disabled={selected.user ? true : false}
                  />
                </td>
              
                  <td className="w-3/12 py-4 px-6">
                    <DataField
                      data={selected.userPass}
                      label={t("player.credentials.password")}
                      type="password"
                      onChange={(e) => (selected.userPass = e)}
                    />
                  </td>
                  <td className="w-2/12 align-middle">{selected.user && <button
                      className="self-center bg-sf-mediumblue rounded-full text-white px-4 mt-4"
                      onClick={() =>CMSChangePass(token, selected.user!.email, "", selected.userPass)
                      }
                    >
                      {t("club.credentials.change_pass")}
                    </button>}
                    </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.personal.title")} colapsado={false}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td rowSpan={3} className="w-1/3">
                  <div
                    className="flex flex-col m-auto"
                    style={{ width: "14rem" }}
                  >
                    <ImageField
                      emptyImage={club_icon}
                      uploadedImage={image}
                      uploadImage={uploadImage}
                      defaultImage={
                        selected.image
                          ? cmsStorageURL + selected.image.file
                          : undefined
                      }
                      disabled={!editable}
                    />
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.name}
                    label={t("player.personal.name")}
                    onChange={(e) => (selected.name = e)}
                    required
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.surname}
                    label={t("player.personal.surname")}
                    onChange={(e) => (selected.surname = e)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.birthday}
                    label={t("player.personal.birthdate")}
                    type="date"
                    onChange={(e) => (selected.birthday = e)}
                    required
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.birth_country}
                    type="country"
                    label={t("player.personal.country")}
                    onChange={(e) => (selected.birth_country = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.dni}
                    label={t("player.personal.dni")}
                    placeholder="XXXXXXXX-X"
                    onChange={(e) => (selected.dni = e)}
                  />
                </td>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.dni_expiration_date}
                    label={t("player.personal.expiration")}
                    type="date"
                    onChange={(e) => (selected.dni_expiration_date = e)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.email}
                    label={t("player.personal.email")}
                    onChange={(e) => (selected.email = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.phone_number}
                    label={t("player.personal.phone")}
                    onChange={(e) => (selected.phone_number = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  {" "}
                  {loggedUser?.role.name === "Admin" && (
                    <DataField
                      data={selected.club_id}
                      type="select"
                      label={t("player.personal.club")}
                      options={getClubNames()}
                      placeholder={t("player.personal.select_club")}
                      onChange={(e) => {
                        selected.club_id = e;
                        refreshTeams();
                      }}
                      required
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6"></td>
                <td className="py-4 px-6"></td>
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable || teamsList?.length === 0}
                    data={selected.team_id}
                    label={t("player.personal.team")}
                    type="select"
                    options={teamsList}
                    placeholder={t("player.personal.select_team")}
                    flexible
                    onChange={(e) => (selected.team_id = e)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="pt-4 pb-8 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-1">
                      <DataField
                        disabled={!editable}
                        data={selected.addres}
                        label={t("player.personal.address")}
                        type="textarea"
                        onChange={(e) => (selected.addres = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        disabled={!editable}
                        data={selected.actual_addres}
                        label={t("player.personal.actual_address")}
                        type="textarea"
                        onChange={(e) => (selected.actual_addres = e)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.instagram}
                    label={t("player.personal.instagram")}
                    placeholder={t("player.personal.instagram_placeholder")}
                    onChange={(e) => (selected.instagram = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.facebook}
                    label={t("player.personal.facebook")}
                    placeholder={t("player.personal.facebook_placeholder")}
                    onChange={(e) => (selected.facebook = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.twitter}
                    label={t("player.personal.twitter")}
                    placeholder={t("player.personal.twitter_placeholder")}
                    onChange={(e) => (selected.twitter = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.web}
                    label={t("player.personal.web")}
                    placeholder={t("player.personal.web_placeholder")}
                    onChange={(e) => (selected.web = e)}
                  />
                </td>
                <td colSpan={2} className="pt-4 pb-8 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.other_socials}
                    label={t("player.personal.other")}
                    placeholder={t("player.personal.other_placeholder")}
                    onChange={(e) => (selected.other_socials = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>

        <CollapsablePanel titulo={t("player.family.title")}>
          <table className="w-full table-fixed my-2">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="py-4 pl-6 pr-1">
                  <DataField
                    disabled={!editable}
                    data={selected.core_composition}
                    label={t("player.family.composition")}
                    type="textarea"
                    onChange={(e) => (selected.core_composition = e)}
                  />
                </td>
                <td colSpan={2} className="py-4 pr-6 pl-1">
                  <DataField
                    disabled={!editable}
                    data={selected.comentary}
                    label={t("player.family.comments")}
                    type="textarea"
                    onChange={(e) => (selected.comentary = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex">
            <div className="w-1/3">
              <PersonDetail
                editable={editable}
                tipo={selected.contact_1_type}
                person={selected.contact_1}
                onUpdate={(e, t) => {
                  selected.contact_1 = e as any;
                  selected.contact_1_type = t as any;
                }}
                hasTipo
              />
            </div>
            <div className="w-1/3">
              <PersonDetail
                editable={editable}
                tipo={selected.contact_2_type}
                person={selected.contact_2}
                onUpdate={(e, t) => {
                  selected.contact_2 = e as any;
                  selected.contact_2_type = t as any;
                }}
                hasTipo
              />
            </div>
            <div className="w-1/3">
              <PersonDetail
                editable={editable}
                tipo={selected.contact_3_type}
                person={selected.contact_3}
                onUpdate={(e, t) => {
                  selected.contact_3 = e as any;
                  selected.contact_3_type = t as any;
                }}
                hasTipo
              />
            </div>
          </div>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.school.title")}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.school}
                    label={t("player.school.school")}
                    onChange={(e) => (selected.school = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4 border-r border-l border-gray-400">
                  <DataField
                    disabled={!editable}
                    data={selected.performance}
                    label={t("player.school.performance")}
                    type="select"
                    flexible
                    placeholder={t("player.school.performance_placeholder")}
                    options={[
                      {
                        value: "Bajo",
                        text: t("player.school.performance_low"),
                      },
                      {
                        value: "Medio",
                        text: t("player.school.performance_medium"),
                      },
                      {
                        value: "Alto",
                        text: t("player.school.performance_high"),
                      },
                      {
                        value: "Máximo",
                        text: t("player.school.performance_max"),
                      },
                    ]}
                    onChange={(e) => (selected.performance = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.school_contact?.name}
                    label={t("player.school.school_contact")}
                    onChange={(e) => (selected.school_contact.name = e)}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.school_address}
                    label={t("player.school.school_address")}
                    type="textarea"
                    onChange={(e) => (selected.school_address = e)}
                  />
                </td>
                <td
                  rowSpan={4}
                  className="w-1/3 px-6 py-4 border-r border-l border-gray-400"
                >
                  {listaIdiomasEscolares.map((i, index) => (
                    <div className="flex justify-between">
                      <div className="w-3/6 pr-1">
                        <DataField
                          disabled={!editable}
                          data={i.value}
                          label={t("player.school.lang")}
                          onChange={(e) => {
                            i.value = e;
                            selected.school_languages = JSON.stringify(
                              listaIdiomasEscolares
                            );
                          }}
                          required
                        />
                      </div>
                      <div className="w-2/6 pl-2">
                        <DataField
                          disabled={!editable}
                          data={i.level}
                          label={t("player.school.level")}
                          flexible
                          placeholder={t("01122021.select_placeholders.level")}
                          type="select"
                          options={[
                            {
                              value: "lvl_low",
                              text: t("player.school.level_low"),
                            },
                            {
                              value: "lvl_medium",
                              text: t("player.school.level_medium"),
                            },
                            {
                              value: "lvl_high",
                              text: t("player.school.level_high"),
                            },
                            {
                              value: "lvl_max",
                              text: t("player.school.level_max"),
                            },
                          ]}
                          onChange={(e) => {
                            i.level = e;
                            selected.school_languages = JSON.stringify(
                              listaIdiomasEscolares
                            );
                          }}
                          required
                        />
                      </div>
                      <div
                        className="pl-1 flex-1 flex cursor-pointer pt-4"
                        onClick={() => {
                          listaIdiomasEscolares.splice(index, 1);
                          setListaIdiomasEscolares(
                            listaIdiomasEscolares.map((idioma) => idioma)
                          );
                          selected.school_languages = JSON.stringify(
                            listaIdiomasEscolares
                          );
                        }}
                      >
                        <p className="self-center ml-auto">
                          <img src={cross_icon} className="h-3 w-3" alt="close" />
                        </p>
                      </div>
                    </div>
                  ))}
                  {editable && (
                    <button
                      className={
                        "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full mt-4"
                      }
                      onClick={() => {
                        listaIdiomasEscolares.push({} as Idioma);
                        setListaIdiomasEscolares(
                          listaIdiomasEscolares.map((i) => i)
                        );
                      }}
                    >
                      <img
                        src={add_icon}
                        alt="add"
                        className="h-6 p-1 text-center m-auto"
                      />
                      <p className="pl-2">{t("player.school.add_lang")}</p>
                    </button>
                  )}
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.school_contact_rol}
                    label={t("player.school.role")}
                    type="select"
                    flexible
                    placeholder={t("player.school.role_placeholder")}
                    options={[
                      {
                        value: "Director/a",
                        text: t("player.school.role_director"),
                      },
                      {
                        value: "Jefe/a de Estudios",
                        text: t("player.school.role_chief"),
                      },
                      {
                        value: "Tutor/a",
                        text: t("player.school.role_tutor"),
                      },
                    ]}
                    onChange={(e) => (selected.school_contact_rol = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.school_contact?.email}
                    label={t("player.school.email")}
                    onChange={(e) => (selected.school_contact.email = e)}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.school_comentary}
                    label={t("player.school.comments")}
                    type="textarea"
                    onChange={(e) => (selected.school_comentary = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.school_contact?.phone_number}
                    label={t("player.school.phone")}
                    onChange={(e) => (selected.school_contact.phone_number = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.job.title")}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.job_type}
                    label={t("player.job.type")}
                    onChange={(e) => (selected.job_type = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <div className="flex justify-between">
                    <div className="pr-1">
                      {t("player.job.driving")}
                      <div
                        className="flex justify-center"
                        onChange={(e: any) =>
                          (selected.driving_license =
                            e.target.value === "si" ? true : false)
                        }
                      >
                        <input
                          className="self-center"
                          type="radio"
                          id="cy"
                          name="carnet"
                          value="si"
                          disabled={!editable}
                          defaultChecked={selected.driving_license === true}
                        />
                        <label className="self-center pr-5 pl-1" htmlFor="cy">
                          {t("player.job.driving_yes")}
                        </label>
                        <input
                          className="self-center pl-5"
                          type="radio"
                          id="cn"
                          name="carnet"
                          value="no"
                          disabled={!editable}
                          defaultChecked={selected.driving_license === false}
                        />
                        <label className="self-center pl-1" htmlFor="cn">
                          {t("player.job.driving_no")}
                        </label>
                      </div>
                    </div>
                    <div className="1/2 pl-1">
                      {t("player.job.own_car")}
                      <div
                        className="flex justify-center"
                        onChange={(e: any) =>
                          (selected.own_car =
                            e.target.value === "si" ? true : false)
                        }
                      >
                        <input
                          className="self-center"
                          type="radio"
                          id="cpy"
                          name="coche"
                          value="si"
                          disabled={!editable}
                          defaultChecked={selected.own_car === true}
                        />
                        <label className="self-center pl-1 pr-5" htmlFor="cpy">
                          {t("player.job.own_car_yes")}
                        </label>
                        <input
                          className="self-center pl-5"
                          type="radio"
                          id="cpn"
                          name="coche"
                          value="no"
                          disabled={!editable}
                          defaultChecked={selected.own_car === false}
                        />
                        <label className="self-center pl-1" htmlFor="cpn">
                          {t("player.job.own_car_no")}
                        </label>
                      </div>
                    </div>
                  </div>
                </td>
                <td rowSpan={3} className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.job_comentary}
                    type="textarea"
                    label={t("player.job.comments")}
                    onChange={(e) => (selected.job_comentary = e)}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.job_site}
                    label={t("player.job.place")}
                    type="textarea"
                    onChange={(e) => (selected.job_site = e)}
                  />
                </td>
                <td rowSpan={2} className="w-1/3 px-6 py-4">
                  {listaIdiomasLaborales.map((i, index) => (
                    <div className="flex justify-between">
                      <div className="w-3/6 pr-1">
                        <DataField
                          disabled={!editable}
                          data={i.value}
                          label={t("player.job.lang")}
                          flexible
                          placeholder={t("01122021.select_placeholders.level")}
                          onChange={(e) => {
                            i.value = e;
                            selected.job_languages = JSON.stringify(
                              listaIdiomasLaborales
                            );
                          }}
                          required
                        />
                      </div>
                      <div className="w-2/6 pl-1">
                        <DataField
                          disabled={!editable}
                          data={i.level}
                          label={t("player.job.level")}
                          type="select"
                          flexible
                          placeholder={t("01122021.select_placeholders.level")}
                          options={[
                            {
                              value: "lvl_low",
                              text: t("player.job.level_low"),
                            },
                            {
                              value: "lvl_medium",
                              text: t("player.job.level_medium"),
                            },
                            {
                              value: "lvl_high",
                              text: t("player.job.level_high"),
                            },
                            {
                              value: "lvl_max",
                              text: t("player.job.level_max"),
                            },
                          ]}
                          onChange={(e) => {
                            i.level = e;
                            selected.job_languages = JSON.stringify(
                              listaIdiomasLaborales
                            );
                          }}
                          required
                        />
                      </div>
                      <div
                        className="pl-1 flex-1 flex cursor-pointer pt-4"
                        onClick={() => {
                          listaIdiomasLaborales.splice(index, 1);
                          setListaIdiomasLaborales(
                            listaIdiomasLaborales.map((idioma) => idioma)
                          );
                          selected.job_languages = JSON.stringify(
                            listaIdiomasLaborales
                          );
                        }}
                      >
                        <p className="self-center ml-auto">
                          <img src={cross_icon} className="h-3 w-3" alt="cross" />
                        </p>
                      </div>
                    </div>
                  ))}
                  {editable && (
                    <button
                      className={
                        "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full mt-4"
                      }
                      onClick={() => {
                        listaIdiomasLaborales.push({} as Idioma);
                        setListaIdiomasLaborales(
                          listaIdiomasLaborales.map((i) => i)
                        );
                      }}
                    >
                      <img
                        src={add_icon}
                        alt="add"
                        className="h-6 p-1 text-center m-auto"
                      />
                      <p>{t("player.job.add_lang")}</p>
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.sport.title")}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.sport_name}
                    label={t("player.sport.name")}
                    onChange={(e) => (selected.sport_name = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <div className="flex">
                    <div className="w-1/2 pr-1">
                      <DataField
                        disabled={!editable}
                        data={selected.leg}
                        label={t("player.sport.leg")}
                        placeholder={t("01122021.select_placeholders.level")}
                        type="select"
                        flexible
                        options={[
                          { value: "Ambidiestro", text: t("player.sport.leg_amb") },
                          { value: "Diestro", text: t("player.sport.leg_di") },
                          { value: "Zurdo", text: t("player.sport.leg_zu") },
                        ]}
                        onChange={(e) => (selected.leg = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        disabled={!editable}
                        data={selected.dorsal}
                        label={t("player.sport.dorsal")}
                        type="number"
                        onChange={(e) => (selected.dorsal = e)}
                      />
                    </div>
                  </div>
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.projection}
                    label={t("player.sport.projection")}
                    type="select"
                    flexible
                    placeholder={t("01122021.select_placeholders.level")}
                    options={[
                      {
                        value: "Máxima",
                        text: t("player.sport.projection_max"),
                      },
                      {
                        value: "Alta",
                        text: t("player.sport.projection_high"),
                      },
                      {
                        value: "Media",
                        text: t("player.sport.projection_medium"),
                      },
                      {
                        value: "Baja",
                        text: t("player.sport.projection_low"),
                      },
                    ]}
                    onChange={(e) => (selected.projection = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.position}
                    label={t("player.sport.position")}
                    placeholder={t("player.sport.position_placeholder")}
                    type="select"
                    flexible
                    options={positions}
                    onChange={(e) => (selected.position = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.extra_position}
                    label={t("player.sport.secondary_positions")}
                    placeholder={t("player.sport.secondary_placeholder")}
                    onChange={(e) => (selected.extra_position = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <div className="flex">
                    <div className="w-1/2 pr-1">
                      <DataField
                        disabled={!editable}
                        data={selected.height}
                        label={t("player.sport.height")}
                        type="number"
                        onChange={(e) => (selected.height = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        disabled={!editable}
                        data={selected.weight}
                        label={t("player.sport.weight")}
                        type="number"
                        onChange={(e) => (selected.weight = e)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4"></td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.inc_date}
                    label={t("player.sport.inc_date")}
                    type="date"
                    onChange={(e) => (selected.inc_date = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.end_contract}
                    label={t("player.sport.end_contract")}
                    type="date"
                    onChange={(e) => (selected.end_contract = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.incl_date}
                    label={t("player.sport.incl_date")}
                    type="date"
                    onChange={(e) => (selected.incl_date = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.excl_date}
                    label={t("player.sport.excl_date")}
                    type="date"
                    onChange={(e) => (selected.excl_date = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.procedence_club}
                    label={t("player.sport.club")}
                    onChange={(e) => (selected.procedence_club = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.club_temps}
                    label={t("player.sport.club_temps")}
                    type="number"
                    onChange={(e) => (selected.club_temps = e)}
                  />
                </td>
                <td className="px-6 py-4"></td>
              </tr>
              <tr>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.international}
                    label={t("player.sport.international")}
                    onChange={(e) => (selected.international = e)}
                  />
                </td>
                <td colSpan={2} className="px-6 py-4 pb-8">
                  <DataField
                    disabled={!editable}
                    data={selected.previous_trainers}
                    label={t("player.sport.previous_trainers")}
                    onChange={(e) => (selected.previous_trainers = e)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.agent?.name}
                    label={t("player.sport.agent")}
                    onChange={(e) => (selected.agent.name = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.agent?.phone_number}
                    label={t("player.sport.phone")}
                    onChange={(e) => (selected.agent.phone_number = e)}
                  />
                </td>
                <td className="px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.agent?.email}
                    label={t("player.sport.email")}
                    onChange={(e) => (selected.agent.email = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.cesion.title")}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.cesion_club}
                    label={t("player.cesion.club")}
                    onChange={(e) => (selected.cesion_club = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.cesion_from}
                    label={t("player.cesion.from")}
                    type="date"
                    onChange={(e) => (selected.cesion_from = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.cesion_to}
                    label={t("player.cesion.to")}
                    type="date"
                    onChange={(e) => (selected.cesion_to = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.cesion_trainer}
                    label={t("player.cesion.trainer")}
                    onChange={(e) => (selected.cesion_trainer = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.cesion_tlf}
                    label={t("player.cesion.phone")}
                    onChange={(e) => (selected.cesion_tlf = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.cesion_email}
                    label={t("player.cesion.email")}
                    onChange={(e) => (selected.cesion_email = e)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td colSpan={2} className="w-1/3 px-6 py-4">
                  <DataField
                    disabled={!editable}
                    data={selected.cesion_comentary}
                    label={t("player.cesion.comments")}
                    type="textarea"
                    onChange={(e) => (selected.cesion_comentary = e)}
                  />
                </td>
                <td className="w-1/3 px-6 py-4"></td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>

        <CollapsablePanel titulo={t("player.stats.title")}>
          <div className="h-20 w-2/3 m-auto bg-gray-700 text-white rounded-lg flex items-center text-center">
            <div className="w-3/12">
              <p>{t("player.stats.minutes")}</p>{" "}
              <p>{estadisticas?.played_mins}</p>
            </div>
            <div className="w-2/12">
              <p>{t("player.stats.goals")}</p> <p>{estadisticas?.goals}</p>
            </div>
            <div className="w-2/12">
              <p>{t("player.stats.yellow")}</p>{" "}
              <p>{estadisticas?.yellow_cards}</p>
            </div>
            <div className="w-2/12">
              <p>{t("player.stats.red")}</p> <p>{estadisticas?.red_cards}</p>
            </div>
            <div className="h-full w-3/12 border-l-2 border-white items-center flex">
              <div className="w-full">
                <p>{t("player.stats.average")}</p> <p>{estadisticas?.score}</p>
              </div>
            </div>
          </div>
          <table className="w-full table-fixed mt-2 m-auto">
            <thead>
              <tr>
                <td className="w-2/12 py-4 px-4">{/*t("player.stats.season")*/}</td>
                <td className="w-3/12 py-4 px-4 bg-sf-darkblue text-white border-r border-gray-200 text-center">{t("player.stats.team")}</td>
                <td className="w-2/12 py-4 px-4 bg-sf-darkblue text-white border-r border-gray-200 text-center">{t("player.stats.played")}</td>
                <td className="w-1/12 py-4 px-4 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                  {t("player.stats.p_goals")}
                </td>
                <td className="w-1/12 py-4 px-4 bg-sf-darkblue text-white border-r border-gray-200 text-center">
                  {t("player.stats.p_yellow")}
                </td>
                <td className="w-1/12 py-4 px-4 bg-sf-darkblue text-white border-r border-gray-200 text-center">{t("player.stats.p_red")}</td>
                <td className="w-2/12 py-4 px-4 bg-sf-darkblue text-white text-center">
                  {t("player.stats.p_average")}
                </td>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: "top" }}>
              {selected.matches?.filter(m => (m as any).match)?.map((m, index) => (
                 <tr className="border-t border-gray-200" key={index}>
                  <td className="w-2/12 py-4 px-6 text-center bg-sf-darkblue text-white border-r border-gray-200">
                    {(m.match as any)?.backup_id === 0 ? t("09022022.tempAct") : t("14052021.player.temp")}
                  </td>
                  <td className={"w-3/12 py-4 px-6 text-center border-r border-gray-200" + (index%2 === 0 ? " bg-gray-400": "")}>
                    {
                      GetTeams()?.find((e) => e.id === m.match?.team_id)
                        ?.team_name + (m.match?.enemy ? " VS " + m.match?.enemy : "")
                    }
                  </td>
                  <td className={"w-2/12 py-4 px-6 text-center border-r border-gray-200" + (index%2 === 0 ? " bg-gray-400": "")}>{m.played_mins ?? 0}</td>
                  <td className={"w-1/12 py-4 px-6 text-center border-r border-gray-200" + (index%2 === 0 ? " bg-gray-400": "")}>{m.goals ?? 0}</td>
                  <td className={"w-1/12 py-4 px-6 text-center border-r border-gray-200" + (index%2 === 0 ? " bg-gray-400": "")}>{m.yellow_cards ?? 0}</td>
                  <td className={"w-1/12 py-4 px-6 text-center border-r border-gray-200" + (index%2 === 0 ? " bg-gray-400": "")}>{m.red_cards ?? 0}</td>
                  <td className={"w-2/12 py-4 px-6 text-center" + (index%2 === 0 ? " bg-gray-400": "")}>{m.score ?? 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.ipis.title")}>
          <table className="w-full table-fixed mt-2 m-auto">
            <thead>
              <tr className="text-center">
                <td className="w-1/5 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">{t("player.ipis.game")}</td>
                <td className="w-1/5 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">{t("player.ipis.body")}</td>
                <td className="w-1/5 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                  {t("player.ipis.environment")}
                </td>
                <td className="w-1/5 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">{t("player.ipis.feelings")}</td>
                <td className="w-1/5 py-4 px-6 bg-sf-darkblue text-white">{t("player.ipis.values")}</td>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/5 py-4 px-6">
                  {editable && (
                    <button
                      className={
                        "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-1"
                      }
                      onClick={() => {
                        ipis.push(
                          Object.assign({
                            type: "Juego",
                            name: "",
                            checked: false,
                          } as Ipi)
                        );
                        setIpis(ipis.map((i) => i));
                        selected.evaluations[0].ipis = ipis;
                      }}
                    >
                      <img
                        src={add_icon}
                        alt="add"
                        className="h-6 p-1 text-center m-auto"
                      />
                      <p>{t("player.ipis.add_body")}</p>
                    </button>
                  )}
                  {/*IPIS Juego*/}

                  {ipis
                    .filter((ipi: Ipi) => ipi.type === "Juego")
                    .map((ipi, index) => (
                      <div className="flex items-center mt-3" key={index}>
                        <input
                          type="checkbox"
                          defaultChecked={ipi.checked}
                          onChange={(e) => (ipi.checked = e.target.checked)}
                          disabled={!editable}
                          className="w-2/12"
                        />
                        <div className="px-2 w-8/12">
                          <DataField
                            disabled={!editable}
                            data={ipi.name}
                            placeholder={t("player.ipis.placeholder_game")}
                            onChange={(e) => (ipi.name = e)}
                            required
                          />
                        </div>
                        {editable && (
                          <button
                            onClick={() => {
                              ipis.splice(ipis.indexOf(ipi), 1);
                              setIpis(ipis.map((i) => i));
                              selected.evaluations[0].ipis = ipis;
                            }}
                            className="w-2/12"
                          >
                            <img
                              src={cross_icon}
                              alt="close"
                              className="h-4 w-4 self-center"
                            />
                          </button>
                        )}
                      </div>
                    ))}
                </td>
                <td className="w-1/5 py-4 px-6">
                  {editable && (
                    <button
                      className={
                        "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-1"
                      }
                      onClick={() => {
                        ipis.push(
                          Object.assign({
                            type: "Cuerpo",
                            name: "",
                            checked: false,
                          } as Ipi)
                        );
                        setIpis(ipis.map((i) => i));
                        selected.evaluations[0].ipis = ipis;
                      }}
                    >
                      <img
                        src={add_icon}
                        alt="add"
                        className="h-6 p-1 text-center m-auto"
                      />
                      <p>{t("player.ipis.add_body")}</p>
                    </button>
                  )}
                  {/*IPIS Juego*/}

                  {ipis
                    .filter((ipi: Ipi) => ipi.type === "Cuerpo")
                    .map((ipi, index) => (
                      <div className="flex items-center mt-3" key={index}>
                        <input
                          type="checkbox"
                          defaultChecked={ipi.checked}
                          onChange={(e) => (ipi.checked = e.target.checked)}
                          disabled={!editable}
                        />
                        <div className="px-2 w-full">
                          <DataField
                            disabled={!editable}
                            data={ipi.name}
                            placeholder={t("player.ipis.placeholder_body")}
                            onChange={(e) => (ipi.name = e)}
                            required
                          />
                        </div>
                        {editable && (
                          <button
                            onClick={() => {
                              ipis.splice(ipis.indexOf(ipi), 1);
                              setIpis(ipis.map((i) => i));
                              selected.evaluations[0].ipis = ipis;
                            }}
                          >
                            X
                          </button>
                        )}
                      </div>
                    ))}
                </td>
                <td className="w-1/5 py-4 px-6">
                  {editable && (
                    <button
                      className={
                        "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-1"
                      }
                      onClick={() => {
                        ipis.push(
                          Object.assign({
                            type: "Entorno",
                            name: "",
                            checked: false,
                          } as Ipi)
                        );
                        setIpis(ipis.map((i) => i));
                        selected.evaluations[0].ipis = ipis;
                      }}
                    >
                      <img
                        src={add_icon}
                        alt="add"
                        className="h-6 p-1 text-center m-auto"
                      />
                      <p>{t("player.ipis.add_environment")}</p>
                    </button>
                  )}
                  {/*IPIS Juego*/}

                  {ipis
                    .filter((ipi: Ipi) => ipi.type === "Entorno")
                    .map((ipi, index) => (
                      <div className="flex items-center mt-3" key={index}>
                        <input
                          type="checkbox"
                          defaultChecked={ipi.checked}
                          onChange={(e) => (ipi.checked = e.target.checked)}
                          disabled={!editable}
                        />
                        <div className="px-2 w-full">
                          <DataField
                            disabled={!editable}
                            data={ipi.name}
                            placeholder={t(
                              "player.ipis.placeholder_environment"
                            )}
                            onChange={(e) => (ipi.name = e)}
                            required
                          />
                        </div>
                        {editable && (
                          <button
                            onClick={() => {
                              ipis.splice(ipis.indexOf(ipi), 1);
                              setIpis(ipis.map((i) => i));
                              selected.evaluations[0].ipis = ipis;
                            }}
                          >
                            X
                          </button>
                        )}
                      </div>
                    ))}
                </td>
                <td className="w-1/5 py-4 px-6">
                  {editable && (
                    <button
                      className={
                        "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-1"
                      }
                      onClick={() => {
                        ipis.push(
                          Object.assign({
                            type: "Emociones",
                            name: "",
                            checked: false,
                          } as Ipi)
                        );
                        setIpis(ipis.map((i) => i));
                        selected.evaluations[0].ipis = ipis;
                      }}
                    >
                      <img
                        src={add_icon}
                        alt="add"
                        className="h-6 p-1 text-center m-auto"
                      />
                      <p>{t("player.ipis.add_feelings")}</p>
                    </button>
                  )}
                  {/*IPIS Juego*/}

                  {ipis
                    .filter((ipi: Ipi) => ipi.type === "Emociones")
                    .map((ipi, index) => (
                      <div className="flex items-center mt-3" key={index}>
                        <input
                          type="checkbox"
                          defaultChecked={ipi.checked}
                          onChange={(e) => (ipi.checked = e.target.checked)}
                          disabled={!editable}
                        />
                        <div className="px-2 w-full">
                          <DataField
                            disabled={!editable}
                            data={ipi.name}
                            placeholder={t("player.ipis.placeholder_feelings")}
                            onChange={(e) => (ipi.name = e)}
                            required
                          />
                        </div>
                        {editable && (
                          <button
                            onClick={() => {
                              ipis.splice(ipis.indexOf(ipi), 1);
                              setIpis(ipis.map((i) => i));
                              selected.evaluations[0].ipis = ipis;
                            }}
                          >
                            X
                          </button>
                        )}
                      </div>
                    ))}
                </td>
                <td className="w-1/5 py-4 px-6">
                  {editable && (
                    <button
                      className={
                        "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-1"
                      }
                      onClick={() => {
                        ipis.push(
                          Object.assign({
                            type: "Valores",
                            name: "",
                            checked: false,
                          } as Ipi)
                        );
                        setIpis(ipis.map((i) => i));
                        selected.evaluations[0].ipis = ipis;
                      }}
                    >
                      <img
                        src={add_icon}
                        alt="add"
                        className="h-6 p-1 text-center m-auto"
                      />
                      <p>{t("player.ipis.add_values")}</p>
                    </button>
                  )}
                  {/*IPIS Juego*/}

                  {ipis
                    .filter((ipi: Ipi) => ipi.type === "Valores")
                    .map((ipi, index) => (
                      <div className="flex items-center mt-3" key={index}>
                        <input
                          type="checkbox"
                          defaultChecked={ipi.checked}
                          onChange={(e) => (ipi.checked = e.target.checked)}
                          disabled={!editable}
                        />
                        <div className="px-2 w-full">
                          <DataField
                            disabled={!editable}
                            data={ipi.name}
                            placeholder={t("player.ipis.placeholder_values")}
                            onChange={(e) => (ipi.name = e)}
                            required
                          />
                        </div>
                        {editable && (
                          <button
                            onClick={() => {
                              ipis.splice(ipis.indexOf(ipi), 1);
                              setIpis(ipis.map((i) => i));
                              selected.evaluations[0].ipis = ipis;
                            }}
                          >
                            X
                          </button>
                        )}
                      </div>
                    ))}
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.injuries.title")}>
          {editable && (
            <button
              className={
                "flex ml-6 bg-sf-mediumblue hover:bg-sf-lightblue py-1 pr-4 pl-2 rounded-full text-white mr-2"
              }
              onClick={() => {
                injuries.push(Object.assign({ backup_id: 0 } as Injurie));
                setInjuries(injuries.map((i) => i));
                selected.evaluations[0].injuries = injuries;
              }}
            >
              <img
                src={add_icon}
                alt="Añadir"
                className="h-6 transform scale-50"
                style={{ filter: "invert()" }}
              />
              <p className="pl-2">{t("player.injuries.add_injurie")}</p>
            </button>
          )}
          {injuries.filter((i) => i.backup_id === 0).length > 0 && (
            <table className="w-full table-fixed mt-2 m-auto border-b-2 border-gray-400">
              <thead>
                <tr>
                  <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                    {t("player.injuries.injury_tipo")}
                  </td>
                  <td className="w-3/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                    {t("player.injuries.injury_treatment")}
                  </td>
                  <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                    {t("player.injuries.injury_date")}
                  </td>
                  <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                    {t("player.injuries.injury_medic")}
                  </td>
                  <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                    {t("player.injuries.injury_sport")}
                  </td>
                  <td className="w-1/12 py-4 px-6"></td>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "top" }}>
                {injuries
                  .filter((i) => i.backup_id === 0)
                  .map((i, index) => (
                    <tr key={index}>
                      <td className="w-2/12 py-4 px-6">
                        <DataField
                          disabled={!editable}
                          data={i.name}
                          placeholder={t("player.injuries.placeholder_injury")}
                          onChange={(e) => (i.name = e)}
                        />
                      </td>
                      <td className="w-3/12 py-4 px-6">
                        <DataField
                          disabled={!editable}
                          data={i.treatment}
                          placeholder={t(
                            "player.injuries.placeholder_evaluation"
                          )}
                          type="textarea"
                          onChange={(e) => (i.treatment = e)}
                        />
                      </td>
                      <td className="w-2/12 py-4 px-6">
                        <DataField
                          disabled={!editable}
                          data={i.lesion_date}
                          placeholder={t("player.injuries.placeholder_date")}
                          type="date"
                          onChange={(e) => (i.lesion_date = e)}
                        />
                      </td>
                      <td className="w-2/12 py-4 px-6">
                        <DataField
                          disabled={!editable}
                          data={i.health_date}
                          placeholder={t("player.injuries.placeholder_health")}
                          type="date"
                          onChange={(e) => (i.health_date = e)}
                        />
                      </td>
                      <td className="w-2/12 py-4 px-6">
                        <DataField
                          disabled={!editable}
                          data={i.sport_date}
                          placeholder={t("player.injuries.placeholder_sport")}
                          type="date"
                          onChange={(e) => (i.sport_date = e)}
                        />
                      </td>
                      <td className="w-1/12 py-4 px-6">
                        <div
                          className="pl-1 flex-1 flex cursor-pointer pt-4"
                          onClick={() => {
                            injuries.splice(injuries.indexOf(i), 1);
                            setInjuries(injuries.map((i) => i));
                          }}
                        >
                          <p className="self-center ml-auto">
                            <img src={cross_icon} className="h-3 w-3" alt="close" />
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          {injuries
                .filter((i) => injurieSeason === null || injurieSeason === undefined || injurieSeason.toString() === "0"  ? i.backup_id !== 0 : i.backup_id.toString() === injurieSeason.toString()).length > 0 && <table>
            <thead>
              <tr className="border-t-2border-gray-200">
                <td colSpan={5} className="py-4 px-6">
                  {t("player.injuries.previous_injuries")}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="w-6/12 py-4 px-6">
                  <div className="w-3/4">
                  <DataField
                    disabled={injuries
                      .filter((i) => i.backup_id !== 0).length === 0}
                    data={injurieSeason}
                      flexible
                    label={t("training.data.season")}
                    type="select"
                    options={injuries
                      .filter((i, index, self) => i.backup_id !== 0 && index === self.findIndex((t) => (
                        t.backup_id === i.backup_id
                      ))).map(s => {return {value: s.backup_id, text: s.backupName}})}
                    placeholder={injuries
                      .filter((i) => i.backup_id !== 0).length === 0 ? t("01122021.season.no_season") : t("01122021.season.all_season")}
                    onChange={(e) => {setInjurieSeason(e); console.log(e)}}
                  />
                  </div>
                </td>
                <td className="w-2/12"></td>
                <td className="w-2/12"></td>
                <td className="w-2/12"></td>
              </tr>
              
                <tr>
                  <td className="w-2/12 py-4 px-6">
                    {/*t("14052021.player.temp")*/}
                  </td>
                  <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                    {t("player.injuries.previous_tipo")}
                  </td>
                  <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                    {t("player.injuries.previous_treatment")}
                  </td>
                  <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                    {t("player.injuries.previous_date")}
                  </td>
                  <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">
                    {t("player.injuries.previous_medic")}
                  </td>
                  <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white">
                    {t("player.injuries.previous_sport")}
                  </td>
                </tr>
              
            </thead>
            <tbody style={{ verticalAlign: "top" }}>
              {injuries
                .filter((i) => injurieSeason === null || injurieSeason === undefined || injurieSeason.toString() === "0"  ? i.backup_id !== 0 : i.backup_id.toString() === injurieSeason.toString())
                .map((i, index) => (
                  <tr key={index}>
                    <td className="w-2/12 py-4 px-6 bg-sf-darkblue text-white border-r border-gray-200">{i.backupName}</td>
                    <td className={"w-2/12 py-4 px-6 border-r border-gray-200" + (index%2 === 0 ? " bg-gray-400": "")}>{i.name}</td>
                    <td className={"w-2/12 py-4 px-6 border-r border-gray-200" + (index%2 === 0 ? " bg-gray-400": "")}>{i.treatment}</td>
                    <td className={"w-2/12 py-4 px-6 border-r border-gray-200" + (index%2 === 0 ? " bg-gray-400": "")}>{i.lesion_date}</td>
                    <td className={"w-2/12 py-4 px-6 border-r border-gray-200" + (index%2 === 0 ? " bg-gray-400": "")}>{i.health_date}</td>
                    <td className={"w-2/12 py-4 px-6" + (index%2 === 0 ? " bg-gray-400": "")}>{i.sport_date}</td>
                  </tr>
                ))}
            </tbody>
          </table>}
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.interview.title")}>
          <table className="w-full table-fixed">
            <tbody>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.fav_position}
                    label={t("player.interview.fav_position")}
                    placeholder={t("player.interview.fav_placeholder")}
                    onChange={(e) => (selected.evaluations[0].fav_position = e)}
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.played_positions}
                    label={t("player.interview.played_positions")}
                    placeholder={t("player.interview.played_placeholder")}
                    onChange={(e) =>
                      (selected.evaluations[0].played_positions = e)
                    }
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.fav_team}
                    label={t("player.interview.fav_team")}
                    placeholder={t("player.interview.fav_team_placeholder")}
                    onChange={(e) => (selected.evaluations[0].fav_team = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.ref_players}
                    label={t("player.interview.ref_players")}
                    placeholder={t("player.interview.ref_players_placeholder")}
                    type="textarea"
                    rows={3}
                    onChange={(e) => (selected.evaluations[0].ref_players = e)}
                  />
                </td>
                <td rowSpan={2} className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.player_def}
                    label={t("player.interview.player_def")}
                    placeholder={t("player.interview.player_def_placeholder")}
                    type="textarea"
                    rows={7}
                    onChange={(e) => (selected.evaluations[0].player_def = e)}
                  />
                </td>
                <td rowSpan={2} className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.person_def}
                    label={t("player.interview.person_def")}
                    placeholder={t("player.interview.person_def_placeholder")}
                    type="textarea"
                    rows={7}
                    onChange={(e) => (selected.evaluations[0].person_def = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.injury_history}
                    label={t("player.interview.injury_history")}
                    placeholder={t(
                      "player.interview.injury_history_placeholder"
                    )}
                    rows={3}
                    type="textarea"
                    onChange={(e) =>
                      (selected.evaluations[0].injury_history = e)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.strengths}
                    label={t("player.interview.strengths")}
                    placeholder={t("player.interview.strengths_placeholder")}
                    type="textarea"
                    rows={3}
                    onChange={(e) => (selected.evaluations[0].strengths = e)}
                  />
                </td>
                <td rowSpan={2} className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.improve}
                    label={t("player.interview.improve")}
                    placeholder={t("player.interview.improve_placeholder")}
                    type="textarea"
                    rows={7}
                    onChange={(e) => (selected.evaluations[0].improve = e)}
                  />
                </td>
                <td rowSpan={2} className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.contribute}
                    label={t("player.interview.contribute")}
                    placeholder={t("player.interview.contribute_placeholder")}
                    type="textarea"
                    rows={7}
                    onChange={(e) => (selected.evaluations[0].contribute = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.prof_obj}
                    label={t("player.interview.prof_obj")}
                    placeholder={t("player.interview.prof_obj_placeholder")}
                    rows={3}
                    type="textarea"
                    onChange={(e) => (selected.evaluations[0].prof_obj = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.season_obj}
                    label={t("player.interview.season_obj")}
                    placeholder={t("player.interview.season_obj_placeholder")}
                    rows={3}
                    type="textarea"
                    onChange={(e) => (selected.evaluations[0].season_obj = e)}
                  />
                </td>
                <td colSpan={2} className="w-2/3 py-4 px-6">
                  <DataField
                    disabled={!editable}
                    data={selected.evaluations[0]?.environment}
                    label={t("player.interview.environment")}
                    placeholder={t("player.interview.environment_placeholder")}
                    rows={3}
                    type="textarea"
                    onChange={(e) => (selected.evaluations[0].environment = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("player.reports.title")}>
          {editable && (
            <div className="flex">
              <DataField
                data={undefined}
                onChange={(v) => setNombreInforme(v)}
                placeholder={t("14052021.player.report_name")}
              />
              <button
                className={
                  "flex ml-6 py-1 pr-4 pl-2 rounded-full text-white mr-2 " + ((nombreInforme === null || nombreInforme === undefined || nombreInforme === "" || selected.position === undefined || selected.position === null || selected.position === "") ? "bg-gray-500 hover:bg-gray-400": "bg-sf-mediumblue hover:bg-sf-lightblue")
                }
                onClick={() => {
                  if(nombreInforme === null || nombreInforme === undefined || nombreInforme === "" || selected.position === undefined || selected.position === null || selected.position === "")return;
                  let nuevoInforme = CreateNewReport(
                    selected.position === "P"
                      ? "portero"
                      : selected.position === "LD" ||
                        selected.position === "LI"
                      ? "lateral"
                      : selected.position === "DFC" ||
                        selected.position === "DFCD" ||
                        selected.position === "DFCI"
                      ? "central"
                      : selected.position === "MCD"
                      ? "mediocentro_def"
                      : selected.position === "MC"
                      ? "mediocentro"
                      : selected.position === "MCO"
                      ? "mediocentro_ofensivo"
                      : selected.position === "MD" ||
                        selected.position === "MI"
                      ? "medio"
                      : selected.position === "ED" ||
                        selected.position === "EI"
                      ? "extremo"
                      : selected.position === "DC"
                      ? "delantero"
                      : "central"
                  ) as any;
                  nuevoInforme.name = nombreInforme;
                  informes.push({
                    data: nuevoInforme,
                    name: nombreInforme,
                  } as Report);
                  
                  setInformes(informes.map((i) => i));

                  saveReports();

                  //selected.evaluations[0].ipis = ipis;
                }}
              >
                <img
                  src={add_icon}
                  alt="Añadir"
                  className="h-6 transform scale-50"
                  style={{ filter: "invert()" }}
                />
                <p className="pl-2">{t("player.reports.new_report")}</p>
              </button>
            </div>
          )}
          <div className="grid grid-cols-2">
            {informes.map((informe, index) => {
              return (
                <div key={index} className="px-12">
                  <div className="py-2">
                    <Radar
                      data={{
                        labels: [
                          t("player.reports.graph_int"),
                          t("player.reports.graph_body"),
                          t("player.reports.graph_injurie"),
                          t("player.reports.graph_motor"),
                          t("player.reports.graph_environment"),
                          t("player.reports.graph_values"),
                          t("player.reports.graph_feelings"),
                          t("player.reports.graph_role_with"),
                          t("player.reports.graph_role_without"),
                          t("player.reports.graph_learn_with"),
                          t("player.reports.graph_learn_without"),
                          t("player.reports.graph_creativity"),
                        ],
                        datasets: [
                          {
                            data: [
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) => r.type === "game|intelligenceAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) =>
                                      r.type ===
                                      "body|somatotipeConstDataAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) => r.type === "body|injuriesAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? ((informe.data.find(
                                    (r) => r.type === "body|strengthAverage"
                                  )?.score || 0) +
                                    (informe.data.find(
                                      (r) => r.type === "body|speedAverage"
                                    )?.score || 0) +
                                    (informe.data.find(
                                      (r) => r.type === "body|staminaAverage"
                                    )?.score || 0) +
                                    (informe.data.find(
                                      (r) =>
                                        r.type === "body|flexibilityAverage"
                                    )?.score || 0) +
                                    (informe.data.find(
                                      (r) => r.type === "body|mobilityAverage"
                                    )?.score || 0) +
                                    (informe.data.find(
                                      (r) =>
                                        r.type === "body|neuromuscularAverage"
                                    )?.score || 0)) /
                                  6
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) => r.type === "environmentAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) => r.type === "valuesAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) => r.type === "feelingsAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) => r.type === "game|rolesWithAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) => r.type === "game|rolesWithoutAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) => r.type === "game|learningWithAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) =>
                                      r.type === "game|learningWithoutAverage"
                                  )?.score
                                : 0,
                              informe.data && informe.data.length > 0
                                ? informe.data.find(
                                    (r) => r.type === "game|creativityAverage"
                                  )?.score
                                : 0,
                            ],
                            backgroundColor: "rgba(0, 150, 255, 0.2)",
                            borderColor: "rgba(0, 100, 255, 0.4)",
                          },
                        ],
                      }}
                      legend={null}
                      width={100}
                      height={100}
                      options={{
                        scale: {
                          ticks: {
                            suggestedMin: 0,
                            suggestedMax: 10,
                          },
                        },
                      }}
                    />
                  </div>
                  <p className="text-center">
                    {informe.name} -{" "}
                    {informe.created_at
                      ? new Date(informe.created_at).toLocaleString()
                      : new Date().toLocaleString()}
                  </p>
                  <button
                    className={
                      "flex bg-white border-2 border-sf-mediumblue py-1 pr-4 pl-2 rounded-full m-auto mt-4"
                    }
                    onClick={() =>
                      setModalContent(
                        reportDetail(
                          informe,
                          informe.name +
                            " - " +
                            (informe.created_at
                              ? new Date(informe.created_at).toLocaleString()
                              : new Date().toLocaleString())
                        ),
                        informe.name +
                          " - " +
                          (informe.created_at
                            ? new Date(informe.created_at).toLocaleString()
                            : new Date().toLocaleString())
                      )
                    }
                  >
                    <img
                      src={add_icon}
                      alt="add"
                      className="h-6 p-1 text-center m-auto"
                    />
                    <p>
                      {editable
                        ? t("player.reports.edit_report")
                        : t("player.reports.view_report")}
                    </p>
                  </button>
                </div>
              );
            })}
          </div>
        </CollapsablePanel>
      </form>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="flex h-full w-full">
        <div className="m-auto flex flex-col">
          <img src={loadingGif} className="w-12 h-12 mx-auto" alt="loading" />
          <p>{t("29082021.loading.generic")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlayerDetail;
