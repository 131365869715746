import React, { useState } from "react";
import { PersonDetail } from "../Person/PersonDetail";
import club_icon from "../../images/icons/clubs-icon.svg";

import "../../styles/fa.css";
import "../../styles/style.css";

import CollapsablePanel from "../CollapsablePanel";
import ImageField from "../ImageField";
import DataField from "../DataField";
import {
  ClubData,
  PlayerData,
  TeamData,
} from "../../CMSUtils/Icms";
import useStore from "../../store";
import { cmsStorageURL } from "../../CMSUtils/FileCalls";
import { useTranslation } from "react-i18next";
import { StartSeason } from "../../CMSUtils/ClubCalls";
import { CMSChangePass } from "../../CMSUtils/LoginCalls";

export const ClubDetail: React.FC<{
  selected: ClubData;

  uploadImage: (image: File | undefined) => void;
  image: File | undefined;
  validForm: (state: boolean) => void;
}> = ({ uploadImage, selected, image, validForm }) => {
  const { t } = useTranslation();
  const setModalContent = useStore((state) => state.setModalContent);
  const token = useStore((state) => state.token);
  const setCambiosSinGuardar = useStore((state) => state.setCambiosSinGuardar);
  const [seasonName, setSeasonName] = useState("");
  //let seasonName: string = "";

  function getTeams() {
    return (
      <div className="flex">
        <table
          className="w-full flex-1 border-2 border-gray-300"
          style={{ minWidth: "800px" }}
        >
          <thead className="bg-blue-900 text-white">
            <tr>
              <td className="w-2/5 py-2 px-6">
                {t("14052021.club.window_sf_name")}
              </td>
              <td className="w-1/5 py-2 px-6">
                {t("14052021.club.window_sf_cat")}
              </td>
              <td className="w-1/5 py-2 px-6">
                {t("14052021.club.window_sf_mod")}
              </td>
              <td className="w-1/5 py-2 px-6">
                {t("14052021.club.window_sf_trainer")}
              </td>
            </tr>
          </thead>
          <tbody style={{ verticalAlign: "top" }}>
            {selected.club_teams.map((t: TeamData, i) => (
              <tr className={i % 2 !== 0 ? "bg-gray-200" : "bg-white"}>
                <td className="w-2/5 py-2 px-6">{t.team_name}</td>
                <td className="w-1/5 py-2 px-6">{t.category}</td>
                <td className="w-1/5 py-2 px-6">{t.modality}</td>
                <td className="w-1/5 py-2 px-6">{t.trainer?.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  function getPlayers() {
    return (
      <div className="flex">
        <table
          className="w-full flex-1 border-2 border-gray-300"
          style={{ minWidth: "800px" }}
        >
          <thead className="bg-blue-900 text-white">
            <tr>
              <td className="w-4/12 py-2 px-6">
                {t("14052021.club.window_pl_name")}
              </td>
              <td className="w-2/12 py-2 px-6">
                {t("14052021.club.window_pl_team")}
              </td>
              <td className="w-2/12 py-2 px-6">
                {t("14052021.club.window_pl_pos")}
              </td>
              <td className="w-3/12 py-2 px-6 text-center">
                {t("14052021.club.window_pl_birth")}
              </td>
              <td className="w-1/12 py-2 px-6">
                {t("14052021.club.window_pl_dni")}
              </td>
            </tr>
          </thead>
          <tbody style={{ verticalAlign: "top" }}>
            {selected.club_players.map((p: PlayerData, i) => (
              <tr className={i % 2 !== 0 ? "bg-gray-200" : "bg-white"}>
                <td className="w-4/12 py-2 px-6">{p.name}</td>
                <td className="w-2/12 py-2 px-6">{p.team?.team_name}</td>
                <td className="w-2/12 py-2 px-6">{p.position}</td>
                <td className="w-3/12 py-2 px-6  text-center">
                  {Intl.DateTimeFormat("es-ES", {
                    year: "numeric",
                    month: "numeric",
                    day: "2-digit",
                  }).format(new Date(p.birthday))}
                </td>
                <td className="w-1/12 py-2 px-6">{p.dni}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  function archivarTemporada() {
    return (
      <React.Fragment>
        <div className="flex flex-col justify-items-center"><p>{t("01122021.season.msg")}</p>
        <button
          className="self-center bg-sf-mediumblue rounded-full text-white px-4 py-2 mt-4"
          onClick={() =>{StartSeason(
            token || "",
            selected.id.toString(),
            seasonName,
            JSON.stringify(selected)
          );
          setModalContent(undefined);}
          }
        >
          {t("01122021.season.btn")}
        </button>
      </div>
      </React.Fragment>
    );
  }

  return <React.Fragment>
      <form
        onSubmit={(e) => e.preventDefault()}
        onChange={(e) => {
          validForm(e.currentTarget.checkValidity());
          setCambiosSinGuardar(true);
        }}
        onLoad={(e) => validForm(e.currentTarget.checkValidity())}
      >
        <CollapsablePanel titulo={t("club.credentials.title")}>
          <table className="table-fixed w-full">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-3/12 py-4 px-6">
                  <DataField
                    data={selected.user?.name ?? selected.userName}
                    label={t("club.credentials.username")}
                    onChange={(e) => (selected.userName = e)}
                    disabled={selected.user ? true : false}
                  />
                </td>
                <td className="w-4/12 py-4 px-6">
                  <DataField
                    data={selected.user?.email ?? selected.userEmail}
                    label={t("club.credentials.email")}
                    onChange={(e) => (selected.userEmail = e)}
                    disabled={selected.user ? true : false}
                  />
                </td>
                
                  <td className="w-2/12 py-4 px-6">
                    <DataField
                      data={""}
                      label={t("club.credentials.password")}
                      type="password"
                      onChange={(e) => (selected.userPass = e)}
                    />
                  </td>
                  <td className="w-2/12 align-middle">{selected.user && <button
                      className="self-center bg-sf-mediumblue rounded-full text-white px-4 mt-4"
                      onClick={() =>CMSChangePass(token, selected.user!.email, "", selected.userPass)
                      }
                    >
                      {t("club.credentials.change_pass")}
                    </button>}
                    </td>
                <td className="w-1/12 py-4 px-6">
                  <div className="flex flex-col">
                    <label className="text-center text-sm text-sf-mediumblue">
                      {t("club.credentials.disabled")}
                    </label>
                    <input
                      className="self-center mx-auto mt-2"
                      type="checkbox"
                      onChange={(e) => (selected.active = !e.target.checked)}
                      defaultChecked={!selected.active}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("club.data.title")} colapsado={false}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td rowSpan={3} className="w-1/3">
                  <div
                    className="flex flex-col m-auto"
                    style={{ width: "14rem" }}
                  >
                    <ImageField
                      emptyImage={club_icon}
                      uploadedImage={image}
                      uploadImage={uploadImage}
                      defaultImage={
                        selected.image
                          ? cmsStorageURL + selected.image.file
                          : undefined
                      }
                    />
                  </div>
                </td>
                <td className="w-1/3 py-4 px-6">
                  {selected.id && (
                    <DataField
                      data={undefined}
                      label={t("14052021.club.temp")}
                      onChange={(e) => setSeasonName(e)}
                    />
                  )}
                </td>
                <td className="w-1/3 py-4 px-6 align-bottom">
                  {selected.id && (
                    <button
                      className="self-center bg-sf-mediumblue rounded-full text-white px-4 py-1"
                      disabled={seasonName === undefined || seasonName === ""}
                      onClick={() =>
                        setModalContent(archivarTemporada())
                      }
                    >
                      {t("14052021.club.new_temp")}
                    </button>
                  )}
                </td>
              </tr>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.entity_name}
                    label={t("club.data.name")}
                    onChange={(e) => (selected.entity_name = e)}
                    required
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.entity_abr}
                    label={t("club.data.abr")}
                    maxLength={2}
                    onChange={(e) => (selected.entity_abr = e)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.entity_country_code}
                    label={t("club.data.country")}
                    type="country"
                    onChange={(e) => (selected.entity_country_code = e)}
                    required
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.entity_city}
                    label={t("club.data.city")}
                    onChange={(e) => (selected.entity_city = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.entity_contract_start}
                    label={t("club.data.contract_start")}
                    type="date"
                    onChange={(e) => {
                      selected.entity_contract_start = e;
                    }}
                    required
                  />
                </td>
                <td className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.entity_contract_end}
                    label={t("club.data.contract_end")}
                    type="date"
                    onChange={(e) => {
                      selected.entity_contract_end = e;
                    }}
                    required
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="py-4 px-6">
                  <DataField
                    data={selected.entity_headquarters}
                    label={t("club.data.headquarters")}
                    placeholder="Nombre de la sede"
                    onChange={(e) => (selected.entity_headquarters = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.entity_sports_city}
                    label={t("club.data.sports_city")}
                    onChange={(e) => (selected.entity_sports_city = e)}
                  />
                </td>
                <td className="py-4 px-6">
                  <DataField
                    data={selected.entity_stadium}
                    label={t("club.data.stadium")}
                    onChange={(e) => (selected.entity_stadium = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-4 pb-8 px-6">
                  <div className="flex">
                    <div className="w-1/3">
                      <DataField
                        data={selected.entity_id}
                        label={t("club.data.id")}
                        placeholder="XXXXXXXX"
                        onChange={(e) => (selected.entity_id = e)}
                      />
                    </div>
                    <div className="w-2/3 pl-2">
                      <DataField
                        data={selected.entity_vat_number}
                        label={t("club.data.vat")}
                        placeholder="XXXXXXXXXX"
                        onChange={(e) => (selected.entity_vat_number = e)}
                      />
                    </div>
                  </div>
                </td>
                <td colSpan={2} className="pt-4 pb-8 px-6">
                  <DataField
                    data={selected.entity_account_number}
                    label={t("club.data.account")}
                    placeholder="XXXX-XXXXXXX-XXXXXX"
                    onChange={(e) => (selected.entity_account_number = e)}
                    required
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td colSpan={3} className="py-4 px-6">
                  <DataField
                    data={selected.entity_web}
                    label={t("club.data.web")}
                    placeholder={t("club.data.web_placeholder")}
                    onChange={(e) => (selected.entity_web = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table-fixed w-full">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/4 py-4 px-6">
                  <DataField
                    data={selected.entity_facebook}
                    label={t("club.data.facebook")}
                    placeholder={t("club.data.facebook_placeholder")}
                    onChange={(e) => (selected.entity_facebook = e)}
                  />
                </td>
                <td className="w-1/4 py-4 px-6">
                  <DataField
                    data={selected.entity_twitter}
                    label={t("club.data.twitter")}
                    placeholder={t("club.data.twitter_placeholder")}
                    onChange={(e) => (selected.entity_twitter = e)}
                  />
                </td>
                <td className="w-1/4 py-4 px-6">
                  <DataField
                    data={selected.entity_instagram}
                    label={t("club.data.instagram")}
                    placeholder={t("club.data.instagram_placeholder")}
                    onChange={(e) => (selected.entity_instagram = e)}
                  />
                </td>
                <td className="w-1/4 py-4 px-6">
                  <DataField
                    data={selected.entity_other}
                    label={t("club.data.other")}
                    placeholder={t("club.data.other_placeholder")}
                    onChange={(e) => (selected.entity_other = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("club.personal.title")}>
          <div className="flex">
            <div className="w-1/3">
              <PersonDetail
                person={selected.entity_contact1}
                onUpdate={(e) => {
                  selected.entity_contact1 = e as any;
                }}
                required
              />
            </div>
            <div className="w-1/3 border-l-2 border-r-2 border-gray-200">
              <PersonDetail
                person={selected.entity_contact2}
                onUpdate={(e) => (selected.entity_contact2 = e as any)}
              />
            </div>
            <div className="w-1/3">
              <PersonDetail
                person={selected.entity_contact3}
                onUpdate={(e) => (selected.entity_contact3 = e as any)}
              />
            </div>
          </div>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("club.sports.title")}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.sport_category}
                    label={t("club.sports.first_team")}
                    onChange={(e) => (selected.sport_category = e)}
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.sport_category}
                    label={t("club.sports.club_category")}
                    type="select"
                    flexible
                    options={[
                      { value: "lv_1", text: t("club.sports.level_1") },
                      { value: "lv_2", text: t("club.sports.level_2") },
                      { value: "lv_3", text: t("club.sports.level_3") },
                      { value: "lv_4", text: t("club.sports.level_4") },
                      { value: "lv_5", text: t("club.sports.level_5") },
                    ]}
                    placeholder={t("club.sports.category_placeholder")}
                    onChange={(e) => (selected.sport_club_category = e)}
                  />
                </td>
                <td className="w-1/3 py-4 px-6 align-baseline pb-8">
                  <DataField
                    data={selected.sport_quality_control}
                    label={t("club.sports.quality")}
                    type="stars"
                    onChange={(i) => (selected.sport_quality_control = i)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="py-4 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-1 pt-2">
                      <DataField
                        data={selected.sports_teams}
                        label={t("club.sports.total_teams")}
                        onChange={(e) => (selected.sports_teams = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-6">
                      <label className="text-sm text-sf-mediumblue">
                        {t("club.sports.sf_teams")}
                      </label>
                      <div className="flex justify-between">
                        <p className="pt-1 px-2 text-gray-600">
                          {selected.club_teams?.length || 0}
                        </p>
                        <button
                          onClick={() =>
                            setModalContent(
                              getTeams(),
                              t("14052021.club.window_sf_title")
                            )
                          }
                          className="flex bg-white hover:bg-gray-200 py-1 pr-4 pl-2 rounded-full text-black border border-1 border-gray-400"
                          disabled={
                            !selected.club_teams ||
                            selected.club_teams.length === 0
                          }
                        >
                          <p className="self-center text-sm">
                            {t("club.sports.inspect_list")}
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td colSpan={2} rowSpan={2} className="w-2/3 py-4 px-6">
                  <DataField
                    data={selected.sport_obs}
                    label={t("club.sports.observations")}
                    type="textarea"
                    onChange={(e) => (selected.sport_obs = e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="py-4 px-6 align-baseline">
                  <div className="flex">
                    <div className="w-1/2 pr-1 pt-2">
                      <DataField
                        data={selected.sports_players}
                        label={t("club.sports.total_players")}
                        onChange={(e) => (selected.sports_players = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-6">
                      <label className="text-sm text-sf-mediumblue">
                        {t("club.sports.sf_players")}
                      </label>
                      <div className="flex justify-between">
                        <p className="pt-1 px-2 text-gray-600">
                          {selected.club_players?.length || 0}
                        </p>
                        <button
                          onClick={() =>
                            setModalContent(
                              getPlayers(),
                              t("14052021.club.window_pl_title")
                            )
                          }
                          className="flex bg-white hover:bg-gray-200 py-1 pr-4 pl-2 rounded-full text-black border border-1 border-gray-400"
                          disabled={
                            !selected.club_players ||
                            selected.club_players.length === 0
                          }
                        >
                          <p className="self-center text-sm">
                            {t("club.sports.inspect_players_list")}
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
        <CollapsablePanel titulo={t("club.other.title")}>
          <table className="w-full table-fixed">
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td className="w-1/3 py-4 px-6">
                  <div className="flex">
                    <div className="w-1/2 pr-1">
                      <DataField
                        data={selected.creation_year}
                        type="number"
                        label={t("club.other.creation")}
                        onChange={(e) => (selected.creation_year = e)}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <DataField
                        data={selected.num_partners}
                        type="number"
                        label={t("club.other.partners")}
                        onChange={(e) => (selected.num_partners = e)}
                      />
                    </div>
                  </div>
                </td>
                <td rowSpan={2} className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.rival_teams}
                    type="textarea"
                    label={t("club.other.rivals")}
                    onChange={(e) => (selected.rival_teams = e)}
                  />
                </td>
                <td className="w-1/3"></td>
              </tr>
              <tr>
                <td className="py-4 px-6 pb-8">
                  <DataField
                    data={selected.fan_name}
                    label={t("club.other.fan_name")}
                    onChange={(e) => (selected.fan_name = e)}
                  />
                </td>
              </tr>
              <tr className="border-t-2 border-gray-200">
                <td className="w-1/3 py-4 px-6 align-baseline">
                  <DataField
                    data={selected.palmares}
                    label={t("club.other.palmares")}
                    type="textarea"
                    onChange={(e) => (selected.palmares = e)}
                  />
                </td>
                <td className="w-1/3 py-4 px-6">
                  <DataField
                    data={selected.historic_players}
                    label={t("club.other.historic")}
                    type="textarea"
                    onChange={(e) => (selected.historic_players = e)}
                  />
                </td>
                <td className="w-1/3 py-4">
                  <DataField
                    data={selected.ref_trainers}
                    label={t("club.other.trainers")}
                    type="textarea"
                    onChange={(e) => (selected.ref_trainers = e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsablePanel>
      </form>
    </React.Fragment>
  
};

export default ClubDetail;
